/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
html, body {
  height: 100%;
  color: rgba(0, 0, 0, 0.87);
  background: white;
  position: relative; }

body {
  margin: 0;
  padding: 0; }

[tabindex='-1']:focus {
  outline: none; }

.inset {
  padding: 10px; }

button.md-no-style {
  font-weight: normal;
  background-color: inherit;
  text-align: left;
  border: none;
  padding: 0;
  margin: 0; }

select,
button,
textarea,
input {
  vertical-align: baseline; }

input[type="reset"],
input[type="submit"],
html input[type="button"],
button {
  cursor: pointer;
  -webkit-appearance: button; }
  input[type="reset"][disabled],
  input[type="submit"][disabled],
  html input[type="button"][disabled],
  button[disabled] {
    cursor: default; }

textarea {
  vertical-align: top;
  overflow: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
  -webkit-box-sizing: content-box; }
  input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none; }

.md-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-transform: none;
  width: 1px; }

.md-shadow {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  pointer-events: none; }

.md-shadow-bottom-z-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }

.md-shadow-bottom-z-2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); }

.md-shadow-animated.md-shadow {
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1); }

/*
 * A container inside of a rippling element (eg a button),
 * which contains all of the individual ripples
 */
.md-ripple-container {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.55s cubic-bezier(0.25, 0.8, 0.25, 1); }

.md-ripple {
  position: absolute;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: 50% 50%;
  opacity: 0;
  border-radius: 50%; }
  .md-ripple.md-ripple-placed {
    transition: margin 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), border 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), height 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), opacity 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.9s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .md-ripple.md-ripple-scaled {
    transform: translate(-50%, -50%) scale(1); }
  .md-ripple.md-ripple-active, .md-ripple.md-ripple-full, .md-ripple.md-ripple-visible {
    opacity: 0.20; }

.md-padding {
  padding: 8px; }

.md-margin {
  margin: 8px; }

.md-scroll-mask {
  position: absolute;
  background-color: transparent;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .md-scroll-mask > .md-scroll-mask-bar {
    display: block;
    position: absolute;
    background-color: #fafafa;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 65;
    box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.3); }

@media (min-width: 960px) {
  .md-padding {
    padding: 16px; } }

html, body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/************
 * Headings
 ************/
.md-display-4 {
  font-size: 112px;
  font-weight: 300;
  letter-spacing: -0.010em;
  line-height: 112px; }

.md-display-3 {
  font-size: 56px;
  font-weight: 400;
  letter-spacing: -0.005em;
  line-height: 56px; }

.md-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 64px; }

.md-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px; }

.md-headline {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px; }

.md-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em; }

.md-subhead {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: 24px; }

/************
 * Body Copy
 ************/
.md-body-1 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: 20px; }

.md-body-2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.010em;
  line-height: 24px; }

.md-caption {
  font-size: 12px;
  letter-spacing: 0.020em; }

.md-button {
  letter-spacing: 0.010em; }

/************
 * Defaults
 ************/
button,
select,
html,
textarea,
input {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

select,
button,
textarea,
input {
  font-size: 100%; }

/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*
*/
@-moz-document url-prefix() {
  .layout-fill {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%; } }

/*
 *  Apply Mixins to create Layout/Flexbox styles
 *
 */
.flex-order {
  order: 0; }

.flex-order--20 {
  order: -20; }

.flex-order--19 {
  order: -19; }

.flex-order--18 {
  order: -18; }

.flex-order--17 {
  order: -17; }

.flex-order--16 {
  order: -16; }

.flex-order--15 {
  order: -15; }

.flex-order--14 {
  order: -14; }

.flex-order--13 {
  order: -13; }

.flex-order--12 {
  order: -12; }

.flex-order--11 {
  order: -11; }

.flex-order--10 {
  order: -10; }

.flex-order--9 {
  order: -9; }

.flex-order--8 {
  order: -8; }

.flex-order--7 {
  order: -7; }

.flex-order--6 {
  order: -6; }

.flex-order--5 {
  order: -5; }

.flex-order--4 {
  order: -4; }

.flex-order--3 {
  order: -3; }

.flex-order--2 {
  order: -2; }

.flex-order--1 {
  order: -1; }

.flex-order-0 {
  order: 0; }

.flex-order-1 {
  order: 1; }

.flex-order-2 {
  order: 2; }

.flex-order-3 {
  order: 3; }

.flex-order-4 {
  order: 4; }

.flex-order-5 {
  order: 5; }

.flex-order-6 {
  order: 6; }

.flex-order-7 {
  order: 7; }

.flex-order-8 {
  order: 8; }

.flex-order-9 {
  order: 9; }

.flex-order-10 {
  order: 10; }

.flex-order-11 {
  order: 11; }

.flex-order-12 {
  order: 12; }

.flex-order-13 {
  order: 13; }

.flex-order-14 {
  order: 14; }

.flex-order-15 {
  order: 15; }

.flex-order-16 {
  order: 16; }

.flex-order-17 {
  order: 17; }

.flex-order-18 {
  order: 18; }

.flex-order-19 {
  order: 19; }

.flex-order-20 {
  order: 20; }

.offset-0, .flex-offset-0 {
  margin-left: 0%; }

.offset-5, .flex-offset-5 {
  margin-left: 5%; }

.offset-10, .flex-offset-10 {
  margin-left: 10%; }

.offset-15, .flex-offset-15 {
  margin-left: 15%; }

.offset-20, .flex-offset-20 {
  margin-left: 20%; }

.offset-25, .flex-offset-25 {
  margin-left: 25%; }

.offset-30, .flex-offset-30 {
  margin-left: 30%; }

.offset-35, .flex-offset-35 {
  margin-left: 35%; }

.offset-40, .flex-offset-40 {
  margin-left: 40%; }

.offset-45, .flex-offset-45 {
  margin-left: 45%; }

.offset-50, .flex-offset-50 {
  margin-left: 50%; }

.offset-55, .flex-offset-55 {
  margin-left: 55%; }

.offset-60, .flex-offset-60 {
  margin-left: 60%; }

.offset-65, .flex-offset-65 {
  margin-left: 65%; }

.offset-70, .flex-offset-70 {
  margin-left: 70%; }

.offset-75, .flex-offset-75 {
  margin-left: 75%; }

.offset-80, .flex-offset-80 {
  margin-left: 80%; }

.offset-85, .flex-offset-85 {
  margin-left: 85%; }

.offset-90, .flex-offset-90 {
  margin-left: 90%; }

.offset-95, .flex-offset-95 {
  margin-left: 95%; }

.offset-33, .flex-offset-33 {
  margin-left: calc(100% / 3); }

.offset-66, .flex-offset-66 {
  margin-left: calc(200% / 3); }

.layout-align,
.layout-align-start-stretch {
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch; }

.layout-align-start,
.layout-align-start-start,
.layout-align-start-center,
.layout-align-start-end,
.layout-align-start-stretch {
  justify-content: flex-start; }

.layout-align-center,
.layout-align-center-start,
.layout-align-center-center,
.layout-align-center-end,
.layout-align-center-stretch {
  justify-content: center; }

.layout-align-end,
.layout-align-end-start,
.layout-align-end-center,
.layout-align-end-end,
.layout-align-end-stretch {
  justify-content: flex-end; }

.layout-align-space-around,
.layout-align-space-around-center,
.layout-align-space-around-start,
.layout-align-space-around-end,
.layout-align-space-around-stretch {
  justify-content: space-around; }

.layout-align-space-between,
.layout-align-space-between-center,
.layout-align-space-between-start,
.layout-align-space-between-end,
.layout-align-space-between-stretch {
  justify-content: space-between; }

.layout-align-start-start,
.layout-align-center-start,
.layout-align-end-start,
.layout-align-space-between-start,
.layout-align-space-around-start {
  align-items: flex-start;
  align-content: flex-start; }

.layout-align-start-center,
.layout-align-center-center,
.layout-align-end-center,
.layout-align-space-between-center,
.layout-align-space-around-center {
  align-items: center;
  align-content: center;
  max-width: 100%; }

.layout-align-start-center > *,
.layout-align-center-center > *,
.layout-align-end-center > *,
.layout-align-space-between-center > *,
.layout-align-space-around-center > * {
  max-width: 100%;
  box-sizing: border-box; }

.layout-align-start-end,
.layout-align-center-end,
.layout-align-end-end,
.layout-align-space-between-end,
.layout-align-space-around-end {
  align-items: flex-end;
  align-content: flex-end; }

.layout-align-start-stretch,
.layout-align-center-stretch,
.layout-align-end-stretch,
.layout-align-space-between-stretch,
.layout-align-space-around-stretch {
  align-items: stretch;
  align-content: stretch; }

.flex {
  flex: 1;
  box-sizing: border-box; }

@media screen\0 {
  .flex {
    flex: 1 1 0%; } }

.flex-grow {
  flex: 1 1 100%;
  box-sizing: border-box; }

.flex-initial {
  flex: 0 1 auto;
  box-sizing: border-box; }

.flex-auto {
  flex: 1 1 auto;
  box-sizing: border-box; }

.flex-none {
  flex: 0 0 auto;
  box-sizing: border-box; }

.flex-noshrink {
  flex: 1 0 auto;
  box-sizing: border-box; }

.flex-nogrow {
  flex: 0 1 auto;
  box-sizing: border-box; }

.flex-0 {
  flex: 1 1 0%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-0,
.layout-row > .flex-0 {
  flex: 1 1 0%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box;
  min-width: 0; }

.layout-column > .flex-0,
.layout-column > .flex-0 {
  flex: 1 1 0%;
  max-width: 100%;
  max-height: 0%;
  box-sizing: border-box;
  min-height: 0; }

.flex-5 {
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-5,
.layout-row > .flex-5 {
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-5,
.layout-column > .flex-5 {
  flex: 1 1 5%;
  max-width: 100%;
  max-height: 5%;
  box-sizing: border-box; }

.flex-10 {
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-10,
.layout-row > .flex-10 {
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-10,
.layout-column > .flex-10 {
  flex: 1 1 10%;
  max-width: 100%;
  max-height: 10%;
  box-sizing: border-box; }

.flex-15 {
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-15,
.layout-row > .flex-15 {
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-15,
.layout-column > .flex-15 {
  flex: 1 1 15%;
  max-width: 100%;
  max-height: 15%;
  box-sizing: border-box; }

.flex-20 {
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-20,
.layout-row > .flex-20 {
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-20,
.layout-column > .flex-20 {
  flex: 1 1 20%;
  max-width: 100%;
  max-height: 20%;
  box-sizing: border-box; }

.flex-25 {
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-25,
.layout-row > .flex-25 {
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-25,
.layout-column > .flex-25 {
  flex: 1 1 25%;
  max-width: 100%;
  max-height: 25%;
  box-sizing: border-box; }

.flex-30 {
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-30,
.layout-row > .flex-30 {
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-30,
.layout-column > .flex-30 {
  flex: 1 1 30%;
  max-width: 100%;
  max-height: 30%;
  box-sizing: border-box; }

.flex-35 {
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-35,
.layout-row > .flex-35 {
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-35,
.layout-column > .flex-35 {
  flex: 1 1 35%;
  max-width: 100%;
  max-height: 35%;
  box-sizing: border-box; }

.flex-40 {
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-40,
.layout-row > .flex-40 {
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-40,
.layout-column > .flex-40 {
  flex: 1 1 40%;
  max-width: 100%;
  max-height: 40%;
  box-sizing: border-box; }

.flex-45 {
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-45,
.layout-row > .flex-45 {
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-45,
.layout-column > .flex-45 {
  flex: 1 1 45%;
  max-width: 100%;
  max-height: 45%;
  box-sizing: border-box; }

.flex-50 {
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-50,
.layout-row > .flex-50 {
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-50,
.layout-column > .flex-50 {
  flex: 1 1 50%;
  max-width: 100%;
  max-height: 50%;
  box-sizing: border-box; }

.flex-55 {
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-55,
.layout-row > .flex-55 {
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-55,
.layout-column > .flex-55 {
  flex: 1 1 55%;
  max-width: 100%;
  max-height: 55%;
  box-sizing: border-box; }

.flex-60 {
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-60,
.layout-row > .flex-60 {
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-60,
.layout-column > .flex-60 {
  flex: 1 1 60%;
  max-width: 100%;
  max-height: 60%;
  box-sizing: border-box; }

.flex-65 {
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-65,
.layout-row > .flex-65 {
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-65,
.layout-column > .flex-65 {
  flex: 1 1 65%;
  max-width: 100%;
  max-height: 65%;
  box-sizing: border-box; }

.flex-70 {
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-70,
.layout-row > .flex-70 {
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-70,
.layout-column > .flex-70 {
  flex: 1 1 70%;
  max-width: 100%;
  max-height: 70%;
  box-sizing: border-box; }

.flex-75 {
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-75,
.layout-row > .flex-75 {
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-75,
.layout-column > .flex-75 {
  flex: 1 1 75%;
  max-width: 100%;
  max-height: 75%;
  box-sizing: border-box; }

.flex-80 {
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-80,
.layout-row > .flex-80 {
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-80,
.layout-column > .flex-80 {
  flex: 1 1 80%;
  max-width: 100%;
  max-height: 80%;
  box-sizing: border-box; }

.flex-85 {
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-85,
.layout-row > .flex-85 {
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-85,
.layout-column > .flex-85 {
  flex: 1 1 85%;
  max-width: 100%;
  max-height: 85%;
  box-sizing: border-box; }

.flex-90 {
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-90,
.layout-row > .flex-90 {
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-90,
.layout-column > .flex-90 {
  flex: 1 1 90%;
  max-width: 100%;
  max-height: 90%;
  box-sizing: border-box; }

.flex-95 {
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-95,
.layout-row > .flex-95 {
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-95,
.layout-column > .flex-95 {
  flex: 1 1 95%;
  max-width: 100%;
  max-height: 95%;
  box-sizing: border-box; }

.flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-100,
.layout-row > .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-100,
.layout-column > .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-33, .layout-row > .flex-33, .layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-66, .layout-row > .flex-66, .layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex, .layout-row > .flex {
  min-width: 0; }

.layout-column > .flex-33, .layout-column > .flex-33, .layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box; }

.layout-column > .flex-66, .layout-column > .flex-66, .layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box; }

.layout-column > .flex, .layout-column > .flex {
  min-height: 0; }

.layout, .layout-column, .layout-row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex; }

.layout-column {
  flex-direction: column; }

.layout-row {
  flex-direction: row; }

.layout-padding-sm > *,
.layout-padding > .flex-sm {
  padding: 4px; }

.layout-padding,
.layout-padding-gt-sm,
.layout-padding-md,
.layout-padding > *,
.layout-padding-gt-sm > *,
.layout-padding-md > *,
.layout-padding > .flex,
.layout-padding > .flex-gt-sm,
.layout-padding > .flex-md {
  padding: 8px; }

.layout-padding-gt-md > *,
.layout-padding-lg > *,
.layout-padding-gt-lg > *,
.layout-padding > .flex-gt-md,
.layout-padding > .flex-lg,
.layout-padding > .flex-lg,
.layout-padding > .flex-gt-lg {
  padding: 16px; }

.layout-margin-sm > *,
.layout-margin > .flex-sm {
  margin: 4px; }

.layout-margin,
.layout-margin-gt-sm,
.layout-margin-md,
.layout-margin > *,
.layout-margin-gt-sm > *,
.layout-margin-md > *,
.layout-margin > .flex,
.layout-margin > .flex-gt-sm,
.layout-margin > .flex-md {
  margin: 8px; }

.layout-margin-gt-md > *,
.layout-margin-lg > *,
.layout-margin-gt-lg > *,
.layout-margin > .flex-gt-md,
.layout-margin > .flex-lg,
.layout-margin > .flex-gt-lg {
  margin: 16px; }

.layout-wrap {
  flex-wrap: wrap; }

.layout-nowrap {
  flex-wrap: nowrap; }

.layout-fill {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%; }

/**
 * `hide-gt-sm show-gt-lg` should hide from 600px to 1200px
 * `show-md hide-gt-sm` should show from 0px to 960px and hide at >960px
 * `hide-gt-md show-gt-sm` should show everywhere (show overrides hide)`
 *
 *  hide means hide everywhere
 *  Sizes:
 *         $layout-breakpoint-xs:     600px !default;
 *         $layout-breakpoint-sm:     960px !default;
 *         $layout-breakpoint-md:     1280px !default;
 *         $layout-breakpoint-lg:     1920px !default;
 */
@media (max-width: 599px) {
  .hide-xs:not(.show-xs):not(.show), .hide:not(.show-xs):not(.show) {
    display: none; }
  .flex-order-xs--20 {
    order: -20; }
  .flex-order-xs--19 {
    order: -19; }
  .flex-order-xs--18 {
    order: -18; }
  .flex-order-xs--17 {
    order: -17; }
  .flex-order-xs--16 {
    order: -16; }
  .flex-order-xs--15 {
    order: -15; }
  .flex-order-xs--14 {
    order: -14; }
  .flex-order-xs--13 {
    order: -13; }
  .flex-order-xs--12 {
    order: -12; }
  .flex-order-xs--11 {
    order: -11; }
  .flex-order-xs--10 {
    order: -10; }
  .flex-order-xs--9 {
    order: -9; }
  .flex-order-xs--8 {
    order: -8; }
  .flex-order-xs--7 {
    order: -7; }
  .flex-order-xs--6 {
    order: -6; }
  .flex-order-xs--5 {
    order: -5; }
  .flex-order-xs--4 {
    order: -4; }
  .flex-order-xs--3 {
    order: -3; }
  .flex-order-xs--2 {
    order: -2; }
  .flex-order-xs--1 {
    order: -1; }
  .flex-order-xs-0 {
    order: 0; }
  .flex-order-xs-1 {
    order: 1; }
  .flex-order-xs-2 {
    order: 2; }
  .flex-order-xs-3 {
    order: 3; }
  .flex-order-xs-4 {
    order: 4; }
  .flex-order-xs-5 {
    order: 5; }
  .flex-order-xs-6 {
    order: 6; }
  .flex-order-xs-7 {
    order: 7; }
  .flex-order-xs-8 {
    order: 8; }
  .flex-order-xs-9 {
    order: 9; }
  .flex-order-xs-10 {
    order: 10; }
  .flex-order-xs-11 {
    order: 11; }
  .flex-order-xs-12 {
    order: 12; }
  .flex-order-xs-13 {
    order: 13; }
  .flex-order-xs-14 {
    order: 14; }
  .flex-order-xs-15 {
    order: 15; }
  .flex-order-xs-16 {
    order: 16; }
  .flex-order-xs-17 {
    order: 17; }
  .flex-order-xs-18 {
    order: 18; }
  .flex-order-xs-19 {
    order: 19; }
  .flex-order-xs-20 {
    order: 20; }
  .offset-xs-0, .flex-offset-xs-0 {
    margin-left: 0%; }
  .offset-xs-5, .flex-offset-xs-5 {
    margin-left: 5%; }
  .offset-xs-10, .flex-offset-xs-10 {
    margin-left: 10%; }
  .offset-xs-15, .flex-offset-xs-15 {
    margin-left: 15%; }
  .offset-xs-20, .flex-offset-xs-20 {
    margin-left: 20%; }
  .offset-xs-25, .flex-offset-xs-25 {
    margin-left: 25%; }
  .offset-xs-30, .flex-offset-xs-30 {
    margin-left: 30%; }
  .offset-xs-35, .flex-offset-xs-35 {
    margin-left: 35%; }
  .offset-xs-40, .flex-offset-xs-40 {
    margin-left: 40%; }
  .offset-xs-45, .flex-offset-xs-45 {
    margin-left: 45%; }
  .offset-xs-50, .flex-offset-xs-50 {
    margin-left: 50%; }
  .offset-xs-55, .flex-offset-xs-55 {
    margin-left: 55%; }
  .offset-xs-60, .flex-offset-xs-60 {
    margin-left: 60%; }
  .offset-xs-65, .flex-offset-xs-65 {
    margin-left: 65%; }
  .offset-xs-70, .flex-offset-xs-70 {
    margin-left: 70%; }
  .offset-xs-75, .flex-offset-xs-75 {
    margin-left: 75%; }
  .offset-xs-80, .flex-offset-xs-80 {
    margin-left: 80%; }
  .offset-xs-85, .flex-offset-xs-85 {
    margin-left: 85%; }
  .offset-xs-90, .flex-offset-xs-90 {
    margin-left: 90%; }
  .offset-xs-95, .flex-offset-xs-95 {
    margin-left: 95%; }
  .offset-xs-33, .flex-offset-xs-33 {
    margin-left: calc(100% / 3); }
  .offset-xs-66, .flex-offset-xs-66 {
    margin-left: calc(200% / 3); }
  .layout-align-xs,
  .layout-align-xs-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-xs-start,
  .layout-align-xs-start-start,
  .layout-align-xs-start-center,
  .layout-align-xs-start-end,
  .layout-align-xs-start-stretch {
    justify-content: flex-start; }
  .layout-align-xs-center,
  .layout-align-xs-center-start,
  .layout-align-xs-center-center,
  .layout-align-xs-center-end,
  .layout-align-xs-center-stretch {
    justify-content: center; }
  .layout-align-xs-end,
  .layout-align-xs-end-start,
  .layout-align-xs-end-center,
  .layout-align-xs-end-end,
  .layout-align-xs-end-stretch {
    justify-content: flex-end; }
  .layout-align-xs-space-around,
  .layout-align-xs-space-around-center,
  .layout-align-xs-space-around-start,
  .layout-align-xs-space-around-end,
  .layout-align-xs-space-around-stretch {
    justify-content: space-around; }
  .layout-align-xs-space-between,
  .layout-align-xs-space-between-center,
  .layout-align-xs-space-between-start,
  .layout-align-xs-space-between-end,
  .layout-align-xs-space-between-stretch {
    justify-content: space-between; }
  .layout-align-xs-start-start,
  .layout-align-xs-center-start,
  .layout-align-xs-end-start,
  .layout-align-xs-space-between-start,
  .layout-align-xs-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-xs-start-center,
  .layout-align-xs-center-center,
  .layout-align-xs-end-center,
  .layout-align-xs-space-between-center,
  .layout-align-xs-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-xs-start-center > *,
  .layout-align-xs-center-center > *,
  .layout-align-xs-end-center > *,
  .layout-align-xs-space-between-center > *,
  .layout-align-xs-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-xs-start-end,
  .layout-align-xs-center-end,
  .layout-align-xs-end-end,
  .layout-align-xs-space-between-end,
  .layout-align-xs-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-xs-start-stretch,
  .layout-align-xs-center-stretch,
  .layout-align-xs-end-stretch,
  .layout-align-xs-space-between-stretch,
  .layout-align-xs-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-xs {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (max-width: 599px) {
  .flex-xs {
    flex: 1 1 0%; } }

@media (max-width: 599px) {
  .flex-xs-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-xs-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xs-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-xs-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-xs-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-xs-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xs-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-0,
  .layout-xs-row > .flex-xs-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-xs-0,
  .layout-xs-column > .flex-xs-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-xs-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-5,
  .layout-xs-row > .flex-xs-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-5,
  .layout-xs-column > .flex-xs-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-xs-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-10,
  .layout-xs-row > .flex-xs-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-10,
  .layout-xs-column > .flex-xs-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-xs-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-15,
  .layout-xs-row > .flex-xs-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-15,
  .layout-xs-column > .flex-xs-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-xs-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-20,
  .layout-xs-row > .flex-xs-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-20,
  .layout-xs-column > .flex-xs-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-xs-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-25,
  .layout-xs-row > .flex-xs-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-25,
  .layout-xs-column > .flex-xs-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-xs-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-30,
  .layout-xs-row > .flex-xs-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-30,
  .layout-xs-column > .flex-xs-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-xs-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-35,
  .layout-xs-row > .flex-xs-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-35,
  .layout-xs-column > .flex-xs-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-xs-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-40,
  .layout-xs-row > .flex-xs-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-40,
  .layout-xs-column > .flex-xs-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-xs-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-45,
  .layout-xs-row > .flex-xs-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-45,
  .layout-xs-column > .flex-xs-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-xs-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-50,
  .layout-xs-row > .flex-xs-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-50,
  .layout-xs-column > .flex-xs-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-xs-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-55,
  .layout-xs-row > .flex-xs-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-55,
  .layout-xs-column > .flex-xs-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-xs-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-60,
  .layout-xs-row > .flex-xs-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-60,
  .layout-xs-column > .flex-xs-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-xs-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-65,
  .layout-xs-row > .flex-xs-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-65,
  .layout-xs-column > .flex-xs-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-xs-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-70,
  .layout-xs-row > .flex-xs-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-70,
  .layout-xs-column > .flex-xs-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-xs-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-75,
  .layout-xs-row > .flex-xs-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-75,
  .layout-xs-column > .flex-xs-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-xs-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-80,
  .layout-xs-row > .flex-xs-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-80,
  .layout-xs-column > .flex-xs-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-xs-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-85,
  .layout-xs-row > .flex-xs-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-85,
  .layout-xs-column > .flex-xs-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-xs-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-90,
  .layout-xs-row > .flex-xs-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-90,
  .layout-xs-column > .flex-xs-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-xs-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-95,
  .layout-xs-row > .flex-xs-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-95,
  .layout-xs-column > .flex-xs-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-100,
  .layout-xs-row > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-100,
  .layout-xs-column > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33, .layout-xs-row > .flex-xs-33, .layout-xs-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66, .layout-xs-row > .flex-xs-66, .layout-xs-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex, .layout-xs-row > .flex {
    min-width: 0; }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33, .layout-xs-column > .flex-xs-33, .layout-xs-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66, .layout-xs-column > .flex-xs-66, .layout-xs-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-column > .flex, .layout-xs-column > .flex {
    min-height: 0; }
  .layout-xs, .layout-xs-column, .layout-xs-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-xs-column {
    flex-direction: column; }
  .layout-xs-row {
    flex-direction: row; } }

@media (min-width: 600px) {
  .flex-order-gt-xs--20 {
    order: -20; }
  .flex-order-gt-xs--19 {
    order: -19; }
  .flex-order-gt-xs--18 {
    order: -18; }
  .flex-order-gt-xs--17 {
    order: -17; }
  .flex-order-gt-xs--16 {
    order: -16; }
  .flex-order-gt-xs--15 {
    order: -15; }
  .flex-order-gt-xs--14 {
    order: -14; }
  .flex-order-gt-xs--13 {
    order: -13; }
  .flex-order-gt-xs--12 {
    order: -12; }
  .flex-order-gt-xs--11 {
    order: -11; }
  .flex-order-gt-xs--10 {
    order: -10; }
  .flex-order-gt-xs--9 {
    order: -9; }
  .flex-order-gt-xs--8 {
    order: -8; }
  .flex-order-gt-xs--7 {
    order: -7; }
  .flex-order-gt-xs--6 {
    order: -6; }
  .flex-order-gt-xs--5 {
    order: -5; }
  .flex-order-gt-xs--4 {
    order: -4; }
  .flex-order-gt-xs--3 {
    order: -3; }
  .flex-order-gt-xs--2 {
    order: -2; }
  .flex-order-gt-xs--1 {
    order: -1; }
  .flex-order-gt-xs-0 {
    order: 0; }
  .flex-order-gt-xs-1 {
    order: 1; }
  .flex-order-gt-xs-2 {
    order: 2; }
  .flex-order-gt-xs-3 {
    order: 3; }
  .flex-order-gt-xs-4 {
    order: 4; }
  .flex-order-gt-xs-5 {
    order: 5; }
  .flex-order-gt-xs-6 {
    order: 6; }
  .flex-order-gt-xs-7 {
    order: 7; }
  .flex-order-gt-xs-8 {
    order: 8; }
  .flex-order-gt-xs-9 {
    order: 9; }
  .flex-order-gt-xs-10 {
    order: 10; }
  .flex-order-gt-xs-11 {
    order: 11; }
  .flex-order-gt-xs-12 {
    order: 12; }
  .flex-order-gt-xs-13 {
    order: 13; }
  .flex-order-gt-xs-14 {
    order: 14; }
  .flex-order-gt-xs-15 {
    order: 15; }
  .flex-order-gt-xs-16 {
    order: 16; }
  .flex-order-gt-xs-17 {
    order: 17; }
  .flex-order-gt-xs-18 {
    order: 18; }
  .flex-order-gt-xs-19 {
    order: 19; }
  .flex-order-gt-xs-20 {
    order: 20; }
  .offset-gt-xs-0, .flex-offset-gt-xs-0 {
    margin-left: 0%; }
  .offset-gt-xs-5, .flex-offset-gt-xs-5 {
    margin-left: 5%; }
  .offset-gt-xs-10, .flex-offset-gt-xs-10 {
    margin-left: 10%; }
  .offset-gt-xs-15, .flex-offset-gt-xs-15 {
    margin-left: 15%; }
  .offset-gt-xs-20, .flex-offset-gt-xs-20 {
    margin-left: 20%; }
  .offset-gt-xs-25, .flex-offset-gt-xs-25 {
    margin-left: 25%; }
  .offset-gt-xs-30, .flex-offset-gt-xs-30 {
    margin-left: 30%; }
  .offset-gt-xs-35, .flex-offset-gt-xs-35 {
    margin-left: 35%; }
  .offset-gt-xs-40, .flex-offset-gt-xs-40 {
    margin-left: 40%; }
  .offset-gt-xs-45, .flex-offset-gt-xs-45 {
    margin-left: 45%; }
  .offset-gt-xs-50, .flex-offset-gt-xs-50 {
    margin-left: 50%; }
  .offset-gt-xs-55, .flex-offset-gt-xs-55 {
    margin-left: 55%; }
  .offset-gt-xs-60, .flex-offset-gt-xs-60 {
    margin-left: 60%; }
  .offset-gt-xs-65, .flex-offset-gt-xs-65 {
    margin-left: 65%; }
  .offset-gt-xs-70, .flex-offset-gt-xs-70 {
    margin-left: 70%; }
  .offset-gt-xs-75, .flex-offset-gt-xs-75 {
    margin-left: 75%; }
  .offset-gt-xs-80, .flex-offset-gt-xs-80 {
    margin-left: 80%; }
  .offset-gt-xs-85, .flex-offset-gt-xs-85 {
    margin-left: 85%; }
  .offset-gt-xs-90, .flex-offset-gt-xs-90 {
    margin-left: 90%; }
  .offset-gt-xs-95, .flex-offset-gt-xs-95 {
    margin-left: 95%; }
  .offset-gt-xs-33, .flex-offset-gt-xs-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-xs-66, .flex-offset-gt-xs-66 {
    margin-left: calc(200% / 3); }
  .layout-align-gt-xs,
  .layout-align-gt-xs-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-xs-start,
  .layout-align-gt-xs-start-start,
  .layout-align-gt-xs-start-center,
  .layout-align-gt-xs-start-end,
  .layout-align-gt-xs-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-xs-center,
  .layout-align-gt-xs-center-start,
  .layout-align-gt-xs-center-center,
  .layout-align-gt-xs-center-end,
  .layout-align-gt-xs-center-stretch {
    justify-content: center; }
  .layout-align-gt-xs-end,
  .layout-align-gt-xs-end-start,
  .layout-align-gt-xs-end-center,
  .layout-align-gt-xs-end-end,
  .layout-align-gt-xs-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-xs-space-around,
  .layout-align-gt-xs-space-around-center,
  .layout-align-gt-xs-space-around-start,
  .layout-align-gt-xs-space-around-end,
  .layout-align-gt-xs-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-xs-space-between,
  .layout-align-gt-xs-space-between-center,
  .layout-align-gt-xs-space-between-start,
  .layout-align-gt-xs-space-between-end,
  .layout-align-gt-xs-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-xs-start-start,
  .layout-align-gt-xs-center-start,
  .layout-align-gt-xs-end-start,
  .layout-align-gt-xs-space-between-start,
  .layout-align-gt-xs-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-xs-start-center,
  .layout-align-gt-xs-center-center,
  .layout-align-gt-xs-end-center,
  .layout-align-gt-xs-space-between-center,
  .layout-align-gt-xs-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-xs-start-center > *,
  .layout-align-gt-xs-center-center > *,
  .layout-align-gt-xs-end-center > *,
  .layout-align-gt-xs-space-between-center > *,
  .layout-align-gt-xs-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-xs-start-end,
  .layout-align-gt-xs-center-end,
  .layout-align-gt-xs-end-end,
  .layout-align-gt-xs-space-between-end,
  .layout-align-gt-xs-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-xs-start-stretch,
  .layout-align-gt-xs-center-stretch,
  .layout-align-gt-xs-end-stretch,
  .layout-align-gt-xs-space-between-stretch,
  .layout-align-gt-xs-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-xs {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 600px) {
  .flex-gt-xs {
    flex: 1 1 0%; } }

@media (min-width: 600px) {
  .flex-gt-xs-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-xs-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-xs-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-xs-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-xs-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-xs-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-xs-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-0,
  .layout-gt-xs-row > .flex-gt-xs-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-xs-0,
  .layout-gt-xs-column > .flex-gt-xs-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-xs-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-5,
  .layout-gt-xs-row > .flex-gt-xs-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-5,
  .layout-gt-xs-column > .flex-gt-xs-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-xs-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-10,
  .layout-gt-xs-row > .flex-gt-xs-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-10,
  .layout-gt-xs-column > .flex-gt-xs-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-xs-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-15,
  .layout-gt-xs-row > .flex-gt-xs-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-15,
  .layout-gt-xs-column > .flex-gt-xs-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-xs-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-20,
  .layout-gt-xs-row > .flex-gt-xs-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-20,
  .layout-gt-xs-column > .flex-gt-xs-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-xs-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-25,
  .layout-gt-xs-row > .flex-gt-xs-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-25,
  .layout-gt-xs-column > .flex-gt-xs-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-xs-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-30,
  .layout-gt-xs-row > .flex-gt-xs-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-30,
  .layout-gt-xs-column > .flex-gt-xs-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-xs-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-35,
  .layout-gt-xs-row > .flex-gt-xs-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-35,
  .layout-gt-xs-column > .flex-gt-xs-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-xs-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-40,
  .layout-gt-xs-row > .flex-gt-xs-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-40,
  .layout-gt-xs-column > .flex-gt-xs-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-xs-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-45,
  .layout-gt-xs-row > .flex-gt-xs-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-45,
  .layout-gt-xs-column > .flex-gt-xs-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-xs-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-50,
  .layout-gt-xs-row > .flex-gt-xs-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-50,
  .layout-gt-xs-column > .flex-gt-xs-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-xs-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-55,
  .layout-gt-xs-row > .flex-gt-xs-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-55,
  .layout-gt-xs-column > .flex-gt-xs-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-xs-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-60,
  .layout-gt-xs-row > .flex-gt-xs-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-60,
  .layout-gt-xs-column > .flex-gt-xs-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-xs-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-65,
  .layout-gt-xs-row > .flex-gt-xs-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-65,
  .layout-gt-xs-column > .flex-gt-xs-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-xs-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-70,
  .layout-gt-xs-row > .flex-gt-xs-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-70,
  .layout-gt-xs-column > .flex-gt-xs-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-xs-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-75,
  .layout-gt-xs-row > .flex-gt-xs-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-75,
  .layout-gt-xs-column > .flex-gt-xs-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-xs-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-80,
  .layout-gt-xs-row > .flex-gt-xs-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-80,
  .layout-gt-xs-column > .flex-gt-xs-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-xs-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-85,
  .layout-gt-xs-row > .flex-gt-xs-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-85,
  .layout-gt-xs-column > .flex-gt-xs-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-xs-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-90,
  .layout-gt-xs-row > .flex-gt-xs-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-90,
  .layout-gt-xs-column > .flex-gt-xs-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-xs-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-95,
  .layout-gt-xs-row > .flex-gt-xs-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-95,
  .layout-gt-xs-column > .flex-gt-xs-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-100,
  .layout-gt-xs-row > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-100,
  .layout-gt-xs-column > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33, .layout-gt-xs-row > .flex-gt-xs-33, .layout-gt-xs-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66, .layout-gt-xs-row > .flex-gt-xs-66, .layout-gt-xs-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex, .layout-gt-xs-row > .flex {
    min-width: 0; }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33, .layout-gt-xs-column > .flex-gt-xs-33, .layout-gt-xs-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66, .layout-gt-xs-column > .flex-gt-xs-66, .layout-gt-xs-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-column > .flex, .layout-gt-xs-column > .flex {
    min-height: 0; }
  .layout-gt-xs, .layout-gt-xs-column, .layout-gt-xs-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-xs-column {
    flex-direction: column; }
  .layout-gt-xs-row {
    flex-direction: row; } }

@media (min-width: 600px) and (max-width: 959px) {
  .hide:not(.show-gt-xs):not(.show-sm):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-sm):not(.show) {
    display: none; }
  .hide-sm:not(.show-gt-xs):not(.show-sm):not(.show) {
    display: none; }
  .flex-order-sm--20 {
    order: -20; }
  .flex-order-sm--19 {
    order: -19; }
  .flex-order-sm--18 {
    order: -18; }
  .flex-order-sm--17 {
    order: -17; }
  .flex-order-sm--16 {
    order: -16; }
  .flex-order-sm--15 {
    order: -15; }
  .flex-order-sm--14 {
    order: -14; }
  .flex-order-sm--13 {
    order: -13; }
  .flex-order-sm--12 {
    order: -12; }
  .flex-order-sm--11 {
    order: -11; }
  .flex-order-sm--10 {
    order: -10; }
  .flex-order-sm--9 {
    order: -9; }
  .flex-order-sm--8 {
    order: -8; }
  .flex-order-sm--7 {
    order: -7; }
  .flex-order-sm--6 {
    order: -6; }
  .flex-order-sm--5 {
    order: -5; }
  .flex-order-sm--4 {
    order: -4; }
  .flex-order-sm--3 {
    order: -3; }
  .flex-order-sm--2 {
    order: -2; }
  .flex-order-sm--1 {
    order: -1; }
  .flex-order-sm-0 {
    order: 0; }
  .flex-order-sm-1 {
    order: 1; }
  .flex-order-sm-2 {
    order: 2; }
  .flex-order-sm-3 {
    order: 3; }
  .flex-order-sm-4 {
    order: 4; }
  .flex-order-sm-5 {
    order: 5; }
  .flex-order-sm-6 {
    order: 6; }
  .flex-order-sm-7 {
    order: 7; }
  .flex-order-sm-8 {
    order: 8; }
  .flex-order-sm-9 {
    order: 9; }
  .flex-order-sm-10 {
    order: 10; }
  .flex-order-sm-11 {
    order: 11; }
  .flex-order-sm-12 {
    order: 12; }
  .flex-order-sm-13 {
    order: 13; }
  .flex-order-sm-14 {
    order: 14; }
  .flex-order-sm-15 {
    order: 15; }
  .flex-order-sm-16 {
    order: 16; }
  .flex-order-sm-17 {
    order: 17; }
  .flex-order-sm-18 {
    order: 18; }
  .flex-order-sm-19 {
    order: 19; }
  .flex-order-sm-20 {
    order: 20; }
  .offset-sm-0, .flex-offset-sm-0 {
    margin-left: 0%; }
  .offset-sm-5, .flex-offset-sm-5 {
    margin-left: 5%; }
  .offset-sm-10, .flex-offset-sm-10 {
    margin-left: 10%; }
  .offset-sm-15, .flex-offset-sm-15 {
    margin-left: 15%; }
  .offset-sm-20, .flex-offset-sm-20 {
    margin-left: 20%; }
  .offset-sm-25, .flex-offset-sm-25 {
    margin-left: 25%; }
  .offset-sm-30, .flex-offset-sm-30 {
    margin-left: 30%; }
  .offset-sm-35, .flex-offset-sm-35 {
    margin-left: 35%; }
  .offset-sm-40, .flex-offset-sm-40 {
    margin-left: 40%; }
  .offset-sm-45, .flex-offset-sm-45 {
    margin-left: 45%; }
  .offset-sm-50, .flex-offset-sm-50 {
    margin-left: 50%; }
  .offset-sm-55, .flex-offset-sm-55 {
    margin-left: 55%; }
  .offset-sm-60, .flex-offset-sm-60 {
    margin-left: 60%; }
  .offset-sm-65, .flex-offset-sm-65 {
    margin-left: 65%; }
  .offset-sm-70, .flex-offset-sm-70 {
    margin-left: 70%; }
  .offset-sm-75, .flex-offset-sm-75 {
    margin-left: 75%; }
  .offset-sm-80, .flex-offset-sm-80 {
    margin-left: 80%; }
  .offset-sm-85, .flex-offset-sm-85 {
    margin-left: 85%; }
  .offset-sm-90, .flex-offset-sm-90 {
    margin-left: 90%; }
  .offset-sm-95, .flex-offset-sm-95 {
    margin-left: 95%; }
  .offset-sm-33, .flex-offset-sm-33 {
    margin-left: calc(100% / 3); }
  .offset-sm-66, .flex-offset-sm-66 {
    margin-left: calc(200% / 3); }
  .layout-align-sm,
  .layout-align-sm-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-sm-start,
  .layout-align-sm-start-start,
  .layout-align-sm-start-center,
  .layout-align-sm-start-end,
  .layout-align-sm-start-stretch {
    justify-content: flex-start; }
  .layout-align-sm-center,
  .layout-align-sm-center-start,
  .layout-align-sm-center-center,
  .layout-align-sm-center-end,
  .layout-align-sm-center-stretch {
    justify-content: center; }
  .layout-align-sm-end,
  .layout-align-sm-end-start,
  .layout-align-sm-end-center,
  .layout-align-sm-end-end,
  .layout-align-sm-end-stretch {
    justify-content: flex-end; }
  .layout-align-sm-space-around,
  .layout-align-sm-space-around-center,
  .layout-align-sm-space-around-start,
  .layout-align-sm-space-around-end,
  .layout-align-sm-space-around-stretch {
    justify-content: space-around; }
  .layout-align-sm-space-between,
  .layout-align-sm-space-between-center,
  .layout-align-sm-space-between-start,
  .layout-align-sm-space-between-end,
  .layout-align-sm-space-between-stretch {
    justify-content: space-between; }
  .layout-align-sm-start-start,
  .layout-align-sm-center-start,
  .layout-align-sm-end-start,
  .layout-align-sm-space-between-start,
  .layout-align-sm-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-sm-start-center,
  .layout-align-sm-center-center,
  .layout-align-sm-end-center,
  .layout-align-sm-space-between-center,
  .layout-align-sm-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-sm-start-center > *,
  .layout-align-sm-center-center > *,
  .layout-align-sm-end-center > *,
  .layout-align-sm-space-between-center > *,
  .layout-align-sm-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-sm-start-end,
  .layout-align-sm-center-end,
  .layout-align-sm-end-end,
  .layout-align-sm-space-between-end,
  .layout-align-sm-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-sm-start-stretch,
  .layout-align-sm-center-stretch,
  .layout-align-sm-end-stretch,
  .layout-align-sm-space-between-stretch,
  .layout-align-sm-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-sm {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 600px) and (max-width: 959px) {
  .flex-sm {
    flex: 1 1 0%; } }

@media (min-width: 600px) and (max-width: 959px) {
  .flex-sm-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-sm-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-sm-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-sm-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-sm-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-sm-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-sm-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-0,
  .layout-sm-row > .flex-sm-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-sm-0,
  .layout-sm-column > .flex-sm-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-sm-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-5,
  .layout-sm-row > .flex-sm-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-5,
  .layout-sm-column > .flex-sm-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-sm-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-10,
  .layout-sm-row > .flex-sm-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-10,
  .layout-sm-column > .flex-sm-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-sm-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-15,
  .layout-sm-row > .flex-sm-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-15,
  .layout-sm-column > .flex-sm-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-sm-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-20,
  .layout-sm-row > .flex-sm-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-20,
  .layout-sm-column > .flex-sm-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-sm-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-25,
  .layout-sm-row > .flex-sm-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-25,
  .layout-sm-column > .flex-sm-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-sm-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-30,
  .layout-sm-row > .flex-sm-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-30,
  .layout-sm-column > .flex-sm-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-sm-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-35,
  .layout-sm-row > .flex-sm-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-35,
  .layout-sm-column > .flex-sm-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-sm-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-40,
  .layout-sm-row > .flex-sm-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-40,
  .layout-sm-column > .flex-sm-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-sm-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-45,
  .layout-sm-row > .flex-sm-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-45,
  .layout-sm-column > .flex-sm-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-sm-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-50,
  .layout-sm-row > .flex-sm-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-50,
  .layout-sm-column > .flex-sm-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-sm-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-55,
  .layout-sm-row > .flex-sm-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-55,
  .layout-sm-column > .flex-sm-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-sm-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-60,
  .layout-sm-row > .flex-sm-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-60,
  .layout-sm-column > .flex-sm-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-sm-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-65,
  .layout-sm-row > .flex-sm-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-65,
  .layout-sm-column > .flex-sm-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-sm-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-70,
  .layout-sm-row > .flex-sm-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-70,
  .layout-sm-column > .flex-sm-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-sm-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-75,
  .layout-sm-row > .flex-sm-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-75,
  .layout-sm-column > .flex-sm-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-sm-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-80,
  .layout-sm-row > .flex-sm-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-80,
  .layout-sm-column > .flex-sm-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-sm-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-85,
  .layout-sm-row > .flex-sm-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-85,
  .layout-sm-column > .flex-sm-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-sm-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-90,
  .layout-sm-row > .flex-sm-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-90,
  .layout-sm-column > .flex-sm-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-sm-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-95,
  .layout-sm-row > .flex-sm-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-95,
  .layout-sm-column > .flex-sm-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-100,
  .layout-sm-row > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-100,
  .layout-sm-column > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33, .layout-sm-row > .flex-sm-33, .layout-sm-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66, .layout-sm-row > .flex-sm-66, .layout-sm-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex, .layout-sm-row > .flex {
    min-width: 0; }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33, .layout-sm-column > .flex-sm-33, .layout-sm-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66, .layout-sm-column > .flex-sm-66, .layout-sm-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-column > .flex, .layout-sm-column > .flex {
    min-height: 0; }
  .layout-sm, .layout-sm-column, .layout-sm-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-sm-column {
    flex-direction: column; }
  .layout-sm-row {
    flex-direction: row; } }

@media (min-width: 960px) {
  .flex-order-gt-sm--20 {
    order: -20; }
  .flex-order-gt-sm--19 {
    order: -19; }
  .flex-order-gt-sm--18 {
    order: -18; }
  .flex-order-gt-sm--17 {
    order: -17; }
  .flex-order-gt-sm--16 {
    order: -16; }
  .flex-order-gt-sm--15 {
    order: -15; }
  .flex-order-gt-sm--14 {
    order: -14; }
  .flex-order-gt-sm--13 {
    order: -13; }
  .flex-order-gt-sm--12 {
    order: -12; }
  .flex-order-gt-sm--11 {
    order: -11; }
  .flex-order-gt-sm--10 {
    order: -10; }
  .flex-order-gt-sm--9 {
    order: -9; }
  .flex-order-gt-sm--8 {
    order: -8; }
  .flex-order-gt-sm--7 {
    order: -7; }
  .flex-order-gt-sm--6 {
    order: -6; }
  .flex-order-gt-sm--5 {
    order: -5; }
  .flex-order-gt-sm--4 {
    order: -4; }
  .flex-order-gt-sm--3 {
    order: -3; }
  .flex-order-gt-sm--2 {
    order: -2; }
  .flex-order-gt-sm--1 {
    order: -1; }
  .flex-order-gt-sm-0 {
    order: 0; }
  .flex-order-gt-sm-1 {
    order: 1; }
  .flex-order-gt-sm-2 {
    order: 2; }
  .flex-order-gt-sm-3 {
    order: 3; }
  .flex-order-gt-sm-4 {
    order: 4; }
  .flex-order-gt-sm-5 {
    order: 5; }
  .flex-order-gt-sm-6 {
    order: 6; }
  .flex-order-gt-sm-7 {
    order: 7; }
  .flex-order-gt-sm-8 {
    order: 8; }
  .flex-order-gt-sm-9 {
    order: 9; }
  .flex-order-gt-sm-10 {
    order: 10; }
  .flex-order-gt-sm-11 {
    order: 11; }
  .flex-order-gt-sm-12 {
    order: 12; }
  .flex-order-gt-sm-13 {
    order: 13; }
  .flex-order-gt-sm-14 {
    order: 14; }
  .flex-order-gt-sm-15 {
    order: 15; }
  .flex-order-gt-sm-16 {
    order: 16; }
  .flex-order-gt-sm-17 {
    order: 17; }
  .flex-order-gt-sm-18 {
    order: 18; }
  .flex-order-gt-sm-19 {
    order: 19; }
  .flex-order-gt-sm-20 {
    order: 20; }
  .offset-gt-sm-0, .flex-offset-gt-sm-0 {
    margin-left: 0%; }
  .offset-gt-sm-5, .flex-offset-gt-sm-5 {
    margin-left: 5%; }
  .offset-gt-sm-10, .flex-offset-gt-sm-10 {
    margin-left: 10%; }
  .offset-gt-sm-15, .flex-offset-gt-sm-15 {
    margin-left: 15%; }
  .offset-gt-sm-20, .flex-offset-gt-sm-20 {
    margin-left: 20%; }
  .offset-gt-sm-25, .flex-offset-gt-sm-25 {
    margin-left: 25%; }
  .offset-gt-sm-30, .flex-offset-gt-sm-30 {
    margin-left: 30%; }
  .offset-gt-sm-35, .flex-offset-gt-sm-35 {
    margin-left: 35%; }
  .offset-gt-sm-40, .flex-offset-gt-sm-40 {
    margin-left: 40%; }
  .offset-gt-sm-45, .flex-offset-gt-sm-45 {
    margin-left: 45%; }
  .offset-gt-sm-50, .flex-offset-gt-sm-50 {
    margin-left: 50%; }
  .offset-gt-sm-55, .flex-offset-gt-sm-55 {
    margin-left: 55%; }
  .offset-gt-sm-60, .flex-offset-gt-sm-60 {
    margin-left: 60%; }
  .offset-gt-sm-65, .flex-offset-gt-sm-65 {
    margin-left: 65%; }
  .offset-gt-sm-70, .flex-offset-gt-sm-70 {
    margin-left: 70%; }
  .offset-gt-sm-75, .flex-offset-gt-sm-75 {
    margin-left: 75%; }
  .offset-gt-sm-80, .flex-offset-gt-sm-80 {
    margin-left: 80%; }
  .offset-gt-sm-85, .flex-offset-gt-sm-85 {
    margin-left: 85%; }
  .offset-gt-sm-90, .flex-offset-gt-sm-90 {
    margin-left: 90%; }
  .offset-gt-sm-95, .flex-offset-gt-sm-95 {
    margin-left: 95%; }
  .offset-gt-sm-33, .flex-offset-gt-sm-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-sm-66, .flex-offset-gt-sm-66 {
    margin-left: calc(200% / 3); }
  .layout-align-gt-sm,
  .layout-align-gt-sm-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-sm-start,
  .layout-align-gt-sm-start-start,
  .layout-align-gt-sm-start-center,
  .layout-align-gt-sm-start-end,
  .layout-align-gt-sm-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-sm-center,
  .layout-align-gt-sm-center-start,
  .layout-align-gt-sm-center-center,
  .layout-align-gt-sm-center-end,
  .layout-align-gt-sm-center-stretch {
    justify-content: center; }
  .layout-align-gt-sm-end,
  .layout-align-gt-sm-end-start,
  .layout-align-gt-sm-end-center,
  .layout-align-gt-sm-end-end,
  .layout-align-gt-sm-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-sm-space-around,
  .layout-align-gt-sm-space-around-center,
  .layout-align-gt-sm-space-around-start,
  .layout-align-gt-sm-space-around-end,
  .layout-align-gt-sm-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-sm-space-between,
  .layout-align-gt-sm-space-between-center,
  .layout-align-gt-sm-space-between-start,
  .layout-align-gt-sm-space-between-end,
  .layout-align-gt-sm-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-sm-start-start,
  .layout-align-gt-sm-center-start,
  .layout-align-gt-sm-end-start,
  .layout-align-gt-sm-space-between-start,
  .layout-align-gt-sm-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-sm-start-center,
  .layout-align-gt-sm-center-center,
  .layout-align-gt-sm-end-center,
  .layout-align-gt-sm-space-between-center,
  .layout-align-gt-sm-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-sm-start-center > *,
  .layout-align-gt-sm-center-center > *,
  .layout-align-gt-sm-end-center > *,
  .layout-align-gt-sm-space-between-center > *,
  .layout-align-gt-sm-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-sm-start-end,
  .layout-align-gt-sm-center-end,
  .layout-align-gt-sm-end-end,
  .layout-align-gt-sm-space-between-end,
  .layout-align-gt-sm-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-sm-start-stretch,
  .layout-align-gt-sm-center-stretch,
  .layout-align-gt-sm-end-stretch,
  .layout-align-gt-sm-space-between-stretch,
  .layout-align-gt-sm-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-sm {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 960px) {
  .flex-gt-sm {
    flex: 1 1 0%; } }

@media (min-width: 960px) {
  .flex-gt-sm-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-sm-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-sm-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-sm-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-sm-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-sm-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-sm-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-0,
  .layout-gt-sm-row > .flex-gt-sm-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-sm-0,
  .layout-gt-sm-column > .flex-gt-sm-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-sm-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-5,
  .layout-gt-sm-row > .flex-gt-sm-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-5,
  .layout-gt-sm-column > .flex-gt-sm-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-sm-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-10,
  .layout-gt-sm-row > .flex-gt-sm-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-10,
  .layout-gt-sm-column > .flex-gt-sm-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-sm-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-15,
  .layout-gt-sm-row > .flex-gt-sm-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-15,
  .layout-gt-sm-column > .flex-gt-sm-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-sm-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-20,
  .layout-gt-sm-row > .flex-gt-sm-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-20,
  .layout-gt-sm-column > .flex-gt-sm-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-sm-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-25,
  .layout-gt-sm-row > .flex-gt-sm-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-25,
  .layout-gt-sm-column > .flex-gt-sm-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-sm-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-30,
  .layout-gt-sm-row > .flex-gt-sm-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-30,
  .layout-gt-sm-column > .flex-gt-sm-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-sm-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-35,
  .layout-gt-sm-row > .flex-gt-sm-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-35,
  .layout-gt-sm-column > .flex-gt-sm-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-sm-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-40,
  .layout-gt-sm-row > .flex-gt-sm-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-40,
  .layout-gt-sm-column > .flex-gt-sm-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-sm-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-45,
  .layout-gt-sm-row > .flex-gt-sm-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-45,
  .layout-gt-sm-column > .flex-gt-sm-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-sm-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-50,
  .layout-gt-sm-row > .flex-gt-sm-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-50,
  .layout-gt-sm-column > .flex-gt-sm-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-sm-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-55,
  .layout-gt-sm-row > .flex-gt-sm-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-55,
  .layout-gt-sm-column > .flex-gt-sm-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-sm-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-60,
  .layout-gt-sm-row > .flex-gt-sm-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-60,
  .layout-gt-sm-column > .flex-gt-sm-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-sm-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-65,
  .layout-gt-sm-row > .flex-gt-sm-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-65,
  .layout-gt-sm-column > .flex-gt-sm-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-sm-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-70,
  .layout-gt-sm-row > .flex-gt-sm-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-70,
  .layout-gt-sm-column > .flex-gt-sm-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-sm-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-75,
  .layout-gt-sm-row > .flex-gt-sm-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-75,
  .layout-gt-sm-column > .flex-gt-sm-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-sm-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-80,
  .layout-gt-sm-row > .flex-gt-sm-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-80,
  .layout-gt-sm-column > .flex-gt-sm-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-sm-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-85,
  .layout-gt-sm-row > .flex-gt-sm-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-85,
  .layout-gt-sm-column > .flex-gt-sm-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-sm-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-90,
  .layout-gt-sm-row > .flex-gt-sm-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-90,
  .layout-gt-sm-column > .flex-gt-sm-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-sm-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-95,
  .layout-gt-sm-row > .flex-gt-sm-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-95,
  .layout-gt-sm-column > .flex-gt-sm-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-100,
  .layout-gt-sm-row > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-100,
  .layout-gt-sm-column > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33, .layout-gt-sm-row > .flex-gt-sm-33, .layout-gt-sm-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66, .layout-gt-sm-row > .flex-gt-sm-66, .layout-gt-sm-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex, .layout-gt-sm-row > .flex {
    min-width: 0; }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33, .layout-gt-sm-column > .flex-gt-sm-33, .layout-gt-sm-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66, .layout-gt-sm-column > .flex-gt-sm-66, .layout-gt-sm-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-column > .flex, .layout-gt-sm-column > .flex {
    min-height: 0; }
  .layout-gt-sm, .layout-gt-sm-column, .layout-gt-sm-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-sm-column {
    flex-direction: column; }
  .layout-gt-sm-row {
    flex-direction: row; } }

@media (min-width: 960px) and (max-width: 1279px) {
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show) {
    display: none; }
  .hide-md:not(.show-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none; }
  .flex-order-md--20 {
    order: -20; }
  .flex-order-md--19 {
    order: -19; }
  .flex-order-md--18 {
    order: -18; }
  .flex-order-md--17 {
    order: -17; }
  .flex-order-md--16 {
    order: -16; }
  .flex-order-md--15 {
    order: -15; }
  .flex-order-md--14 {
    order: -14; }
  .flex-order-md--13 {
    order: -13; }
  .flex-order-md--12 {
    order: -12; }
  .flex-order-md--11 {
    order: -11; }
  .flex-order-md--10 {
    order: -10; }
  .flex-order-md--9 {
    order: -9; }
  .flex-order-md--8 {
    order: -8; }
  .flex-order-md--7 {
    order: -7; }
  .flex-order-md--6 {
    order: -6; }
  .flex-order-md--5 {
    order: -5; }
  .flex-order-md--4 {
    order: -4; }
  .flex-order-md--3 {
    order: -3; }
  .flex-order-md--2 {
    order: -2; }
  .flex-order-md--1 {
    order: -1; }
  .flex-order-md-0 {
    order: 0; }
  .flex-order-md-1 {
    order: 1; }
  .flex-order-md-2 {
    order: 2; }
  .flex-order-md-3 {
    order: 3; }
  .flex-order-md-4 {
    order: 4; }
  .flex-order-md-5 {
    order: 5; }
  .flex-order-md-6 {
    order: 6; }
  .flex-order-md-7 {
    order: 7; }
  .flex-order-md-8 {
    order: 8; }
  .flex-order-md-9 {
    order: 9; }
  .flex-order-md-10 {
    order: 10; }
  .flex-order-md-11 {
    order: 11; }
  .flex-order-md-12 {
    order: 12; }
  .flex-order-md-13 {
    order: 13; }
  .flex-order-md-14 {
    order: 14; }
  .flex-order-md-15 {
    order: 15; }
  .flex-order-md-16 {
    order: 16; }
  .flex-order-md-17 {
    order: 17; }
  .flex-order-md-18 {
    order: 18; }
  .flex-order-md-19 {
    order: 19; }
  .flex-order-md-20 {
    order: 20; }
  .offset-md-0, .flex-offset-md-0 {
    margin-left: 0%; }
  .offset-md-5, .flex-offset-md-5 {
    margin-left: 5%; }
  .offset-md-10, .flex-offset-md-10 {
    margin-left: 10%; }
  .offset-md-15, .flex-offset-md-15 {
    margin-left: 15%; }
  .offset-md-20, .flex-offset-md-20 {
    margin-left: 20%; }
  .offset-md-25, .flex-offset-md-25 {
    margin-left: 25%; }
  .offset-md-30, .flex-offset-md-30 {
    margin-left: 30%; }
  .offset-md-35, .flex-offset-md-35 {
    margin-left: 35%; }
  .offset-md-40, .flex-offset-md-40 {
    margin-left: 40%; }
  .offset-md-45, .flex-offset-md-45 {
    margin-left: 45%; }
  .offset-md-50, .flex-offset-md-50 {
    margin-left: 50%; }
  .offset-md-55, .flex-offset-md-55 {
    margin-left: 55%; }
  .offset-md-60, .flex-offset-md-60 {
    margin-left: 60%; }
  .offset-md-65, .flex-offset-md-65 {
    margin-left: 65%; }
  .offset-md-70, .flex-offset-md-70 {
    margin-left: 70%; }
  .offset-md-75, .flex-offset-md-75 {
    margin-left: 75%; }
  .offset-md-80, .flex-offset-md-80 {
    margin-left: 80%; }
  .offset-md-85, .flex-offset-md-85 {
    margin-left: 85%; }
  .offset-md-90, .flex-offset-md-90 {
    margin-left: 90%; }
  .offset-md-95, .flex-offset-md-95 {
    margin-left: 95%; }
  .offset-md-33, .flex-offset-md-33 {
    margin-left: calc(100% / 3); }
  .offset-md-66, .flex-offset-md-66 {
    margin-left: calc(200% / 3); }
  .layout-align-md,
  .layout-align-md-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-md-start,
  .layout-align-md-start-start,
  .layout-align-md-start-center,
  .layout-align-md-start-end,
  .layout-align-md-start-stretch {
    justify-content: flex-start; }
  .layout-align-md-center,
  .layout-align-md-center-start,
  .layout-align-md-center-center,
  .layout-align-md-center-end,
  .layout-align-md-center-stretch {
    justify-content: center; }
  .layout-align-md-end,
  .layout-align-md-end-start,
  .layout-align-md-end-center,
  .layout-align-md-end-end,
  .layout-align-md-end-stretch {
    justify-content: flex-end; }
  .layout-align-md-space-around,
  .layout-align-md-space-around-center,
  .layout-align-md-space-around-start,
  .layout-align-md-space-around-end,
  .layout-align-md-space-around-stretch {
    justify-content: space-around; }
  .layout-align-md-space-between,
  .layout-align-md-space-between-center,
  .layout-align-md-space-between-start,
  .layout-align-md-space-between-end,
  .layout-align-md-space-between-stretch {
    justify-content: space-between; }
  .layout-align-md-start-start,
  .layout-align-md-center-start,
  .layout-align-md-end-start,
  .layout-align-md-space-between-start,
  .layout-align-md-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-md-start-center,
  .layout-align-md-center-center,
  .layout-align-md-end-center,
  .layout-align-md-space-between-center,
  .layout-align-md-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-md-start-center > *,
  .layout-align-md-center-center > *,
  .layout-align-md-end-center > *,
  .layout-align-md-space-between-center > *,
  .layout-align-md-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-md-start-end,
  .layout-align-md-center-end,
  .layout-align-md-end-end,
  .layout-align-md-space-between-end,
  .layout-align-md-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-md-start-stretch,
  .layout-align-md-center-stretch,
  .layout-align-md-end-stretch,
  .layout-align-md-space-between-stretch,
  .layout-align-md-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-md {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 960px) and (max-width: 1279px) {
  .flex-md {
    flex: 1 1 0%; } }

@media (min-width: 960px) and (max-width: 1279px) {
  .flex-md-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-md-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-md-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-md-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-md-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-md-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-md-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-0,
  .layout-md-row > .flex-md-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-md-0,
  .layout-md-column > .flex-md-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-md-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-5,
  .layout-md-row > .flex-md-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-5,
  .layout-md-column > .flex-md-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-md-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-10,
  .layout-md-row > .flex-md-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-10,
  .layout-md-column > .flex-md-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-md-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-15,
  .layout-md-row > .flex-md-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-15,
  .layout-md-column > .flex-md-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-20,
  .layout-md-row > .flex-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-20,
  .layout-md-column > .flex-md-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-md-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-25,
  .layout-md-row > .flex-md-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-25,
  .layout-md-column > .flex-md-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-md-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-30,
  .layout-md-row > .flex-md-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-30,
  .layout-md-column > .flex-md-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-md-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-35,
  .layout-md-row > .flex-md-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-35,
  .layout-md-column > .flex-md-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-md-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-40,
  .layout-md-row > .flex-md-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-40,
  .layout-md-column > .flex-md-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-md-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-45,
  .layout-md-row > .flex-md-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-45,
  .layout-md-column > .flex-md-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-50,
  .layout-md-row > .flex-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-50,
  .layout-md-column > .flex-md-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-md-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-55,
  .layout-md-row > .flex-md-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-55,
  .layout-md-column > .flex-md-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-md-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-60,
  .layout-md-row > .flex-md-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-60,
  .layout-md-column > .flex-md-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-md-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-65,
  .layout-md-row > .flex-md-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-65,
  .layout-md-column > .flex-md-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-md-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-70,
  .layout-md-row > .flex-md-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-70,
  .layout-md-column > .flex-md-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-md-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-75,
  .layout-md-row > .flex-md-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-75,
  .layout-md-column > .flex-md-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-md-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-80,
  .layout-md-row > .flex-md-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-80,
  .layout-md-column > .flex-md-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-md-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-85,
  .layout-md-row > .flex-md-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-85,
  .layout-md-column > .flex-md-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-md-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-90,
  .layout-md-row > .flex-md-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-90,
  .layout-md-column > .flex-md-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-md-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-95,
  .layout-md-row > .flex-md-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-95,
  .layout-md-column > .flex-md-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-100,
  .layout-md-row > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-100,
  .layout-md-column > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33, .layout-md-row > .flex-md-33, .layout-md-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66, .layout-md-row > .flex-md-66, .layout-md-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex, .layout-md-row > .flex {
    min-width: 0; }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33, .layout-md-column > .flex-md-33, .layout-md-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66, .layout-md-column > .flex-md-66, .layout-md-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-column > .flex, .layout-md-column > .flex {
    min-height: 0; }
  .layout-md, .layout-md-column, .layout-md-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-md-column {
    flex-direction: column; }
  .layout-md-row {
    flex-direction: row; } }

@media (min-width: 1280px) {
  .flex-order-gt-md--20 {
    order: -20; }
  .flex-order-gt-md--19 {
    order: -19; }
  .flex-order-gt-md--18 {
    order: -18; }
  .flex-order-gt-md--17 {
    order: -17; }
  .flex-order-gt-md--16 {
    order: -16; }
  .flex-order-gt-md--15 {
    order: -15; }
  .flex-order-gt-md--14 {
    order: -14; }
  .flex-order-gt-md--13 {
    order: -13; }
  .flex-order-gt-md--12 {
    order: -12; }
  .flex-order-gt-md--11 {
    order: -11; }
  .flex-order-gt-md--10 {
    order: -10; }
  .flex-order-gt-md--9 {
    order: -9; }
  .flex-order-gt-md--8 {
    order: -8; }
  .flex-order-gt-md--7 {
    order: -7; }
  .flex-order-gt-md--6 {
    order: -6; }
  .flex-order-gt-md--5 {
    order: -5; }
  .flex-order-gt-md--4 {
    order: -4; }
  .flex-order-gt-md--3 {
    order: -3; }
  .flex-order-gt-md--2 {
    order: -2; }
  .flex-order-gt-md--1 {
    order: -1; }
  .flex-order-gt-md-0 {
    order: 0; }
  .flex-order-gt-md-1 {
    order: 1; }
  .flex-order-gt-md-2 {
    order: 2; }
  .flex-order-gt-md-3 {
    order: 3; }
  .flex-order-gt-md-4 {
    order: 4; }
  .flex-order-gt-md-5 {
    order: 5; }
  .flex-order-gt-md-6 {
    order: 6; }
  .flex-order-gt-md-7 {
    order: 7; }
  .flex-order-gt-md-8 {
    order: 8; }
  .flex-order-gt-md-9 {
    order: 9; }
  .flex-order-gt-md-10 {
    order: 10; }
  .flex-order-gt-md-11 {
    order: 11; }
  .flex-order-gt-md-12 {
    order: 12; }
  .flex-order-gt-md-13 {
    order: 13; }
  .flex-order-gt-md-14 {
    order: 14; }
  .flex-order-gt-md-15 {
    order: 15; }
  .flex-order-gt-md-16 {
    order: 16; }
  .flex-order-gt-md-17 {
    order: 17; }
  .flex-order-gt-md-18 {
    order: 18; }
  .flex-order-gt-md-19 {
    order: 19; }
  .flex-order-gt-md-20 {
    order: 20; }
  .offset-gt-md-0, .flex-offset-gt-md-0 {
    margin-left: 0%; }
  .offset-gt-md-5, .flex-offset-gt-md-5 {
    margin-left: 5%; }
  .offset-gt-md-10, .flex-offset-gt-md-10 {
    margin-left: 10%; }
  .offset-gt-md-15, .flex-offset-gt-md-15 {
    margin-left: 15%; }
  .offset-gt-md-20, .flex-offset-gt-md-20 {
    margin-left: 20%; }
  .offset-gt-md-25, .flex-offset-gt-md-25 {
    margin-left: 25%; }
  .offset-gt-md-30, .flex-offset-gt-md-30 {
    margin-left: 30%; }
  .offset-gt-md-35, .flex-offset-gt-md-35 {
    margin-left: 35%; }
  .offset-gt-md-40, .flex-offset-gt-md-40 {
    margin-left: 40%; }
  .offset-gt-md-45, .flex-offset-gt-md-45 {
    margin-left: 45%; }
  .offset-gt-md-50, .flex-offset-gt-md-50 {
    margin-left: 50%; }
  .offset-gt-md-55, .flex-offset-gt-md-55 {
    margin-left: 55%; }
  .offset-gt-md-60, .flex-offset-gt-md-60 {
    margin-left: 60%; }
  .offset-gt-md-65, .flex-offset-gt-md-65 {
    margin-left: 65%; }
  .offset-gt-md-70, .flex-offset-gt-md-70 {
    margin-left: 70%; }
  .offset-gt-md-75, .flex-offset-gt-md-75 {
    margin-left: 75%; }
  .offset-gt-md-80, .flex-offset-gt-md-80 {
    margin-left: 80%; }
  .offset-gt-md-85, .flex-offset-gt-md-85 {
    margin-left: 85%; }
  .offset-gt-md-90, .flex-offset-gt-md-90 {
    margin-left: 90%; }
  .offset-gt-md-95, .flex-offset-gt-md-95 {
    margin-left: 95%; }
  .offset-gt-md-33, .flex-offset-gt-md-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-md-66, .flex-offset-gt-md-66 {
    margin-left: calc(200% / 3); }
  .layout-align-gt-md,
  .layout-align-gt-md-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-md-start,
  .layout-align-gt-md-start-start,
  .layout-align-gt-md-start-center,
  .layout-align-gt-md-start-end,
  .layout-align-gt-md-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-md-center,
  .layout-align-gt-md-center-start,
  .layout-align-gt-md-center-center,
  .layout-align-gt-md-center-end,
  .layout-align-gt-md-center-stretch {
    justify-content: center; }
  .layout-align-gt-md-end,
  .layout-align-gt-md-end-start,
  .layout-align-gt-md-end-center,
  .layout-align-gt-md-end-end,
  .layout-align-gt-md-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-md-space-around,
  .layout-align-gt-md-space-around-center,
  .layout-align-gt-md-space-around-start,
  .layout-align-gt-md-space-around-end,
  .layout-align-gt-md-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-md-space-between,
  .layout-align-gt-md-space-between-center,
  .layout-align-gt-md-space-between-start,
  .layout-align-gt-md-space-between-end,
  .layout-align-gt-md-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-md-start-start,
  .layout-align-gt-md-center-start,
  .layout-align-gt-md-end-start,
  .layout-align-gt-md-space-between-start,
  .layout-align-gt-md-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-md-start-center,
  .layout-align-gt-md-center-center,
  .layout-align-gt-md-end-center,
  .layout-align-gt-md-space-between-center,
  .layout-align-gt-md-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-md-start-center > *,
  .layout-align-gt-md-center-center > *,
  .layout-align-gt-md-end-center > *,
  .layout-align-gt-md-space-between-center > *,
  .layout-align-gt-md-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-md-start-end,
  .layout-align-gt-md-center-end,
  .layout-align-gt-md-end-end,
  .layout-align-gt-md-space-between-end,
  .layout-align-gt-md-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-md-start-stretch,
  .layout-align-gt-md-center-stretch,
  .layout-align-gt-md-end-stretch,
  .layout-align-gt-md-space-between-stretch,
  .layout-align-gt-md-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-md {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 1280px) {
  .flex-gt-md {
    flex: 1 1 0%; } }

@media (min-width: 1280px) {
  .flex-gt-md-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-md-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-md-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-md-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-md-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-md-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-md-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-0,
  .layout-gt-md-row > .flex-gt-md-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-md-0,
  .layout-gt-md-column > .flex-gt-md-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-md-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-5,
  .layout-gt-md-row > .flex-gt-md-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-5,
  .layout-gt-md-column > .flex-gt-md-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-md-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-10,
  .layout-gt-md-row > .flex-gt-md-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-10,
  .layout-gt-md-column > .flex-gt-md-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-md-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-15,
  .layout-gt-md-row > .flex-gt-md-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-15,
  .layout-gt-md-column > .flex-gt-md-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-20,
  .layout-gt-md-row > .flex-gt-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-20,
  .layout-gt-md-column > .flex-gt-md-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-md-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-25,
  .layout-gt-md-row > .flex-gt-md-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-25,
  .layout-gt-md-column > .flex-gt-md-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-md-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-30,
  .layout-gt-md-row > .flex-gt-md-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-30,
  .layout-gt-md-column > .flex-gt-md-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-md-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-35,
  .layout-gt-md-row > .flex-gt-md-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-35,
  .layout-gt-md-column > .flex-gt-md-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-md-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-40,
  .layout-gt-md-row > .flex-gt-md-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-40,
  .layout-gt-md-column > .flex-gt-md-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-md-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-45,
  .layout-gt-md-row > .flex-gt-md-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-45,
  .layout-gt-md-column > .flex-gt-md-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-50,
  .layout-gt-md-row > .flex-gt-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-50,
  .layout-gt-md-column > .flex-gt-md-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-md-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-55,
  .layout-gt-md-row > .flex-gt-md-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-55,
  .layout-gt-md-column > .flex-gt-md-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-md-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-60,
  .layout-gt-md-row > .flex-gt-md-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-60,
  .layout-gt-md-column > .flex-gt-md-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-md-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-65,
  .layout-gt-md-row > .flex-gt-md-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-65,
  .layout-gt-md-column > .flex-gt-md-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-md-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-70,
  .layout-gt-md-row > .flex-gt-md-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-70,
  .layout-gt-md-column > .flex-gt-md-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-md-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-75,
  .layout-gt-md-row > .flex-gt-md-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-75,
  .layout-gt-md-column > .flex-gt-md-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-md-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-80,
  .layout-gt-md-row > .flex-gt-md-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-80,
  .layout-gt-md-column > .flex-gt-md-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-md-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-85,
  .layout-gt-md-row > .flex-gt-md-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-85,
  .layout-gt-md-column > .flex-gt-md-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-md-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-90,
  .layout-gt-md-row > .flex-gt-md-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-90,
  .layout-gt-md-column > .flex-gt-md-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-md-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-95,
  .layout-gt-md-row > .flex-gt-md-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-95,
  .layout-gt-md-column > .flex-gt-md-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-100,
  .layout-gt-md-row > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-100,
  .layout-gt-md-column > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33, .layout-gt-md-row > .flex-gt-md-33, .layout-gt-md-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66, .layout-gt-md-row > .flex-gt-md-66, .layout-gt-md-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex, .layout-gt-md-row > .flex {
    min-width: 0; }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33, .layout-gt-md-column > .flex-gt-md-33, .layout-gt-md-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66, .layout-gt-md-column > .flex-gt-md-66, .layout-gt-md-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-column > .flex, .layout-gt-md-column > .flex {
    min-height: 0; }
  .layout-gt-md, .layout-gt-md-column, .layout-gt-md-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-md-column {
    flex-direction: column; }
  .layout-gt-md-row {
    flex-direction: row; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-md:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show) {
    display: none; }
  .hide-lg:not(.show-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none; }
  .flex-order-lg--20 {
    order: -20; }
  .flex-order-lg--19 {
    order: -19; }
  .flex-order-lg--18 {
    order: -18; }
  .flex-order-lg--17 {
    order: -17; }
  .flex-order-lg--16 {
    order: -16; }
  .flex-order-lg--15 {
    order: -15; }
  .flex-order-lg--14 {
    order: -14; }
  .flex-order-lg--13 {
    order: -13; }
  .flex-order-lg--12 {
    order: -12; }
  .flex-order-lg--11 {
    order: -11; }
  .flex-order-lg--10 {
    order: -10; }
  .flex-order-lg--9 {
    order: -9; }
  .flex-order-lg--8 {
    order: -8; }
  .flex-order-lg--7 {
    order: -7; }
  .flex-order-lg--6 {
    order: -6; }
  .flex-order-lg--5 {
    order: -5; }
  .flex-order-lg--4 {
    order: -4; }
  .flex-order-lg--3 {
    order: -3; }
  .flex-order-lg--2 {
    order: -2; }
  .flex-order-lg--1 {
    order: -1; }
  .flex-order-lg-0 {
    order: 0; }
  .flex-order-lg-1 {
    order: 1; }
  .flex-order-lg-2 {
    order: 2; }
  .flex-order-lg-3 {
    order: 3; }
  .flex-order-lg-4 {
    order: 4; }
  .flex-order-lg-5 {
    order: 5; }
  .flex-order-lg-6 {
    order: 6; }
  .flex-order-lg-7 {
    order: 7; }
  .flex-order-lg-8 {
    order: 8; }
  .flex-order-lg-9 {
    order: 9; }
  .flex-order-lg-10 {
    order: 10; }
  .flex-order-lg-11 {
    order: 11; }
  .flex-order-lg-12 {
    order: 12; }
  .flex-order-lg-13 {
    order: 13; }
  .flex-order-lg-14 {
    order: 14; }
  .flex-order-lg-15 {
    order: 15; }
  .flex-order-lg-16 {
    order: 16; }
  .flex-order-lg-17 {
    order: 17; }
  .flex-order-lg-18 {
    order: 18; }
  .flex-order-lg-19 {
    order: 19; }
  .flex-order-lg-20 {
    order: 20; }
  .offset-lg-0, .flex-offset-lg-0 {
    margin-left: 0%; }
  .offset-lg-5, .flex-offset-lg-5 {
    margin-left: 5%; }
  .offset-lg-10, .flex-offset-lg-10 {
    margin-left: 10%; }
  .offset-lg-15, .flex-offset-lg-15 {
    margin-left: 15%; }
  .offset-lg-20, .flex-offset-lg-20 {
    margin-left: 20%; }
  .offset-lg-25, .flex-offset-lg-25 {
    margin-left: 25%; }
  .offset-lg-30, .flex-offset-lg-30 {
    margin-left: 30%; }
  .offset-lg-35, .flex-offset-lg-35 {
    margin-left: 35%; }
  .offset-lg-40, .flex-offset-lg-40 {
    margin-left: 40%; }
  .offset-lg-45, .flex-offset-lg-45 {
    margin-left: 45%; }
  .offset-lg-50, .flex-offset-lg-50 {
    margin-left: 50%; }
  .offset-lg-55, .flex-offset-lg-55 {
    margin-left: 55%; }
  .offset-lg-60, .flex-offset-lg-60 {
    margin-left: 60%; }
  .offset-lg-65, .flex-offset-lg-65 {
    margin-left: 65%; }
  .offset-lg-70, .flex-offset-lg-70 {
    margin-left: 70%; }
  .offset-lg-75, .flex-offset-lg-75 {
    margin-left: 75%; }
  .offset-lg-80, .flex-offset-lg-80 {
    margin-left: 80%; }
  .offset-lg-85, .flex-offset-lg-85 {
    margin-left: 85%; }
  .offset-lg-90, .flex-offset-lg-90 {
    margin-left: 90%; }
  .offset-lg-95, .flex-offset-lg-95 {
    margin-left: 95%; }
  .offset-lg-33, .flex-offset-lg-33 {
    margin-left: calc(100% / 3); }
  .offset-lg-66, .flex-offset-lg-66 {
    margin-left: calc(200% / 3); }
  .layout-align-lg,
  .layout-align-lg-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-lg-start,
  .layout-align-lg-start-start,
  .layout-align-lg-start-center,
  .layout-align-lg-start-end,
  .layout-align-lg-start-stretch {
    justify-content: flex-start; }
  .layout-align-lg-center,
  .layout-align-lg-center-start,
  .layout-align-lg-center-center,
  .layout-align-lg-center-end,
  .layout-align-lg-center-stretch {
    justify-content: center; }
  .layout-align-lg-end,
  .layout-align-lg-end-start,
  .layout-align-lg-end-center,
  .layout-align-lg-end-end,
  .layout-align-lg-end-stretch {
    justify-content: flex-end; }
  .layout-align-lg-space-around,
  .layout-align-lg-space-around-center,
  .layout-align-lg-space-around-start,
  .layout-align-lg-space-around-end,
  .layout-align-lg-space-around-stretch {
    justify-content: space-around; }
  .layout-align-lg-space-between,
  .layout-align-lg-space-between-center,
  .layout-align-lg-space-between-start,
  .layout-align-lg-space-between-end,
  .layout-align-lg-space-between-stretch {
    justify-content: space-between; }
  .layout-align-lg-start-start,
  .layout-align-lg-center-start,
  .layout-align-lg-end-start,
  .layout-align-lg-space-between-start,
  .layout-align-lg-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-lg-start-center,
  .layout-align-lg-center-center,
  .layout-align-lg-end-center,
  .layout-align-lg-space-between-center,
  .layout-align-lg-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-lg-start-center > *,
  .layout-align-lg-center-center > *,
  .layout-align-lg-end-center > *,
  .layout-align-lg-space-between-center > *,
  .layout-align-lg-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-lg-start-end,
  .layout-align-lg-center-end,
  .layout-align-lg-end-end,
  .layout-align-lg-space-between-end,
  .layout-align-lg-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-lg-start-stretch,
  .layout-align-lg-center-stretch,
  .layout-align-lg-end-stretch,
  .layout-align-lg-space-between-stretch,
  .layout-align-lg-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-lg {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 1280px) and (max-width: 1919px) {
  .flex-lg {
    flex: 1 1 0%; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  .flex-lg-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-lg-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-lg-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-lg-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-lg-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-lg-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-lg-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-0,
  .layout-lg-row > .flex-lg-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-lg-0,
  .layout-lg-column > .flex-lg-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-lg-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-5,
  .layout-lg-row > .flex-lg-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-5,
  .layout-lg-column > .flex-lg-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-lg-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-10,
  .layout-lg-row > .flex-lg-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-10,
  .layout-lg-column > .flex-lg-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-lg-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-15,
  .layout-lg-row > .flex-lg-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-15,
  .layout-lg-column > .flex-lg-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-20,
  .layout-lg-row > .flex-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-20,
  .layout-lg-column > .flex-lg-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-lg-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-25,
  .layout-lg-row > .flex-lg-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-25,
  .layout-lg-column > .flex-lg-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-lg-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-30,
  .layout-lg-row > .flex-lg-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-30,
  .layout-lg-column > .flex-lg-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-lg-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-35,
  .layout-lg-row > .flex-lg-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-35,
  .layout-lg-column > .flex-lg-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-lg-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-40,
  .layout-lg-row > .flex-lg-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-40,
  .layout-lg-column > .flex-lg-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-lg-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-45,
  .layout-lg-row > .flex-lg-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-45,
  .layout-lg-column > .flex-lg-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-lg-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-50,
  .layout-lg-row > .flex-lg-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-50,
  .layout-lg-column > .flex-lg-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-lg-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-55,
  .layout-lg-row > .flex-lg-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-55,
  .layout-lg-column > .flex-lg-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-lg-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-60,
  .layout-lg-row > .flex-lg-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-60,
  .layout-lg-column > .flex-lg-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-lg-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-65,
  .layout-lg-row > .flex-lg-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-65,
  .layout-lg-column > .flex-lg-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-lg-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-70,
  .layout-lg-row > .flex-lg-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-70,
  .layout-lg-column > .flex-lg-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-lg-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-75,
  .layout-lg-row > .flex-lg-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-75,
  .layout-lg-column > .flex-lg-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-lg-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-80,
  .layout-lg-row > .flex-lg-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-80,
  .layout-lg-column > .flex-lg-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-lg-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-85,
  .layout-lg-row > .flex-lg-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-85,
  .layout-lg-column > .flex-lg-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-lg-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-90,
  .layout-lg-row > .flex-lg-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-90,
  .layout-lg-column > .flex-lg-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-lg-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-95,
  .layout-lg-row > .flex-lg-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-95,
  .layout-lg-column > .flex-lg-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-100,
  .layout-lg-row > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-100,
  .layout-lg-column > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33, .layout-lg-row > .flex-lg-33, .layout-lg-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66, .layout-lg-row > .flex-lg-66, .layout-lg-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex, .layout-lg-row > .flex {
    min-width: 0; }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33, .layout-lg-column > .flex-lg-33, .layout-lg-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66, .layout-lg-column > .flex-lg-66, .layout-lg-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-column > .flex, .layout-lg-column > .flex {
    min-height: 0; }
  .layout-lg, .layout-lg-column, .layout-lg-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-lg-column {
    flex-direction: column; }
  .layout-lg-row {
    flex-direction: row; } }

@media (min-width: 1920px) {
  .flex-order-gt-lg--20 {
    order: -20; }
  .flex-order-gt-lg--19 {
    order: -19; }
  .flex-order-gt-lg--18 {
    order: -18; }
  .flex-order-gt-lg--17 {
    order: -17; }
  .flex-order-gt-lg--16 {
    order: -16; }
  .flex-order-gt-lg--15 {
    order: -15; }
  .flex-order-gt-lg--14 {
    order: -14; }
  .flex-order-gt-lg--13 {
    order: -13; }
  .flex-order-gt-lg--12 {
    order: -12; }
  .flex-order-gt-lg--11 {
    order: -11; }
  .flex-order-gt-lg--10 {
    order: -10; }
  .flex-order-gt-lg--9 {
    order: -9; }
  .flex-order-gt-lg--8 {
    order: -8; }
  .flex-order-gt-lg--7 {
    order: -7; }
  .flex-order-gt-lg--6 {
    order: -6; }
  .flex-order-gt-lg--5 {
    order: -5; }
  .flex-order-gt-lg--4 {
    order: -4; }
  .flex-order-gt-lg--3 {
    order: -3; }
  .flex-order-gt-lg--2 {
    order: -2; }
  .flex-order-gt-lg--1 {
    order: -1; }
  .flex-order-gt-lg-0 {
    order: 0; }
  .flex-order-gt-lg-1 {
    order: 1; }
  .flex-order-gt-lg-2 {
    order: 2; }
  .flex-order-gt-lg-3 {
    order: 3; }
  .flex-order-gt-lg-4 {
    order: 4; }
  .flex-order-gt-lg-5 {
    order: 5; }
  .flex-order-gt-lg-6 {
    order: 6; }
  .flex-order-gt-lg-7 {
    order: 7; }
  .flex-order-gt-lg-8 {
    order: 8; }
  .flex-order-gt-lg-9 {
    order: 9; }
  .flex-order-gt-lg-10 {
    order: 10; }
  .flex-order-gt-lg-11 {
    order: 11; }
  .flex-order-gt-lg-12 {
    order: 12; }
  .flex-order-gt-lg-13 {
    order: 13; }
  .flex-order-gt-lg-14 {
    order: 14; }
  .flex-order-gt-lg-15 {
    order: 15; }
  .flex-order-gt-lg-16 {
    order: 16; }
  .flex-order-gt-lg-17 {
    order: 17; }
  .flex-order-gt-lg-18 {
    order: 18; }
  .flex-order-gt-lg-19 {
    order: 19; }
  .flex-order-gt-lg-20 {
    order: 20; }
  .offset-gt-lg-0, .flex-offset-gt-lg-0 {
    margin-left: 0%; }
  .offset-gt-lg-5, .flex-offset-gt-lg-5 {
    margin-left: 5%; }
  .offset-gt-lg-10, .flex-offset-gt-lg-10 {
    margin-left: 10%; }
  .offset-gt-lg-15, .flex-offset-gt-lg-15 {
    margin-left: 15%; }
  .offset-gt-lg-20, .flex-offset-gt-lg-20 {
    margin-left: 20%; }
  .offset-gt-lg-25, .flex-offset-gt-lg-25 {
    margin-left: 25%; }
  .offset-gt-lg-30, .flex-offset-gt-lg-30 {
    margin-left: 30%; }
  .offset-gt-lg-35, .flex-offset-gt-lg-35 {
    margin-left: 35%; }
  .offset-gt-lg-40, .flex-offset-gt-lg-40 {
    margin-left: 40%; }
  .offset-gt-lg-45, .flex-offset-gt-lg-45 {
    margin-left: 45%; }
  .offset-gt-lg-50, .flex-offset-gt-lg-50 {
    margin-left: 50%; }
  .offset-gt-lg-55, .flex-offset-gt-lg-55 {
    margin-left: 55%; }
  .offset-gt-lg-60, .flex-offset-gt-lg-60 {
    margin-left: 60%; }
  .offset-gt-lg-65, .flex-offset-gt-lg-65 {
    margin-left: 65%; }
  .offset-gt-lg-70, .flex-offset-gt-lg-70 {
    margin-left: 70%; }
  .offset-gt-lg-75, .flex-offset-gt-lg-75 {
    margin-left: 75%; }
  .offset-gt-lg-80, .flex-offset-gt-lg-80 {
    margin-left: 80%; }
  .offset-gt-lg-85, .flex-offset-gt-lg-85 {
    margin-left: 85%; }
  .offset-gt-lg-90, .flex-offset-gt-lg-90 {
    margin-left: 90%; }
  .offset-gt-lg-95, .flex-offset-gt-lg-95 {
    margin-left: 95%; }
  .offset-gt-lg-33, .flex-offset-gt-lg-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-lg-66, .flex-offset-gt-lg-66 {
    margin-left: calc(200% / 3); }
  .layout-align-gt-lg,
  .layout-align-gt-lg-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-lg-start,
  .layout-align-gt-lg-start-start,
  .layout-align-gt-lg-start-center,
  .layout-align-gt-lg-start-end,
  .layout-align-gt-lg-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-lg-center,
  .layout-align-gt-lg-center-start,
  .layout-align-gt-lg-center-center,
  .layout-align-gt-lg-center-end,
  .layout-align-gt-lg-center-stretch {
    justify-content: center; }
  .layout-align-gt-lg-end,
  .layout-align-gt-lg-end-start,
  .layout-align-gt-lg-end-center,
  .layout-align-gt-lg-end-end,
  .layout-align-gt-lg-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-lg-space-around,
  .layout-align-gt-lg-space-around-center,
  .layout-align-gt-lg-space-around-start,
  .layout-align-gt-lg-space-around-end,
  .layout-align-gt-lg-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-lg-space-between,
  .layout-align-gt-lg-space-between-center,
  .layout-align-gt-lg-space-between-start,
  .layout-align-gt-lg-space-between-end,
  .layout-align-gt-lg-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-lg-start-start,
  .layout-align-gt-lg-center-start,
  .layout-align-gt-lg-end-start,
  .layout-align-gt-lg-space-between-start,
  .layout-align-gt-lg-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-lg-start-center,
  .layout-align-gt-lg-center-center,
  .layout-align-gt-lg-end-center,
  .layout-align-gt-lg-space-between-center,
  .layout-align-gt-lg-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-lg-start-center > *,
  .layout-align-gt-lg-center-center > *,
  .layout-align-gt-lg-end-center > *,
  .layout-align-gt-lg-space-between-center > *,
  .layout-align-gt-lg-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-lg-start-end,
  .layout-align-gt-lg-center-end,
  .layout-align-gt-lg-end-end,
  .layout-align-gt-lg-space-between-end,
  .layout-align-gt-lg-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-lg-start-stretch,
  .layout-align-gt-lg-center-stretch,
  .layout-align-gt-lg-end-stretch,
  .layout-align-gt-lg-space-between-stretch,
  .layout-align-gt-lg-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-lg {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 1920px) {
  .flex-gt-lg {
    flex: 1 1 0%; } }

@media (min-width: 1920px) {
  .flex-gt-lg-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-lg-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-lg-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-lg-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-lg-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-lg-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-lg-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-0,
  .layout-gt-lg-row > .flex-gt-lg-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-lg-0,
  .layout-gt-lg-column > .flex-gt-lg-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-lg-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-5,
  .layout-gt-lg-row > .flex-gt-lg-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-5,
  .layout-gt-lg-column > .flex-gt-lg-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-lg-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-10,
  .layout-gt-lg-row > .flex-gt-lg-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-10,
  .layout-gt-lg-column > .flex-gt-lg-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-lg-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-15,
  .layout-gt-lg-row > .flex-gt-lg-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-15,
  .layout-gt-lg-column > .flex-gt-lg-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-20,
  .layout-gt-lg-row > .flex-gt-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-20,
  .layout-gt-lg-column > .flex-gt-lg-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-lg-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-25,
  .layout-gt-lg-row > .flex-gt-lg-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-25,
  .layout-gt-lg-column > .flex-gt-lg-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-lg-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-30,
  .layout-gt-lg-row > .flex-gt-lg-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-30,
  .layout-gt-lg-column > .flex-gt-lg-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-lg-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-35,
  .layout-gt-lg-row > .flex-gt-lg-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-35,
  .layout-gt-lg-column > .flex-gt-lg-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-lg-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-40,
  .layout-gt-lg-row > .flex-gt-lg-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-40,
  .layout-gt-lg-column > .flex-gt-lg-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-lg-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-45,
  .layout-gt-lg-row > .flex-gt-lg-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-45,
  .layout-gt-lg-column > .flex-gt-lg-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-lg-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-50,
  .layout-gt-lg-row > .flex-gt-lg-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-50,
  .layout-gt-lg-column > .flex-gt-lg-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-lg-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-55,
  .layout-gt-lg-row > .flex-gt-lg-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-55,
  .layout-gt-lg-column > .flex-gt-lg-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-lg-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-60,
  .layout-gt-lg-row > .flex-gt-lg-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-60,
  .layout-gt-lg-column > .flex-gt-lg-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-lg-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-65,
  .layout-gt-lg-row > .flex-gt-lg-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-65,
  .layout-gt-lg-column > .flex-gt-lg-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-lg-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-70,
  .layout-gt-lg-row > .flex-gt-lg-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-70,
  .layout-gt-lg-column > .flex-gt-lg-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-lg-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-75,
  .layout-gt-lg-row > .flex-gt-lg-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-75,
  .layout-gt-lg-column > .flex-gt-lg-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-lg-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-80,
  .layout-gt-lg-row > .flex-gt-lg-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-80,
  .layout-gt-lg-column > .flex-gt-lg-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-lg-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-85,
  .layout-gt-lg-row > .flex-gt-lg-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-85,
  .layout-gt-lg-column > .flex-gt-lg-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-lg-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-90,
  .layout-gt-lg-row > .flex-gt-lg-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-90,
  .layout-gt-lg-column > .flex-gt-lg-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-lg-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-95,
  .layout-gt-lg-row > .flex-gt-lg-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-95,
  .layout-gt-lg-column > .flex-gt-lg-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-100,
  .layout-gt-lg-row > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-100,
  .layout-gt-lg-column > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33, .layout-gt-lg-row > .flex-gt-lg-33, .layout-gt-lg-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66, .layout-gt-lg-row > .flex-gt-lg-66, .layout-gt-lg-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex, .layout-gt-lg-row > .flex {
    min-width: 0; }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33, .layout-gt-lg-column > .flex-gt-lg-33, .layout-gt-lg-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66, .layout-gt-lg-column > .flex-gt-lg-66, .layout-gt-lg-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-column > .flex, .layout-gt-lg-column > .flex {
    min-height: 0; }
  .layout-gt-lg, .layout-gt-lg-column, .layout-gt-lg-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-lg-column {
    flex-direction: column; }
  .layout-gt-lg-row {
    flex-direction: row; }
  .flex-order-xl--20 {
    order: -20; }
  .flex-order-xl--19 {
    order: -19; }
  .flex-order-xl--18 {
    order: -18; }
  .flex-order-xl--17 {
    order: -17; }
  .flex-order-xl--16 {
    order: -16; }
  .flex-order-xl--15 {
    order: -15; }
  .flex-order-xl--14 {
    order: -14; }
  .flex-order-xl--13 {
    order: -13; }
  .flex-order-xl--12 {
    order: -12; }
  .flex-order-xl--11 {
    order: -11; }
  .flex-order-xl--10 {
    order: -10; }
  .flex-order-xl--9 {
    order: -9; }
  .flex-order-xl--8 {
    order: -8; }
  .flex-order-xl--7 {
    order: -7; }
  .flex-order-xl--6 {
    order: -6; }
  .flex-order-xl--5 {
    order: -5; }
  .flex-order-xl--4 {
    order: -4; }
  .flex-order-xl--3 {
    order: -3; }
  .flex-order-xl--2 {
    order: -2; }
  .flex-order-xl--1 {
    order: -1; }
  .flex-order-xl-0 {
    order: 0; }
  .flex-order-xl-1 {
    order: 1; }
  .flex-order-xl-2 {
    order: 2; }
  .flex-order-xl-3 {
    order: 3; }
  .flex-order-xl-4 {
    order: 4; }
  .flex-order-xl-5 {
    order: 5; }
  .flex-order-xl-6 {
    order: 6; }
  .flex-order-xl-7 {
    order: 7; }
  .flex-order-xl-8 {
    order: 8; }
  .flex-order-xl-9 {
    order: 9; }
  .flex-order-xl-10 {
    order: 10; }
  .flex-order-xl-11 {
    order: 11; }
  .flex-order-xl-12 {
    order: 12; }
  .flex-order-xl-13 {
    order: 13; }
  .flex-order-xl-14 {
    order: 14; }
  .flex-order-xl-15 {
    order: 15; }
  .flex-order-xl-16 {
    order: 16; }
  .flex-order-xl-17 {
    order: 17; }
  .flex-order-xl-18 {
    order: 18; }
  .flex-order-xl-19 {
    order: 19; }
  .flex-order-xl-20 {
    order: 20; }
  .offset-xl-0, .flex-offset-xl-0 {
    margin-left: 0%; }
  .offset-xl-5, .flex-offset-xl-5 {
    margin-left: 5%; }
  .offset-xl-10, .flex-offset-xl-10 {
    margin-left: 10%; }
  .offset-xl-15, .flex-offset-xl-15 {
    margin-left: 15%; }
  .offset-xl-20, .flex-offset-xl-20 {
    margin-left: 20%; }
  .offset-xl-25, .flex-offset-xl-25 {
    margin-left: 25%; }
  .offset-xl-30, .flex-offset-xl-30 {
    margin-left: 30%; }
  .offset-xl-35, .flex-offset-xl-35 {
    margin-left: 35%; }
  .offset-xl-40, .flex-offset-xl-40 {
    margin-left: 40%; }
  .offset-xl-45, .flex-offset-xl-45 {
    margin-left: 45%; }
  .offset-xl-50, .flex-offset-xl-50 {
    margin-left: 50%; }
  .offset-xl-55, .flex-offset-xl-55 {
    margin-left: 55%; }
  .offset-xl-60, .flex-offset-xl-60 {
    margin-left: 60%; }
  .offset-xl-65, .flex-offset-xl-65 {
    margin-left: 65%; }
  .offset-xl-70, .flex-offset-xl-70 {
    margin-left: 70%; }
  .offset-xl-75, .flex-offset-xl-75 {
    margin-left: 75%; }
  .offset-xl-80, .flex-offset-xl-80 {
    margin-left: 80%; }
  .offset-xl-85, .flex-offset-xl-85 {
    margin-left: 85%; }
  .offset-xl-90, .flex-offset-xl-90 {
    margin-left: 90%; }
  .offset-xl-95, .flex-offset-xl-95 {
    margin-left: 95%; }
  .offset-xl-33, .flex-offset-xl-33 {
    margin-left: calc(100% / 3); }
  .offset-xl-66, .flex-offset-xl-66 {
    margin-left: calc(200% / 3); }
  .layout-align-xl,
  .layout-align-xl-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-xl-start,
  .layout-align-xl-start-start,
  .layout-align-xl-start-center,
  .layout-align-xl-start-end,
  .layout-align-xl-start-stretch {
    justify-content: flex-start; }
  .layout-align-xl-center,
  .layout-align-xl-center-start,
  .layout-align-xl-center-center,
  .layout-align-xl-center-end,
  .layout-align-xl-center-stretch {
    justify-content: center; }
  .layout-align-xl-end,
  .layout-align-xl-end-start,
  .layout-align-xl-end-center,
  .layout-align-xl-end-end,
  .layout-align-xl-end-stretch {
    justify-content: flex-end; }
  .layout-align-xl-space-around,
  .layout-align-xl-space-around-center,
  .layout-align-xl-space-around-start,
  .layout-align-xl-space-around-end,
  .layout-align-xl-space-around-stretch {
    justify-content: space-around; }
  .layout-align-xl-space-between,
  .layout-align-xl-space-between-center,
  .layout-align-xl-space-between-start,
  .layout-align-xl-space-between-end,
  .layout-align-xl-space-between-stretch {
    justify-content: space-between; }
  .layout-align-xl-start-start,
  .layout-align-xl-center-start,
  .layout-align-xl-end-start,
  .layout-align-xl-space-between-start,
  .layout-align-xl-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-xl-start-center,
  .layout-align-xl-center-center,
  .layout-align-xl-end-center,
  .layout-align-xl-space-between-center,
  .layout-align-xl-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-xl-start-center > *,
  .layout-align-xl-center-center > *,
  .layout-align-xl-end-center > *,
  .layout-align-xl-space-between-center > *,
  .layout-align-xl-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-xl-start-end,
  .layout-align-xl-center-end,
  .layout-align-xl-end-end,
  .layout-align-xl-space-between-end,
  .layout-align-xl-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-xl-start-stretch,
  .layout-align-xl-center-stretch,
  .layout-align-xl-end-stretch,
  .layout-align-xl-space-between-stretch,
  .layout-align-xl-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-xl {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 1920px) {
  .flex-xl {
    flex: 1 1 0%; } }

@media (min-width: 1920px) {
  .flex-xl-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-xl-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xl-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-xl-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-xl-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-xl-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xl-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-0,
  .layout-xl-row > .flex-xl-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-xl-0,
  .layout-xl-column > .flex-xl-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-xl-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-5,
  .layout-xl-row > .flex-xl-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-5,
  .layout-xl-column > .flex-xl-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-xl-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-10,
  .layout-xl-row > .flex-xl-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-10,
  .layout-xl-column > .flex-xl-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-xl-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-15,
  .layout-xl-row > .flex-xl-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-15,
  .layout-xl-column > .flex-xl-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-xl-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-20,
  .layout-xl-row > .flex-xl-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-20,
  .layout-xl-column > .flex-xl-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-xl-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-25,
  .layout-xl-row > .flex-xl-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-25,
  .layout-xl-column > .flex-xl-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-xl-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-30,
  .layout-xl-row > .flex-xl-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-30,
  .layout-xl-column > .flex-xl-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-xl-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-35,
  .layout-xl-row > .flex-xl-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-35,
  .layout-xl-column > .flex-xl-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-xl-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-40,
  .layout-xl-row > .flex-xl-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-40,
  .layout-xl-column > .flex-xl-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-xl-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-45,
  .layout-xl-row > .flex-xl-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-45,
  .layout-xl-column > .flex-xl-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-xl-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-50,
  .layout-xl-row > .flex-xl-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-50,
  .layout-xl-column > .flex-xl-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-xl-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-55,
  .layout-xl-row > .flex-xl-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-55,
  .layout-xl-column > .flex-xl-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-xl-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-60,
  .layout-xl-row > .flex-xl-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-60,
  .layout-xl-column > .flex-xl-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-xl-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-65,
  .layout-xl-row > .flex-xl-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-65,
  .layout-xl-column > .flex-xl-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-xl-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-70,
  .layout-xl-row > .flex-xl-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-70,
  .layout-xl-column > .flex-xl-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-xl-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-75,
  .layout-xl-row > .flex-xl-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-75,
  .layout-xl-column > .flex-xl-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-xl-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-80,
  .layout-xl-row > .flex-xl-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-80,
  .layout-xl-column > .flex-xl-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-xl-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-85,
  .layout-xl-row > .flex-xl-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-85,
  .layout-xl-column > .flex-xl-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-xl-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-90,
  .layout-xl-row > .flex-xl-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-90,
  .layout-xl-column > .flex-xl-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-xl-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-95,
  .layout-xl-row > .flex-xl-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-95,
  .layout-xl-column > .flex-xl-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-100,
  .layout-xl-row > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-100,
  .layout-xl-column > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33, .layout-xl-row > .flex-xl-33, .layout-xl-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66, .layout-xl-row > .flex-xl-66, .layout-xl-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex, .layout-xl-row > .flex {
    min-width: 0; }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33, .layout-xl-column > .flex-xl-33, .layout-xl-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66, .layout-xl-column > .flex-xl-66, .layout-xl-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-column > .flex, .layout-xl-column > .flex {
    min-height: 0; }
  .layout-xl, .layout-xl-column, .layout-xl-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-xl-column {
    flex-direction: column; }
  .layout-xl-row {
    flex-direction: row; }
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-md:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-lg:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show) {
    display: none; }
  .hide-xl:not(.show-xl):not(.show-gt-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none; } }

@keyframes md-autocomplete-list-out {
  0% {
    animation-timing-function: linear; }
  50% {
    opacity: 0;
    height: 40px;
    animation-timing-function: ease-in; }
  100% {
    height: 0;
    opacity: 0; } }

@keyframes md-autocomplete-list-in {
  0% {
    opacity: 0;
    height: 0;
    animation-timing-function: ease-out; }
  50% {
    opacity: 0;
    height: 40px; }
  100% {
    opacity: 1;
    height: 40px; } }

md-autocomplete {
  border-radius: 2px;
  display: block;
  height: 40px;
  position: relative;
  overflow: visible;
  min-width: 190px; }
  md-autocomplete[disabled] input {
    cursor: default; }
  md-autocomplete[md-floating-label] {
    border-radius: 0;
    background: transparent;
    height: auto; }
    md-autocomplete[md-floating-label] md-input-container {
      padding-bottom: 26px; }
      md-autocomplete[md-floating-label] md-input-container.md-input-has-messages {
        padding-bottom: 2px; }
    md-autocomplete[md-floating-label] md-autocomplete-wrap {
      height: auto; }
    md-autocomplete[md-floating-label] button {
      position: absolute;
      top: auto;
      bottom: 0;
      right: 0;
      width: 30px;
      height: 30px; }
  md-autocomplete md-autocomplete-wrap {
    display: block;
    position: relative;
    overflow: visible;
    height: 40px; }
    md-autocomplete md-autocomplete-wrap.md-menu-showing {
      z-index: 51; }
    md-autocomplete md-autocomplete-wrap md-progress-linear {
      position: absolute;
      bottom: -2px;
      left: 0; }
      md-autocomplete md-autocomplete-wrap md-progress-linear.md-inline {
        bottom: 40px;
        right: 2px;
        left: 2px;
        width: auto; }
      md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        transition: none; }
        md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate .md-container {
          transition: none;
          height: 3px; }
        md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-enter {
          transition: opacity 0.15s linear; }
          md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-enter.ng-enter-active {
            opacity: 1; }
        md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-leave {
          transition: opacity 0.15s linear; }
          md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-leave.ng-leave-active {
            opacity: 0; }
  md-autocomplete input:not(.md-input) {
    font-size: 14px;
    box-sizing: border-box;
    border: none;
    box-shadow: none;
    outline: none;
    background: transparent;
    width: 100%;
    padding: 0 15px;
    line-height: 40px;
    height: 40px; }
    md-autocomplete input:not(.md-input)::-ms-clear {
      display: none; }
  md-autocomplete button {
    position: relative;
    line-height: 20px;
    text-align: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    padding: 0;
    font-size: 12px;
    background: transparent;
    margin: auto 5px; }
    md-autocomplete button:after {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border-radius: 50%;
      transform: scale(0);
      opacity: 0;
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
    md-autocomplete button:focus {
      outline: none; }
      md-autocomplete button:focus:after {
        transform: scale(1);
        opacity: 1; }
    md-autocomplete button md-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) scale(0.9); }
      md-autocomplete button md-icon path {
        stroke-width: 0; }
    md-autocomplete button.ng-enter {
      transform: scale(0);
      transition: transform 0.15s ease-out; }
      md-autocomplete button.ng-enter.ng-enter-active {
        transform: scale(1); }
    md-autocomplete button.ng-leave {
      transition: transform 0.15s ease-out; }
      md-autocomplete button.ng-leave.ng-leave-active {
        transform: scale(0); }
  @media screen and (-ms-high-contrast: active) {
    md-autocomplete input {
      border: 1px solid #fff; }
    md-autocomplete li:focus {
      color: #fff; } }

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  position: absolute;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  height: 225.5px;
  max-height: 225.5px;
  z-index: 100; }

.md-virtual-repeat-container.md-not-found {
  height: 48px; }

.md-autocomplete-suggestions {
  margin: 0;
  list-style: none;
  padding: 0; }
  .md-autocomplete-suggestions li {
    font-size: 14px;
    overflow: hidden;
    padding: 0 15px;
    line-height: 48px;
    height: 48px;
    transition: background 0.15s linear;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis; }
    .md-autocomplete-suggestions li:focus {
      outline: none; }
    .md-autocomplete-suggestions li:not(.md-not-found-wrapper) {
      cursor: pointer; }

@media screen and (-ms-high-contrast: active) {
  md-autocomplete,
  .md-autocomplete-suggestions {
    border: 1px solid #fff; } }

md-backdrop {
  transition: opacity 450ms;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50; }
  md-backdrop.md-menu-backdrop {
    position: fixed !important;
    z-index: 99; }
  md-backdrop.md-select-backdrop {
    z-index: 81;
    transition-duration: 0; }
  md-backdrop.md-dialog-backdrop {
    z-index: 79; }
  md-backdrop.md-bottom-sheet-backdrop {
    z-index: 69; }
  md-backdrop.md-sidenav-backdrop {
    z-index: 59; }
  md-backdrop.md-click-catcher {
    position: absolute; }
  md-backdrop.md-opaque {
    opacity: .48; }
    md-backdrop.md-opaque.ng-enter {
      opacity: 0; }
    md-backdrop.md-opaque.ng-enter.md-opaque.ng-enter-active {
      opacity: .48; }
    md-backdrop.md-opaque.ng-leave {
      opacity: .48;
      transition: opacity 400ms; }
    md-backdrop.md-opaque.ng-leave.md-opaque.ng-leave-active {
      opacity: 0; }

md-bottom-sheet {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px 16px 88px 16px;
  z-index: 70;
  border-top-width: 1px;
  border-top-style: solid;
  transform: translate3d(0, 80px, 0);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: transform; }
  md-bottom-sheet.md-has-header {
    padding-top: 0; }
  md-bottom-sheet.ng-enter {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  md-bottom-sheet.ng-enter-active {
    opacity: 1;
    display: block;
    transform: translate3d(0, 80px, 0) !important; }
  md-bottom-sheet.ng-leave-active {
    transform: translate3d(0, 100%, 0) !important;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-bottom-sheet .md-subheader {
    background-color: transparent;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    line-height: 56px;
    padding: 0;
    white-space: nowrap; }
  md-bottom-sheet md-inline-icon {
    display: inline-block;
    height: 24px;
    width: 24px;
    fill: #444; }
  md-bottom-sheet md-list-item {
    display: flex;
    outline: none; }
    md-bottom-sheet md-list-item:hover {
      cursor: pointer; }
  md-bottom-sheet.md-list md-list-item {
    padding: 0;
    align-items: center;
    height: 48px; }
    md-bottom-sheet.md-list md-list-item div.md-icon-container {
      display: inline-block;
      height: 24px;
      margin-right: 32px; }
  md-bottom-sheet.md-grid {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0; }
    md-bottom-sheet.md-grid md-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      transition: all 0.5s;
      align-items: center; }
    md-bottom-sheet.md-grid md-list-item {
      flex-direction: column;
      align-items: center;
      transition: all 0.5s;
      height: 96px;
      margin-top: 8px;
      margin-bottom: 8px;
      /* Mixin for how many grid items to show per row */ }
      @media screen and (max-width: 960px) {
        md-bottom-sheet.md-grid md-list-item {
          flex: 1 1 33.33333%;
          max-width: 33.33333%; }
          md-bottom-sheet.md-grid md-list-item:nth-of-type(3n + 1) {
            align-items: flex-start; }
          md-bottom-sheet.md-grid md-list-item:nth-of-type(3n) {
            align-items: flex-end; } }
      @media screen and (min-width: 960px) and (max-width: 1279px) {
        md-bottom-sheet.md-grid md-list-item {
          flex: 1 1 25%;
          max-width: 25%; } }
      @media screen and (min-width: 1280px) and (max-width: 1919px) {
        md-bottom-sheet.md-grid md-list-item {
          flex: 1 1 16.66667%;
          max-width: 16.66667%; } }
      @media screen and (min-width: 1920px) {
        md-bottom-sheet.md-grid md-list-item {
          flex: 1 1 14.28571%;
          max-width: 14.28571%; } }
      md-bottom-sheet.md-grid md-list-item .md-list-item-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 48px;
        padding-bottom: 16px; }
      md-bottom-sheet.md-grid md-list-item .md-grid-item-content {
        border: 1px solid transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80px; }
      md-bottom-sheet.md-grid md-list-item .md-icon-container {
        display: inline-block;
        box-sizing: border-box;
        height: 48px;
        width: 48px;
        margin: 0 0; }
      md-bottom-sheet.md-grid md-list-item .md-grid-text {
        font-weight: 400;
        line-height: 16px;
        font-size: 13px;
        margin: 0;
        white-space: nowrap;
        width: 64px;
        text-align: center;
        text-transform: none;
        padding-top: 8px; }

@media screen and (-ms-high-contrast: active) {
  md-bottom-sheet {
    border: 1px solid #fff; } }

button.md-button::-moz-focus-inner {
  border: 0; }

.md-button {
  border-radius: 3px;
  box-sizing: border-box;
  color: currentColor;
  user-select: none;
  position: relative;
  outline: none;
  border: 0;
  display: inline-block;
  align-items: center;
  padding: 0 6px;
  margin: 6px 8px;
  line-height: 36px;
  min-height: 36px;
  background: transparent;
  white-space: nowrap;
  min-width: 88px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .md-button:focus {
    outline: none; }
  .md-button:hover, .md-button:focus {
    text-decoration: none; }
  .md-button.ng-hide, .md-button.ng-leave {
    transition: none; }
  .md-button.md-cornered {
    border-radius: 0; }
  .md-button.md-icon {
    padding: 0;
    background: none; }
  .md-button.md-raised:not([disabled]) {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }
  .md-button.md-icon-button {
    margin: 0 6px;
    height: 40px;
    min-width: 0;
    line-height: 24px;
    padding: 8px;
    width: 40px;
    border-radius: 50%; }
    .md-button.md-icon-button .md-ripple-container {
      border-radius: 50%;
      background-clip: padding-box;
      overflow: hidden;
      -webkit-mask-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC"); }
  .md-button.md-fab {
    z-index: 20;
    line-height: 56px;
    min-width: 0;
    width: 56px;
    height: 56px;
    vertical-align: middle;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border-radius: 50%;
    background-clip: padding-box;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-property: background-color, box-shadow, transform; }
    .md-button.md-fab.md-fab-bottom-right {
      top: auto;
      right: 20px;
      bottom: 20px;
      left: auto;
      position: absolute; }
    .md-button.md-fab.md-fab-bottom-left {
      top: auto;
      right: auto;
      bottom: 20px;
      left: 20px;
      position: absolute; }
    .md-button.md-fab.md-fab-top-right {
      top: 20px;
      right: 20px;
      bottom: auto;
      left: auto;
      position: absolute; }
    .md-button.md-fab.md-fab-top-left {
      top: 20px;
      right: auto;
      bottom: auto;
      left: 20px;
      position: absolute; }
    .md-button.md-fab .md-ripple-container {
      border-radius: 50%;
      background-clip: padding-box;
      overflow: hidden;
      -webkit-mask-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC"); }
    .md-button.md-fab.md-mini {
      line-height: 40px;
      width: 40px;
      height: 40px; }
    .md-button.md-fab.ng-hide, .md-button.md-fab.ng-leave {
      transition: none; }
  .md-button:not([disabled]).md-raised.md-focused, .md-button:not([disabled]).md-fab.md-focused {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }
  .md-button:not([disabled]).md-raised:active, .md-button:not([disabled]).md-fab:active {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); }
  .md-button .md-ripple-container {
    border-radius: 3px;
    background-clip: padding-box;
    overflow: hidden;
    -webkit-mask-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC"); }

.md-toast-open-top .md-button.md-fab-top-left,
.md-toast-open-top .md-button.md-fab-top-right {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate3d(0, 42px, 0); }
  .md-toast-open-top .md-button.md-fab-top-left:not([disabled]).md-focused, .md-toast-open-top .md-button.md-fab-top-left:not([disabled]):hover,
  .md-toast-open-top .md-button.md-fab-top-right:not([disabled]).md-focused,
  .md-toast-open-top .md-button.md-fab-top-right:not([disabled]):hover {
    transform: translate3d(0, 41px, 0); }

.md-toast-open-bottom .md-button.md-fab-bottom-left,
.md-toast-open-bottom .md-button.md-fab-bottom-right {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate3d(0, -42px, 0); }
  .md-toast-open-bottom .md-button.md-fab-bottom-left:not([disabled]).md-focused, .md-toast-open-bottom .md-button.md-fab-bottom-left:not([disabled]):hover,
  .md-toast-open-bottom .md-button.md-fab-bottom-right:not([disabled]).md-focused,
  .md-toast-open-bottom .md-button.md-fab-bottom-right:not([disabled]):hover {
    transform: translate3d(0, -43px, 0); }

.md-button-group {
  display: flex;
  flex: 1;
  width: 100%; }

.md-button-group > .md-button {
  flex: 1;
  display: block;
  overflow: hidden;
  width: 0;
  border-width: 1px 0px 1px 1px;
  border-radius: 0;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap; }
  .md-button-group > .md-button:first-child {
    border-radius: 2px 0px 0px 2px; }
  .md-button-group > .md-button:last-child {
    border-right-width: 1px;
    border-radius: 0px 2px 2px 0px; }

@media screen and (-ms-high-contrast: active) {
  .md-button.md-raised,
  .md-button.md-fab {
    border: 1px solid #fff; } }

md-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 8px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  md-card md-card-header {
    padding: 16px;
    display: flex;
    flex-direction: row; }
    md-card md-card-header:first-child md-card-avatar {
      margin-right: 12px; }
    md-card md-card-header:last-child md-card-avatar {
      margin-left: 12px; }
    md-card md-card-header md-card-avatar {
      width: 40px;
      height: 40px; }
      md-card md-card-header md-card-avatar .md-user-avatar,
      md-card md-card-header md-card-avatar md-icon {
        border-radius: 50%; }
      md-card md-card-header md-card-avatar md-icon {
        padding: 8px; }
      md-card md-card-header md-card-avatar + md-card-header-text {
        max-height: 40px; }
        md-card md-card-header md-card-avatar + md-card-header-text .md-title {
          font-size: 14px; }
    md-card md-card-header md-card-header-text {
      display: flex;
      flex: 1;
      flex-direction: column; }
      md-card md-card-header md-card-header-text .md-subhead {
        font-size: 14px; }
  md-card > img,
  md-card > :not(md-card-content) img {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    width: 100%;
    height: auto; }
  md-card md-card-title {
    padding: 24px 16px 16px;
    display: flex;
    flex: 1;
    flex-direction: row; }
    md-card md-card-title + md-card-content {
      padding-top: 0; }
    md-card md-card-title md-card-title-text {
      flex: 1;
      flex-direction: column;
      display: flex; }
      md-card md-card-title md-card-title-text .md-subhead {
        padding-top: 0;
        font-size: 14px; }
      md-card md-card-title md-card-title-text:only-child .md-subhead {
        padding-top: 12px; }
    md-card md-card-title md-card-title-media {
      margin-top: -8px; }
      md-card md-card-title md-card-title-media .md-media-sm {
        height: 80px;
        width: 80px; }
      md-card md-card-title md-card-title-media .md-media-md {
        height: 112px;
        width: 112px; }
      md-card md-card-title md-card-title-media .md-media-lg {
        height: 152px;
        width: 152px; }
  md-card md-card-content {
    display: block;
    padding: 16px; }
    md-card md-card-content > p:first-child {
      margin-top: 0; }
    md-card md-card-content > p:last-child {
      margin-bottom: 0; }
    md-card md-card-content .md-media-xl {
      height: 240px;
      width: 240px; }
  md-card .md-actions, md-card md-card-actions {
    margin: 8px; }
    md-card .md-actions.layout-column .md-button:not(.md-icon-button), md-card md-card-actions.layout-column .md-button:not(.md-icon-button) {
      margin: 2px 0; }
      md-card .md-actions.layout-column .md-button:not(.md-icon-button):first-of-type, md-card md-card-actions.layout-column .md-button:not(.md-icon-button):first-of-type {
        margin-top: 0; }
      md-card .md-actions.layout-column .md-button:not(.md-icon-button):last-of-type, md-card md-card-actions.layout-column .md-button:not(.md-icon-button):last-of-type {
        margin-bottom: 0; }
    md-card .md-actions.layout-column .md-button.md-icon-button, md-card md-card-actions.layout-column .md-button.md-icon-button {
      margin-top: 6px;
      margin-bottom: 6px; }
    md-card .md-actions md-card-icon-actions, md-card md-card-actions md-card-icon-actions {
      flex: 1;
      justify-content: flex-start;
      display: flex;
      flex-direction: row; }
    md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button), md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button) {
      margin: 0 4px; }
      md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type, md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type {
        margin-left: 0; }
      md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type, md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type {
        margin-right: 0; }
    md-card .md-actions:not(.layout-column) .md-button.md-icon-button, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button {
      margin-left: 6px;
      margin-right: 6px; }
      md-card .md-actions:not(.layout-column) .md-button.md-icon-button:first-of-type, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:first-of-type {
        margin-left: 12px; }
      md-card .md-actions:not(.layout-column) .md-button.md-icon-button:last-of-type, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:last-of-type {
        margin-right: 12px; }
    md-card .md-actions:not(.layout-column) .md-button + md-card-icon-actions, md-card md-card-actions:not(.layout-column) .md-button + md-card-icon-actions {
      flex: 1;
      justify-content: flex-end;
      display: flex;
      flex-direction: row; }
  md-card md-card-footer {
    margin-top: auto;
    padding: 16px; }

@media screen and (-ms-high-contrast: active) {
  md-card {
    border: 1px solid #fff; } }

.md-inline-form md-checkbox {
  margin: 19px 0 18px; }

md-checkbox {
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 16px;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  user-select: none;
  position: relative;
  min-width: 20px;
  min-height: 20px;
  margin-left: inherit;
  margin-right: 16px; }
  html[dir=rtl] md-checkbox {
    margin-left: 16px;
    unicode-bidi: embed; }
  body[dir=rtl] md-checkbox {
    margin-left: 16px;
    unicode-bidi: embed; }
  md-checkbox bdo[dir=rtl] {
    direction: rtl;
    unicode-bidi: bidi-override; }
  md-checkbox bdo[dir=ltr] {
    direction: ltr;
    unicode-bidi: bidi-override; }
  html[dir=rtl] md-checkbox {
    margin-right: inherit;
    unicode-bidi: embed; }
  body[dir=rtl] md-checkbox {
    margin-right: inherit;
    unicode-bidi: embed; }
  md-checkbox bdo[dir=rtl] {
    direction: rtl;
    unicode-bidi: bidi-override; }
  md-checkbox bdo[dir=ltr] {
    direction: ltr;
    unicode-bidi: bidi-override; }
  md-checkbox:last-of-type {
    margin-left: inherit;
    margin-right: 0; }
    html[dir=rtl] md-checkbox:last-of-type {
      margin-left: 0;
      unicode-bidi: embed; }
    body[dir=rtl] md-checkbox:last-of-type {
      margin-left: 0;
      unicode-bidi: embed; }
    md-checkbox:last-of-type bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-checkbox:last-of-type bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    html[dir=rtl] md-checkbox:last-of-type {
      margin-right: inherit;
      unicode-bidi: embed; }
    body[dir=rtl] md-checkbox:last-of-type {
      margin-right: inherit;
      unicode-bidi: embed; }
    md-checkbox:last-of-type bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-checkbox:last-of-type bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
  md-checkbox.md-focused:not([disabled]) .md-container:before {
    left: -8px;
    top: -8px;
    right: -8px;
    bottom: -8px; }
  md-checkbox.md-focused:not([disabled]):not(.md-checked) .md-container:before {
    background-color: rgba(0, 0, 0, 0.12); }
  md-checkbox .md-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    display: inline-block;
    width: 20px;
    height: 20px;
    left: 0;
    right: auto; }
    html[dir=rtl] md-checkbox .md-container {
      left: auto;
      unicode-bidi: embed; }
    body[dir=rtl] md-checkbox .md-container {
      left: auto;
      unicode-bidi: embed; }
    md-checkbox .md-container bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-checkbox .md-container bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    html[dir=rtl] md-checkbox .md-container {
      right: 0;
      unicode-bidi: embed; }
    body[dir=rtl] md-checkbox .md-container {
      right: 0;
      unicode-bidi: embed; }
    md-checkbox .md-container bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-checkbox .md-container bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    md-checkbox .md-container:before {
      box-sizing: border-box;
      background-color: transparent;
      border-radius: 50%;
      content: '';
      position: absolute;
      display: block;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s;
      width: auto; }
    md-checkbox .md-container:after {
      box-sizing: border-box;
      content: '';
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px; }
    md-checkbox .md-container .md-ripple-container {
      position: absolute;
      display: block;
      width: auto;
      height: auto;
      left: -15px;
      top: -15px;
      right: -15px;
      bottom: -15px; }
  md-checkbox.md-align-top-left > div.md-container {
    top: 12px; }
  md-checkbox .md-icon {
    box-sizing: border-box;
    transition: 240ms;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-style: solid;
    border-radius: 2px; }
  md-checkbox.md-checked .md-icon {
    border: none; }
  md-checkbox[disabled] {
    cursor: default; }
  md-checkbox.md-checked .md-icon:after {
    box-sizing: border-box;
    transform: rotate(45deg);
    position: absolute;
    left: 6.66667px;
    top: 2.22222px;
    display: table;
    width: 6.66667px;
    height: 13.33333px;
    border-width: 2px;
    border-style: solid;
    border-top: 0;
    border-left: 0;
    content: ''; }
  md-checkbox .md-label {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    user-select: text;
    margin-left: 30px;
    margin-right: 0; }
    html[dir=rtl] md-checkbox .md-label {
      margin-left: 0;
      unicode-bidi: embed; }
    body[dir=rtl] md-checkbox .md-label {
      margin-left: 0;
      unicode-bidi: embed; }
    md-checkbox .md-label bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-checkbox .md-label bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    html[dir=rtl] md-checkbox .md-label {
      margin-right: 30px;
      unicode-bidi: embed; }
    body[dir=rtl] md-checkbox .md-label {
      margin-right: 30px;
      unicode-bidi: embed; }
    md-checkbox .md-label bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-checkbox .md-label bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }

.md-contact-chips .md-chips .md-chip {
  padding: 0 25px 0 0; }
  .md-contact-chips .md-chips .md-chip .md-contact-avatar {
    float: left; }
    .md-contact-chips .md-chips .md-chip .md-contact-avatar img {
      height: 32px;
      border-radius: 16px; }
  .md-contact-chips .md-chips .md-chip .md-contact-name {
    display: inline-block;
    height: 32px;
    margin-left: 8px; }

.md-contact-suggestion {
  height: 56px; }
  .md-contact-suggestion img {
    height: 40px;
    border-radius: 20px;
    margin-top: 8px; }
  .md-contact-suggestion .md-contact-name {
    margin-left: 8px;
    width: 120px; }
  .md-contact-suggestion .md-contact-name, .md-contact-suggestion .md-contact-email {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis; }

.md-contact-chips-suggestions li {
  height: 100%; }

.md-chips {
  display: block;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  padding: 0 0 8px 3px;
  vertical-align: middle; }
  .md-chips:after {
    content: '';
    display: table;
    clear: both; }
  .md-chips:not(.md-readonly) {
    cursor: text; }
    .md-chips:not(.md-readonly) .md-chip:not(.md-readonly) {
      padding-right: 22px; }
      .md-chips:not(.md-readonly) .md-chip:not(.md-readonly) .md-chip-content {
        padding-right: 4px; }
  .md-chips .md-chip {
    cursor: default;
    border-radius: 16px;
    display: block;
    height: 32px;
    line-height: 32px;
    margin: 8px 8px 0 0;
    padding: 0 12px 0 12px;
    float: left;
    box-sizing: border-box;
    max-width: 100%;
    position: relative; }
    .md-chips .md-chip .md-chip-content {
      display: block;
      float: left;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis; }
      .md-chips .md-chip .md-chip-content:focus {
        outline: none; }
    .md-chips .md-chip .md-chip-remove-container {
      position: absolute;
      right: 0;
      line-height: 22px; }
    .md-chips .md-chip .md-chip-remove {
      text-align: center;
      width: 32px;
      height: 32px;
      min-width: 0;
      padding: 0;
      background: transparent;
      border: none;
      box-shadow: none;
      margin: 0;
      position: relative; }
      .md-chips .md-chip .md-chip-remove md-icon {
        height: 18px;
        width: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0); }
  .md-chips .md-chip-input-container {
    display: block;
    line-height: 32px;
    margin: 8px 8px 0 0;
    padding: 0;
    float: left; }
    .md-chips .md-chip-input-container input:not([type]), .md-chips .md-chip-input-container input[type="email"], .md-chips .md-chip-input-container input[type="number"], .md-chips .md-chip-input-container input[type="tel"], .md-chips .md-chip-input-container input[type="url"], .md-chips .md-chip-input-container input[type="text"] {
      border: 0;
      height: 32px;
      line-height: 32px;
      padding: 0; }
      .md-chips .md-chip-input-container input:not([type]):focus, .md-chips .md-chip-input-container input[type="email"]:focus, .md-chips .md-chip-input-container input[type="number"]:focus, .md-chips .md-chip-input-container input[type="tel"]:focus, .md-chips .md-chip-input-container input[type="url"]:focus, .md-chips .md-chip-input-container input[type="text"]:focus {
        outline: none; }
    .md-chips .md-chip-input-container md-autocomplete, .md-chips .md-chip-input-container md-autocomplete-wrap {
      background: transparent;
      height: 32px; }
    .md-chips .md-chip-input-container md-autocomplete md-autocomplete-wrap {
      box-shadow: none; }
    .md-chips .md-chip-input-container md-autocomplete input {
      position: relative; }
    .md-chips .md-chip-input-container input {
      border: 0;
      height: 32px;
      line-height: 32px;
      padding: 0; }
      .md-chips .md-chip-input-container input:focus {
        outline: none; }
    .md-chips .md-chip-input-container md-autocomplete, .md-chips .md-chip-input-container md-autocomplete-wrap {
      height: 32px; }
    .md-chips .md-chip-input-container md-autocomplete {
      box-shadow: none; }
      .md-chips .md-chip-input-container md-autocomplete input {
        position: relative; }
    .md-chips .md-chip-input-container:not(:first-child) {
      margin: 8px 8px 0 0; }
    .md-chips .md-chip-input-container input {
      background: transparent;
      border-width: 0; }
  .md-chips md-autocomplete button {
    display: none; }

@media screen and (-ms-high-contrast: active) {
  .md-chip-input-container,
  md-chip {
    border: 1px solid #fff; }
  .md-chip-input-container md-autocomplete {
    border: none; } }

md-content {
  display: block;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  md-content[md-scroll-y] {
    overflow-y: auto;
    overflow-x: hidden; }
  md-content[md-scroll-x] {
    overflow-x: auto;
    overflow-y: hidden; }
  md-content.autoScroll {
    -webkit-overflow-scrolling: auto; }

/** Styles for mdCalendar. */
md-calendar {
  font-size: 13px;
  user-select: none; }

.md-calendar-scroll-mask {
  display: inline-block;
  overflow: hidden;
  height: 308px; }
  .md-calendar-scroll-mask .md-virtual-repeat-scroller {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
    .md-calendar-scroll-mask .md-virtual-repeat-scroller::-webkit-scrollbar {
      display: none; }
  .md-calendar-scroll-mask .md-virtual-repeat-offsetter {
    width: 100%; }

.md-calendar-scroll-container {
  box-shadow: inset -3px 3px 6px rgba(0, 0, 0, 0.2);
  display: inline-block;
  height: 308px;
  width: 346px; }

.md-calendar-date {
  height: 44px;
  width: 44px;
  text-align: center;
  padding: 0;
  border: none; }
  .md-calendar-date:first-child {
    padding-left: 16px; }
  .md-calendar-date:last-child {
    padding-right: 16px; }
  .md-calendar-date.md-calendar-date-disabled {
    cursor: default; }

.md-calendar-date-selection-indicator {
  transition: background-color, color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 50%;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px; }
  .md-calendar-date:not(.md-disabled) .md-calendar-date-selection-indicator {
    cursor: pointer; }

.md-calendar-month-label {
  height: 44px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 0 0 24px; }

.md-calendar-day-header {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse; }
  .md-calendar-day-header th {
    height: 44px;
    width: 44px;
    text-align: center;
    padding: 0;
    border: none;
    font-weight: normal;
    height: 40px; }
    .md-calendar-day-header th:first-child {
      padding-left: 16px; }
    .md-calendar-day-header th:last-child {
      padding-right: 16px; }

.md-calendar {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse; }
  .md-calendar tr:last-child td {
    border-bottom-width: 1px;
    border-bottom-style: solid; }
  .md-calendar:first-child {
    border-top: 1px solid transparent; }

/** Styles for mdDatepicker. */
md-datepicker {
  white-space: nowrap;
  overflow: hidden;
  padding-right: 18px;
  margin-right: -18px;
  vertical-align: middle; }

.md-inline-form md-datepicker {
  margin-top: 12px; }

.md-datepicker-button {
  display: inline-block;
  box-sizing: border-box;
  background: none; }

.md-datepicker-input {
  font-size: 14px;
  box-sizing: border-box;
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
  min-width: 120px;
  max-width: 328px; }
  .md-datepicker-input::-ms-clear {
    display: none; }

.md-datepicker-input-container {
  position: relative;
  padding-bottom: 5px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: inline-block;
  width: auto;
  margin-left: 12px; }
  .md-datepicker-input-container.md-datepicker-focused {
    border-bottom-width: 2px; }

.md-datepicker-calendar-pane {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  border-width: 1px;
  border-style: solid;
  background: transparent;
  transform: scale(0);
  transform-origin: 0 0;
  transition: transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .md-datepicker-calendar-pane.md-pane-open {
    transform: scale(1); }

.md-datepicker-input-mask {
  height: 40px;
  width: 340px;
  position: relative;
  background: transparent;
  pointer-events: none;
  cursor: text; }

.md-datepicker-input-mask-opaque {
  position: absolute;
  right: 0;
  left: 120px;
  height: 100%; }

.md-datepicker-calendar {
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.5, 0, 0.25, 1); }
  .md-pane-open .md-datepicker-calendar {
    opacity: 1; }
  .md-datepicker-calendar md-calendar:focus {
    outline: none; }

.md-datepicker-expand-triangle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid; }

.md-datepicker-triangle-button {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-25%) translateX(45%); }

.md-datepicker-triangle-button.md-button.md-icon-button {
  height: 100%;
  width: 36px;
  position: absolute; }

md-datepicker[disabled] .md-datepicker-input-container {
  border-bottom-color: transparent; }

md-datepicker[disabled] .md-datepicker-triangle-button {
  display: none; }

.md-datepicker-open .md-datepicker-input-container {
  margin-left: -12px;
  border: none; }

.md-datepicker-open .md-datepicker-input {
  margin-left: 24px;
  height: 40px; }

.md-datepicker-open .md-datepicker-triangle-button {
  display: none; }

.md-datepicker-pos-adjusted .md-datepicker-input-mask {
  display: none; }

.md-datepicker-calendar-pane .md-calendar {
  transform: translateY(-85px);
  transition: transform 0.65s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-delay: 0.125s; }

.md-datepicker-calendar-pane.md-pane-open .md-calendar {
  transform: translateY(0); }

.md-dialog-is-showing {
  max-height: 100%; }

.md-dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 80;
  overflow: hidden; }

md-dialog {
  opacity: 0;
  min-width: 240px;
  max-width: 80%;
  max-height: 80%;
  position: relative;
  overflow: auto;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column; }
  md-dialog.md-transition-in {
    opacity: 1;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transform: translate(0, 0) scale(1); }
  md-dialog.md-transition-out {
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transform: translate(0, 100%) scale(0.2); }
  md-dialog > form {
    display: flex;
    flex-direction: column;
    overflow: auto; }
  md-dialog .md-dialog-content {
    padding: 24px; }
  md-dialog md-dialog-content {
    order: 1;
    flex-direction: column;
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
    md-dialog md-dialog-content:not([layout=row]) > *:first-child:not(.md-subheader) {
      margin-top: 0; }
    md-dialog md-dialog-content:focus {
      outline: none; }
    md-dialog md-dialog-content .md-subheader {
      margin: 0; }
      md-dialog md-dialog-content .md-subheader.sticky-clone {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16); }
    md-dialog md-dialog-content.sticky-container {
      padding: 0; }
      md-dialog md-dialog-content.sticky-container > div {
        padding: 24px;
        padding-top: 0; }
    md-dialog md-dialog-content .md-dialog-content-body {
      width: 100%; }
  md-dialog .md-actions, md-dialog md-dialog-actions {
    display: flex;
    order: 2;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
    padding-right: 8px;
    padding-left: 16px;
    min-height: 52px;
    overflow: hidden; }
    md-dialog .md-actions .md-button, md-dialog md-dialog-actions .md-button {
      margin-bottom: 8px;
      margin-left: 8px;
      margin-right: 0;
      margin-top: 8px; }
  md-dialog.md-content-overflow .md-actions, md-dialog.md-content-overflow md-dialog-actions {
    border-top-width: 1px;
    border-top-style: solid; }

@media screen and (-ms-high-contrast: active) {
  md-dialog {
    border: 1px solid #fff; } }

@media (max-width: 959px) {
  md-dialog.md-dialog-fullscreen {
    min-height: 100%;
    min-width: 100%;
    border-radius: 0; } }

md-divider {
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  margin: 0; }
  md-divider[md-inset] {
    margin-left: 80px; }

.layout-row > md-divider {
  border-top-width: 0;
  border-right-width: 1px;
  border-right-style: solid; }

md-fab-speed-dial {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 20;
  /*
   * Hide some graphics glitches if switching animation types
   */
  /*
   * Handle the animations
   */ }
  md-fab-speed-dial.md-fab-bottom-right {
    top: auto;
    right: 20px;
    bottom: 20px;
    left: auto;
    position: absolute; }
  md-fab-speed-dial.md-fab-bottom-left {
    top: auto;
    right: auto;
    bottom: 20px;
    left: 20px;
    position: absolute; }
  md-fab-speed-dial.md-fab-top-right {
    top: 20px;
    right: 20px;
    bottom: auto;
    left: auto;
    position: absolute; }
  md-fab-speed-dial.md-fab-top-left {
    top: 20px;
    right: auto;
    bottom: auto;
    left: 20px;
    position: absolute; }
  md-fab-speed-dial:not(.md-hover-full) {
    pointer-events: none; }
    md-fab-speed-dial:not(.md-hover-full) md-fab-trigger, md-fab-speed-dial:not(.md-hover-full) .md-fab-action-item {
      pointer-events: auto; }
    md-fab-speed-dial:not(.md-hover-full).md-is-open {
      pointer-events: auto; }
  md-fab-speed-dial .md-css-variables {
    z-index: 20; }
  md-fab-speed-dial.md-is-open .md-fab-action-item {
    align-items: center; }
  md-fab-speed-dial md-fab-actions {
    display: flex;
    height: auto; }
    md-fab-speed-dial md-fab-actions .md-fab-action-item {
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-fab-speed-dial.md-down {
    flex-direction: column; }
    md-fab-speed-dial.md-down md-fab-trigger {
      order: 1; }
    md-fab-speed-dial.md-down md-fab-actions {
      flex-direction: column;
      order: 2; }
  md-fab-speed-dial.md-up {
    flex-direction: column; }
    md-fab-speed-dial.md-up md-fab-trigger {
      order: 2; }
    md-fab-speed-dial.md-up md-fab-actions {
      flex-direction: column-reverse;
      order: 1; }
  md-fab-speed-dial.md-left {
    flex-direction: row; }
    md-fab-speed-dial.md-left md-fab-trigger {
      order: 2; }
    md-fab-speed-dial.md-left md-fab-actions {
      flex-direction: row-reverse;
      order: 1; }
      md-fab-speed-dial.md-left md-fab-actions .md-fab-action-item {
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-fab-speed-dial.md-right {
    flex-direction: row; }
    md-fab-speed-dial.md-right md-fab-trigger {
      order: 1; }
    md-fab-speed-dial.md-right md-fab-actions {
      flex-direction: row;
      order: 2; }
      md-fab-speed-dial.md-right md-fab-actions .md-fab-action-item {
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-fab-speed-dial.md-fling-remove .md-fab-action-item > *, md-fab-speed-dial.md-scale-remove .md-fab-action-item > * {
    visibility: hidden; }
  md-fab-speed-dial.md-fling .md-fab-action-item {
    opacity: 1; }
  md-fab-speed-dial.md-fling.md-animations-waiting .md-fab-action-item {
    opacity: 0;
    transition-duration: 0s; }
  md-fab-speed-dial.md-scale .md-fab-action-item {
    transform: scale(0);
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-duration: 0.14286s; }

md-fab-toolbar {
  display: block;
  /*
   * Closed styling
   */
  /*
   * Hover styling
   */ }
  md-fab-toolbar.md-fab-bottom-right {
    top: auto;
    right: 20px;
    bottom: 20px;
    left: auto;
    position: absolute; }
  md-fab-toolbar.md-fab-bottom-left {
    top: auto;
    right: auto;
    bottom: 20px;
    left: 20px;
    position: absolute; }
  md-fab-toolbar.md-fab-top-right {
    top: 20px;
    right: 20px;
    bottom: auto;
    left: auto;
    position: absolute; }
  md-fab-toolbar.md-fab-top-left {
    top: 20px;
    right: auto;
    bottom: auto;
    left: 20px;
    position: absolute; }
  md-fab-toolbar .md-fab-toolbar-wrapper {
    display: block;
    position: relative;
    overflow: hidden;
    height: 68px; }
  md-fab-toolbar md-fab-trigger {
    position: absolute;
    z-index: 20; }
    md-fab-toolbar md-fab-trigger button {
      overflow: visible !important; }
    md-fab-toolbar md-fab-trigger .md-fab-toolbar-background {
      display: block;
      position: absolute;
      z-index: 21;
      opacity: 1;
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
    md-fab-toolbar md-fab-trigger md-icon {
      position: relative;
      z-index: 22;
      opacity: 1;
      transition: all 200ms ease-in; }
  md-fab-toolbar.md-left md-fab-trigger {
    right: 0; }
  md-fab-toolbar.md-left .md-toolbar-tools {
    flex-direction: row-reverse; }
    md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
      margin-right: 0.6rem; }
    md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
      margin-left: -0.8rem; }
    md-fab-toolbar.md-left .md-toolbar-tools > .md-button:last-child {
      margin-right: 8px; }
  md-fab-toolbar.md-right md-fab-trigger {
    left: 0; }
  md-fab-toolbar.md-right .md-toolbar-tools {
    flex-direction: row; }
  md-fab-toolbar md-toolbar {
    background-color: transparent !important;
    pointer-events: none;
    z-index: 23; }
    md-fab-toolbar md-toolbar .md-toolbar-tools {
      padding: 0 20px;
      margin-top: 3px; }
    md-fab-toolbar md-toolbar .md-fab-action-item {
      opacity: 0;
      transform: scale(0);
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
      transition-duration: 0.15s; }
  md-fab-toolbar.md-is-open md-fab-trigger > button {
    box-shadow: none; }
    md-fab-toolbar.md-is-open md-fab-trigger > button md-icon {
      opacity: 0; }
  md-fab-toolbar.md-is-open .md-fab-action-item {
    opacity: 1;
    transform: scale(1); }

md-grid-list {
  box-sizing: border-box;
  display: block;
  position: relative; }
  md-grid-list md-grid-tile,
  md-grid-list md-grid-tile > figure,
  md-grid-list md-grid-tile-header,
  md-grid-list md-grid-tile-footer {
    box-sizing: border-box; }
  md-grid-list md-grid-tile {
    display: block;
    position: absolute; }
    md-grid-list md-grid-tile figure {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0;
      margin: 0; }
    md-grid-list md-grid-tile md-grid-tile-header,
    md-grid-list md-grid-tile md-grid-tile-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 48px;
      color: #fff;
      background: rgba(0, 0, 0, 0.18);
      overflow: hidden;
      position: absolute;
      left: 0;
      right: 0; }
      md-grid-list md-grid-tile md-grid-tile-header h3,
      md-grid-list md-grid-tile md-grid-tile-header h4,
      md-grid-list md-grid-tile md-grid-tile-footer h3,
      md-grid-list md-grid-tile md-grid-tile-footer h4 {
        font-weight: 400;
        margin: 0 0 0 16px; }
      md-grid-list md-grid-tile md-grid-tile-header h3,
      md-grid-list md-grid-tile md-grid-tile-footer h3 {
        font-size: 14px; }
      md-grid-list md-grid-tile md-grid-tile-header h4,
      md-grid-list md-grid-tile md-grid-tile-footer h4 {
        font-size: 12px; }
    md-grid-list md-grid-tile md-grid-tile-header {
      top: 0; }
    md-grid-list md-grid-tile md-grid-tile-footer {
      bottom: 0; }

@media screen and (-ms-high-contrast: active) {
  md-grid-tile {
    border: 1px solid #fff; }
  md-grid-tile-footer {
    border-top: 1px solid #fff; } }

md-icon {
  margin: auto;
  background-repeat: no-repeat no-repeat;
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  height: 24px;
  width: 24px; }
  md-icon svg {
    pointer-events: none;
    display: block; }
  md-icon[md-font-icon] {
    line-height: 1;
    width: auto; }

md-input-container {
  display: inline-block;
  position: relative;
  padding: 2px;
  margin: 18px 0;
  vertical-align: middle;
  /*
   * The .md-input class is added to the input/textarea
   */ }
  md-input-container:after {
    content: '';
    display: table;
    clear: both; }
  md-input-container.md-block {
    display: block; }
  md-input-container .md-errors-spacer {
    float: right;
    min-height: 24px;
    min-width: 1px; }
    html[dir=rtl] md-input-container .md-errors-spacer {
      float: left;
      unicode-bidi: embed; }
    body[dir=rtl] md-input-container .md-errors-spacer {
      float: left;
      unicode-bidi: embed; }
    md-input-container .md-errors-spacer bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-input-container .md-errors-spacer bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
  md-input-container > md-icon {
    position: absolute;
    top: 5px;
    left: 2px;
    right: auto; }
    html[dir=rtl] md-input-container > md-icon {
      left: auto;
      unicode-bidi: embed; }
    body[dir=rtl] md-input-container > md-icon {
      left: auto;
      unicode-bidi: embed; }
    md-input-container > md-icon bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-input-container > md-icon bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    html[dir=rtl] md-input-container > md-icon {
      right: 2px;
      unicode-bidi: embed; }
    body[dir=rtl] md-input-container > md-icon {
      right: 2px;
      unicode-bidi: embed; }
    md-input-container > md-icon bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-input-container > md-icon bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
  md-input-container textarea,
  md-input-container input[type="text"],
  md-input-container input[type="password"],
  md-input-container input[type="datetime"],
  md-input-container input[type="datetime-local"],
  md-input-container input[type="date"],
  md-input-container input[type="month"],
  md-input-container input[type="time"],
  md-input-container input[type="week"],
  md-input-container input[type="number"],
  md-input-container input[type="email"],
  md-input-container input[type="url"],
  md-input-container input[type="search"],
  md-input-container input[type="tel"],
  md-input-container input[type="color"] {
    /* remove default appearance from all input/textarea */
    -moz-appearance: none;
    -webkit-appearance: none; }
  md-input-container input[type="date"],
  md-input-container input[type="datetime-local"],
  md-input-container input[type="month"],
  md-input-container input[type="time"],
  md-input-container input[type="week"] {
    min-height: 26px; }
  md-input-container textarea {
    resize: none;
    overflow: hidden; }
  md-input-container textarea.md-input {
    min-height: 26px;
    -ms-flex-preferred-size: auto; }
  md-input-container label:not(.md-container-ignore) {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: auto; }
    html[dir=rtl] md-input-container label:not(.md-container-ignore) {
      left: auto;
      unicode-bidi: embed; }
    body[dir=rtl] md-input-container label:not(.md-container-ignore) {
      left: auto;
      unicode-bidi: embed; }
    md-input-container label:not(.md-container-ignore) bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-input-container label:not(.md-container-ignore) bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    html[dir=rtl] md-input-container label:not(.md-container-ignore) {
      right: 0;
      unicode-bidi: embed; }
    body[dir=rtl] md-input-container label:not(.md-container-ignore) {
      right: 0;
      unicode-bidi: embed; }
    md-input-container label:not(.md-container-ignore) bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-input-container label:not(.md-container-ignore) bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
  md-input-container label:not(.md-no-float):not(.md-container-ignore),
  md-input-container .md-placeholder {
    order: 1;
    pointer-events: none;
    -webkit-font-smoothing: antialiased;
    padding-left: 3px;
    padding-right: 0;
    z-index: 1;
    transform: translate3d(0, 28px, 0) scale(1);
    transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.25s;
    transform-origin: left top; }
    html[dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), html[dir=rtl]
    md-input-container .md-placeholder {
      padding-left: 0;
      unicode-bidi: embed; }
    body[dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), body[dir=rtl]
    md-input-container .md-placeholder {
      padding-left: 0;
      unicode-bidi: embed; }
    md-input-container label:not(.md-no-float):not(.md-container-ignore) bdo[dir=rtl],
    md-input-container .md-placeholder bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-input-container label:not(.md-no-float):not(.md-container-ignore) bdo[dir=ltr],
    md-input-container .md-placeholder bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    html[dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), html[dir=rtl]
    md-input-container .md-placeholder {
      padding-right: 3px;
      unicode-bidi: embed; }
    body[dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), body[dir=rtl]
    md-input-container .md-placeholder {
      padding-right: 3px;
      unicode-bidi: embed; }
    md-input-container label:not(.md-no-float):not(.md-container-ignore) bdo[dir=rtl],
    md-input-container .md-placeholder bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-input-container label:not(.md-no-float):not(.md-container-ignore) bdo[dir=ltr],
    md-input-container .md-placeholder bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    html[dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), html[dir=rtl]
    md-input-container .md-placeholder {
      transform-origin: right top;
      unicode-bidi: embed; }
    body[dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), body[dir=rtl]
    md-input-container .md-placeholder {
      transform-origin: right top;
      unicode-bidi: embed; }
    md-input-container label:not(.md-no-float):not(.md-container-ignore) bdo[dir=rtl],
    md-input-container .md-placeholder bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-input-container label:not(.md-no-float):not(.md-container-ignore) bdo[dir=ltr],
    md-input-container .md-placeholder bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
  md-input-container .md-placeholder {
    position: absolute;
    top: 0;
    opacity: 0;
    transition-property: opacity, transform;
    transform: translate3d(0, 30px, 0); }
  md-input-container.md-input-focused .md-placeholder {
    opacity: 1;
    transform: translate3d(0, 24px, 0); }
  md-input-container.md-input-has-value .md-placeholder {
    transition: none;
    opacity: 0; }
  md-input-container:not(.md-input-has-value) input:not(:focus),
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-ampm-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-day-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-hour-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-millisecond-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-minute-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-month-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-second-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-week-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-year-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-text {
    color: transparent; }
  md-input-container .md-input {
    order: 2;
    display: block;
    margin-top: 0;
    background: none;
    padding-top: 2px;
    padding-bottom: 1px;
    padding-left: 2px;
    padding-right: 2px;
    border-width: 0 0 1px 0;
    line-height: 26px;
    height: 30px;
    -ms-flex-preferred-size: 26px;
    border-radius: 0;
    border-style: solid;
    width: 100%;
    box-sizing: border-box;
    float: left; }
    html[dir=rtl] md-input-container .md-input {
      float: right;
      unicode-bidi: embed; }
    body[dir=rtl] md-input-container .md-input {
      float: right;
      unicode-bidi: embed; }
    md-input-container .md-input bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-input-container .md-input bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    md-input-container .md-input:focus {
      outline: none; }
    md-input-container .md-input:invalid {
      outline: none;
      box-shadow: none; }
    md-input-container .md-input.md-no-flex {
      flex: none !important; }
  md-input-container .md-char-counter {
    text-align: right;
    padding-right: 2px;
    padding-left: 0; }
    html[dir=rtl] md-input-container .md-char-counter {
      text-align: left;
      unicode-bidi: embed; }
    body[dir=rtl] md-input-container .md-char-counter {
      text-align: left;
      unicode-bidi: embed; }
    md-input-container .md-char-counter bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-input-container .md-char-counter bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    html[dir=rtl] md-input-container .md-char-counter {
      padding-right: 0;
      unicode-bidi: embed; }
    body[dir=rtl] md-input-container .md-char-counter {
      padding-right: 0;
      unicode-bidi: embed; }
    md-input-container .md-char-counter bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-input-container .md-char-counter bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    html[dir=rtl] md-input-container .md-char-counter {
      padding-left: 2px;
      unicode-bidi: embed; }
    body[dir=rtl] md-input-container .md-char-counter {
      padding-left: 2px;
      unicode-bidi: embed; }
    md-input-container .md-char-counter bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-input-container .md-char-counter bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
  md-input-container ng-messages, md-input-container data-ng-messages, md-input-container x-ng-messages,
  md-input-container [ng-messages], md-input-container [data-ng-messages], md-input-container [x-ng-messages] {
    position: relative;
    order: 4;
    overflow: hidden;
    clear: left; }
    html[dir=rtl] md-input-container ng-messages, html[dir=rtl] md-input-container data-ng-messages, html[dir=rtl] md-input-container x-ng-messages, html[dir=rtl]
    md-input-container [ng-messages], html[dir=rtl] md-input-container [data-ng-messages], html[dir=rtl] md-input-container [x-ng-messages] {
      clear: right;
      unicode-bidi: embed; }
    body[dir=rtl] md-input-container ng-messages, body[dir=rtl] md-input-container data-ng-messages, body[dir=rtl] md-input-container x-ng-messages, body[dir=rtl]
    md-input-container [ng-messages], body[dir=rtl] md-input-container [data-ng-messages], body[dir=rtl] md-input-container [x-ng-messages] {
      clear: right;
      unicode-bidi: embed; }
    md-input-container ng-messages bdo[dir=rtl], md-input-container data-ng-messages bdo[dir=rtl], md-input-container x-ng-messages bdo[dir=rtl],
    md-input-container [ng-messages] bdo[dir=rtl], md-input-container [data-ng-messages] bdo[dir=rtl], md-input-container [x-ng-messages] bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-input-container ng-messages bdo[dir=ltr], md-input-container data-ng-messages bdo[dir=ltr], md-input-container x-ng-messages bdo[dir=ltr],
    md-input-container [ng-messages] bdo[dir=ltr], md-input-container [data-ng-messages] bdo[dir=ltr], md-input-container [x-ng-messages] bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    md-input-container ng-messages.ng-enter ng-message, md-input-container ng-messages.ng-enter data-ng-message, md-input-container ng-messages.ng-enter x-ng-message,
    md-input-container ng-messages.ng-enter [ng-message], md-input-container ng-messages.ng-enter [data-ng-message], md-input-container ng-messages.ng-enter [x-ng-message],
    md-input-container ng-messages.ng-enter [ng-message-exp], md-input-container ng-messages.ng-enter [data-ng-message-exp], md-input-container ng-messages.ng-enter [x-ng-message-exp], md-input-container data-ng-messages.ng-enter ng-message, md-input-container data-ng-messages.ng-enter data-ng-message, md-input-container data-ng-messages.ng-enter x-ng-message,
    md-input-container data-ng-messages.ng-enter [ng-message], md-input-container data-ng-messages.ng-enter [data-ng-message], md-input-container data-ng-messages.ng-enter [x-ng-message],
    md-input-container data-ng-messages.ng-enter [ng-message-exp], md-input-container data-ng-messages.ng-enter [data-ng-message-exp], md-input-container data-ng-messages.ng-enter [x-ng-message-exp], md-input-container x-ng-messages.ng-enter ng-message, md-input-container x-ng-messages.ng-enter data-ng-message, md-input-container x-ng-messages.ng-enter x-ng-message,
    md-input-container x-ng-messages.ng-enter [ng-message], md-input-container x-ng-messages.ng-enter [data-ng-message], md-input-container x-ng-messages.ng-enter [x-ng-message],
    md-input-container x-ng-messages.ng-enter [ng-message-exp], md-input-container x-ng-messages.ng-enter [data-ng-message-exp], md-input-container x-ng-messages.ng-enter [x-ng-message-exp],
    md-input-container [ng-messages].ng-enter ng-message,
    md-input-container [ng-messages].ng-enter data-ng-message,
    md-input-container [ng-messages].ng-enter x-ng-message,
    md-input-container [ng-messages].ng-enter [ng-message],
    md-input-container [ng-messages].ng-enter [data-ng-message],
    md-input-container [ng-messages].ng-enter [x-ng-message],
    md-input-container [ng-messages].ng-enter [ng-message-exp],
    md-input-container [ng-messages].ng-enter [data-ng-message-exp],
    md-input-container [ng-messages].ng-enter [x-ng-message-exp], md-input-container [data-ng-messages].ng-enter ng-message, md-input-container [data-ng-messages].ng-enter data-ng-message, md-input-container [data-ng-messages].ng-enter x-ng-message,
    md-input-container [data-ng-messages].ng-enter [ng-message], md-input-container [data-ng-messages].ng-enter [data-ng-message], md-input-container [data-ng-messages].ng-enter [x-ng-message],
    md-input-container [data-ng-messages].ng-enter [ng-message-exp], md-input-container [data-ng-messages].ng-enter [data-ng-message-exp], md-input-container [data-ng-messages].ng-enter [x-ng-message-exp], md-input-container [x-ng-messages].ng-enter ng-message, md-input-container [x-ng-messages].ng-enter data-ng-message, md-input-container [x-ng-messages].ng-enter x-ng-message,
    md-input-container [x-ng-messages].ng-enter [ng-message], md-input-container [x-ng-messages].ng-enter [data-ng-message], md-input-container [x-ng-messages].ng-enter [x-ng-message],
    md-input-container [x-ng-messages].ng-enter [ng-message-exp], md-input-container [x-ng-messages].ng-enter [data-ng-message-exp], md-input-container [x-ng-messages].ng-enter [x-ng-message-exp] {
      opacity: 0;
      margin-top: -100px; }
  md-input-container ng-message, md-input-container data-ng-message, md-input-container x-ng-message,
  md-input-container [ng-message], md-input-container [data-ng-message], md-input-container [x-ng-message],
  md-input-container [ng-message-exp], md-input-container [data-ng-message-exp], md-input-container [x-ng-message-exp],
  md-input-container .md-char-counter {
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    opacity: 1;
    margin-top: 0;
    padding-top: 5px; }
    md-input-container ng-message:not(.md-char-counter), md-input-container data-ng-message:not(.md-char-counter), md-input-container x-ng-message:not(.md-char-counter),
    md-input-container [ng-message]:not(.md-char-counter), md-input-container [data-ng-message]:not(.md-char-counter), md-input-container [x-ng-message]:not(.md-char-counter),
    md-input-container [ng-message-exp]:not(.md-char-counter), md-input-container [data-ng-message-exp]:not(.md-char-counter), md-input-container [x-ng-message-exp]:not(.md-char-counter),
    md-input-container .md-char-counter:not(.md-char-counter) {
      padding-right: 5px;
      padding-left: 0; }
      html[dir=rtl] md-input-container ng-message:not(.md-char-counter), html[dir=rtl] md-input-container data-ng-message:not(.md-char-counter), html[dir=rtl] md-input-container x-ng-message:not(.md-char-counter), html[dir=rtl]
      md-input-container [ng-message]:not(.md-char-counter), html[dir=rtl] md-input-container [data-ng-message]:not(.md-char-counter), html[dir=rtl] md-input-container [x-ng-message]:not(.md-char-counter), html[dir=rtl]
      md-input-container [ng-message-exp]:not(.md-char-counter), html[dir=rtl] md-input-container [data-ng-message-exp]:not(.md-char-counter), html[dir=rtl] md-input-container [x-ng-message-exp]:not(.md-char-counter), html[dir=rtl]
      md-input-container .md-char-counter:not(.md-char-counter) {
        padding-right: 0;
        unicode-bidi: embed; }
      body[dir=rtl] md-input-container ng-message:not(.md-char-counter), body[dir=rtl] md-input-container data-ng-message:not(.md-char-counter), body[dir=rtl] md-input-container x-ng-message:not(.md-char-counter), body[dir=rtl]
      md-input-container [ng-message]:not(.md-char-counter), body[dir=rtl] md-input-container [data-ng-message]:not(.md-char-counter), body[dir=rtl] md-input-container [x-ng-message]:not(.md-char-counter), body[dir=rtl]
      md-input-container [ng-message-exp]:not(.md-char-counter), body[dir=rtl] md-input-container [data-ng-message-exp]:not(.md-char-counter), body[dir=rtl] md-input-container [x-ng-message-exp]:not(.md-char-counter), body[dir=rtl]
      md-input-container .md-char-counter:not(.md-char-counter) {
        padding-right: 0;
        unicode-bidi: embed; }
      md-input-container ng-message:not(.md-char-counter) bdo[dir=rtl], md-input-container data-ng-message:not(.md-char-counter) bdo[dir=rtl], md-input-container x-ng-message:not(.md-char-counter) bdo[dir=rtl],
      md-input-container [ng-message]:not(.md-char-counter) bdo[dir=rtl], md-input-container [data-ng-message]:not(.md-char-counter) bdo[dir=rtl], md-input-container [x-ng-message]:not(.md-char-counter) bdo[dir=rtl],
      md-input-container [ng-message-exp]:not(.md-char-counter) bdo[dir=rtl], md-input-container [data-ng-message-exp]:not(.md-char-counter) bdo[dir=rtl], md-input-container [x-ng-message-exp]:not(.md-char-counter) bdo[dir=rtl],
      md-input-container .md-char-counter:not(.md-char-counter) bdo[dir=rtl] {
        direction: rtl;
        unicode-bidi: bidi-override; }
      md-input-container ng-message:not(.md-char-counter) bdo[dir=ltr], md-input-container data-ng-message:not(.md-char-counter) bdo[dir=ltr], md-input-container x-ng-message:not(.md-char-counter) bdo[dir=ltr],
      md-input-container [ng-message]:not(.md-char-counter) bdo[dir=ltr], md-input-container [data-ng-message]:not(.md-char-counter) bdo[dir=ltr], md-input-container [x-ng-message]:not(.md-char-counter) bdo[dir=ltr],
      md-input-container [ng-message-exp]:not(.md-char-counter) bdo[dir=ltr], md-input-container [data-ng-message-exp]:not(.md-char-counter) bdo[dir=ltr], md-input-container [x-ng-message-exp]:not(.md-char-counter) bdo[dir=ltr],
      md-input-container .md-char-counter:not(.md-char-counter) bdo[dir=ltr] {
        direction: ltr;
        unicode-bidi: bidi-override; }
      html[dir=rtl] md-input-container ng-message:not(.md-char-counter), html[dir=rtl] md-input-container data-ng-message:not(.md-char-counter), html[dir=rtl] md-input-container x-ng-message:not(.md-char-counter), html[dir=rtl]
      md-input-container [ng-message]:not(.md-char-counter), html[dir=rtl] md-input-container [data-ng-message]:not(.md-char-counter), html[dir=rtl] md-input-container [x-ng-message]:not(.md-char-counter), html[dir=rtl]
      md-input-container [ng-message-exp]:not(.md-char-counter), html[dir=rtl] md-input-container [data-ng-message-exp]:not(.md-char-counter), html[dir=rtl] md-input-container [x-ng-message-exp]:not(.md-char-counter), html[dir=rtl]
      md-input-container .md-char-counter:not(.md-char-counter) {
        padding-left: 5px;
        unicode-bidi: embed; }
      body[dir=rtl] md-input-container ng-message:not(.md-char-counter), body[dir=rtl] md-input-container data-ng-message:not(.md-char-counter), body[dir=rtl] md-input-container x-ng-message:not(.md-char-counter), body[dir=rtl]
      md-input-container [ng-message]:not(.md-char-counter), body[dir=rtl] md-input-container [data-ng-message]:not(.md-char-counter), body[dir=rtl] md-input-container [x-ng-message]:not(.md-char-counter), body[dir=rtl]
      md-input-container [ng-message-exp]:not(.md-char-counter), body[dir=rtl] md-input-container [data-ng-message-exp]:not(.md-char-counter), body[dir=rtl] md-input-container [x-ng-message-exp]:not(.md-char-counter), body[dir=rtl]
      md-input-container .md-char-counter:not(.md-char-counter) {
        padding-left: 5px;
        unicode-bidi: embed; }
      md-input-container ng-message:not(.md-char-counter) bdo[dir=rtl], md-input-container data-ng-message:not(.md-char-counter) bdo[dir=rtl], md-input-container x-ng-message:not(.md-char-counter) bdo[dir=rtl],
      md-input-container [ng-message]:not(.md-char-counter) bdo[dir=rtl], md-input-container [data-ng-message]:not(.md-char-counter) bdo[dir=rtl], md-input-container [x-ng-message]:not(.md-char-counter) bdo[dir=rtl],
      md-input-container [ng-message-exp]:not(.md-char-counter) bdo[dir=rtl], md-input-container [data-ng-message-exp]:not(.md-char-counter) bdo[dir=rtl], md-input-container [x-ng-message-exp]:not(.md-char-counter) bdo[dir=rtl],
      md-input-container .md-char-counter:not(.md-char-counter) bdo[dir=rtl] {
        direction: rtl;
        unicode-bidi: bidi-override; }
      md-input-container ng-message:not(.md-char-counter) bdo[dir=ltr], md-input-container data-ng-message:not(.md-char-counter) bdo[dir=ltr], md-input-container x-ng-message:not(.md-char-counter) bdo[dir=ltr],
      md-input-container [ng-message]:not(.md-char-counter) bdo[dir=ltr], md-input-container [data-ng-message]:not(.md-char-counter) bdo[dir=ltr], md-input-container [x-ng-message]:not(.md-char-counter) bdo[dir=ltr],
      md-input-container [ng-message-exp]:not(.md-char-counter) bdo[dir=ltr], md-input-container [data-ng-message-exp]:not(.md-char-counter) bdo[dir=ltr], md-input-container [x-ng-message-exp]:not(.md-char-counter) bdo[dir=ltr],
      md-input-container .md-char-counter:not(.md-char-counter) bdo[dir=ltr] {
        direction: ltr;
        unicode-bidi: bidi-override; }
  md-input-container:not(.md-input-invalid) .md-auto-hide .md-input-message-animation {
    opacity: 0;
    margin-top: -100px; }
  md-input-container .md-auto-hide .md-input-message-animation:not(.ng-animate) {
    opacity: 0;
    margin-top: -100px; }
  md-input-container .md-input-message-animation.ng-enter {
    opacity: 0;
    margin-top: -100px; }
  md-input-container.md-input-focused label:not(.md-no-float), md-input-container.md-input-has-placeholder label:not(.md-no-float), md-input-container.md-input-has-value label:not(.md-no-float) {
    transform: translate3d(0, 6px, 0) scale(0.75); }
  md-input-container.md-input-has-value label {
    transition: none; }
  md-input-container.md-input-focused .md-input,
  md-input-container .md-input.ng-invalid.ng-dirty {
    padding-bottom: 0;
    border-width: 0 0 2px 0; }
  md-input-container .md-input[disabled],
  [disabled] md-input-container .md-input {
    background-position: 0 bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;
    margin-bottom: -1px; }
  md-input-container.md-icon-float {
    transition: margin-top 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); }
    md-input-container.md-icon-float > label {
      pointer-events: none;
      position: absolute; }
    md-input-container.md-icon-float > md-icon {
      top: 2px;
      left: 2px;
      right: auto; }
      html[dir=rtl] md-input-container.md-icon-float > md-icon {
        left: auto;
        unicode-bidi: embed; }
      body[dir=rtl] md-input-container.md-icon-float > md-icon {
        left: auto;
        unicode-bidi: embed; }
      md-input-container.md-icon-float > md-icon bdo[dir=rtl] {
        direction: rtl;
        unicode-bidi: bidi-override; }
      md-input-container.md-icon-float > md-icon bdo[dir=ltr] {
        direction: ltr;
        unicode-bidi: bidi-override; }
      html[dir=rtl] md-input-container.md-icon-float > md-icon {
        right: 2px;
        unicode-bidi: embed; }
      body[dir=rtl] md-input-container.md-icon-float > md-icon {
        right: 2px;
        unicode-bidi: embed; }
      md-input-container.md-icon-float > md-icon bdo[dir=rtl] {
        direction: rtl;
        unicode-bidi: bidi-override; }
      md-input-container.md-icon-float > md-icon bdo[dir=ltr] {
        direction: ltr;
        unicode-bidi: bidi-override; }
    md-input-container.md-icon-float.md-input-focused label, md-input-container.md-icon-float.md-input-has-value label {
      transform: translate3d(0, 6px, 0) scale(0.75);
      transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.5s; }
  md-input-container.md-icon-left {
    padding-left: 36px;
    padding-right: 0; }
    html[dir=rtl] md-input-container.md-icon-left {
      padding-left: 0;
      unicode-bidi: embed; }
    body[dir=rtl] md-input-container.md-icon-left {
      padding-left: 0;
      unicode-bidi: embed; }
    md-input-container.md-icon-left bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-input-container.md-icon-left bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    html[dir=rtl] md-input-container.md-icon-left {
      padding-right: 36px;
      unicode-bidi: embed; }
    body[dir=rtl] md-input-container.md-icon-left {
      padding-right: 36px;
      unicode-bidi: embed; }
    md-input-container.md-icon-left bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-input-container.md-icon-left bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    md-input-container.md-icon-left > label {
      left: 36px;
      right: auto; }
      html[dir=rtl] md-input-container.md-icon-left > label {
        left: auto;
        unicode-bidi: embed; }
      body[dir=rtl] md-input-container.md-icon-left > label {
        left: auto;
        unicode-bidi: embed; }
      md-input-container.md-icon-left > label bdo[dir=rtl] {
        direction: rtl;
        unicode-bidi: bidi-override; }
      md-input-container.md-icon-left > label bdo[dir=ltr] {
        direction: ltr;
        unicode-bidi: bidi-override; }
      html[dir=rtl] md-input-container.md-icon-left > label {
        right: 36px;
        unicode-bidi: embed; }
      body[dir=rtl] md-input-container.md-icon-left > label {
        right: 36px;
        unicode-bidi: embed; }
      md-input-container.md-icon-left > label bdo[dir=rtl] {
        direction: rtl;
        unicode-bidi: bidi-override; }
      md-input-container.md-icon-left > label bdo[dir=ltr] {
        direction: ltr;
        unicode-bidi: bidi-override; }
  md-input-container.md-icon-right {
    padding-left: 0;
    padding-right: 36px; }
    html[dir=rtl] md-input-container.md-icon-right {
      padding-left: 36px;
      unicode-bidi: embed; }
    body[dir=rtl] md-input-container.md-icon-right {
      padding-left: 36px;
      unicode-bidi: embed; }
    md-input-container.md-icon-right bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-input-container.md-icon-right bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    html[dir=rtl] md-input-container.md-icon-right {
      padding-right: 0;
      unicode-bidi: embed; }
    body[dir=rtl] md-input-container.md-icon-right {
      padding-right: 0;
      unicode-bidi: embed; }
    md-input-container.md-icon-right bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-input-container.md-icon-right bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    md-input-container.md-icon-right > md-icon:last-of-type {
      margin: 0;
      right: 2px;
      left: auto; }
      html[dir=rtl] md-input-container.md-icon-right > md-icon:last-of-type {
        right: auto;
        unicode-bidi: embed; }
      body[dir=rtl] md-input-container.md-icon-right > md-icon:last-of-type {
        right: auto;
        unicode-bidi: embed; }
      md-input-container.md-icon-right > md-icon:last-of-type bdo[dir=rtl] {
        direction: rtl;
        unicode-bidi: bidi-override; }
      md-input-container.md-icon-right > md-icon:last-of-type bdo[dir=ltr] {
        direction: ltr;
        unicode-bidi: bidi-override; }
      html[dir=rtl] md-input-container.md-icon-right > md-icon:last-of-type {
        left: 2px;
        unicode-bidi: embed; }
      body[dir=rtl] md-input-container.md-icon-right > md-icon:last-of-type {
        left: 2px;
        unicode-bidi: embed; }
      md-input-container.md-icon-right > md-icon:last-of-type bdo[dir=rtl] {
        direction: rtl;
        unicode-bidi: bidi-override; }
      md-input-container.md-icon-right > md-icon:last-of-type bdo[dir=ltr] {
        direction: ltr;
        unicode-bidi: bidi-override; }
  md-input-container.md-icon-left.md-icon-right {
    padding-left: 36px;
    padding-right: 36px; }

@media screen and (-ms-high-contrast: active) {
  md-input-container.md-default-theme > md-icon {
    fill: #fff; } }

md-list {
  display: block;
  padding: 8px 0px 8px 0px; }
  md-list .md-subheader {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.010em;
    line-height: 1.2em; }

md-list-item {
  position: relative; }
  md-list-item.md-proxy-focus.md-focused .md-no-style {
    transition: background-color 0.15s linear; }
  md-list-item.md-no-proxy,
  md-list-item .md-no-style {
    position: relative;
    padding: 0px 16px;
    flex: 1 1 auto; }
    md-list-item.md-no-proxy.md-button,
    md-list-item .md-no-style.md-button {
      font-size: inherit;
      height: inherit;
      text-align: left;
      text-transform: none;
      width: 100%;
      white-space: normal;
      flex-direction: inherit;
      align-items: inherit;
      border-radius: 0; }
      md-list-item.md-no-proxy.md-button > .md-ripple-container,
      md-list-item .md-no-style.md-button > .md-ripple-container {
        border-radius: 0; }
    md-list-item.md-no-proxy:focus,
    md-list-item .md-no-style:focus {
      outline: none; }
  md-list-item.md-with-secondary {
    position: relative; }
  md-list-item.md-clickable:hover {
    cursor: pointer; }
  md-list-item md-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
    md-list-item md-divider[md-inset] {
      left: 96px;
      width: calc(100% - 96px);
      margin: 0; }

md-list-item, md-list-item .md-list-item-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 48px;
  height: auto; }
  md-list-item > div.md-primary > md-icon:not(.md-avatar-icon),
  md-list-item > div.md-secondary > md-icon:not(.md-avatar-icon),
  md-list-item > md-icon:first-child:not(.md-avatar-icon),
  md-list-item > md-icon.md-secondary:not(.md-avatar-icon), md-list-item .md-list-item-inner > div.md-primary > md-icon:not(.md-avatar-icon),
  md-list-item .md-list-item-inner > div.md-secondary > md-icon:not(.md-avatar-icon),
  md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon),
  md-list-item .md-list-item-inner > md-icon.md-secondary:not(.md-avatar-icon) {
    width: 24px;
    margin-top: 16px;
    margin-bottom: 12px;
    box-sizing: content-box; }
  md-list-item > div.md-primary > md-checkbox,
  md-list-item > div.md-secondary > md-checkbox,
  md-list-item > md-checkbox,
  md-list-item md-checkbox.md-secondary, md-list-item .md-list-item-inner > div.md-primary > md-checkbox,
  md-list-item .md-list-item-inner > div.md-secondary > md-checkbox,
  md-list-item .md-list-item-inner > md-checkbox,
  md-list-item .md-list-item-inner md-checkbox.md-secondary {
    align-self: center; }
    md-list-item > div.md-primary > md-checkbox .md-label,
    md-list-item > div.md-secondary > md-checkbox .md-label,
    md-list-item > md-checkbox .md-label,
    md-list-item md-checkbox.md-secondary .md-label, md-list-item .md-list-item-inner > div.md-primary > md-checkbox .md-label,
    md-list-item .md-list-item-inner > div.md-secondary > md-checkbox .md-label,
    md-list-item .md-list-item-inner > md-checkbox .md-label,
    md-list-item .md-list-item-inner md-checkbox.md-secondary .md-label {
      display: none; }
  md-list-item > md-icon:first-child:not(.md-avatar-icon), md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
    margin-right: 32px; }
  md-list-item > md-checkbox, md-list-item .md-list-item-inner > md-checkbox {
    width: 24px;
    margin-left: 3px;
    margin-right: 29px;
    margin-top: 16px; }
  md-list-item .md-avatar, md-list-item .md-avatar-icon, md-list-item .md-list-item-inner .md-avatar, md-list-item .md-list-item-inner .md-avatar-icon {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 16px;
    border-radius: 50%;
    box-sizing: content-box; }
  md-list-item .md-avatar, md-list-item .md-list-item-inner .md-avatar {
    width: 40px;
    height: 40px; }
  md-list-item .md-avatar-icon, md-list-item .md-list-item-inner .md-avatar-icon {
    padding: 8px; }
  md-list-item md-checkbox.md-secondary,
  md-list-item md-switch.md-secondary, md-list-item .md-list-item-inner md-checkbox.md-secondary,
  md-list-item .md-list-item-inner md-switch.md-secondary {
    margin-top: 0;
    margin-bottom: 0; }
  md-list-item md-checkbox.md-secondary, md-list-item .md-list-item-inner md-checkbox.md-secondary {
    margin-right: 0; }
  md-list-item md-switch.md-secondary, md-list-item .md-list-item-inner md-switch.md-secondary {
    margin-right: -6px; }
  md-list-item button.md-button.md-secondary-container, md-list-item .md-list-item-inner button.md-button.md-secondary-container {
    background-color: transparent;
    align-self: center;
    border-radius: 50%;
    margin: 0px;
    min-width: 0px; }
    md-list-item button.md-button.md-secondary-container .md-ripple,
    md-list-item button.md-button.md-secondary-container .md-ripple-container, md-list-item .md-list-item-inner button.md-button.md-secondary-container .md-ripple,
    md-list-item .md-list-item-inner button.md-button.md-secondary-container .md-ripple-container {
      border-radius: 50%; }
    md-list-item button.md-button.md-secondary-container.md-icon-button, md-list-item .md-list-item-inner button.md-button.md-secondary-container.md-icon-button {
      margin-right: -12px; }
  md-list-item .md-secondary-container,
  md-list-item .md-secondary, md-list-item .md-list-item-inner .md-secondary-container,
  md-list-item .md-list-item-inner .md-secondary {
    position: absolute;
    top: 50%;
    right: 16px;
    margin: 0 0 0 16px;
    transform: translate3d(0, -50%, 0); }
  md-list-item > .md-button.md-secondary-container > .md-secondary, md-list-item .md-list-item-inner > .md-button.md-secondary-container > .md-secondary {
    margin-left: 0;
    position: static; }
  md-list-item > p, md-list-item > .md-list-item-inner > p, md-list-item .md-list-item-inner > p, md-list-item .md-list-item-inner > .md-list-item-inner > p {
    flex: 1;
    margin: 0; }

md-list-item.md-2-line,
md-list-item.md-2-line > .md-no-style,
md-list-item.md-3-line,
md-list-item.md-3-line > .md-no-style {
  align-items: flex-start;
  justify-content: center; }
  md-list-item.md-2-line .md-list-item-text,
  md-list-item.md-2-line > .md-no-style .md-list-item-text,
  md-list-item.md-3-line .md-list-item-text,
  md-list-item.md-3-line > .md-no-style .md-list-item-text {
    flex: 1;
    margin: auto;
    text-overflow: ellipsis; }
    md-list-item.md-2-line .md-list-item-text.md-offset,
    md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset,
    md-list-item.md-3-line .md-list-item-text.md-offset,
    md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
      margin-left: 56px; }
    md-list-item.md-2-line .md-list-item-text h3,
    md-list-item.md-2-line > .md-no-style .md-list-item-text h3,
    md-list-item.md-3-line .md-list-item-text h3,
    md-list-item.md-3-line > .md-no-style .md-list-item-text h3 {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.010em;
      margin: 0 0 0px 0;
      line-height: 1.2em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    md-list-item.md-2-line .md-list-item-text h4,
    md-list-item.md-2-line > .md-no-style .md-list-item-text h4,
    md-list-item.md-3-line .md-list-item-text h4,
    md-list-item.md-3-line > .md-no-style .md-list-item-text h4 {
      font-size: 14px;
      letter-spacing: 0.010em;
      margin: 3px 0 1px 0;
      font-weight: 400;
      line-height: 1.2em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    md-list-item.md-2-line .md-list-item-text p,
    md-list-item.md-2-line > .md-no-style .md-list-item-text p,
    md-list-item.md-3-line .md-list-item-text p,
    md-list-item.md-3-line > .md-no-style .md-list-item-text p {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.010em;
      margin: 0 0 0 0;
      line-height: 1.6em; }

md-list-item.md-2-line,
md-list-item.md-2-line > .md-no-style {
  height: auto;
  min-height: 72px; }
  md-list-item.md-2-line.md-long-text,
  md-list-item.md-2-line > .md-no-style.md-long-text {
    margin: 1.6em; }
  md-list-item.md-2-line > .md-avatar, md-list-item.md-2-line .md-avatar-icon,
  md-list-item.md-2-line > .md-no-style > .md-avatar,
  md-list-item.md-2-line > .md-no-style .md-avatar-icon {
    margin-top: 12px; }
  md-list-item.md-2-line > md-icon:first-child,
  md-list-item.md-2-line > .md-no-style > md-icon:first-child {
    align-self: flex-start; }
  md-list-item.md-2-line .md-list-item-text,
  md-list-item.md-2-line > .md-no-style .md-list-item-text {
    flex: 1; }

md-list-item.md-3-line,
md-list-item.md-3-line > .md-no-style {
  height: auto;
  min-height: 88px; }
  md-list-item.md-3-line.md-long-text,
  md-list-item.md-3-line > .md-no-style.md-long-text {
    margin: 1.6em; }
  md-list-item.md-3-line > md-icon:first-child,
  md-list-item.md-3-line > .md-avatar,
  md-list-item.md-3-line > .md-no-style > md-icon:first-child,
  md-list-item.md-3-line > .md-no-style > .md-avatar {
    margin-top: 16px; }

.md-open-menu-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  opacity: 0;
  border-radius: 2px; }
  .md-open-menu-container md-menu-divider {
    margin-top: 4px;
    margin-bottom: 4px;
    height: 1px;
    min-height: 1px;
    max-height: 1px;
    width: 100%; }
  .md-open-menu-container md-menu-content > * {
    opacity: 0; }
  .md-open-menu-container:not(.md-clickable) {
    pointer-events: none; }
  .md-open-menu-container.md-active {
    opacity: 1;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 200ms; }
    .md-open-menu-container.md-active > md-menu-content > * {
      opacity: 1;
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
      transition-duration: 200ms;
      transition-delay: 100ms; }
  .md-open-menu-container.md-leave {
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-duration: 250ms; }

md-menu-content {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  max-height: 304px;
  overflow-y: auto; }
  md-menu-content.md-dense {
    max-height: 208px; }
    md-menu-content.md-dense md-menu-item {
      height: 32px;
      min-height: 0px; }

md-menu-item {
  display: flex;
  flex-direction: row;
  min-height: 48px;
  height: 48px;
  align-content: center;
  justify-content: flex-start; }
  md-menu-item > * {
    width: 100%;
    margin: auto 0;
    padding-left: 16px;
    padding-right: 16px; }
  md-menu-item > a.md-button {
    display: flex; }
  md-menu-item > .md-button {
    border-radius: 0;
    margin: auto 0;
    font-size: 15px;
    text-transform: none;
    font-weight: 400;
    text-align: left;
    text-align: start;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    display: inline-block;
    align-items: baseline;
    align-content: flex-start;
    width: 100%; }
    md-menu-item > .md-button md-icon {
      margin: auto 16px auto 0; }
    md-menu-item > .md-button p {
      display: inline-block;
      margin: auto; }
    md-menu-item > .md-button span {
      margin-top: auto;
      margin-bottom: auto; }
    md-menu-item > .md-button .md-ripple-container {
      border-radius: inherit; }

.md-menu {
  padding: 8px 0; }

md-toolbar .md-menu {
  height: auto;
  margin: auto;
  padding: 0; }

@media (max-width: 959px) {
  md-menu-content {
    min-width: 112px; }
  md-menu-content[width="3"] {
    min-width: 168px; }
  md-menu-content[width="4"] {
    min-width: 224px; }
  md-menu-content[width="5"] {
    min-width: 280px; }
  md-menu-content[width="6"] {
    min-width: 336px; }
  md-menu-content[width="7"] {
    min-width: 392px; } }

@media (min-width: 960px) {
  md-menu-content {
    min-width: 96px; }
  md-menu-content[width="3"] {
    min-width: 192px; }
  md-menu-content[width="4"] {
    min-width: 256px; }
  md-menu-content[width="5"] {
    min-width: 320px; }
  md-menu-content[width="6"] {
    min-width: 384px; }
  md-menu-content[width="7"] {
    min-width: 448px; } }

md-toolbar.md-menu-toolbar h2.md-toolbar-tools {
  line-height: 1rem;
  height: auto;
  padding: 28px;
  padding-bottom: 12px; }

md-menu-bar {
  padding: 0 20px;
  display: block;
  position: relative;
  z-index: 2; }
  md-menu-bar .md-menu {
    display: inline-block;
    padding: 0;
    position: relative; }
  md-menu-bar button {
    font-size: 14px;
    padding: 0 10px;
    margin: 0;
    border: 0;
    background-color: transparent;
    height: 40px; }
  md-menu-bar md-backdrop.md-menu-backdrop {
    z-index: -2; }

md-menu-content.md-menu-bar-menu.md-dense {
  max-height: none;
  padding: 16px 0; }
  md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent {
    position: relative; }
    md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > md-icon {
      position: absolute;
      padding: 0;
      width: 24px;
      top: 6px;
      left: 24px; }
    md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > .md-button, md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent .md-menu > .md-button {
      padding: 0 32px 0 64px; }
  md-menu-content.md-menu-bar-menu.md-dense .md-button {
    min-height: 0;
    height: 32px;
    display: flex; }
    md-menu-content.md-menu-bar-menu.md-dense .md-button span {
      flex-grow: 1; }
    md-menu-content.md-menu-bar-menu.md-dense .md-button span.md-alt-text {
      flex-grow: 0;
      align-self: flex-end;
      margin: 0 8px; }
  md-menu-content.md-menu-bar-menu.md-dense md-menu-divider {
    margin: 8px 0; }
  md-menu-content.md-menu-bar-menu.md-dense md-menu-item > .md-button, md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
    text-align: left;
    text-align: start; }
  md-menu-content.md-menu-bar-menu.md-dense .md-menu {
    padding: 0; }
    md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
      position: relative;
      margin: 0;
      width: 100%;
      text-transform: none;
      font-weight: normal;
      border-radius: 0px;
      padding-left: 16px; }
      md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button:after {
        display: block;
        content: '\25BC';
        position: absolute;
        top: 0px;
        speak: none;
        transform: rotate(270deg) scaleY(0.45) scaleX(0.9);
        right: 28px; }

md-progress-circular {
  display: block;
  position: relative;
  width: 100px;
  height: 100px;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  z-index: 2; }
  md-progress-circular .md-spinner-wrapper {
    display: block;
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%; }
    md-progress-circular .md-spinner-wrapper .md-inner {
      width: 100px;
      height: 100px;
      position: relative; }
      md-progress-circular .md-spinner-wrapper .md-inner .md-gap {
        position: absolute;
        left: 49px;
        right: 49px;
        top: 0;
        bottom: 0;
        border-top-width: 10px;
        border-top-style: solid;
        box-sizing: border-box; }
      md-progress-circular .md-spinner-wrapper .md-inner .md-left, md-progress-circular .md-spinner-wrapper .md-inner .md-right {
        position: absolute;
        top: 0;
        height: 100px;
        width: 50px;
        overflow: hidden; }
        md-progress-circular .md-spinner-wrapper .md-inner .md-left .md-half-circle, md-progress-circular .md-spinner-wrapper .md-inner .md-right .md-half-circle {
          position: absolute;
          top: 0;
          width: 100px;
          height: 100px;
          box-sizing: border-box;
          border-width: 10px;
          border-style: solid;
          border-bottom-color: transparent;
          border-radius: 50%; }
      md-progress-circular .md-spinner-wrapper .md-inner .md-left {
        left: 0; }
        md-progress-circular .md-spinner-wrapper .md-inner .md-left .md-half-circle {
          left: 0;
          border-right-color: transparent; }
      md-progress-circular .md-spinner-wrapper .md-inner .md-right {
        right: 0; }
        md-progress-circular .md-spinner-wrapper .md-inner .md-right .md-half-circle {
          right: 0;
          border-left-color: transparent; }
  md-progress-circular .md-mode-indeterminate .md-spinner-wrapper {
    animation: outer-rotate 2.91667s linear infinite; }
    md-progress-circular .md-mode-indeterminate .md-spinner-wrapper .md-inner {
      animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite; }
      md-progress-circular .md-mode-indeterminate .md-spinner-wrapper .md-inner .md-left .md-half-circle, md-progress-circular .md-mode-indeterminate .md-spinner-wrapper .md-inner .md-right .md-half-circle {
        animation-iteration-count: infinite;
        animation-duration: 1.3125s;
        animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1); }
      md-progress-circular .md-mode-indeterminate .md-spinner-wrapper .md-inner .md-left .md-half-circle {
        animation-name: left-wobble; }
      md-progress-circular .md-mode-indeterminate .md-spinner-wrapper .md-inner .md-right .md-half-circle {
        animation-name: right-wobble; }
  md-progress-circular md-progress-circular.ng-hide .md-spinner-wrapper {
    animation: none; }
    md-progress-circular md-progress-circular.ng-hide .md-spinner-wrapper .md-inner {
      animation: none; }
      md-progress-circular md-progress-circular.ng-hide .md-spinner-wrapper .md-inner .md-left .md-half-circle {
        animation-name: none; }
      md-progress-circular md-progress-circular.ng-hide .md-spinner-wrapper .md-inner .md-right .md-half-circle {
        animation-name: none; }
  md-progress-circular .md-spinner-wrapper.ng-hide {
    animation: none; }
    md-progress-circular .md-spinner-wrapper.ng-hide .md-inner {
      animation: none; }
      md-progress-circular .md-spinner-wrapper.ng-hide .md-inner .md-left .md-half-circle {
        animation-name: none; }
      md-progress-circular .md-spinner-wrapper.ng-hide .md-inner .md-right .md-half-circle {
        animation-name: none; }

@keyframes outer-rotate {
  0% {
    transform: rotate(0deg) scale(0.5); }
  100% {
    transform: rotate(360deg) scale(0.5); } }

@keyframes left-wobble {
  0%, 100% {
    transform: rotate(130deg); }
  50% {
    transform: rotate(-5deg); } }

@keyframes right-wobble {
  0%, 100% {
    transform: rotate(-130deg); }
  50% {
    transform: rotate(5deg); } }

@keyframes sporadic-rotate {
  12.5% {
    transform: rotate(135deg); }
  25% {
    transform: rotate(270deg); }
  37.5% {
    transform: rotate(405deg); }
  50% {
    transform: rotate(540deg); }
  62.5% {
    transform: rotate(675deg); }
  75% {
    transform: rotate(810deg); }
  87.5% {
    transform: rotate(945deg); }
  100% {
    transform: rotate(1080deg); } }

md-progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding-top: 0 !important;
  margin-bottom: 0 !important; }
  md-progress-linear .md-container {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 5px;
    transform: translate(0, 0) scale(1, 1); }
    md-progress-linear .md-container .md-bar {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 5px; }
    md-progress-linear .md-container .md-dashed:before {
      content: "";
      display: none;
      position: absolute;
      margin-top: 0;
      height: 5px;
      width: 100%;
      background-color: transparent;
      background-size: 10px 10px !important;
      background-position: 0px -23px; }
    md-progress-linear .md-container .md-bar1, md-progress-linear .md-container .md-bar2 {
      transition: transform 0.2s linear; }
    md-progress-linear .md-container.md-mode-query .md-bar1 {
      display: none; }
    md-progress-linear .md-container.md-mode-query .md-bar2 {
      transition: all 0.2s linear;
      animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1); }
    md-progress-linear .md-container.md-mode-determinate .md-bar1 {
      display: none; }
    md-progress-linear .md-container.md-mode-indeterminate .md-bar1 {
      animation: md-progress-linear-indeterminate-scale-1 4s infinite, md-progress-linear-indeterminate-1 4s infinite; }
    md-progress-linear .md-container.md-mode-indeterminate .md-bar2 {
      animation: md-progress-linear-indeterminate-scale-2 4s infinite, md-progress-linear-indeterminate-2 4s infinite; }
    md-progress-linear .md-container.ng-hide {
      animation: none; }
      md-progress-linear .md-container.ng-hide .md-bar1 {
        animation-name: none; }
      md-progress-linear .md-container.ng-hide .md-bar2 {
        animation-name: none; }
  md-progress-linear .md-container.md-mode-buffer {
    background-color: transparent !important;
    transition: all 0.2s linear; }
    md-progress-linear .md-container.md-mode-buffer .md-dashed:before {
      display: block;
      animation: buffer 3s infinite linear; }

@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1); }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1); } }

@keyframes buffer {
  0% {
    opacity: 1;
    background-position: 0px -23px; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    background-position: -200px -23px; } }

@keyframes md-progress-linear-indeterminate-scale-1 {
  0% {
    transform: scaleX(0.1);
    animation-timing-function: linear; }
  36.6% {
    transform: scaleX(0.1);
    animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1); }
  69.15% {
    transform: scaleX(0.83);
    animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098); }
  100% {
    transform: scaleX(0.1); } }

@keyframes md-progress-linear-indeterminate-1 {
  0% {
    left: -105.16667%;
    animation-timing-function: linear; }
  20% {
    left: -105.16667%;
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582); }
  69.15% {
    left: 21.5%;
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635); }
  100% {
    left: 95.44444%; } }

@keyframes md-progress-linear-indeterminate-scale-2 {
  0% {
    transform: scaleX(0.1);
    animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397); }
  19.15% {
    transform: scaleX(0.57);
    animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432); }
  44.15% {
    transform: scaleX(0.91);
    animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179); }
  100% {
    transform: scaleX(0.1); } }

@keyframes md-progress-linear-indeterminate-2 {
  0% {
    left: -54.88889%;
    animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968); }
  25% {
    left: -17.25%;
    animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372); }
  48.35% {
    left: 29.5%;
    animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203); }
  100% {
    left: 117.38889%; } }

md-radio-button {
  box-sizing: border-box;
  display: block;
  margin-bottom: 16px;
  white-space: nowrap;
  cursor: pointer;
  position: relative; }
  md-radio-button[disabled] {
    cursor: default; }
    md-radio-button[disabled] .md-container {
      cursor: default; }
  md-radio-button .md-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    left: 0;
    right: auto; }
    html[dir=rtl] md-radio-button .md-container {
      left: auto;
      unicode-bidi: embed; }
    body[dir=rtl] md-radio-button .md-container {
      left: auto;
      unicode-bidi: embed; }
    md-radio-button .md-container bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-radio-button .md-container bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    html[dir=rtl] md-radio-button .md-container {
      right: 0;
      unicode-bidi: embed; }
    body[dir=rtl] md-radio-button .md-container {
      right: 0;
      unicode-bidi: embed; }
    md-radio-button .md-container bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-radio-button .md-container bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    md-radio-button .md-container .md-ripple-container {
      position: absolute;
      display: block;
      width: auto;
      height: auto;
      left: -15px;
      top: -15px;
      right: -15px;
      bottom: -15px; }
    md-radio-button .md-container:before {
      box-sizing: border-box;
      background-color: transparent;
      border-radius: 50%;
      content: '';
      position: absolute;
      display: block;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s;
      width: auto; }
  md-radio-button.md-align-top-left > div.md-container {
    top: 12px; }
  md-radio-button .md-off {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    transition: border-color ease 0.28s; }
  md-radio-button .md-on {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: transform ease 0.28s;
    transform: scale(0); }
  md-radio-button.md-checked .md-on {
    transform: scale(0.5); }
  md-radio-button .md-label {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    margin-left: 30px;
    margin-right: 0;
    vertical-align: middle;
    white-space: normal;
    pointer-events: none;
    width: auto; }
    html[dir=rtl] md-radio-button .md-label {
      margin-left: 0;
      unicode-bidi: embed; }
    body[dir=rtl] md-radio-button .md-label {
      margin-left: 0;
      unicode-bidi: embed; }
    md-radio-button .md-label bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-radio-button .md-label bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    html[dir=rtl] md-radio-button .md-label {
      margin-right: 30px;
      unicode-bidi: embed; }
    body[dir=rtl] md-radio-button .md-label {
      margin-right: 30px;
      unicode-bidi: embed; }
    md-radio-button .md-label bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-radio-button .md-label bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
  md-radio-button .circle {
    border-radius: 50%; }

md-radio-group md-radio-button:not(:first-child) {
  margin-top: 16px; }

md-radio-group.layout-row md-radio-button {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: inherit;
  margin-right: 16px; }
  html[dir=rtl] md-radio-group.layout-row md-radio-button {
    margin-left: 16px;
    unicode-bidi: embed; }
  body[dir=rtl] md-radio-group.layout-row md-radio-button {
    margin-left: 16px;
    unicode-bidi: embed; }
  md-radio-group.layout-row md-radio-button bdo[dir=rtl] {
    direction: rtl;
    unicode-bidi: bidi-override; }
  md-radio-group.layout-row md-radio-button bdo[dir=ltr] {
    direction: ltr;
    unicode-bidi: bidi-override; }
  html[dir=rtl] md-radio-group.layout-row md-radio-button {
    margin-right: inherit;
    unicode-bidi: embed; }
  body[dir=rtl] md-radio-group.layout-row md-radio-button {
    margin-right: inherit;
    unicode-bidi: embed; }
  md-radio-group.layout-row md-radio-button bdo[dir=rtl] {
    direction: rtl;
    unicode-bidi: bidi-override; }
  md-radio-group.layout-row md-radio-button bdo[dir=ltr] {
    direction: ltr;
    unicode-bidi: bidi-override; }
  md-radio-group.layout-row md-radio-button:last-of-type {
    margin-left: inherit;
    margin-right: 0; }
    html[dir=rtl] md-radio-group.layout-row md-radio-button:last-of-type {
      margin-left: 0;
      unicode-bidi: embed; }
    body[dir=rtl] md-radio-group.layout-row md-radio-button:last-of-type {
      margin-left: 0;
      unicode-bidi: embed; }
    md-radio-group.layout-row md-radio-button:last-of-type bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-radio-group.layout-row md-radio-button:last-of-type bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    html[dir=rtl] md-radio-group.layout-row md-radio-button:last-of-type {
      margin-right: inherit;
      unicode-bidi: embed; }
    body[dir=rtl] md-radio-group.layout-row md-radio-button:last-of-type {
      margin-right: inherit;
      unicode-bidi: embed; }
    md-radio-group.layout-row md-radio-button:last-of-type bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-radio-group.layout-row md-radio-button:last-of-type bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }

md-radio-group:focus {
  outline: none; }

md-radio-group.md-focused .md-checked .md-container:before {
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px; }

.md-inline-form md-radio-group {
  margin: 18px 0 19px; }
  .md-inline-form md-radio-group md-radio-button {
    display: inline-block;
    height: 30px;
    padding: 2px;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0; }

@media screen and (-ms-high-contrast: active) {
  md-radio-button.md-default-theme .md-on {
    background-color: #fff; } }

.md-select-menu-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 90;
  opacity: 0;
  display: none; }
  .md-select-menu-container:not(.md-clickable) {
    pointer-events: none; }
  .md-select-menu-container md-progress-circular {
    display: table;
    margin: 24px auto !important; }
  .md-select-menu-container.md-active {
    display: block;
    opacity: 1; }
    .md-select-menu-container.md-active md-select-menu {
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-duration: 150ms; }
      .md-select-menu-container.md-active md-select-menu > * {
        opacity: 1;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
        transition-duration: 150ms;
        transition-delay: 100ms; }
  .md-select-menu-container.md-leave {
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-duration: 250ms; }

md-input-container > md-select {
  margin: 0;
  order: 2; }

md-select {
  display: flex;
  margin: 20px 0 26px 0; }
  md-select[disabled] .md-select-value {
    background-position: 0 bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;
    margin-bottom: -1px; }
  md-select:focus {
    outline: none; }
  md-select[disabled]:hover {
    cursor: default; }
  md-select:not([disabled]):hover {
    cursor: pointer; }
  md-select:not([disabled]).ng-invalid.ng-dirty .md-select-value {
    border-bottom: 2px solid;
    padding-bottom: 0; }
  md-select:not([disabled]):focus .md-select-value {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding-bottom: 0; }

.md-select-value {
  display: flex;
  align-items: center;
  padding: 2px 2px 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  box-sizing: content-box;
  min-width: 64px;
  min-height: 26px;
  flex-grow: 1; }
  .md-select-value .md-text {
    display: inline; }
  .md-select-value *:first-child {
    flex: 1 0 auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    transform: translate3d(0, 2px, 0); }
  .md-select-value .md-select-icon {
    display: block;
    align-items: flex-end;
    text-align: end;
    width: 24px;
    margin: 0 4px;
    transform: translate3d(0, 1px, 0); }
  .md-select-value .md-select-icon:after {
    display: block;
    content: '\25BC';
    position: relative;
    top: 2px;
    speak: none;
    transform: scaleY(0.6) scaleX(1); }
  .md-select-value.md-select-placeholder {
    display: flex;
    order: 1;
    pointer-events: none;
    -webkit-font-smoothing: antialiased;
    padding-left: 2px;
    z-index: 1; }

md-select-menu {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  max-height: 256px;
  min-height: 48px;
  overflow-y: hidden;
  transform-origin: left top;
  transform: scale(1); }
  md-select-menu.md-reverse {
    flex-direction: column-reverse; }
  md-select-menu:not(.md-overflow) md-content {
    padding-top: 8px;
    padding-bottom: 8px; }
  html[dir=rtl] md-select-menu {
    transform-origin: right top;
    unicode-bidi: embed; }
  body[dir=rtl] md-select-menu {
    transform-origin: right top;
    unicode-bidi: embed; }
  md-select-menu bdo[dir=rtl] {
    direction: rtl;
    unicode-bidi: bidi-override; }
  md-select-menu bdo[dir=ltr] {
    direction: ltr;
    unicode-bidi: bidi-override; }
  md-select-menu md-content {
    min-width: 136px;
    min-height: 48px;
    max-height: 256px;
    overflow-y: auto; }
  md-select-menu > * {
    opacity: 0; }

md-option {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  padding: 0 16px 0 16px;
  height: 48px; }
  md-option[disabled] {
    cursor: default; }
  md-option:focus {
    outline: none; }
  md-option .md-text {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px; }

md-optgroup {
  display: block; }
  md-optgroup label {
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    padding: 16px;
    font-weight: 500; }
  md-optgroup md-option {
    padding-left: 32px;
    padding-right: 32px; }

@media screen and (-ms-high-contrast: active) {
  .md-select-backdrop {
    background-color: transparent; }
  md-select-menu {
    border: 1px solid #fff; } }

md-sidenav {
  box-sizing: border-box;
  position: absolute;
  flex-direction: column;
  z-index: 60;
  width: 320px;
  max-width: 320px;
  bottom: 0;
  overflow: auto; }
  md-sidenav ul {
    list-style: none; }
  md-sidenav.md-closed {
    display: none; }
  md-sidenav.md-closed-add, md-sidenav.md-closed-remove {
    display: flex;
    transition: 0.2s ease-in all; }
  md-sidenav.md-closed-add.md-closed-add-active, md-sidenav.md-closed-remove.md-closed-remove-active {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
  md-sidenav.md-locked-open-add, md-sidenav.md-locked-open-remove {
    position: static;
    display: flex;
    transform: translate3d(0, 0, 0); }
  md-sidenav.md-locked-open, md-sidenav.md-locked-open.md-closed, md-sidenav.md-locked-open.md-closed.md-sidenav-left, md-sidenav.md-locked-open.md-closed, md-sidenav.md-locked-open.md-closed.md-sidenav-right, md-sidenav.md-locked-open-remove.md-closed {
    position: static;
    display: flex;
    transform: translate3d(0, 0, 0); }
  md-sidenav.md-locked-open-remove-active {
    transition: width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), min-width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    width: 0;
    min-width: 0; }
  md-sidenav.md-closed.md-locked-open-add {
    width: 0;
    min-width: 0;
    transform: translate3d(0%, 0, 0); }
  md-sidenav.md-closed.md-locked-open-add-active {
    transition: width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), min-width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    width: 320px;
    min-width: 320px;
    transform: translate3d(0%, 0, 0); }

.md-sidenav-backdrop.md-locked-open {
  display: none; }

.md-sidenav-left, md-sidenav {
  left: 0;
  top: 0;
  transform: translate3d(0%, 0, 0); }
  .md-sidenav-left.md-closed, md-sidenav.md-closed {
    transform: translate3d(-100%, 0, 0); }

.md-sidenav-right {
  left: 100%;
  top: 0;
  transform: translate3d(-100%, 0, 0); }
  .md-sidenav-right.md-closed {
    transform: translate3d(0%, 0, 0); }

@media screen and (min-width: 600px) {
  md-sidenav {
    max-width: 400px; } }

@media screen and (max-width: 456px) {
  md-sidenav {
    width: calc(100% - 56px);
    min-width: calc(100% - 56px);
    max-width: calc(100% - 56px); } }

@media screen and (-ms-high-contrast: active) {
  .md-sidenav-left, md-sidenav {
    border-right: 1px solid #fff; }
  .md-sidenav-right {
    border-left: 1px solid #fff; } }

@keyframes sliderFocusThumb {
  0% {
    opacity: 0;
    transform: scale(0); }
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    opacity: 0; } }

md-slider {
  height: 48px;
  position: relative;
  display: block;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  /**
   * Track
   */
  /**
   * Slider thumb
   */
  /* The sign that's focused in discrete mode */
  /**
   * The border/background that comes in when focused in non-discrete mode
   */
  /* Don't animate left/right while panning */ }
  md-slider *, md-slider *:after {
    box-sizing: border-box; }
  md-slider .md-slider-wrapper {
    position: relative; }
  md-slider .md-track-container {
    width: 100%;
    position: absolute;
    top: 23px;
    height: 2px; }
  md-slider .md-track {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%; }
  md-slider .md-track-fill {
    transition: width 0.05s linear; }
  md-slider .md-track-ticks {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%; }
  md-slider .md-track-ticks canvas {
    width: 100%; }
  md-slider .md-thumb-container {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: left 0.1s linear; }
  md-slider .md-thumb {
    z-index: 1;
    position: absolute;
    left: -19px;
    top: 5px;
    width: 38px;
    height: 38px;
    border-radius: 38px;
    transform: scale(0.5);
    transition: all 0.1s linear; }
    md-slider .md-thumb:after {
      content: '';
      position: absolute;
      left: 3px;
      top: 3px;
      width: 32px;
      height: 32px;
      border-radius: 32px;
      border-width: 3px;
      border-style: solid; }
  md-slider .md-sign {
    /* Center the children (slider-thumb-text) */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -14px;
    top: -20px;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    transform: scale(0.4) translate3d(0, 70px, 0);
    transition: all 0.2s ease-in-out;
    /* The arrow pointing down under the sign */ }
    md-slider .md-sign:after {
      position: absolute;
      content: '';
      left: 0px;
      border-radius: 16px;
      top: 19px;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-top-width: 16px;
      border-top-style: solid;
      opacity: 0;
      transform: translate3d(0, -8px, 0);
      transition: all 0.2s ease-in-out; }
    md-slider .md-sign .md-thumb-text {
      z-index: 1;
      font-size: 12px;
      font-weight: bold; }
  md-slider .md-focus-thumb {
    position: absolute;
    left: -24px;
    top: 0px;
    width: 48px;
    height: 48px;
    border-radius: 48px;
    display: none;
    opacity: 0;
    background-color: #C0C0C0;
    animation: sliderFocusThumb 0.4s linear; }
  md-slider .md-focus-ring {
    position: absolute;
    left: -24px;
    top: 0px;
    width: 48px;
    height: 48px;
    border-radius: 48px;
    transform: scale(0);
    transition: all 0.2s linear;
    opacity: 0.26; }
  md-slider .md-disabled-thumb {
    position: absolute;
    left: -22px;
    top: 2px;
    width: 44px;
    height: 44px;
    border-radius: 44px;
    transform: scale(0.35);
    border-width: 6px;
    border-style: solid;
    display: none; }
  md-slider.md-min .md-thumb:after {
    background-color: white; }
  md-slider.md-min .md-sign {
    opacity: 0; }
  md-slider:focus {
    outline: none; }
  md-slider.md-dragging .md-thumb-container,
  md-slider.md-dragging .md-track-fill {
    transition: none; }
  md-slider:not([md-discrete]) {
    /* Hide the sign and ticks in non-discrete mode */ }
    md-slider:not([md-discrete]) .md-track-ticks,
    md-slider:not([md-discrete]) .md-sign {
      display: none; }
    md-slider:not([md-discrete]):not([disabled]):hover .md-thumb {
      transform: scale(0.6); }
    md-slider:not([md-discrete]):not([disabled]):focus .md-focus-thumb, md-slider:not([md-discrete]):not([disabled]).md-active .md-focus-thumb {
      display: block; }
    md-slider:not([md-discrete]):not([disabled]):focus .md-focus-ring, md-slider:not([md-discrete]):not([disabled]).md-active .md-focus-ring {
      transform: scale(1); }
    md-slider:not([md-discrete]):not([disabled]):focus .md-thumb, md-slider:not([md-discrete]):not([disabled]).md-active .md-thumb {
      transform: scale(0.85); }
  md-slider[md-discrete] {
    /* Hide the focus thumb in discrete mode */ }
    md-slider[md-discrete] .md-focus-thumb,
    md-slider[md-discrete] .md-focus-ring {
      display: none; }
    md-slider[md-discrete]:not([disabled]):focus .md-sign,
    md-slider[md-discrete]:not([disabled]):focus .md-sign:after, md-slider[md-discrete]:not([disabled]).md-active .md-sign,
    md-slider[md-discrete]:not([disabled]).md-active .md-sign:after {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1); }
  md-slider[disabled] .md-track-fill {
    display: none; }
  md-slider[disabled] .md-sign {
    display: none; }
  md-slider[disabled] .md-thumb {
    transform: scale(0.35); }
  md-slider[disabled] .md-disabled-thumb {
    display: block; }

@media screen and (-ms-high-contrast: active) {
  md-slider.md-default-theme .md-track {
    border-bottom: 1px solid #fff; } }

.md-sticky-clone {
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  position: absolute !important;
  transform: translate3d(-9999px, -9999px, 0); }
  .md-sticky-clone[sticky-state="active"] {
    transform: translate3d(0, 0, 0); }
    .md-sticky-clone[sticky-state="active"]:not(.md-sticky-no-effect) .md-subheader-inner {
      animation: subheaderStickyHoverIn 0.3s ease-out both; }

@keyframes subheaderStickyHoverIn {
  0% {
    box-shadow: 0 0 0 0 transparent; }
  100% {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.16); } }

@keyframes subheaderStickyHoverOut {
  0% {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.16); }
  100% {
    box-shadow: 0 0 0 0 transparent; } }

.md-subheader-wrapper:not(.md-sticky-no-effect) {
  transition: 0.2s ease-out margin; }
  .md-subheader-wrapper:not(.md-sticky-no-effect) .md-subheader {
    margin: 0; }
  .md-subheader-wrapper:not(.md-sticky-no-effect).md-sticky-clone {
    z-index: 2; }
  .md-subheader-wrapper:not(.md-sticky-no-effect)[sticky-state="active"] {
    margin-top: -2px; }
  .md-subheader-wrapper:not(.md-sticky-no-effect):not(.md-sticky-clone)[sticky-prev-state="active"] .md-subheader-inner:after {
    animation: subheaderStickyHoverOut 0.3s ease-out both; }

.md-subheader {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1em;
  margin: 0 0 0 0;
  position: relative; }
  .md-subheader .md-subheader-inner {
    display: block;
    padding: 16px; }
  .md-subheader .md-subheader-content {
    display: block;
    z-index: 1;
    position: relative; }

.md-inline-form md-switch {
  margin-top: 18px;
  margin-bottom: 19px; }

md-switch {
  margin: 16px 0;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  user-select: none;
  height: 30px;
  line-height: 28px;
  align-items: center;
  display: flex;
  margin-left: inherit;
  margin-right: 16px; }
  html[dir=rtl] md-switch {
    margin-left: 16px;
    unicode-bidi: embed; }
  body[dir=rtl] md-switch {
    margin-left: 16px;
    unicode-bidi: embed; }
  md-switch bdo[dir=rtl] {
    direction: rtl;
    unicode-bidi: bidi-override; }
  md-switch bdo[dir=ltr] {
    direction: ltr;
    unicode-bidi: bidi-override; }
  html[dir=rtl] md-switch {
    margin-right: inherit;
    unicode-bidi: embed; }
  body[dir=rtl] md-switch {
    margin-right: inherit;
    unicode-bidi: embed; }
  md-switch bdo[dir=rtl] {
    direction: rtl;
    unicode-bidi: bidi-override; }
  md-switch bdo[dir=ltr] {
    direction: ltr;
    unicode-bidi: bidi-override; }
  md-switch:last-of-type {
    margin-left: inherit;
    margin-right: 0; }
    html[dir=rtl] md-switch:last-of-type {
      margin-left: 0;
      unicode-bidi: embed; }
    body[dir=rtl] md-switch:last-of-type {
      margin-left: 0;
      unicode-bidi: embed; }
    md-switch:last-of-type bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-switch:last-of-type bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
    html[dir=rtl] md-switch:last-of-type {
      margin-right: inherit;
      unicode-bidi: embed; }
    body[dir=rtl] md-switch:last-of-type {
      margin-right: inherit;
      unicode-bidi: embed; }
    md-switch:last-of-type bdo[dir=rtl] {
      direction: rtl;
      unicode-bidi: bidi-override; }
    md-switch:last-of-type bdo[dir=ltr] {
      direction: ltr;
      unicode-bidi: bidi-override; }
  md-switch[disabled] {
    cursor: default; }
    md-switch[disabled] .md-container {
      cursor: default; }
  md-switch .md-container {
    cursor: grab;
    width: 36px;
    height: 24px;
    position: relative;
    user-select: none;
    margin-right: 8px;
    float: left; }
  md-switch:not([disabled]) .md-dragging,
  md-switch:not([disabled]).md-dragging .md-container {
    cursor: grabbing; }
  md-switch.md-focused:not([disabled]) .md-thumb:before {
    left: -8px;
    top: -8px;
    right: -8px;
    bottom: -8px; }
  md-switch.md-focused:not([disabled]):not(.md-checked) .md-thumb:before {
    background-color: rgba(0, 0, 0, 0.12); }
  md-switch .md-label {
    border-color: transparent;
    border-width: 0;
    float: left; }
  md-switch .md-bar {
    left: 1px;
    width: 34px;
    top: 5px;
    height: 14px;
    border-radius: 8px;
    position: absolute; }
  md-switch .md-thumb-container {
    top: 2px;
    left: 0;
    width: 16px;
    position: absolute;
    transform: translate3d(0, 0, 0);
    z-index: 1; }
  md-switch.md-checked .md-thumb-container {
    transform: translate3d(100%, 0, 0); }
  md-switch .md-thumb {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    outline: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
    md-switch .md-thumb:before {
      background-color: transparent;
      border-radius: 50%;
      content: '';
      position: absolute;
      display: block;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s;
      width: auto; }
    md-switch .md-thumb .md-ripple-container {
      position: absolute;
      display: block;
      width: auto;
      height: auto;
      left: -20px;
      top: -20px;
      right: -20px;
      bottom: -20px; }
  md-switch:not(.md-dragging) .md-bar,
  md-switch:not(.md-dragging) .md-thumb-container,
  md-switch:not(.md-dragging) .md-thumb {
    transition: all 0.08s linear;
    transition-property: transform, background-color; }
  md-switch:not(.md-dragging) .md-bar,
  md-switch:not(.md-dragging) .md-thumb {
    transition-delay: 0.05s; }

@media screen and (-ms-high-contrast: active) {
  md-switch.md-default-theme .md-bar {
    background-color: #666; }
  md-switch.md-default-theme.md-checked .md-bar {
    background-color: #9E9E9E; }
  md-switch.md-default-theme .md-thumb {
    background-color: #fff; } }

@keyframes md-tab-content-hide {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

md-tab-data {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0; }

md-tabs {
  display: block;
  margin: 0;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0; }
  md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
    min-height: 248px; }
  md-tabs[md-align-tabs="bottom"] {
    padding-bottom: 48px; }
    md-tabs[md-align-tabs="bottom"] md-tabs-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 48px;
      z-index: 2; }
    md-tabs[md-align-tabs="bottom"] md-tabs-content-wrapper {
      top: 0;
      bottom: 48px; }
  md-tabs.md-dynamic-height md-tabs-content-wrapper {
    min-height: 0;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    overflow: visible; }
  md-tabs.md-dynamic-height md-tab-content.md-active {
    position: relative; }
  md-tabs[md-border-bottom] md-tabs-wrapper {
    border-width: 0 0 1px;
    border-style: solid; }
  md-tabs[md-border-bottom]:not(.md-dynamic-height) md-tabs-content-wrapper {
    top: 49px; }

md-tabs-wrapper {
  display: block;
  position: relative; }
  md-tabs-wrapper md-prev-button, md-tabs-wrapper md-next-button {
    height: 100%;
    width: 32px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1em;
    z-index: 2;
    cursor: pointer;
    font-size: 16px;
    background: transparent no-repeat center center;
    transition: all 0.5s cubic-bezier(0.35, 0, 0.25, 1); }
    md-tabs-wrapper md-prev-button:focus, md-tabs-wrapper md-next-button:focus {
      outline: none; }
    md-tabs-wrapper md-prev-button.md-disabled, md-tabs-wrapper md-next-button.md-disabled {
      opacity: 0.25;
      cursor: default; }
    md-tabs-wrapper md-prev-button.ng-leave, md-tabs-wrapper md-next-button.ng-leave {
      transition: none; }
    md-tabs-wrapper md-prev-button md-icon, md-tabs-wrapper md-next-button md-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0); }
  md-tabs-wrapper md-prev-button {
    left: 0;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMjA4IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTUuNCw3LjQgMTQsNiA4LDEyIDE0LDE4IDE1LjQsMTYuNiAxMC44LDEyIAkJIiBzdHlsZT0iZmlsbDp3aGl0ZTsiLz4gPHJlY3QgZmlsbD0ibm9uZSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ii8+IDwvZz4gPC9nPiA8ZyBpZD0iR3JpZCIgZGlzcGxheT0ibm9uZSI+IDxnIGRpc3BsYXk9ImlubGluZSI+IDwvZz4gPC9nPiA8L3N2Zz4NCg=="); }
  md-tabs-wrapper md-next-button {
    right: 0;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMzM2IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTAsNiA4LjYsNy40IDEzLjIsMTIgOC42LDE2LjYgMTAsMTggMTYsMTIgCQkiIHN0eWxlPSJmaWxsOndoaXRlOyIvPiA8cmVjdCBmaWxsPSJub25lIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiLz4gPC9nPiA8L2c+IDxnIGlkPSJHcmlkIiBkaXNwbGF5PSJub25lIj4gPGcgZGlzcGxheT0iaW5saW5lIj4gPC9nPiA8L2c+IDwvc3ZnPg0K"); }
    md-tabs-wrapper md-next-button md-icon {
      transform: translate3d(-50%, -50%, 0) rotate(180deg); }
  md-tabs-wrapper.md-stretch-tabs md-pagination-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row; }
    md-tabs-wrapper.md-stretch-tabs md-pagination-wrapper md-tab-item {
      flex-grow: 1; }

md-tabs-canvas {
  position: relative;
  overflow: hidden;
  display: block;
  height: 48px; }
  md-tabs-canvas:after {
    content: '';
    display: table;
    clear: both; }
  md-tabs-canvas .md-dummy-wrapper {
    position: absolute;
    top: 0;
    left: 0; }
  md-tabs-canvas.md-paginated {
    margin: 0 32px; }
  md-tabs-canvas.md-center-tabs {
    display: flex;
    flex-direction: column;
    text-align: center; }
    md-tabs-canvas.md-center-tabs .md-tab {
      float: none;
      display: inline-block; }

md-pagination-wrapper {
  height: 48px;
  display: block;
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  position: absolute;
  width: 999999px;
  left: 0;
  transform: translate3d(0, 0, 0); }
  md-pagination-wrapper:after {
    content: '';
    display: table;
    clear: both; }
  md-pagination-wrapper.md-center-tabs {
    position: relative;
    width: initial;
    margin: 0 auto; }

md-tabs-content-wrapper {
  display: block;
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }

md-tab-content {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  overflow: auto; }
  md-tab-content.md-no-scroll {
    bottom: auto;
    overflow: hidden; }
  md-tab-content.ng-leave, md-tab-content.md-no-transition {
    transition: none; }
  md-tab-content.md-left:not(.md-active) {
    transform: translateX(-100%);
    animation: 1s md-tab-content-hide;
    opacity: 0; }
    md-tab-content.md-left:not(.md-active) * {
      transition: visibility 0s linear;
      transition-delay: 0.5s;
      visibility: hidden; }
  md-tab-content.md-right:not(.md-active) {
    transform: translateX(100%);
    animation: 1s md-tab-content-hide;
    opacity: 0; }
    md-tab-content.md-right:not(.md-active) * {
      transition: visibility 0s linear;
      transition-delay: 0.5s;
      visibility: hidden; }
  md-tab-content > div.ng-leave {
    animation: 1s md-tab-content-hide; }

md-ink-bar {
  position: absolute;
  left: auto;
  right: auto;
  bottom: 0;
  height: 2px; }
  md-ink-bar.md-left {
    transition: left 0.125s cubic-bezier(0.35, 0, 0.25, 1), right 0.25s cubic-bezier(0.35, 0, 0.25, 1); }
  md-ink-bar.md-right {
    transition: left 0.25s cubic-bezier(0.35, 0, 0.25, 1), right 0.125s cubic-bezier(0.35, 0, 0.25, 1); }

md-tab {
  position: absolute;
  z-index: -1;
  left: -9999px; }

.md-tab {
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  padding: 12px 24px;
  transition: background-color 0.35s cubic-bezier(0.35, 0, 0.25, 1);
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  text-transform: uppercase;
  float: left;
  font-weight: 500;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis; }
  .md-tab.md-focused {
    box-shadow: none;
    outline: none; }
  .md-tab.md-active {
    cursor: default; }
  .md-tab.md-disabled {
    pointer-events: none;
    touch-action: pan-y;
    user-select: none;
    -webkit-user-drag: none;
    opacity: 0.5;
    cursor: default; }
  .md-tab.ng-leave {
    transition: none; }

md-toolbar + md-tabs {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

md-toast {
  position: absolute;
  z-index: 105;
  box-sizing: border-box;
  cursor: default;
  overflow: hidden;
  padding: 8px;
  opacity: 1;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* Transition differently when swiping */
  /*
   * When the toast doesn't take up the whole screen,
   * make it rotate when the user swipes it away
   */ }
  md-toast .md-toast-content {
    display: flex;
    align-items: center;
    height: 0;
    max-height: 168px;
    max-width: 100%;
    min-height: 48px;
    padding-left: 24px;
    padding-right: 24px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border-radius: 2px;
    font-size: 14px;
    overflow: hidden;
    transform: translate3d(0, 0, 0) rotateZ(0deg);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
  md-toast.md-capsule {
    border-radius: 24px; }
    md-toast.md-capsule .md-toast-content {
      border-radius: 24px; }
  md-toast.ng-leave-active .md-toast-content {
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-toast.md-swipeleft .md-toast-content, md-toast.md-swiperight .md-toast-content, md-toast.md-swipeup .md-toast-content, md-toast.md-swipedown .md-toast-content {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
  md-toast.ng-enter {
    opacity: 0; }
    md-toast.ng-enter .md-toast-content {
      transform: translate3d(0, 100%, 0); }
    md-toast.ng-enter.md-top .md-toast-content {
      transform: translate3d(0, -100%, 0); }
    md-toast.ng-enter.ng-enter-active {
      opacity: 1; }
      md-toast.ng-enter.ng-enter-active .md-toast-content {
        transform: translate3d(0, 0, 0); }
  md-toast.ng-leave.ng-leave-active .md-toast-content {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  md-toast.ng-leave.ng-leave-active.md-swipeup .md-toast-content {
    transform: translate3d(0, -50%, 0); }
  md-toast.ng-leave.ng-leave-active.md-swipedown .md-toast-content {
    transform: translate3d(0, 50%, 0); }
  md-toast.ng-leave.ng-leave-active.md-top .md-toast-content {
    transform: translate3d(0, -100%, 0); }
  md-toast .md-action {
    line-height: 19px;
    margin-left: 24px;
    margin-right: 0;
    cursor: pointer;
    text-transform: uppercase;
    float: right; }
    md-toast .md-action.md-button {
      min-width: 0; }

@media (max-width: 959px) {
  md-toast {
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    min-width: 0;
    border-radius: 0;
    bottom: 0; }
    md-toast.ng-leave.ng-leave-active.md-swipeup .md-toast-content {
      transform: translate3d(0, -50%, 0); }
    md-toast.ng-leave.ng-leave-active.md-swipedown .md-toast-content {
      transform: translate3d(0, 50%, 0); } }

@media (min-width: 960px) {
  md-toast {
    min-width: 304px;
    /*
   * When the toast doesn't take up the whole screen,
   * make it rotate when the user swipes it away
   */ }
    md-toast.md-bottom {
      bottom: 0; }
    md-toast.md-left {
      left: 0; }
    md-toast.md-right {
      right: 0; }
    md-toast.md-top {
      top: 0; }
    md-toast.ng-leave.ng-leave-active.md-swipeleft .md-toast-content {
      transform: translate3d(-50%, 0, 0); }
    md-toast.ng-leave.ng-leave-active.md-swiperight .md-toast-content {
      transform: translate3d(50%, 0, 0); } }

@media (min-width: 1920px) {
  md-toast .md-toast-content {
    max-width: 568px; } }

@media screen and (-ms-high-contrast: active) {
  md-toast {
    border: 1px solid #fff; } }

.md-toast-animating {
  overflow: hidden !important; }

md-toolbar {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  font-size: 20px;
  min-height: 64px;
  width: 100%; }
  md-toolbar.md-whiteframe-z1-add, md-toolbar.md-whiteframe-z1-remove {
    transition: box-shadow 0.5s linear; }
  md-toolbar md-toolbar-filler {
    width: 72px; }
  md-toolbar *,
  md-toolbar *:before,
  md-toolbar *:after {
    box-sizing: border-box; }
  md-toolbar.md-tall {
    height: 128px;
    min-height: 128px;
    max-height: 128px; }
  md-toolbar.md-medium-tall {
    height: 88px;
    min-height: 88px;
    max-height: 88px; }
    md-toolbar.md-medium-tall .md-toolbar-tools {
      height: 48px;
      min-height: 48px;
      max-height: 48px; }
  md-toolbar > .md-indent {
    margin-left: 64px; }
  md-toolbar ~ md-content > md-list {
    padding: 0; }
    md-toolbar ~ md-content > md-list md-list-item:last-child md-divider {
      display: none; }

.md-toolbar-tools {
  font-size: 20px;
  letter-spacing: 0.005em;
  box-sizing: border-box;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 64px;
  max-height: 64px;
  padding: 0 16px;
  margin: 0; }
  .md-toolbar-tools h1, .md-toolbar-tools h2, .md-toolbar-tools h3 {
    font-size: inherit;
    font-weight: inherit;
    margin: inherit; }
  .md-toolbar-tools a {
    color: inherit;
    text-decoration: none; }
  .md-toolbar-tools .fill-height {
    display: flex;
    align-items: center; }
  .md-toolbar-tools .md-button {
    margin-top: 0;
    margin-bottom: 0; }
  .md-toolbar-tools > .md-button:first-child {
    margin-left: -8px; }
  .md-toolbar-tools > .md-button:last-child {
    margin-right: -8px; }
  .md-toolbar-tools > md-menu:last-child {
    margin-right: -8px; }
    .md-toolbar-tools > md-menu:last-child > .md-button {
      margin-right: 0; }
  @media screen and (-ms-high-contrast: active) {
    .md-toolbar-tools {
      border-bottom: 1px solid #fff; } }

@media only screen and (min-width: 0) and (max-width: 959px) and (orientation: portrait) {
  md-toolbar {
    min-height: 56px; }
  .md-toolbar-tools {
    height: 56px;
    max-height: 56px; } }

@media only screen and (min-width: 0) and (max-width: 959px) and (orientation: landscape) {
  md-toolbar {
    min-height: 48px; }
  .md-toolbar-tools {
    height: 48px;
    max-height: 48px; } }

md-tooltip {
  position: absolute;
  z-index: 100;
  overflow: hidden;
  pointer-events: none;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px; }
  @media screen and (min-width: 960px) {
    md-tooltip {
      font-size: 10px; } }
  md-tooltip .md-content {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: center top;
    transform: scale(0);
    opacity: 0;
    height: 32px;
    line-height: 32px;
    padding-left: 16px;
    padding-right: 16px; }
    @media screen and (min-width: 960px) {
      md-tooltip .md-content {
        height: 22px;
        line-height: 22px;
        padding-left: 8px;
        padding-right: 8px; } }
    md-tooltip .md-content.md-show-add {
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-duration: .2s;
      transform: scale(0);
      opacity: 0; }
    md-tooltip .md-content.md-show, md-tooltip .md-content.md-show-add-active {
      transform: scale(1);
      opacity: 1;
      transform-origin: center top; }
    md-tooltip .md-content.md-show-remove {
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-duration: .2s; }
      md-tooltip .md-content.md-show-remove.md-show-remove-active {
        transform: scale(0);
        opacity: 0; }
  md-tooltip.md-hide {
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-tooltip.md-show {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    pointer-events: auto;
    will-change: opacity, height, width; }

.md-virtual-repeat-container {
  box-sizing: border-box;
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative; }
  .md-virtual-repeat-container .md-virtual-repeat-scroller {
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    margin: 0;
    overflow-x: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0; }
  .md-virtual-repeat-container .md-virtual-repeat-sizer {
    box-sizing: border-box;
    height: 1px;
    display: block;
    margin: 0;
    padding: 0;
    width: 1px; }
  .md-virtual-repeat-container .md-virtual-repeat-offsetter {
    box-sizing: border-box;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0; }

.md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-scroller {
  overflow-x: auto;
  overflow-y: hidden; }

.md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-offsetter {
  bottom: 16px;
  right: auto;
  white-space: nowrap; }

.md-whiteframe-1dp, .md-whiteframe-z1 {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }

.md-whiteframe-2dp {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }

.md-whiteframe-3dp {
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12); }

.md-whiteframe-4dp, .md-whiteframe-z2 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.md-whiteframe-5dp {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }

.md-whiteframe-6dp {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.md-whiteframe-7dp, .md-whiteframe-z3 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.md-whiteframe-8dp {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.md-whiteframe-9dp {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }

.md-whiteframe-10dp, .md-whiteframe-z4 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }

.md-whiteframe-11dp {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }

.md-whiteframe-12dp {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.md-whiteframe-13dp, .md-whiteframe-z5 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }

.md-whiteframe-14dp {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }

.md-whiteframe-15dp {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }

.md-whiteframe-16dp {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.md-whiteframe-17dp {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }

.md-whiteframe-18dp {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }

.md-whiteframe-19dp {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }

.md-whiteframe-20dp {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }

.md-whiteframe-21dp {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }

.md-whiteframe-22dp {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }

.md-whiteframe-23dp {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }

.md-whiteframe-24dp {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

@media screen and (-ms-high-contrast: active) {
  md-whiteframe {
    border: 1px solid #fff; } }

/*----------------------------------------------------------------*/
/*  Reset
/*----------------------------------------------------------------*/
* {
  text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -webkit-text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box; }
  *:before, *:after {
    box-sizing: border-box; }
  *:focus {
    outline: none; }

*:not(md-input-container) > input[type="text"],
*:not(md-input-container) > input[type="tel"],
*:not(md-input-container) > input[type="email"],
*:not(md-input-container) > input[type="search"],
*:not(md-input-container) > input[type="password"],
*:not(md-input-container) > input[type="button"],
*:not(md-input-container) > button,
*:not(md-input-container) > input[type="submit"],
*:not(md-input-container) > input[type="image"],
*:not(md-input-container) > textarea {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  padding: 0;
  margin: 0; }

*:not(md-input-container) > input[type="button"],
*:not(md-input-container) > button,
*:not(md-input-container) > input[type="submit"] {
  background: none; }

button {
  border-radius: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  margin: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none; }

/*----------------------------------------------------------------*/
/*  Variables
/*----------------------------------------------------------------*/
/*----------------------------------------------------------------*/
/*  Mixins
/*----------------------------------------------------------------*/
/*----------------------------------------------------------------*/
/*  Angular Material Extend
/*  Adds and/or modifies Angular Material styles
/*----------------------------------------------------------------*/
/* --- FIXES --- */
[layout] > * {
  min-height: auto;
  min-height: initial; }

md-card > img,
md-card > :not(md-card-content) img {
  height: inherit;
  height: initial; }

.md-datepicker-calendar-pane * {
  box-sizing: initial; }

/* --- MODIFICATIONS --- */
md-autocomplete-wrap > input[type="search"] {
  background: #FFFFFF;
  padding: 0 15px !important; }

md-backdrop.md-opaque {
  background-color: rgba(33, 33, 33, 0.48) !important; }

md-card md-card-header md-card-avatar md-icon {
  width: 40px;
  height: 40px; }

md-checkbox.no-label {
  margin: 0; }

md-datepicker .md-datepicker-input-container {
  margin-left: 0; }
  md-datepicker .md-datepicker-input-container .md-datepicker-input {
    background: transparent !important; }

md-dialog md-dialog-content {
  padding: 32px 24px 24px 24px; }

md-dialog md-dialog-actions {
  min-height: 64px;
  padding-top: 8px;
  padding-bottom: 8px; }
  md-dialog md-dialog-actions .md-button {
    margin: 0 8px; }

md-input-container.no-errors-spacer .md-errors-spacer {
  display: none; }

md-list-item .md-list-item-text p {
  font-size: 13px !important; }

.md-menu {
  padding: 0; }

.md-open-menu-container md-menu-content md-menu-item.selected {
  opacity: 0.4; }

md-menu-content md-menu-item .md-button {
  text-align: left; }

md-progress-linear .md-container {
  top: 0 !important; }

md-select.simplified {
  margin: 0 8px; }
  md-select.simplified .md-select-value {
    border: none !important;
    box-sizing: border-box;
    padding: 0; }
    md-select.simplified .md-select-value *:first-child {
      transform: none;
      height: auto; }
    md-select.simplified .md-select-value .md-select-icon {
      transform: none;
      font-size: 16px; }
      md-select.simplified .md-select-value .md-select-icon:after {
        top: 1px; }

md-sidenav {
  -webkit-overflow-scrolling: touch; }

md-toolbar.colored-toolbar {
  color: rgba(255, 255, 255, 0.87); }
  md-toolbar.colored-toolbar md-menu-bar md-menu.md-open > button {
    background: rgba(0, 0, 0, 0.12); }
  md-toolbar.colored-toolbar md-menu-bar md-menu .md-open-menu-container {
    background: #FFFFFF; }
    md-toolbar.colored-toolbar md-menu-bar md-menu .md-open-menu-container md-menu-item button {
      color: rgba(0, 0, 0, 0.87) !important; }
    md-toolbar.colored-toolbar md-menu-bar md-menu .md-open-menu-container md-menu-item md-icon {
      color: rgba(0, 0, 0, 0.54); }
  md-toolbar.colored-toolbar md-menu.md-open > button {
    background: rgba(0, 0, 0, 0.12); }
  md-toolbar.colored-toolbar input {
    color: rgba(255, 255, 255, 0.87) !important; }

/*----------------------------------------------------------------*/
/*  Template Layouts
/*----------------------------------------------------------------*/
html, body {
  overflow: hidden !important; }

#main {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

body.boxed {
  background: #3F4450; }
  body.boxed #main {
    overflow: hidden !important;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.45); }

#layout-content-only {
  position: relative;
  height: 100%;
  max-height: 100%; }
  #layout-content-only #content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

#layout-content-with-toolbar {
  position: relative;
  height: 100%;
  max-height: 100%; }
  #layout-content-with-toolbar #content {
    position: absolute;
    top: 64px;
    right: 0;
    bottom: 0;
    left: 0; }

#layout-vertical-navigation {
  height: 100%;
  max-height: 100%; }
  #layout-vertical-navigation #content-container {
    position: relative;
    overflow: hidden; }
    #layout-vertical-navigation #content-container #content {
      /*            position: absolute;
                        top: calc(64px + 46px);*/
      /*top: 0px;*/
      /*            right: 0;
                        bottom: 0;
                        left: 0;*/ }
    #layout-vertical-navigation #content-container #pantallaCarga {
      position: absolute;
      top: 64px;
      bottom: 0;
      left: 0;
      right: 0;
      /*background-color: rgba(150,150,150,1);*/
      background: -webkit-radial-gradient(circle, #646464, #a0a0a0);
      background: -o-radial-gradient(circle, #646464, #a0a0a0);
      background: -moz-radial-gradient(circle, #646464, #a0a0a0);
      background: radial-gradient(circle, #646464, #a0a0a0); }
      #layout-vertical-navigation #content-container #pantallaCarga .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      #layout-vertical-navigation #content-container #pantallaCarga h1, #layout-vertical-navigation #content-container #pantallaCarga h3 {
        color: #000;
        text-align: center; }
    #layout-vertical-navigation #content-container .lsHidden {
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.5s; }
  #layout-vertical-navigation #expire-license-message {
    position: fixed;
    z-index: 99999;
    padding: 8px 4px 8px 16px;
    font-size: 24px;
    background: #ffff82e0;
    width: fit-content;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 8px;
    border-radius: 8px; }
    #layout-vertical-navigation #expire-license-message .md-button {
      margin-right: 0;
      margin-left: 16px; }

#layout-horizontal-navigation {
  height: 100%;
  max-height: 100%; }
  #layout-horizontal-navigation #content-container {
    position: relative;
    overflow: hidden; }
    #layout-horizontal-navigation #content-container #content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }

/*----------------------------------------------------------------*/
/*  Page Layouts
/*----------------------------------------------------------------*/
.page-layout {
  position: relative;
  overflow: hidden; }
  .page-layout .top-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    height: 200px;
    background-image: url("/assets/images/backgrounds/header-bg.png");
    background-size: cover; }
  .page-layout md-backdrop {
    z-index: 50; }
  .page-layout > .header .breadcrumb {
    margin-bottom: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54); }
    .page-layout > .header .breadcrumb md-icon {
      margin: 0; }
    .page-layout > .header .breadcrumb .separator {
      margin: 0 8px; }
  .page-layout > .header .title {
    font-size: 34px; }
  .page-layout.carded {
    min-height: 100%;
    height: 100%; }
    .page-layout.carded.fullwidth.single-scroll {
      height: auto; }
      .page-layout.carded.fullwidth.single-scroll > .center .content-card .content {
        overflow: hidden; }
    .page-layout.carded.fullwidth > .center {
      position: relative;
      z-index: 2;
      margin-left: 32px;
      margin-right: 32px; }
      .page-layout.carded.fullwidth > .center .header {
        height: 136px;
        min-height: 136px;
        max-height: 136px;
        padding: 24px; }
      .page-layout.carded.fullwidth > .center .content-card {
        background: #FFFFFF;
        box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
        overflow: hidden; }
        .page-layout.carded.fullwidth > .center .content-card .toolbar {
          padding: 8px 24px;
          height: 64px;
          min-height: 64px;
          max-height: 64px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
        .page-layout.carded.fullwidth > .center .content-card .content {
          padding: 24px;
          background: #FFFFFF; }
    .page-layout.carded.left-sidenav.single-scroll {
      height: auto; }
      .page-layout.carded.left-sidenav.single-scroll > .center .content-card .content {
        overflow: hidden; }
    .page-layout.carded.left-sidenav > .sidenav {
      width: 240px;
      min-width: 240px;
      max-width: 240px;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
      z-index: 51; }
      .page-layout.carded.left-sidenav > .sidenav .header {
        height: 200px;
        min-height: 200px;
        max-height: 200px;
        padding: 24px; }
      .page-layout.carded.left-sidenav > .sidenav .content {
        background: transparent;
        padding: 24px; }
      .page-layout.carded.left-sidenav > .sidenav.md-locked-open {
        width: 220px;
        min-width: 220px;
        max-width: 220px;
        z-index: 2;
        background: transparent;
        box-shadow: none; }
        .page-layout.carded.left-sidenav > .sidenav.md-locked-open + .center {
          margin-left: 0; }
      .page-layout.carded.left-sidenav > .sidenav:not(.md-locked-open) .header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .page-layout.carded.left-sidenav > .sidenav:not(.md-locked-open) .content {
        overflow: hidden; }
    .page-layout.carded.left-sidenav > .center {
      position: relative;
      z-index: 2;
      margin-left: 32px;
      margin-right: 32px; }
      .page-layout.carded.left-sidenav > .center .header {
        height: 136px;
        min-height: 136px;
        max-height: 136px;
        padding: 24px; }
      .page-layout.carded.left-sidenav > .center .content-card {
        background: #FFFFFF;
        box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
        overflow: hidden; }
        .page-layout.carded.left-sidenav > .center .content-card .toolbar {
          padding: 8px 24px;
          height: 64px;
          min-height: 64px;
          max-height: 64px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
          .page-layout.carded.left-sidenav > .center .content-card .toolbar .sidenav-toggle {
            height: 64px;
            line-height: 64px;
            margin: 0 !important;
            padding: 0 8px 0 0 !important;
            border-radius: 0; }
        .page-layout.carded.left-sidenav > .center .content-card .content {
          padding: 24px;
          background: #FFFFFF; }
    .page-layout.carded.right-sidenav.single-scroll {
      height: auto; }
      .page-layout.carded.right-sidenav.single-scroll > .center .content-card .content {
        overflow: hidden; }
    .page-layout.carded.right-sidenav > .sidenav {
      width: 240px;
      min-width: 240px;
      max-width: 240px;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
      z-index: 51; }
      .page-layout.carded.right-sidenav > .sidenav .header {
        height: 200px;
        min-height: 200px;
        max-height: 200px;
        padding: 24px 24px 24px 0; }
      .page-layout.carded.right-sidenav > .sidenav .content {
        background: transparent;
        padding: 24px 24px 24px 0; }
      .page-layout.carded.right-sidenav > .sidenav.md-locked-open {
        width: 196px;
        min-width: 196px;
        max-width: 196px;
        z-index: 2;
        background: transparent;
        box-shadow: none; }
      .page-layout.carded.right-sidenav > .sidenav:not(.md-locked-open) .header {
        padding: 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .page-layout.carded.right-sidenav > .sidenav:not(.md-locked-open) .content {
        overflow: hidden;
        padding: 24px; }
    .page-layout.carded.right-sidenav > .center {
      position: relative;
      z-index: 2;
      margin-left: 32px;
      margin-right: 32px; }
      .page-layout.carded.right-sidenav > .center .header {
        height: 136px;
        min-height: 136px;
        max-height: 136px;
        padding: 24px; }
      .page-layout.carded.right-sidenav > .center .content-card {
        background: #FFFFFF;
        box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
        overflow: hidden; }
        .page-layout.carded.right-sidenav > .center .content-card .toolbar {
          padding: 8px 24px;
          height: 64px;
          min-height: 64px;
          max-height: 64px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
          .page-layout.carded.right-sidenav > .center .content-card .toolbar .sidenav-toggle {
            height: 64px;
            line-height: 64px;
            margin: 0 !important;
            padding: 0 0 0 8px !important;
            border-radius: 0; }
        .page-layout.carded.right-sidenav > .center .content-card .content {
          padding: 24px;
          background: #FFFFFF; }
  .page-layout.simple.fullwidth {
    min-height: 100%; }
    .page-layout.simple.fullwidth > .header {
      height: 200px;
      min-height: 200px;
      max-height: 200px;
      padding: 24px;
      background-image: url("/assets/images/backgrounds/header-bg.png");
      background-size: cover; }
    .page-layout.simple.fullwidth > .content {
      padding: 24px; }
  .page-layout.simple.left-sidenav {
    min-height: 100%;
    height: 100%; }
    .page-layout.simple.left-sidenav.single-scroll {
      height: auto; }
      .page-layout.simple.left-sidenav.single-scroll > .center {
        overflow: hidden; }
    .page-layout.simple.left-sidenav .sidenav {
      width: 240px;
      min-width: 240px;
      max-width: 240px;
      padding: 24px;
      z-index: 51;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }
      .page-layout.simple.left-sidenav .sidenav.md-locked-open {
        width: 220px;
        min-width: 220px;
        max-width: 220px;
        z-index: 2;
        box-shadow: none;
        background: transparent; }
    .page-layout.simple.left-sidenav > .center {
      position: relative;
      overflow: auto;
      z-index: 3;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }
      .page-layout.simple.left-sidenav > .center .header {
        height: 200px;
        min-height: 200px;
        max-height: 200px;
        padding: 24px;
        background-image: url("/assets/images/backgrounds/header-bg.png");
        background-size: cover; }
      .page-layout.simple.left-sidenav > .center .content {
        padding: 24px;
        background: #FFFFFF; }
  .page-layout.simple.right-sidenav {
    min-height: 100%;
    height: 100%; }
    .page-layout.simple.right-sidenav.single-scroll {
      height: auto; }
      .page-layout.simple.right-sidenav.single-scroll > .center {
        overflow: hidden; }
    .page-layout.simple.right-sidenav > .center {
      position: relative;
      overflow: auto;
      z-index: 3;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }
      .page-layout.simple.right-sidenav > .center .header {
        height: 200px;
        min-height: 200px;
        max-height: 200px;
        padding: 24px;
        background-image: url("/assets/images/backgrounds/header-bg.png");
        background-size: cover; }
      .page-layout.simple.right-sidenav > .center .content {
        padding: 24px;
        background: #FFFFFF; }
    .page-layout.simple.right-sidenav > .sidenav {
      width: 240px;
      min-width: 240px;
      max-width: 240px;
      padding: 24px;
      z-index: 51;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }
      .page-layout.simple.right-sidenav > .sidenav.md-locked-open {
        width: 220px;
        min-width: 220px;
        max-width: 220px;
        z-index: 2;
        box-shadow: none;
        background: transparent; }
  .page-layout.simple.tabbed {
    min-height: 100%; }
    .page-layout.simple.tabbed > .header {
      height: 200px;
      min-height: 200px;
      max-height: 200px;
      padding: 24px;
      background-image: url("/assets/images/backgrounds/header-bg.png");
      background-size: cover; }
    .page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper {
      background: #FFFFFF;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
      padding: 0 24px; }
      .page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper md-tabs-canvas {
        height: 56px; }
        .page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper md-tabs-canvas md-pagination-wrapper {
          height: 56px; }
        .page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper md-tabs-canvas .md-tab {
          padding: 16px 24px;
          text-transform: none; }
    .page-layout.simple.tabbed > .content > md-tabs:not(.md-dynamic-height) md-tabs-content-wrapper {
      top: 56px; }
    .page-layout.simple.tabbed > .content > md-tabs > md-tabs-content-wrapper > md-tab-content {
      padding: 24px; }
  .page-layout.blank {
    min-height: 100%;
    padding: 24px; }

.single-scroll .carded.fullwidth {
  height: auto; }
  .single-scroll .carded.fullwidth > .center .content-card .content {
    overflow: hidden; }

.single-scroll .carded.left-sidenav {
  height: auto; }
  .single-scroll .carded.left-sidenav > .center .content-card .content {
    overflow: hidden; }

.single-scroll .carded.right-sidenav {
  height: auto; }
  .single-scroll .carded.right-sidenav > .center .content-card .content {
    overflow: hidden; }

.single-scroll .simple.left-sidenav {
  height: auto; }
  .single-scroll .simple.left-sidenav > .center {
    overflow: hidden; }

.single-scroll .simple.right-sidenav {
  height: auto; }
  .single-scroll .simple.right-sidenav > .center {
    overflow: hidden; }

@media screen and (max-width: 600px) {
  .page-layout .top-bg {
    height: 160px; }
  .page-layout.carded.right-sidenav > .center, .page-layout.carded.left-sidenav > .center, .page-layout.carded.fullwidth > .center {
    margin-left: 16px;
    margin-right: 16px; }
    .page-layout.carded.right-sidenav > .center .header, .page-layout.carded.left-sidenav > .center .header, .page-layout.carded.fullwidth > .center .header {
      height: 96px;
      min-height: 96px;
      max-height: 96px;
      padding: 16px; }
  .page-layout.carded.fullwidth {
    height: auto; }
    .page-layout.carded.fullwidth > .center .content-card .content {
      overflow: hidden; }
  .page-layout.carded.right-sidenav, .page-layout.carded.left-sidenav {
    height: auto; }
    .page-layout.carded.right-sidenav > .center .content-card .content, .page-layout.carded.left-sidenav > .center .content-card .content {
      overflow: hidden; }
  .page-layout.simple.fullwidth > .header {
    height: 160px;
    min-height: 160px;
    max-height: 160px;
    padding: 16px; }
  .page-layout.simple.right-sidenav > .center .header, .page-layout.simple.left-sidenav > .center .header {
    height: 160px;
    min-height: 160px;
    max-height: 160px;
    padding: 16px; }
  .page-layout.simple.left-sidenav, .page-layout.simple.right-sidenav {
    height: auto; }
    .page-layout.simple.left-sidenav > .center, .page-layout.simple.right-sidenav > .center {
      overflow: hidden; } }

/*----------------------------------------------------------------*/
/*  Animations
/*----------------------------------------------------------------*/
.animate-slide-up {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }
  .animate-slide-up.ng-enter {
    transform: translateY(100%);
    opacity: 0; }
    .animate-slide-up.ng-enter.ng-enter-active {
      transition-property: all;
      transform: translateY(0);
      opacity: 1; }
  .animate-slide-up.ng-leave {
    transform: translateY(0);
    opacity: 1; }
    .animate-slide-up.ng-leave.ng-leave-active {
      transition-property: all;
      transform: translateY(-100%);
      opacity: 0; }
  .animate-slide-up.ng-hide {
    transform: translateY(-100%);
    opacity: 0; }
  .animate-slide-up.ng-hide-add-active, .animate-slide-up.ng-hide-remove-active {
    transition-property: all; }

.animate-slide-down {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }
  .animate-slide-down.ng-enter {
    transform: translateY(-100%);
    opacity: 0; }
    .animate-slide-down.ng-enter.ng-enter-active {
      transition-property: all;
      transform: translateY(0);
      opacity: 1; }
  .animate-slide-down.ng-leave {
    transform: translateY(0);
    opacity: 1; }
    .animate-slide-down.ng-leave.ng-leave-active {
      transition-property: all;
      transform: translateY(100%);
      opacity: 0; }
  .animate-slide-down.ng-hide {
    transform: translateY(-100%);
    opacity: 0; }
  .animate-slide-down.ng-hide-add-active, .animate-slide-down.ng-hide-remove-active {
    transition-property: all; }

.animate-slide-left {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }
  .animate-slide-left.ng-enter {
    transform: translateX(100%);
    opacity: 0; }
    .animate-slide-left.ng-enter.ng-enter-active {
      transition-property: all;
      transform: translateX(0);
      opacity: 1; }
  .animate-slide-left.ng-leave {
    transform: translateX(0);
    opacity: 1; }
    .animate-slide-left.ng-leave.ng-leave-active {
      transition-property: all;
      transform: translateX(-100%);
      opacity: 0; }
  .animate-slide-left.ng-hide {
    transform: translateX(-100%);
    opacity: 0; }
  .animate-slide-left.ng-hide-add-active, .animate-slide-left.ng-hide-remove-active {
    transition-property: all; }

.animate-slide-right {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }
  .animate-slide-right.ng-enter {
    transform: translateX(-100%);
    opacity: 0; }
    .animate-slide-right.ng-enter.ng-enter-active {
      transition-property: all;
      transform: translateX(0);
      opacity: 1; }
  .animate-slide-right.ng-leave {
    transform: translateX(0);
    opacity: 1; }
    .animate-slide-right.ng-leave.ng-leave-active {
      transition-property: all;
      transform: translateX(100%);
      opacity: 0; }
  .animate-slide-right.ng-hide {
    transform: translateX(100%);
    opacity: 0; }
  .animate-slide-right.ng-hide-add-active, .animate-slide-right.ng-hide-remove-active {
    transition-property: all; }

.animate-fade-in-out {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }
  .animate-fade-in-out.ng-enter {
    opacity: 0; }
    .animate-fade-in-out.ng-enter.ng-enter-active {
      transition-property: all;
      opacity: 1; }
  .animate-fade-in-out.ng-leave {
    opacity: 1; }
    .animate-fade-in-out.ng-leave.ng-leave-active {
      transition-property: all;
      opacity: 0; }
  .animate-fade-in-out.ng-hide {
    opacity: 0; }
  .animate-fade-in-out.ng-hide-add-active, .animate-fade-in-out.ng-hide-remove-active {
    transition-property: all; }

/*----------------------------------------------------------------*/
/*  @ Custom Animation Keyframes
/*----------------------------------------------------------------*/
@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-500px); }
  65% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    transform: translateX(0); } }

@keyframes slide-in-right {
  0% {
    opacity: 0;
    transform: translateX(500px); }
  65% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    transform: translateX(0); } }

@keyframes slide-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(200px); }
  65% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    transform: translateY(0); } }

.animate-rotate {
  animation: rotate 1.75s linear infinite; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/*----------------------------------------------------------------*/
/*  Colors
/*----------------------------------------------------------------*/
.black-fg,
.black-text {
  color: rgba(0, 0, 0, 0.87) !important; }
  .black-fg.secondary-text,
  .black-fg .secondary-text, .black-fg.icon,
  .black-fg .icon,
  .black-text.secondary-text,
  .black-text .secondary-text,
  .black-text.icon,
  .black-text .icon {
    color: rgba(0, 0, 0, 0.54) !important; }
  .black-fg.hint-text,
  .black-fg .hint-text, .black-fg.disabled-text,
  .black-fg .disabled-text,
  .black-text.hint-text,
  .black-text .hint-text,
  .black-text.disabled-text,
  .black-text .disabled-text {
    color: rgba(0, 0, 0, 0.26) !important; }
  .black-fg.divider,
  .black-fg .divider, .black-fg.faint-text,
  .black-fg .faint-text,
  .black-text.divider,
  .black-text .divider,
  .black-text.faint-text,
  .black-text .faint-text {
    color: rgba(0, 0, 0, 0.12) !important; }

.white-fg,
.white-text {
  color: white !important; }
  .white-fg.secondary-text,
  .white-fg .secondary-text, .white-fg.icon,
  .white-fg .icon,
  .white-text.secondary-text,
  .white-text .secondary-text,
  .white-text.icon,
  .white-text .icon {
    color: rgba(255, 255, 255, 0.7) !important; }
  .white-fg.hint-text,
  .white-fg .hint-text, .white-fg.disabled-text,
  .white-fg .disabled-text,
  .white-text.hint-text,
  .white-text .hint-text,
  .white-text.disabled-text,
  .white-text .disabled-text {
    color: rgba(255, 255, 255, 0.3) !important; }
  .white-fg.divider,
  .white-fg .divider, .white-fg.faint-text,
  .white-fg .faint-text,
  .white-text.divider,
  .white-text .divider,
  .white-text.faint-text,
  .white-text .faint-text {
    color: rgba(255, 255, 255, 0.12) !important; }
  .white-fg md-icon,
  .white-text md-icon {
    color: #FFFFFF; }
  .white-fg md-progress-linear .md-container,
  .white-text md-progress-linear .md-container {
    background: rgba(255, 255, 255, 0.3); }
  .white-fg md-progress-linear .md-bar,
  .white-text md-progress-linear .md-bar {
    background: rgba(255, 255, 255, 0.7); }

.red-50-bg {
  background-color: #FFEBEE !important; }

.md-red-50-bg {
  background-color: #FFEBEE !important;
  color: rgba(0, 0, 0, 0.87); }

.red-50-fg {
  color: #FFEBEE !important; }

.red-50-border {
  border-color: #FFEBEE !important; }

.red-50-border-top {
  border-top-color: #FFEBEE !important; }

.red-50-border-right {
  border-right-color: #FFEBEE !important; }

.red-50-border-bottom {
  border-bottom-color: #FFEBEE !important; }

.red-50-border-left {
  border-left-color: #FFEBEE !important; }

.red-100-bg {
  background-color: #FFCDD2 !important; }

.md-red-100-bg {
  background-color: #FFCDD2 !important;
  color: rgba(0, 0, 0, 0.87); }

.red-100-fg {
  color: #FFCDD2 !important; }

.red-100-border {
  border-color: #FFCDD2 !important; }

.red-100-border-top {
  border-top-color: #FFCDD2 !important; }

.red-100-border-right {
  border-right-color: #FFCDD2 !important; }

.red-100-border-bottom {
  border-bottom-color: #FFCDD2 !important; }

.red-100-border-left {
  border-left-color: #FFCDD2 !important; }

.red-200-bg {
  background-color: #EF9A9A !important; }

.md-red-200-bg {
  background-color: #EF9A9A !important;
  color: rgba(0, 0, 0, 0.87); }

.red-200-fg {
  color: #EF9A9A !important; }

.red-200-border {
  border-color: #EF9A9A !important; }

.red-200-border-top {
  border-top-color: #EF9A9A !important; }

.red-200-border-right {
  border-right-color: #EF9A9A !important; }

.red-200-border-bottom {
  border-bottom-color: #EF9A9A !important; }

.red-200-border-left {
  border-left-color: #EF9A9A !important; }

.red-300-bg {
  background-color: #E57373 !important; }

.md-red-300-bg {
  background-color: #E57373 !important;
  color: rgba(0, 0, 0, 0.87); }

.red-300-fg {
  color: #E57373 !important; }

.red-300-border {
  border-color: #E57373 !important; }

.red-300-border-top {
  border-top-color: #E57373 !important; }

.red-300-border-right {
  border-right-color: #E57373 !important; }

.red-300-border-bottom {
  border-bottom-color: #E57373 !important; }

.red-300-border-left {
  border-left-color: #E57373 !important; }

.red-400-bg {
  background-color: #EF5350 !important; }

.md-red-400-bg {
  background-color: #EF5350 !important;
  color: white; }

.red-400-fg {
  color: #EF5350 !important; }

.red-400-border {
  border-color: #EF5350 !important; }

.red-400-border-top {
  border-top-color: #EF5350 !important; }

.red-400-border-right {
  border-right-color: #EF5350 !important; }

.red-400-border-bottom {
  border-bottom-color: #EF5350 !important; }

.red-400-border-left {
  border-left-color: #EF5350 !important; }

.red-500-bg {
  background-color: #F44336 !important; }

.md-red-500-bg {
  background-color: #F44336 !important;
  color: white; }

.red-500-fg {
  color: #F44336 !important; }

.red-500-border {
  border-color: #F44336 !important; }

.red-500-border-top {
  border-top-color: #F44336 !important; }

.red-500-border-right {
  border-right-color: #F44336 !important; }

.red-500-border-bottom {
  border-bottom-color: #F44336 !important; }

.red-500-border-left {
  border-left-color: #F44336 !important; }

.md-red-bg {
  background-color: #F44336 !important;
  color: white; }

.red-bg {
  background-color: #F44336 !important; }

.red-fg {
  color: #F44336 !important; }

.red-border {
  border-color: #F44336 !important; }

.red-border-top {
  border-top-color: #F44336 !important; }

.red-border-right {
  border-right-color: #F44336 !important; }

.red-border-bottom {
  border-bottom-color: #F44336 !important; }

.red-border-left {
  border-left-color: #F44336 !important; }

.red-600-bg {
  background-color: #E53935 !important; }

.md-red-600-bg {
  background-color: #E53935 !important;
  color: white; }

.red-600-fg {
  color: #E53935 !important; }

.red-600-border {
  border-color: #E53935 !important; }

.red-600-border-top {
  border-top-color: #E53935 !important; }

.red-600-border-right {
  border-right-color: #E53935 !important; }

.red-600-border-bottom {
  border-bottom-color: #E53935 !important; }

.red-600-border-left {
  border-left-color: #E53935 !important; }

.red-700-bg {
  background-color: #D32F2F !important; }

.md-red-700-bg {
  background-color: #D32F2F !important;
  color: white; }

.red-700-fg {
  color: #D32F2F !important; }

.red-700-border {
  border-color: #D32F2F !important; }

.red-700-border-top {
  border-top-color: #D32F2F !important; }

.red-700-border-right {
  border-right-color: #D32F2F !important; }

.red-700-border-bottom {
  border-bottom-color: #D32F2F !important; }

.red-700-border-left {
  border-left-color: #D32F2F !important; }

.red-800-bg {
  background-color: #C62828 !important; }

.md-red-800-bg {
  background-color: #C62828 !important;
  color: rgba(255, 255, 255, 0.87); }

.red-800-fg {
  color: #C62828 !important; }

.red-800-border {
  border-color: #C62828 !important; }

.red-800-border-top {
  border-top-color: #C62828 !important; }

.red-800-border-right {
  border-right-color: #C62828 !important; }

.red-800-border-bottom {
  border-bottom-color: #C62828 !important; }

.red-800-border-left {
  border-left-color: #C62828 !important; }

.red-900-bg {
  background-color: #B71C1C !important; }

.md-red-900-bg {
  background-color: #B71C1C !important;
  color: rgba(255, 255, 255, 0.87); }

.red-900-fg {
  color: #B71C1C !important; }

.red-900-border {
  border-color: #B71C1C !important; }

.red-900-border-top {
  border-top-color: #B71C1C !important; }

.red-900-border-right {
  border-right-color: #B71C1C !important; }

.red-900-border-bottom {
  border-bottom-color: #B71C1C !important; }

.red-900-border-left {
  border-left-color: #B71C1C !important; }

.red-A100-bg {
  background-color: #FF8A80 !important; }

.md-red-A100-bg {
  background-color: #FF8A80 !important;
  color: rgba(0, 0, 0, 0.87); }

.red-A100-fg {
  color: #FF8A80 !important; }

.red-A100-border {
  border-color: #FF8A80 !important; }

.red-A100-border-top {
  border-top-color: #FF8A80 !important; }

.red-A100-border-right {
  border-right-color: #FF8A80 !important; }

.red-A100-border-bottom {
  border-bottom-color: #FF8A80 !important; }

.red-A100-border-left {
  border-left-color: #FF8A80 !important; }

.red-A200-bg {
  background-color: #FF5252 !important; }

.md-red-A200-bg {
  background-color: #FF5252 !important;
  color: white; }

.red-A200-fg {
  color: #FF5252 !important; }

.red-A200-border {
  border-color: #FF5252 !important; }

.red-A200-border-top {
  border-top-color: #FF5252 !important; }

.red-A200-border-right {
  border-right-color: #FF5252 !important; }

.red-A200-border-bottom {
  border-bottom-color: #FF5252 !important; }

.red-A200-border-left {
  border-left-color: #FF5252 !important; }

.red-A400-bg {
  background-color: #FF1744 !important; }

.md-red-A400-bg {
  background-color: #FF1744 !important;
  color: white; }

.red-A400-fg {
  color: #FF1744 !important; }

.red-A400-border {
  border-color: #FF1744 !important; }

.red-A400-border-top {
  border-top-color: #FF1744 !important; }

.red-A400-border-right {
  border-right-color: #FF1744 !important; }

.red-A400-border-bottom {
  border-bottom-color: #FF1744 !important; }

.red-A400-border-left {
  border-left-color: #FF1744 !important; }

.red-A700-bg {
  background-color: #D50000 !important; }

.md-red-A700-bg {
  background-color: #D50000 !important;
  color: white; }

.red-A700-fg {
  color: #D50000 !important; }

.red-A700-border {
  border-color: #D50000 !important; }

.red-A700-border-top {
  border-top-color: #D50000 !important; }

.red-A700-border-right {
  border-right-color: #D50000 !important; }

.red-A700-border-bottom {
  border-bottom-color: #D50000 !important; }

.red-A700-border-left {
  border-left-color: #D50000 !important; }

.pink-50-bg {
  background-color: #FCE4EC !important; }

.md-pink-50-bg {
  background-color: #FCE4EC !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-50-fg {
  color: #FCE4EC !important; }

.pink-50-border {
  border-color: #FCE4EC !important; }

.pink-50-border-top {
  border-top-color: #FCE4EC !important; }

.pink-50-border-right {
  border-right-color: #FCE4EC !important; }

.pink-50-border-bottom {
  border-bottom-color: #FCE4EC !important; }

.pink-50-border-left {
  border-left-color: #FCE4EC !important; }

.pink-100-bg {
  background-color: #F8BBD0 !important; }

.md-pink-100-bg {
  background-color: #F8BBD0 !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-100-fg {
  color: #F8BBD0 !important; }

.pink-100-border {
  border-color: #F8BBD0 !important; }

.pink-100-border-top {
  border-top-color: #F8BBD0 !important; }

.pink-100-border-right {
  border-right-color: #F8BBD0 !important; }

.pink-100-border-bottom {
  border-bottom-color: #F8BBD0 !important; }

.pink-100-border-left {
  border-left-color: #F8BBD0 !important; }

.pink-200-bg {
  background-color: #F48FB1 !important; }

.md-pink-200-bg {
  background-color: #F48FB1 !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-200-fg {
  color: #F48FB1 !important; }

.pink-200-border {
  border-color: #F48FB1 !important; }

.pink-200-border-top {
  border-top-color: #F48FB1 !important; }

.pink-200-border-right {
  border-right-color: #F48FB1 !important; }

.pink-200-border-bottom {
  border-bottom-color: #F48FB1 !important; }

.pink-200-border-left {
  border-left-color: #F48FB1 !important; }

.pink-300-bg {
  background-color: #F06292 !important; }

.md-pink-300-bg {
  background-color: #F06292 !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-300-fg {
  color: #F06292 !important; }

.pink-300-border {
  border-color: #F06292 !important; }

.pink-300-border-top {
  border-top-color: #F06292 !important; }

.pink-300-border-right {
  border-right-color: #F06292 !important; }

.pink-300-border-bottom {
  border-bottom-color: #F06292 !important; }

.pink-300-border-left {
  border-left-color: #F06292 !important; }

.pink-400-bg {
  background-color: #EC407A !important; }

.md-pink-400-bg {
  background-color: #EC407A !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-400-fg {
  color: #EC407A !important; }

.pink-400-border {
  border-color: #EC407A !important; }

.pink-400-border-top {
  border-top-color: #EC407A !important; }

.pink-400-border-right {
  border-right-color: #EC407A !important; }

.pink-400-border-bottom {
  border-bottom-color: #EC407A !important; }

.pink-400-border-left {
  border-left-color: #EC407A !important; }

.pink-500-bg {
  background-color: #E91E63 !important; }

.md-pink-500-bg {
  background-color: #E91E63 !important;
  color: white; }

.pink-500-fg {
  color: #E91E63 !important; }

.pink-500-border {
  border-color: #E91E63 !important; }

.pink-500-border-top {
  border-top-color: #E91E63 !important; }

.pink-500-border-right {
  border-right-color: #E91E63 !important; }

.pink-500-border-bottom {
  border-bottom-color: #E91E63 !important; }

.pink-500-border-left {
  border-left-color: #E91E63 !important; }

.md-pink-bg {
  background-color: #E91E63 !important;
  color: white; }

.pink-bg {
  background-color: #E91E63 !important; }

.pink-fg {
  color: #E91E63 !important; }

.pink-border {
  border-color: #E91E63 !important; }

.pink-border-top {
  border-top-color: #E91E63 !important; }

.pink-border-right {
  border-right-color: #E91E63 !important; }

.pink-border-bottom {
  border-bottom-color: #E91E63 !important; }

.pink-border-left {
  border-left-color: #E91E63 !important; }

.pink-600-bg {
  background-color: #D81B60 !important; }

.md-pink-600-bg {
  background-color: #D81B60 !important;
  color: white; }

.pink-600-fg {
  color: #D81B60 !important; }

.pink-600-border {
  border-color: #D81B60 !important; }

.pink-600-border-top {
  border-top-color: #D81B60 !important; }

.pink-600-border-right {
  border-right-color: #D81B60 !important; }

.pink-600-border-bottom {
  border-bottom-color: #D81B60 !important; }

.pink-600-border-left {
  border-left-color: #D81B60 !important; }

.pink-700-bg {
  background-color: #C2185B !important; }

.md-pink-700-bg {
  background-color: #C2185B !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-700-fg {
  color: #C2185B !important; }

.pink-700-border {
  border-color: #C2185B !important; }

.pink-700-border-top {
  border-top-color: #C2185B !important; }

.pink-700-border-right {
  border-right-color: #C2185B !important; }

.pink-700-border-bottom {
  border-bottom-color: #C2185B !important; }

.pink-700-border-left {
  border-left-color: #C2185B !important; }

.pink-800-bg {
  background-color: #AD1457 !important; }

.md-pink-800-bg {
  background-color: #AD1457 !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-800-fg {
  color: #AD1457 !important; }

.pink-800-border {
  border-color: #AD1457 !important; }

.pink-800-border-top {
  border-top-color: #AD1457 !important; }

.pink-800-border-right {
  border-right-color: #AD1457 !important; }

.pink-800-border-bottom {
  border-bottom-color: #AD1457 !important; }

.pink-800-border-left {
  border-left-color: #AD1457 !important; }

.pink-900-bg {
  background-color: #880E4F !important; }

.md-pink-900-bg {
  background-color: #880E4F !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-900-fg {
  color: #880E4F !important; }

.pink-900-border {
  border-color: #880E4F !important; }

.pink-900-border-top {
  border-top-color: #880E4F !important; }

.pink-900-border-right {
  border-right-color: #880E4F !important; }

.pink-900-border-bottom {
  border-bottom-color: #880E4F !important; }

.pink-900-border-left {
  border-left-color: #880E4F !important; }

.pink-A100-bg {
  background-color: #FF80AB !important; }

.md-pink-A100-bg {
  background-color: #FF80AB !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-A100-fg {
  color: #FF80AB !important; }

.pink-A100-border {
  border-color: #FF80AB !important; }

.pink-A100-border-top {
  border-top-color: #FF80AB !important; }

.pink-A100-border-right {
  border-right-color: #FF80AB !important; }

.pink-A100-border-bottom {
  border-bottom-color: #FF80AB !important; }

.pink-A100-border-left {
  border-left-color: #FF80AB !important; }

.pink-A200-bg {
  background-color: #FF4081 !important; }

.md-pink-A200-bg {
  background-color: #FF4081 !important;
  color: white; }

.pink-A200-fg {
  color: #FF4081 !important; }

.pink-A200-border {
  border-color: #FF4081 !important; }

.pink-A200-border-top {
  border-top-color: #FF4081 !important; }

.pink-A200-border-right {
  border-right-color: #FF4081 !important; }

.pink-A200-border-bottom {
  border-bottom-color: #FF4081 !important; }

.pink-A200-border-left {
  border-left-color: #FF4081 !important; }

.pink-A400-bg {
  background-color: #F50057 !important; }

.md-pink-A400-bg {
  background-color: #F50057 !important;
  color: white; }

.pink-A400-fg {
  color: #F50057 !important; }

.pink-A400-border {
  border-color: #F50057 !important; }

.pink-A400-border-top {
  border-top-color: #F50057 !important; }

.pink-A400-border-right {
  border-right-color: #F50057 !important; }

.pink-A400-border-bottom {
  border-bottom-color: #F50057 !important; }

.pink-A400-border-left {
  border-left-color: #F50057 !important; }

.pink-A700-bg {
  background-color: #C51162 !important; }

.md-pink-A700-bg {
  background-color: #C51162 !important;
  color: white; }

.pink-A700-fg {
  color: #C51162 !important; }

.pink-A700-border {
  border-color: #C51162 !important; }

.pink-A700-border-top {
  border-top-color: #C51162 !important; }

.pink-A700-border-right {
  border-right-color: #C51162 !important; }

.pink-A700-border-bottom {
  border-bottom-color: #C51162 !important; }

.pink-A700-border-left {
  border-left-color: #C51162 !important; }

.purple-50-bg {
  background-color: #F3E5F5 !important; }

.md-purple-50-bg {
  background-color: #F3E5F5 !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-50-fg {
  color: #F3E5F5 !important; }

.purple-50-border {
  border-color: #F3E5F5 !important; }

.purple-50-border-top {
  border-top-color: #F3E5F5 !important; }

.purple-50-border-right {
  border-right-color: #F3E5F5 !important; }

.purple-50-border-bottom {
  border-bottom-color: #F3E5F5 !important; }

.purple-50-border-left {
  border-left-color: #F3E5F5 !important; }

.purple-100-bg {
  background-color: #E1BEE7 !important; }

.md-purple-100-bg {
  background-color: #E1BEE7 !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-100-fg {
  color: #E1BEE7 !important; }

.purple-100-border {
  border-color: #E1BEE7 !important; }

.purple-100-border-top {
  border-top-color: #E1BEE7 !important; }

.purple-100-border-right {
  border-right-color: #E1BEE7 !important; }

.purple-100-border-bottom {
  border-bottom-color: #E1BEE7 !important; }

.purple-100-border-left {
  border-left-color: #E1BEE7 !important; }

.purple-200-bg {
  background-color: #CE93D8 !important; }

.md-purple-200-bg {
  background-color: #CE93D8 !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-200-fg {
  color: #CE93D8 !important; }

.purple-200-border {
  border-color: #CE93D8 !important; }

.purple-200-border-top {
  border-top-color: #CE93D8 !important; }

.purple-200-border-right {
  border-right-color: #CE93D8 !important; }

.purple-200-border-bottom {
  border-bottom-color: #CE93D8 !important; }

.purple-200-border-left {
  border-left-color: #CE93D8 !important; }

.purple-300-bg {
  background-color: #BA68C8 !important; }

.md-purple-300-bg {
  background-color: #BA68C8 !important;
  color: white; }

.purple-300-fg {
  color: #BA68C8 !important; }

.purple-300-border {
  border-color: #BA68C8 !important; }

.purple-300-border-top {
  border-top-color: #BA68C8 !important; }

.purple-300-border-right {
  border-right-color: #BA68C8 !important; }

.purple-300-border-bottom {
  border-bottom-color: #BA68C8 !important; }

.purple-300-border-left {
  border-left-color: #BA68C8 !important; }

.purple-400-bg {
  background-color: #AB47BC !important; }

.md-purple-400-bg {
  background-color: #AB47BC !important;
  color: white; }

.purple-400-fg {
  color: #AB47BC !important; }

.purple-400-border {
  border-color: #AB47BC !important; }

.purple-400-border-top {
  border-top-color: #AB47BC !important; }

.purple-400-border-right {
  border-right-color: #AB47BC !important; }

.purple-400-border-bottom {
  border-bottom-color: #AB47BC !important; }

.purple-400-border-left {
  border-left-color: #AB47BC !important; }

.purple-500-bg {
  background-color: #9C27B0 !important; }

.md-purple-500-bg {
  background-color: #9C27B0 !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-500-fg {
  color: #9C27B0 !important; }

.purple-500-border {
  border-color: #9C27B0 !important; }

.purple-500-border-top {
  border-top-color: #9C27B0 !important; }

.purple-500-border-right {
  border-right-color: #9C27B0 !important; }

.purple-500-border-bottom {
  border-bottom-color: #9C27B0 !important; }

.purple-500-border-left {
  border-left-color: #9C27B0 !important; }

.md-purple-bg {
  background-color: #9C27B0 !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-bg {
  background-color: #9C27B0 !important; }

.purple-fg {
  color: #9C27B0 !important; }

.purple-border {
  border-color: #9C27B0 !important; }

.purple-border-top {
  border-top-color: #9C27B0 !important; }

.purple-border-right {
  border-right-color: #9C27B0 !important; }

.purple-border-bottom {
  border-bottom-color: #9C27B0 !important; }

.purple-border-left {
  border-left-color: #9C27B0 !important; }

.purple-600-bg {
  background-color: #8E24AA !important; }

.md-purple-600-bg {
  background-color: #8E24AA !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-600-fg {
  color: #8E24AA !important; }

.purple-600-border {
  border-color: #8E24AA !important; }

.purple-600-border-top {
  border-top-color: #8E24AA !important; }

.purple-600-border-right {
  border-right-color: #8E24AA !important; }

.purple-600-border-bottom {
  border-bottom-color: #8E24AA !important; }

.purple-600-border-left {
  border-left-color: #8E24AA !important; }

.purple-700-bg {
  background-color: #7B1FA2 !important; }

.md-purple-700-bg {
  background-color: #7B1FA2 !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-700-fg {
  color: #7B1FA2 !important; }

.purple-700-border {
  border-color: #7B1FA2 !important; }

.purple-700-border-top {
  border-top-color: #7B1FA2 !important; }

.purple-700-border-right {
  border-right-color: #7B1FA2 !important; }

.purple-700-border-bottom {
  border-bottom-color: #7B1FA2 !important; }

.purple-700-border-left {
  border-left-color: #7B1FA2 !important; }

.purple-800-bg {
  background-color: #6A1B9A !important; }

.md-purple-800-bg {
  background-color: #6A1B9A !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-800-fg {
  color: #6A1B9A !important; }

.purple-800-border {
  border-color: #6A1B9A !important; }

.purple-800-border-top {
  border-top-color: #6A1B9A !important; }

.purple-800-border-right {
  border-right-color: #6A1B9A !important; }

.purple-800-border-bottom {
  border-bottom-color: #6A1B9A !important; }

.purple-800-border-left {
  border-left-color: #6A1B9A !important; }

.purple-900-bg {
  background-color: #4A148C !important; }

.md-purple-900-bg {
  background-color: #4A148C !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-900-fg {
  color: #4A148C !important; }

.purple-900-border {
  border-color: #4A148C !important; }

.purple-900-border-top {
  border-top-color: #4A148C !important; }

.purple-900-border-right {
  border-right-color: #4A148C !important; }

.purple-900-border-bottom {
  border-bottom-color: #4A148C !important; }

.purple-900-border-left {
  border-left-color: #4A148C !important; }

.purple-A100-bg {
  background-color: #EA80FC !important; }

.md-purple-A100-bg {
  background-color: #EA80FC !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-A100-fg {
  color: #EA80FC !important; }

.purple-A100-border {
  border-color: #EA80FC !important; }

.purple-A100-border-top {
  border-top-color: #EA80FC !important; }

.purple-A100-border-right {
  border-right-color: #EA80FC !important; }

.purple-A100-border-bottom {
  border-bottom-color: #EA80FC !important; }

.purple-A100-border-left {
  border-left-color: #EA80FC !important; }

.purple-A200-bg {
  background-color: #E040FB !important; }

.md-purple-A200-bg {
  background-color: #E040FB !important;
  color: white; }

.purple-A200-fg {
  color: #E040FB !important; }

.purple-A200-border {
  border-color: #E040FB !important; }

.purple-A200-border-top {
  border-top-color: #E040FB !important; }

.purple-A200-border-right {
  border-right-color: #E040FB !important; }

.purple-A200-border-bottom {
  border-bottom-color: #E040FB !important; }

.purple-A200-border-left {
  border-left-color: #E040FB !important; }

.purple-A400-bg {
  background-color: #D500F9 !important; }

.md-purple-A400-bg {
  background-color: #D500F9 !important;
  color: white; }

.purple-A400-fg {
  color: #D500F9 !important; }

.purple-A400-border {
  border-color: #D500F9 !important; }

.purple-A400-border-top {
  border-top-color: #D500F9 !important; }

.purple-A400-border-right {
  border-right-color: #D500F9 !important; }

.purple-A400-border-bottom {
  border-bottom-color: #D500F9 !important; }

.purple-A400-border-left {
  border-left-color: #D500F9 !important; }

.purple-A700-bg {
  background-color: #AA00FF !important; }

.md-purple-A700-bg {
  background-color: #AA00FF !important;
  color: white; }

.purple-A700-fg {
  color: #AA00FF !important; }

.purple-A700-border {
  border-color: #AA00FF !important; }

.purple-A700-border-top {
  border-top-color: #AA00FF !important; }

.purple-A700-border-right {
  border-right-color: #AA00FF !important; }

.purple-A700-border-bottom {
  border-bottom-color: #AA00FF !important; }

.purple-A700-border-left {
  border-left-color: #AA00FF !important; }

.deep-purple-50-bg {
  background-color: #EDE7F6 !important; }

.md-deep-purple-50-bg {
  background-color: #EDE7F6 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-50-fg {
  color: #EDE7F6 !important; }

.deep-purple-50-border {
  border-color: #EDE7F6 !important; }

.deep-purple-50-border-top {
  border-top-color: #EDE7F6 !important; }

.deep-purple-50-border-right {
  border-right-color: #EDE7F6 !important; }

.deep-purple-50-border-bottom {
  border-bottom-color: #EDE7F6 !important; }

.deep-purple-50-border-left {
  border-left-color: #EDE7F6 !important; }

.deep-purple-100-bg {
  background-color: #D1C4E9 !important; }

.md-deep-purple-100-bg {
  background-color: #D1C4E9 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-100-fg {
  color: #D1C4E9 !important; }

.deep-purple-100-border {
  border-color: #D1C4E9 !important; }

.deep-purple-100-border-top {
  border-top-color: #D1C4E9 !important; }

.deep-purple-100-border-right {
  border-right-color: #D1C4E9 !important; }

.deep-purple-100-border-bottom {
  border-bottom-color: #D1C4E9 !important; }

.deep-purple-100-border-left {
  border-left-color: #D1C4E9 !important; }

.deep-purple-200-bg {
  background-color: #B39DDB !important; }

.md-deep-purple-200-bg {
  background-color: #B39DDB !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-200-fg {
  color: #B39DDB !important; }

.deep-purple-200-border {
  border-color: #B39DDB !important; }

.deep-purple-200-border-top {
  border-top-color: #B39DDB !important; }

.deep-purple-200-border-right {
  border-right-color: #B39DDB !important; }

.deep-purple-200-border-bottom {
  border-bottom-color: #B39DDB !important; }

.deep-purple-200-border-left {
  border-left-color: #B39DDB !important; }

.deep-purple-300-bg {
  background-color: #9575CD !important; }

.md-deep-purple-300-bg {
  background-color: #9575CD !important;
  color: white; }

.deep-purple-300-fg {
  color: #9575CD !important; }

.deep-purple-300-border {
  border-color: #9575CD !important; }

.deep-purple-300-border-top {
  border-top-color: #9575CD !important; }

.deep-purple-300-border-right {
  border-right-color: #9575CD !important; }

.deep-purple-300-border-bottom {
  border-bottom-color: #9575CD !important; }

.deep-purple-300-border-left {
  border-left-color: #9575CD !important; }

.deep-purple-400-bg {
  background-color: #7E57C2 !important; }

.md-deep-purple-400-bg {
  background-color: #7E57C2 !important;
  color: white; }

.deep-purple-400-fg {
  color: #7E57C2 !important; }

.deep-purple-400-border {
  border-color: #7E57C2 !important; }

.deep-purple-400-border-top {
  border-top-color: #7E57C2 !important; }

.deep-purple-400-border-right {
  border-right-color: #7E57C2 !important; }

.deep-purple-400-border-bottom {
  border-bottom-color: #7E57C2 !important; }

.deep-purple-400-border-left {
  border-left-color: #7E57C2 !important; }

.deep-purple-500-bg {
  background-color: #673AB7 !important; }

.md-deep-purple-500-bg {
  background-color: #673AB7 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-500-fg {
  color: #673AB7 !important; }

.deep-purple-500-border {
  border-color: #673AB7 !important; }

.deep-purple-500-border-top {
  border-top-color: #673AB7 !important; }

.deep-purple-500-border-right {
  border-right-color: #673AB7 !important; }

.deep-purple-500-border-bottom {
  border-bottom-color: #673AB7 !important; }

.deep-purple-500-border-left {
  border-left-color: #673AB7 !important; }

.md-deep-purple-bg {
  background-color: #673AB7 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-bg {
  background-color: #673AB7 !important; }

.deep-purple-fg {
  color: #673AB7 !important; }

.deep-purple-border {
  border-color: #673AB7 !important; }

.deep-purple-border-top {
  border-top-color: #673AB7 !important; }

.deep-purple-border-right {
  border-right-color: #673AB7 !important; }

.deep-purple-border-bottom {
  border-bottom-color: #673AB7 !important; }

.deep-purple-border-left {
  border-left-color: #673AB7 !important; }

.deep-purple-600-bg {
  background-color: #5E35B1 !important; }

.md-deep-purple-600-bg {
  background-color: #5E35B1 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-600-fg {
  color: #5E35B1 !important; }

.deep-purple-600-border {
  border-color: #5E35B1 !important; }

.deep-purple-600-border-top {
  border-top-color: #5E35B1 !important; }

.deep-purple-600-border-right {
  border-right-color: #5E35B1 !important; }

.deep-purple-600-border-bottom {
  border-bottom-color: #5E35B1 !important; }

.deep-purple-600-border-left {
  border-left-color: #5E35B1 !important; }

.deep-purple-700-bg {
  background-color: #512DA8 !important; }

.md-deep-purple-700-bg {
  background-color: #512DA8 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-700-fg {
  color: #512DA8 !important; }

.deep-purple-700-border {
  border-color: #512DA8 !important; }

.deep-purple-700-border-top {
  border-top-color: #512DA8 !important; }

.deep-purple-700-border-right {
  border-right-color: #512DA8 !important; }

.deep-purple-700-border-bottom {
  border-bottom-color: #512DA8 !important; }

.deep-purple-700-border-left {
  border-left-color: #512DA8 !important; }

.deep-purple-800-bg {
  background-color: #4527A0 !important; }

.md-deep-purple-800-bg {
  background-color: #4527A0 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-800-fg {
  color: #4527A0 !important; }

.deep-purple-800-border {
  border-color: #4527A0 !important; }

.deep-purple-800-border-top {
  border-top-color: #4527A0 !important; }

.deep-purple-800-border-right {
  border-right-color: #4527A0 !important; }

.deep-purple-800-border-bottom {
  border-bottom-color: #4527A0 !important; }

.deep-purple-800-border-left {
  border-left-color: #4527A0 !important; }

.deep-purple-900-bg {
  background-color: #311B92 !important; }

.md-deep-purple-900-bg {
  background-color: #311B92 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-900-fg {
  color: #311B92 !important; }

.deep-purple-900-border {
  border-color: #311B92 !important; }

.deep-purple-900-border-top {
  border-top-color: #311B92 !important; }

.deep-purple-900-border-right {
  border-right-color: #311B92 !important; }

.deep-purple-900-border-bottom {
  border-bottom-color: #311B92 !important; }

.deep-purple-900-border-left {
  border-left-color: #311B92 !important; }

.deep-purple-A100-bg {
  background-color: #B388FF !important; }

.md-deep-purple-A100-bg {
  background-color: #B388FF !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-A100-fg {
  color: #B388FF !important; }

.deep-purple-A100-border {
  border-color: #B388FF !important; }

.deep-purple-A100-border-top {
  border-top-color: #B388FF !important; }

.deep-purple-A100-border-right {
  border-right-color: #B388FF !important; }

.deep-purple-A100-border-bottom {
  border-bottom-color: #B388FF !important; }

.deep-purple-A100-border-left {
  border-left-color: #B388FF !important; }

.deep-purple-A200-bg {
  background-color: #7C4DFF !important; }

.md-deep-purple-A200-bg {
  background-color: #7C4DFF !important;
  color: white; }

.deep-purple-A200-fg {
  color: #7C4DFF !important; }

.deep-purple-A200-border {
  border-color: #7C4DFF !important; }

.deep-purple-A200-border-top {
  border-top-color: #7C4DFF !important; }

.deep-purple-A200-border-right {
  border-right-color: #7C4DFF !important; }

.deep-purple-A200-border-bottom {
  border-bottom-color: #7C4DFF !important; }

.deep-purple-A200-border-left {
  border-left-color: #7C4DFF !important; }

.deep-purple-A400-bg {
  background-color: #651FFF !important; }

.md-deep-purple-A400-bg {
  background-color: #651FFF !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-A400-fg {
  color: #651FFF !important; }

.deep-purple-A400-border {
  border-color: #651FFF !important; }

.deep-purple-A400-border-top {
  border-top-color: #651FFF !important; }

.deep-purple-A400-border-right {
  border-right-color: #651FFF !important; }

.deep-purple-A400-border-bottom {
  border-bottom-color: #651FFF !important; }

.deep-purple-A400-border-left {
  border-left-color: #651FFF !important; }

.deep-purple-A700-bg {
  background-color: #6200EA !important; }

.md-deep-purple-A700-bg {
  background-color: #6200EA !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-A700-fg {
  color: #6200EA !important; }

.deep-purple-A700-border {
  border-color: #6200EA !important; }

.deep-purple-A700-border-top {
  border-top-color: #6200EA !important; }

.deep-purple-A700-border-right {
  border-right-color: #6200EA !important; }

.deep-purple-A700-border-bottom {
  border-bottom-color: #6200EA !important; }

.deep-purple-A700-border-left {
  border-left-color: #6200EA !important; }

.indigo-50-bg {
  background-color: #E8EAF6 !important; }

.md-indigo-50-bg {
  background-color: #E8EAF6 !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-50-fg {
  color: #E8EAF6 !important; }

.indigo-50-border {
  border-color: #E8EAF6 !important; }

.indigo-50-border-top {
  border-top-color: #E8EAF6 !important; }

.indigo-50-border-right {
  border-right-color: #E8EAF6 !important; }

.indigo-50-border-bottom {
  border-bottom-color: #E8EAF6 !important; }

.indigo-50-border-left {
  border-left-color: #E8EAF6 !important; }

.indigo-100-bg {
  background-color: #C5CAE9 !important; }

.md-indigo-100-bg {
  background-color: #C5CAE9 !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-100-fg {
  color: #C5CAE9 !important; }

.indigo-100-border {
  border-color: #C5CAE9 !important; }

.indigo-100-border-top {
  border-top-color: #C5CAE9 !important; }

.indigo-100-border-right {
  border-right-color: #C5CAE9 !important; }

.indigo-100-border-bottom {
  border-bottom-color: #C5CAE9 !important; }

.indigo-100-border-left {
  border-left-color: #C5CAE9 !important; }

.indigo-200-bg {
  background-color: #9FA8DA !important; }

.md-indigo-200-bg {
  background-color: #9FA8DA !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-200-fg {
  color: #9FA8DA !important; }

.indigo-200-border {
  border-color: #9FA8DA !important; }

.indigo-200-border-top {
  border-top-color: #9FA8DA !important; }

.indigo-200-border-right {
  border-right-color: #9FA8DA !important; }

.indigo-200-border-bottom {
  border-bottom-color: #9FA8DA !important; }

.indigo-200-border-left {
  border-left-color: #9FA8DA !important; }

.indigo-300-bg {
  background-color: #7986CB !important; }

.md-indigo-300-bg {
  background-color: #7986CB !important;
  color: white; }

.indigo-300-fg {
  color: #7986CB !important; }

.indigo-300-border {
  border-color: #7986CB !important; }

.indigo-300-border-top {
  border-top-color: #7986CB !important; }

.indigo-300-border-right {
  border-right-color: #7986CB !important; }

.indigo-300-border-bottom {
  border-bottom-color: #7986CB !important; }

.indigo-300-border-left {
  border-left-color: #7986CB !important; }

.indigo-400-bg {
  background-color: #5C6BC0 !important; }

.md-indigo-400-bg {
  background-color: #5C6BC0 !important;
  color: white; }

.indigo-400-fg {
  color: #5C6BC0 !important; }

.indigo-400-border {
  border-color: #5C6BC0 !important; }

.indigo-400-border-top {
  border-top-color: #5C6BC0 !important; }

.indigo-400-border-right {
  border-right-color: #5C6BC0 !important; }

.indigo-400-border-bottom {
  border-bottom-color: #5C6BC0 !important; }

.indigo-400-border-left {
  border-left-color: #5C6BC0 !important; }

.indigo-500-bg {
  background-color: #3F51B5 !important; }

.md-indigo-500-bg {
  background-color: #3F51B5 !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-500-fg {
  color: #3F51B5 !important; }

.indigo-500-border {
  border-color: #3F51B5 !important; }

.indigo-500-border-top {
  border-top-color: #3F51B5 !important; }

.indigo-500-border-right {
  border-right-color: #3F51B5 !important; }

.indigo-500-border-bottom {
  border-bottom-color: #3F51B5 !important; }

.indigo-500-border-left {
  border-left-color: #3F51B5 !important; }

.md-indigo-bg {
  background-color: #3F51B5 !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-bg {
  background-color: #3F51B5 !important; }

.indigo-fg {
  color: #3F51B5 !important; }

.indigo-border {
  border-color: #3F51B5 !important; }

.indigo-border-top {
  border-top-color: #3F51B5 !important; }

.indigo-border-right {
  border-right-color: #3F51B5 !important; }

.indigo-border-bottom {
  border-bottom-color: #3F51B5 !important; }

.indigo-border-left {
  border-left-color: #3F51B5 !important; }

.indigo-600-bg {
  background-color: #3949AB !important; }

.md-indigo-600-bg {
  background-color: #3949AB !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-600-fg {
  color: #3949AB !important; }

.indigo-600-border {
  border-color: #3949AB !important; }

.indigo-600-border-top {
  border-top-color: #3949AB !important; }

.indigo-600-border-right {
  border-right-color: #3949AB !important; }

.indigo-600-border-bottom {
  border-bottom-color: #3949AB !important; }

.indigo-600-border-left {
  border-left-color: #3949AB !important; }

.indigo-700-bg {
  background-color: #303F9F !important; }

.md-indigo-700-bg {
  background-color: #303F9F !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-700-fg {
  color: #303F9F !important; }

.indigo-700-border {
  border-color: #303F9F !important; }

.indigo-700-border-top {
  border-top-color: #303F9F !important; }

.indigo-700-border-right {
  border-right-color: #303F9F !important; }

.indigo-700-border-bottom {
  border-bottom-color: #303F9F !important; }

.indigo-700-border-left {
  border-left-color: #303F9F !important; }

.indigo-800-bg {
  background-color: #283593 !important; }

.md-indigo-800-bg {
  background-color: #283593 !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-800-fg {
  color: #283593 !important; }

.indigo-800-border {
  border-color: #283593 !important; }

.indigo-800-border-top {
  border-top-color: #283593 !important; }

.indigo-800-border-right {
  border-right-color: #283593 !important; }

.indigo-800-border-bottom {
  border-bottom-color: #283593 !important; }

.indigo-800-border-left {
  border-left-color: #283593 !important; }

.indigo-900-bg {
  background-color: #1A237E !important; }

.md-indigo-900-bg {
  background-color: #1A237E !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-900-fg {
  color: #1A237E !important; }

.indigo-900-border {
  border-color: #1A237E !important; }

.indigo-900-border-top {
  border-top-color: #1A237E !important; }

.indigo-900-border-right {
  border-right-color: #1A237E !important; }

.indigo-900-border-bottom {
  border-bottom-color: #1A237E !important; }

.indigo-900-border-left {
  border-left-color: #1A237E !important; }

.indigo-A100-bg {
  background-color: #8C9EFF !important; }

.md-indigo-A100-bg {
  background-color: #8C9EFF !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-A100-fg {
  color: #8C9EFF !important; }

.indigo-A100-border {
  border-color: #8C9EFF !important; }

.indigo-A100-border-top {
  border-top-color: #8C9EFF !important; }

.indigo-A100-border-right {
  border-right-color: #8C9EFF !important; }

.indigo-A100-border-bottom {
  border-bottom-color: #8C9EFF !important; }

.indigo-A100-border-left {
  border-left-color: #8C9EFF !important; }

.indigo-A200-bg {
  background-color: #536DFE !important; }

.md-indigo-A200-bg {
  background-color: #536DFE !important;
  color: white; }

.indigo-A200-fg {
  color: #536DFE !important; }

.indigo-A200-border {
  border-color: #536DFE !important; }

.indigo-A200-border-top {
  border-top-color: #536DFE !important; }

.indigo-A200-border-right {
  border-right-color: #536DFE !important; }

.indigo-A200-border-bottom {
  border-bottom-color: #536DFE !important; }

.indigo-A200-border-left {
  border-left-color: #536DFE !important; }

.indigo-A400-bg {
  background-color: #3D5AFE !important; }

.md-indigo-A400-bg {
  background-color: #3D5AFE !important;
  color: white; }

.indigo-A400-fg {
  color: #3D5AFE !important; }

.indigo-A400-border {
  border-color: #3D5AFE !important; }

.indigo-A400-border-top {
  border-top-color: #3D5AFE !important; }

.indigo-A400-border-right {
  border-right-color: #3D5AFE !important; }

.indigo-A400-border-bottom {
  border-bottom-color: #3D5AFE !important; }

.indigo-A400-border-left {
  border-left-color: #3D5AFE !important; }

.indigo-A700-bg {
  background-color: #304FFE !important; }

.md-indigo-A700-bg {
  background-color: #304FFE !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-A700-fg {
  color: #304FFE !important; }

.indigo-A700-border {
  border-color: #304FFE !important; }

.indigo-A700-border-top {
  border-top-color: #304FFE !important; }

.indigo-A700-border-right {
  border-right-color: #304FFE !important; }

.indigo-A700-border-bottom {
  border-bottom-color: #304FFE !important; }

.indigo-A700-border-left {
  border-left-color: #304FFE !important; }

.blue-50-bg {
  background-color: #E3F2FD !important; }

.md-blue-50-bg {
  background-color: #E3F2FD !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-50-fg {
  color: #E3F2FD !important; }

.blue-50-border {
  border-color: #E3F2FD !important; }

.blue-50-border-top {
  border-top-color: #E3F2FD !important; }

.blue-50-border-right {
  border-right-color: #E3F2FD !important; }

.blue-50-border-bottom {
  border-bottom-color: #E3F2FD !important; }

.blue-50-border-left {
  border-left-color: #E3F2FD !important; }

.blue-100-bg {
  background-color: #BBDEFB !important; }

.md-blue-100-bg {
  background-color: #BBDEFB !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-100-fg {
  color: #BBDEFB !important; }

.blue-100-border {
  border-color: #BBDEFB !important; }

.blue-100-border-top {
  border-top-color: #BBDEFB !important; }

.blue-100-border-right {
  border-right-color: #BBDEFB !important; }

.blue-100-border-bottom {
  border-bottom-color: #BBDEFB !important; }

.blue-100-border-left {
  border-left-color: #BBDEFB !important; }

.blue-200-bg {
  background-color: #90CAF9 !important; }

.md-blue-200-bg {
  background-color: #90CAF9 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-200-fg {
  color: #90CAF9 !important; }

.blue-200-border {
  border-color: #90CAF9 !important; }

.blue-200-border-top {
  border-top-color: #90CAF9 !important; }

.blue-200-border-right {
  border-right-color: #90CAF9 !important; }

.blue-200-border-bottom {
  border-bottom-color: #90CAF9 !important; }

.blue-200-border-left {
  border-left-color: #90CAF9 !important; }

.blue-300-bg {
  background-color: #64B5F6 !important; }

.md-blue-300-bg {
  background-color: #64B5F6 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-300-fg {
  color: #64B5F6 !important; }

.blue-300-border {
  border-color: #64B5F6 !important; }

.blue-300-border-top {
  border-top-color: #64B5F6 !important; }

.blue-300-border-right {
  border-right-color: #64B5F6 !important; }

.blue-300-border-bottom {
  border-bottom-color: #64B5F6 !important; }

.blue-300-border-left {
  border-left-color: #64B5F6 !important; }

.blue-400-bg {
  background-color: #42A5F5 !important; }

.md-blue-400-bg {
  background-color: #42A5F5 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-400-fg {
  color: #42A5F5 !important; }

.blue-400-border {
  border-color: #42A5F5 !important; }

.blue-400-border-top {
  border-top-color: #42A5F5 !important; }

.blue-400-border-right {
  border-right-color: #42A5F5 !important; }

.blue-400-border-bottom {
  border-bottom-color: #42A5F5 !important; }

.blue-400-border-left {
  border-left-color: #42A5F5 !important; }

.blue-500-bg {
  background-color: #2196F3 !important; }

.md-blue-500-bg {
  background-color: #2196F3 !important;
  color: white; }

.blue-500-fg {
  color: #2196F3 !important; }

.blue-500-border {
  border-color: #2196F3 !important; }

.blue-500-border-top {
  border-top-color: #2196F3 !important; }

.blue-500-border-right {
  border-right-color: #2196F3 !important; }

.blue-500-border-bottom {
  border-bottom-color: #2196F3 !important; }

.blue-500-border-left {
  border-left-color: #2196F3 !important; }

.md-blue-bg {
  background-color: #2196F3 !important;
  color: white; }

.blue-bg {
  background-color: #2196F3 !important; }

.blue-fg {
  color: #2196F3 !important; }

.blue-border {
  border-color: #2196F3 !important; }

.blue-border-top {
  border-top-color: #2196F3 !important; }

.blue-border-right {
  border-right-color: #2196F3 !important; }

.blue-border-bottom {
  border-bottom-color: #2196F3 !important; }

.blue-border-left {
  border-left-color: #2196F3 !important; }

.blue-600-bg {
  background-color: #1E88E5 !important; }

.md-blue-600-bg {
  background-color: #1E88E5 !important;
  color: white; }

.blue-600-fg {
  color: #1E88E5 !important; }

.blue-600-border {
  border-color: #1E88E5 !important; }

.blue-600-border-top {
  border-top-color: #1E88E5 !important; }

.blue-600-border-right {
  border-right-color: #1E88E5 !important; }

.blue-600-border-bottom {
  border-bottom-color: #1E88E5 !important; }

.blue-600-border-left {
  border-left-color: #1E88E5 !important; }

.blue-700-bg {
  background-color: #1976D2 !important; }

.md-blue-700-bg {
  background-color: #1976D2 !important;
  color: white; }

.blue-700-fg {
  color: #1976D2 !important; }

.blue-700-border {
  border-color: #1976D2 !important; }

.blue-700-border-top {
  border-top-color: #1976D2 !important; }

.blue-700-border-right {
  border-right-color: #1976D2 !important; }

.blue-700-border-bottom {
  border-bottom-color: #1976D2 !important; }

.blue-700-border-left {
  border-left-color: #1976D2 !important; }

.blue-800-bg {
  background-color: #1565C0 !important; }

.md-blue-800-bg {
  background-color: #1565C0 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-800-fg {
  color: #1565C0 !important; }

.blue-800-border {
  border-color: #1565C0 !important; }

.blue-800-border-top {
  border-top-color: #1565C0 !important; }

.blue-800-border-right {
  border-right-color: #1565C0 !important; }

.blue-800-border-bottom {
  border-bottom-color: #1565C0 !important; }

.blue-800-border-left {
  border-left-color: #1565C0 !important; }

.blue-900-bg {
  background-color: #0D47A1 !important; }

.md-blue-900-bg {
  background-color: #0D47A1 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-900-fg {
  color: #0D47A1 !important; }

.blue-900-border {
  border-color: #0D47A1 !important; }

.blue-900-border-top {
  border-top-color: #0D47A1 !important; }

.blue-900-border-right {
  border-right-color: #0D47A1 !important; }

.blue-900-border-bottom {
  border-bottom-color: #0D47A1 !important; }

.blue-900-border-left {
  border-left-color: #0D47A1 !important; }

.blue-A100-bg {
  background-color: #82B1FF !important; }

.md-blue-A100-bg {
  background-color: #82B1FF !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-A100-fg {
  color: #82B1FF !important; }

.blue-A100-border {
  border-color: #82B1FF !important; }

.blue-A100-border-top {
  border-top-color: #82B1FF !important; }

.blue-A100-border-right {
  border-right-color: #82B1FF !important; }

.blue-A100-border-bottom {
  border-bottom-color: #82B1FF !important; }

.blue-A100-border-left {
  border-left-color: #82B1FF !important; }

.blue-A200-bg {
  background-color: #448AFF !important; }

.md-blue-A200-bg {
  background-color: #448AFF !important;
  color: white; }

.blue-A200-fg {
  color: #448AFF !important; }

.blue-A200-border {
  border-color: #448AFF !important; }

.blue-A200-border-top {
  border-top-color: #448AFF !important; }

.blue-A200-border-right {
  border-right-color: #448AFF !important; }

.blue-A200-border-bottom {
  border-bottom-color: #448AFF !important; }

.blue-A200-border-left {
  border-left-color: #448AFF !important; }

.blue-A400-bg {
  background-color: #2979FF !important; }

.md-blue-A400-bg {
  background-color: #2979FF !important;
  color: white; }

.blue-A400-fg {
  color: #2979FF !important; }

.blue-A400-border {
  border-color: #2979FF !important; }

.blue-A400-border-top {
  border-top-color: #2979FF !important; }

.blue-A400-border-right {
  border-right-color: #2979FF !important; }

.blue-A400-border-bottom {
  border-bottom-color: #2979FF !important; }

.blue-A400-border-left {
  border-left-color: #2979FF !important; }

.blue-A700-bg {
  background-color: #2962FF !important; }

.md-blue-A700-bg {
  background-color: #2962FF !important;
  color: white; }

.blue-A700-fg {
  color: #2962FF !important; }

.blue-A700-border {
  border-color: #2962FF !important; }

.blue-A700-border-top {
  border-top-color: #2962FF !important; }

.blue-A700-border-right {
  border-right-color: #2962FF !important; }

.blue-A700-border-bottom {
  border-bottom-color: #2962FF !important; }

.blue-A700-border-left {
  border-left-color: #2962FF !important; }

.light-blue-50-bg {
  background-color: #E1F5FE !important; }

.md-light-blue-50-bg {
  background-color: #E1F5FE !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-50-fg {
  color: #E1F5FE !important; }

.light-blue-50-border {
  border-color: #E1F5FE !important; }

.light-blue-50-border-top {
  border-top-color: #E1F5FE !important; }

.light-blue-50-border-right {
  border-right-color: #E1F5FE !important; }

.light-blue-50-border-bottom {
  border-bottom-color: #E1F5FE !important; }

.light-blue-50-border-left {
  border-left-color: #E1F5FE !important; }

.light-blue-100-bg {
  background-color: #B3E5FC !important; }

.md-light-blue-100-bg {
  background-color: #B3E5FC !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-100-fg {
  color: #B3E5FC !important; }

.light-blue-100-border {
  border-color: #B3E5FC !important; }

.light-blue-100-border-top {
  border-top-color: #B3E5FC !important; }

.light-blue-100-border-right {
  border-right-color: #B3E5FC !important; }

.light-blue-100-border-bottom {
  border-bottom-color: #B3E5FC !important; }

.light-blue-100-border-left {
  border-left-color: #B3E5FC !important; }

.light-blue-200-bg {
  background-color: #81D4FA !important; }

.md-light-blue-200-bg {
  background-color: #81D4FA !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-200-fg {
  color: #81D4FA !important; }

.light-blue-200-border {
  border-color: #81D4FA !important; }

.light-blue-200-border-top {
  border-top-color: #81D4FA !important; }

.light-blue-200-border-right {
  border-right-color: #81D4FA !important; }

.light-blue-200-border-bottom {
  border-bottom-color: #81D4FA !important; }

.light-blue-200-border-left {
  border-left-color: #81D4FA !important; }

.light-blue-300-bg {
  background-color: #4FC3F7 !important; }

.md-light-blue-300-bg {
  background-color: #4FC3F7 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-300-fg {
  color: #4FC3F7 !important; }

.light-blue-300-border {
  border-color: #4FC3F7 !important; }

.light-blue-300-border-top {
  border-top-color: #4FC3F7 !important; }

.light-blue-300-border-right {
  border-right-color: #4FC3F7 !important; }

.light-blue-300-border-bottom {
  border-bottom-color: #4FC3F7 !important; }

.light-blue-300-border-left {
  border-left-color: #4FC3F7 !important; }

.light-blue-400-bg {
  background-color: #29B6F6 !important; }

.md-light-blue-400-bg {
  background-color: #29B6F6 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-400-fg {
  color: #29B6F6 !important; }

.light-blue-400-border {
  border-color: #29B6F6 !important; }

.light-blue-400-border-top {
  border-top-color: #29B6F6 !important; }

.light-blue-400-border-right {
  border-right-color: #29B6F6 !important; }

.light-blue-400-border-bottom {
  border-bottom-color: #29B6F6 !important; }

.light-blue-400-border-left {
  border-left-color: #29B6F6 !important; }

.light-blue-500-bg {
  background-color: #03A9F4 !important; }

.md-light-blue-500-bg {
  background-color: #03A9F4 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-500-fg {
  color: #03A9F4 !important; }

.light-blue-500-border {
  border-color: #03A9F4 !important; }

.light-blue-500-border-top {
  border-top-color: #03A9F4 !important; }

.light-blue-500-border-right {
  border-right-color: #03A9F4 !important; }

.light-blue-500-border-bottom {
  border-bottom-color: #03A9F4 !important; }

.light-blue-500-border-left {
  border-left-color: #03A9F4 !important; }

.md-light-blue-bg {
  background-color: #03A9F4 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-bg {
  background-color: #03A9F4 !important; }

.light-blue-fg {
  color: #03A9F4 !important; }

.light-blue-border {
  border-color: #03A9F4 !important; }

.light-blue-border-top {
  border-top-color: #03A9F4 !important; }

.light-blue-border-right {
  border-right-color: #03A9F4 !important; }

.light-blue-border-bottom {
  border-bottom-color: #03A9F4 !important; }

.light-blue-border-left {
  border-left-color: #03A9F4 !important; }

.light-blue-600-bg {
  background-color: #039BE5 !important; }

.md-light-blue-600-bg {
  background-color: #039BE5 !important;
  color: white; }

.light-blue-600-fg {
  color: #039BE5 !important; }

.light-blue-600-border {
  border-color: #039BE5 !important; }

.light-blue-600-border-top {
  border-top-color: #039BE5 !important; }

.light-blue-600-border-right {
  border-right-color: #039BE5 !important; }

.light-blue-600-border-bottom {
  border-bottom-color: #039BE5 !important; }

.light-blue-600-border-left {
  border-left-color: #039BE5 !important; }

.light-blue-700-bg {
  background-color: #0288D1 !important; }

.md-light-blue-700-bg {
  background-color: #0288D1 !important;
  color: white; }

.light-blue-700-fg {
  color: #0288D1 !important; }

.light-blue-700-border {
  border-color: #0288D1 !important; }

.light-blue-700-border-top {
  border-top-color: #0288D1 !important; }

.light-blue-700-border-right {
  border-right-color: #0288D1 !important; }

.light-blue-700-border-bottom {
  border-bottom-color: #0288D1 !important; }

.light-blue-700-border-left {
  border-left-color: #0288D1 !important; }

.light-blue-800-bg {
  background-color: #0277BD !important; }

.md-light-blue-800-bg {
  background-color: #0277BD !important;
  color: white; }

.light-blue-800-fg {
  color: #0277BD !important; }

.light-blue-800-border {
  border-color: #0277BD !important; }

.light-blue-800-border-top {
  border-top-color: #0277BD !important; }

.light-blue-800-border-right {
  border-right-color: #0277BD !important; }

.light-blue-800-border-bottom {
  border-bottom-color: #0277BD !important; }

.light-blue-800-border-left {
  border-left-color: #0277BD !important; }

.light-blue-900-bg {
  background-color: #01579B !important; }

.md-light-blue-900-bg {
  background-color: #01579B !important;
  color: white; }

.light-blue-900-fg {
  color: #01579B !important; }

.light-blue-900-border {
  border-color: #01579B !important; }

.light-blue-900-border-top {
  border-top-color: #01579B !important; }

.light-blue-900-border-right {
  border-right-color: #01579B !important; }

.light-blue-900-border-bottom {
  border-bottom-color: #01579B !important; }

.light-blue-900-border-left {
  border-left-color: #01579B !important; }

.light-blue-A100-bg {
  background-color: #80D8FF !important; }

.md-light-blue-A100-bg {
  background-color: #80D8FF !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A100-fg {
  color: #80D8FF !important; }

.light-blue-A100-border {
  border-color: #80D8FF !important; }

.light-blue-A100-border-top {
  border-top-color: #80D8FF !important; }

.light-blue-A100-border-right {
  border-right-color: #80D8FF !important; }

.light-blue-A100-border-bottom {
  border-bottom-color: #80D8FF !important; }

.light-blue-A100-border-left {
  border-left-color: #80D8FF !important; }

.light-blue-A200-bg {
  background-color: #40C4FF !important; }

.md-light-blue-A200-bg {
  background-color: #40C4FF !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A200-fg {
  color: #40C4FF !important; }

.light-blue-A200-border {
  border-color: #40C4FF !important; }

.light-blue-A200-border-top {
  border-top-color: #40C4FF !important; }

.light-blue-A200-border-right {
  border-right-color: #40C4FF !important; }

.light-blue-A200-border-bottom {
  border-bottom-color: #40C4FF !important; }

.light-blue-A200-border-left {
  border-left-color: #40C4FF !important; }

.light-blue-A400-bg {
  background-color: #00B0FF !important; }

.md-light-blue-A400-bg {
  background-color: #00B0FF !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A400-fg {
  color: #00B0FF !important; }

.light-blue-A400-border {
  border-color: #00B0FF !important; }

.light-blue-A400-border-top {
  border-top-color: #00B0FF !important; }

.light-blue-A400-border-right {
  border-right-color: #00B0FF !important; }

.light-blue-A400-border-bottom {
  border-bottom-color: #00B0FF !important; }

.light-blue-A400-border-left {
  border-left-color: #00B0FF !important; }

.light-blue-A700-bg {
  background-color: #0091EA !important; }

.md-light-blue-A700-bg {
  background-color: #0091EA !important;
  color: white; }

.light-blue-A700-fg {
  color: #0091EA !important; }

.light-blue-A700-border {
  border-color: #0091EA !important; }

.light-blue-A700-border-top {
  border-top-color: #0091EA !important; }

.light-blue-A700-border-right {
  border-right-color: #0091EA !important; }

.light-blue-A700-border-bottom {
  border-bottom-color: #0091EA !important; }

.light-blue-A700-border-left {
  border-left-color: #0091EA !important; }

.cyan-50-bg {
  background-color: #E0F7FA !important; }

.md-cyan-50-bg {
  background-color: #E0F7FA !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-50-fg {
  color: #E0F7FA !important; }

.cyan-50-border {
  border-color: #E0F7FA !important; }

.cyan-50-border-top {
  border-top-color: #E0F7FA !important; }

.cyan-50-border-right {
  border-right-color: #E0F7FA !important; }

.cyan-50-border-bottom {
  border-bottom-color: #E0F7FA !important; }

.cyan-50-border-left {
  border-left-color: #E0F7FA !important; }

.cyan-100-bg {
  background-color: #B2EBF2 !important; }

.md-cyan-100-bg {
  background-color: #B2EBF2 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-100-fg {
  color: #B2EBF2 !important; }

.cyan-100-border {
  border-color: #B2EBF2 !important; }

.cyan-100-border-top {
  border-top-color: #B2EBF2 !important; }

.cyan-100-border-right {
  border-right-color: #B2EBF2 !important; }

.cyan-100-border-bottom {
  border-bottom-color: #B2EBF2 !important; }

.cyan-100-border-left {
  border-left-color: #B2EBF2 !important; }

.cyan-200-bg {
  background-color: #80DEEA !important; }

.md-cyan-200-bg {
  background-color: #80DEEA !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-200-fg {
  color: #80DEEA !important; }

.cyan-200-border {
  border-color: #80DEEA !important; }

.cyan-200-border-top {
  border-top-color: #80DEEA !important; }

.cyan-200-border-right {
  border-right-color: #80DEEA !important; }

.cyan-200-border-bottom {
  border-bottom-color: #80DEEA !important; }

.cyan-200-border-left {
  border-left-color: #80DEEA !important; }

.cyan-300-bg {
  background-color: #4DD0E1 !important; }

.md-cyan-300-bg {
  background-color: #4DD0E1 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-300-fg {
  color: #4DD0E1 !important; }

.cyan-300-border {
  border-color: #4DD0E1 !important; }

.cyan-300-border-top {
  border-top-color: #4DD0E1 !important; }

.cyan-300-border-right {
  border-right-color: #4DD0E1 !important; }

.cyan-300-border-bottom {
  border-bottom-color: #4DD0E1 !important; }

.cyan-300-border-left {
  border-left-color: #4DD0E1 !important; }

.cyan-400-bg {
  background-color: #26C6DA !important; }

.md-cyan-400-bg {
  background-color: #26C6DA !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-400-fg {
  color: #26C6DA !important; }

.cyan-400-border {
  border-color: #26C6DA !important; }

.cyan-400-border-top {
  border-top-color: #26C6DA !important; }

.cyan-400-border-right {
  border-right-color: #26C6DA !important; }

.cyan-400-border-bottom {
  border-bottom-color: #26C6DA !important; }

.cyan-400-border-left {
  border-left-color: #26C6DA !important; }

.cyan-500-bg {
  background-color: #00BCD4 !important; }

.md-cyan-500-bg {
  background-color: #00BCD4 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-500-fg {
  color: #00BCD4 !important; }

.cyan-500-border {
  border-color: #00BCD4 !important; }

.cyan-500-border-top {
  border-top-color: #00BCD4 !important; }

.cyan-500-border-right {
  border-right-color: #00BCD4 !important; }

.cyan-500-border-bottom {
  border-bottom-color: #00BCD4 !important; }

.cyan-500-border-left {
  border-left-color: #00BCD4 !important; }

.md-cyan-bg {
  background-color: #00BCD4 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-bg {
  background-color: #00BCD4 !important; }

.cyan-fg {
  color: #00BCD4 !important; }

.cyan-border {
  border-color: #00BCD4 !important; }

.cyan-border-top {
  border-top-color: #00BCD4 !important; }

.cyan-border-right {
  border-right-color: #00BCD4 !important; }

.cyan-border-bottom {
  border-bottom-color: #00BCD4 !important; }

.cyan-border-left {
  border-left-color: #00BCD4 !important; }

.cyan-600-bg {
  background-color: #00ACC1 !important; }

.md-cyan-600-bg {
  background-color: #00ACC1 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-600-fg {
  color: #00ACC1 !important; }

.cyan-600-border {
  border-color: #00ACC1 !important; }

.cyan-600-border-top {
  border-top-color: #00ACC1 !important; }

.cyan-600-border-right {
  border-right-color: #00ACC1 !important; }

.cyan-600-border-bottom {
  border-bottom-color: #00ACC1 !important; }

.cyan-600-border-left {
  border-left-color: #00ACC1 !important; }

.cyan-700-bg {
  background-color: #0097A7 !important; }

.md-cyan-700-bg {
  background-color: #0097A7 !important;
  color: white; }

.cyan-700-fg {
  color: #0097A7 !important; }

.cyan-700-border {
  border-color: #0097A7 !important; }

.cyan-700-border-top {
  border-top-color: #0097A7 !important; }

.cyan-700-border-right {
  border-right-color: #0097A7 !important; }

.cyan-700-border-bottom {
  border-bottom-color: #0097A7 !important; }

.cyan-700-border-left {
  border-left-color: #0097A7 !important; }

.cyan-800-bg {
  background-color: #00838F !important; }

.md-cyan-800-bg {
  background-color: #00838F !important;
  color: white; }

.cyan-800-fg {
  color: #00838F !important; }

.cyan-800-border {
  border-color: #00838F !important; }

.cyan-800-border-top {
  border-top-color: #00838F !important; }

.cyan-800-border-right {
  border-right-color: #00838F !important; }

.cyan-800-border-bottom {
  border-bottom-color: #00838F !important; }

.cyan-800-border-left {
  border-left-color: #00838F !important; }

.cyan-900-bg {
  background-color: #006064 !important; }

.md-cyan-900-bg {
  background-color: #006064 !important;
  color: white; }

.cyan-900-fg {
  color: #006064 !important; }

.cyan-900-border {
  border-color: #006064 !important; }

.cyan-900-border-top {
  border-top-color: #006064 !important; }

.cyan-900-border-right {
  border-right-color: #006064 !important; }

.cyan-900-border-bottom {
  border-bottom-color: #006064 !important; }

.cyan-900-border-left {
  border-left-color: #006064 !important; }

.cyan-A100-bg {
  background-color: #84FFFF !important; }

.md-cyan-A100-bg {
  background-color: #84FFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A100-fg {
  color: #84FFFF !important; }

.cyan-A100-border {
  border-color: #84FFFF !important; }

.cyan-A100-border-top {
  border-top-color: #84FFFF !important; }

.cyan-A100-border-right {
  border-right-color: #84FFFF !important; }

.cyan-A100-border-bottom {
  border-bottom-color: #84FFFF !important; }

.cyan-A100-border-left {
  border-left-color: #84FFFF !important; }

.cyan-A200-bg {
  background-color: #18FFFF !important; }

.md-cyan-A200-bg {
  background-color: #18FFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A200-fg {
  color: #18FFFF !important; }

.cyan-A200-border {
  border-color: #18FFFF !important; }

.cyan-A200-border-top {
  border-top-color: #18FFFF !important; }

.cyan-A200-border-right {
  border-right-color: #18FFFF !important; }

.cyan-A200-border-bottom {
  border-bottom-color: #18FFFF !important; }

.cyan-A200-border-left {
  border-left-color: #18FFFF !important; }

.cyan-A400-bg {
  background-color: #00E5FF !important; }

.md-cyan-A400-bg {
  background-color: #00E5FF !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A400-fg {
  color: #00E5FF !important; }

.cyan-A400-border {
  border-color: #00E5FF !important; }

.cyan-A400-border-top {
  border-top-color: #00E5FF !important; }

.cyan-A400-border-right {
  border-right-color: #00E5FF !important; }

.cyan-A400-border-bottom {
  border-bottom-color: #00E5FF !important; }

.cyan-A400-border-left {
  border-left-color: #00E5FF !important; }

.cyan-A700-bg {
  background-color: #00B8D4 !important; }

.md-cyan-A700-bg {
  background-color: #00B8D4 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A700-fg {
  color: #00B8D4 !important; }

.cyan-A700-border {
  border-color: #00B8D4 !important; }

.cyan-A700-border-top {
  border-top-color: #00B8D4 !important; }

.cyan-A700-border-right {
  border-right-color: #00B8D4 !important; }

.cyan-A700-border-bottom {
  border-bottom-color: #00B8D4 !important; }

.cyan-A700-border-left {
  border-left-color: #00B8D4 !important; }

.teal-50-bg {
  background-color: #E0F2F1 !important; }

.md-teal-50-bg {
  background-color: #E0F2F1 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-50-fg {
  color: #E0F2F1 !important; }

.teal-50-border {
  border-color: #E0F2F1 !important; }

.teal-50-border-top {
  border-top-color: #E0F2F1 !important; }

.teal-50-border-right {
  border-right-color: #E0F2F1 !important; }

.teal-50-border-bottom {
  border-bottom-color: #E0F2F1 !important; }

.teal-50-border-left {
  border-left-color: #E0F2F1 !important; }

.teal-100-bg {
  background-color: #B2DFDB !important; }

.md-teal-100-bg {
  background-color: #B2DFDB !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-100-fg {
  color: #B2DFDB !important; }

.teal-100-border {
  border-color: #B2DFDB !important; }

.teal-100-border-top {
  border-top-color: #B2DFDB !important; }

.teal-100-border-right {
  border-right-color: #B2DFDB !important; }

.teal-100-border-bottom {
  border-bottom-color: #B2DFDB !important; }

.teal-100-border-left {
  border-left-color: #B2DFDB !important; }

.teal-200-bg {
  background-color: #80CBC4 !important; }

.md-teal-200-bg {
  background-color: #80CBC4 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-200-fg {
  color: #80CBC4 !important; }

.teal-200-border {
  border-color: #80CBC4 !important; }

.teal-200-border-top {
  border-top-color: #80CBC4 !important; }

.teal-200-border-right {
  border-right-color: #80CBC4 !important; }

.teal-200-border-bottom {
  border-bottom-color: #80CBC4 !important; }

.teal-200-border-left {
  border-left-color: #80CBC4 !important; }

.teal-300-bg {
  background-color: #4DB6AC !important; }

.md-teal-300-bg {
  background-color: #4DB6AC !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-300-fg {
  color: #4DB6AC !important; }

.teal-300-border {
  border-color: #4DB6AC !important; }

.teal-300-border-top {
  border-top-color: #4DB6AC !important; }

.teal-300-border-right {
  border-right-color: #4DB6AC !important; }

.teal-300-border-bottom {
  border-bottom-color: #4DB6AC !important; }

.teal-300-border-left {
  border-left-color: #4DB6AC !important; }

.teal-400-bg {
  background-color: #26A69A !important; }

.md-teal-400-bg {
  background-color: #26A69A !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-400-fg {
  color: #26A69A !important; }

.teal-400-border {
  border-color: #26A69A !important; }

.teal-400-border-top {
  border-top-color: #26A69A !important; }

.teal-400-border-right {
  border-right-color: #26A69A !important; }

.teal-400-border-bottom {
  border-bottom-color: #26A69A !important; }

.teal-400-border-left {
  border-left-color: #26A69A !important; }

.teal-500-bg {
  background-color: #009688 !important; }

.md-teal-500-bg {
  background-color: #009688 !important;
  color: white; }

.teal-500-fg {
  color: #009688 !important; }

.teal-500-border {
  border-color: #009688 !important; }

.teal-500-border-top {
  border-top-color: #009688 !important; }

.teal-500-border-right {
  border-right-color: #009688 !important; }

.teal-500-border-bottom {
  border-bottom-color: #009688 !important; }

.teal-500-border-left {
  border-left-color: #009688 !important; }

.md-teal-bg {
  background-color: #009688 !important;
  color: white; }

.teal-bg {
  background-color: #009688 !important; }

.teal-fg {
  color: #009688 !important; }

.teal-border {
  border-color: #009688 !important; }

.teal-border-top {
  border-top-color: #009688 !important; }

.teal-border-right {
  border-right-color: #009688 !important; }

.teal-border-bottom {
  border-bottom-color: #009688 !important; }

.teal-border-left {
  border-left-color: #009688 !important; }

.teal-600-bg {
  background-color: #00897B !important; }

.md-teal-600-bg {
  background-color: #00897B !important;
  color: white; }

.teal-600-fg {
  color: #00897B !important; }

.teal-600-border {
  border-color: #00897B !important; }

.teal-600-border-top {
  border-top-color: #00897B !important; }

.teal-600-border-right {
  border-right-color: #00897B !important; }

.teal-600-border-bottom {
  border-bottom-color: #00897B !important; }

.teal-600-border-left {
  border-left-color: #00897B !important; }

.teal-700-bg {
  background-color: #00796B !important; }

.md-teal-700-bg {
  background-color: #00796B !important;
  color: white; }

.teal-700-fg {
  color: #00796B !important; }

.teal-700-border {
  border-color: #00796B !important; }

.teal-700-border-top {
  border-top-color: #00796B !important; }

.teal-700-border-right {
  border-right-color: #00796B !important; }

.teal-700-border-bottom {
  border-bottom-color: #00796B !important; }

.teal-700-border-left {
  border-left-color: #00796B !important; }

.teal-800-bg {
  background-color: #00695C !important; }

.md-teal-800-bg {
  background-color: #00695C !important;
  color: rgba(255, 255, 255, 0.87); }

.teal-800-fg {
  color: #00695C !important; }

.teal-800-border {
  border-color: #00695C !important; }

.teal-800-border-top {
  border-top-color: #00695C !important; }

.teal-800-border-right {
  border-right-color: #00695C !important; }

.teal-800-border-bottom {
  border-bottom-color: #00695C !important; }

.teal-800-border-left {
  border-left-color: #00695C !important; }

.teal-900-bg {
  background-color: #004D40 !important; }

.md-teal-900-bg {
  background-color: #004D40 !important;
  color: rgba(255, 255, 255, 0.87); }

.teal-900-fg {
  color: #004D40 !important; }

.teal-900-border {
  border-color: #004D40 !important; }

.teal-900-border-top {
  border-top-color: #004D40 !important; }

.teal-900-border-right {
  border-right-color: #004D40 !important; }

.teal-900-border-bottom {
  border-bottom-color: #004D40 !important; }

.teal-900-border-left {
  border-left-color: #004D40 !important; }

.teal-A100-bg {
  background-color: #A7FFEB !important; }

.md-teal-A100-bg {
  background-color: #A7FFEB !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A100-fg {
  color: #A7FFEB !important; }

.teal-A100-border {
  border-color: #A7FFEB !important; }

.teal-A100-border-top {
  border-top-color: #A7FFEB !important; }

.teal-A100-border-right {
  border-right-color: #A7FFEB !important; }

.teal-A100-border-bottom {
  border-bottom-color: #A7FFEB !important; }

.teal-A100-border-left {
  border-left-color: #A7FFEB !important; }

.teal-A200-bg {
  background-color: #64FFDA !important; }

.md-teal-A200-bg {
  background-color: #64FFDA !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A200-fg {
  color: #64FFDA !important; }

.teal-A200-border {
  border-color: #64FFDA !important; }

.teal-A200-border-top {
  border-top-color: #64FFDA !important; }

.teal-A200-border-right {
  border-right-color: #64FFDA !important; }

.teal-A200-border-bottom {
  border-bottom-color: #64FFDA !important; }

.teal-A200-border-left {
  border-left-color: #64FFDA !important; }

.teal-A400-bg {
  background-color: #1DE9B6 !important; }

.md-teal-A400-bg {
  background-color: #1DE9B6 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A400-fg {
  color: #1DE9B6 !important; }

.teal-A400-border {
  border-color: #1DE9B6 !important; }

.teal-A400-border-top {
  border-top-color: #1DE9B6 !important; }

.teal-A400-border-right {
  border-right-color: #1DE9B6 !important; }

.teal-A400-border-bottom {
  border-bottom-color: #1DE9B6 !important; }

.teal-A400-border-left {
  border-left-color: #1DE9B6 !important; }

.teal-A700-bg {
  background-color: #00BFA5 !important; }

.md-teal-A700-bg {
  background-color: #00BFA5 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A700-fg {
  color: #00BFA5 !important; }

.teal-A700-border {
  border-color: #00BFA5 !important; }

.teal-A700-border-top {
  border-top-color: #00BFA5 !important; }

.teal-A700-border-right {
  border-right-color: #00BFA5 !important; }

.teal-A700-border-bottom {
  border-bottom-color: #00BFA5 !important; }

.teal-A700-border-left {
  border-left-color: #00BFA5 !important; }

.green-50-bg {
  background-color: #E8F5E9 !important; }

.md-green-50-bg {
  background-color: #E8F5E9 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-50-fg {
  color: #E8F5E9 !important; }

.green-50-border {
  border-color: #E8F5E9 !important; }

.green-50-border-top {
  border-top-color: #E8F5E9 !important; }

.green-50-border-right {
  border-right-color: #E8F5E9 !important; }

.green-50-border-bottom {
  border-bottom-color: #E8F5E9 !important; }

.green-50-border-left {
  border-left-color: #E8F5E9 !important; }

.green-100-bg {
  background-color: #C8E6C9 !important; }

.md-green-100-bg {
  background-color: #C8E6C9 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-100-fg {
  color: #C8E6C9 !important; }

.green-100-border {
  border-color: #C8E6C9 !important; }

.green-100-border-top {
  border-top-color: #C8E6C9 !important; }

.green-100-border-right {
  border-right-color: #C8E6C9 !important; }

.green-100-border-bottom {
  border-bottom-color: #C8E6C9 !important; }

.green-100-border-left {
  border-left-color: #C8E6C9 !important; }

.green-200-bg {
  background-color: #A5D6A7 !important; }

.md-green-200-bg {
  background-color: #A5D6A7 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-200-fg {
  color: #A5D6A7 !important; }

.green-200-border {
  border-color: #A5D6A7 !important; }

.green-200-border-top {
  border-top-color: #A5D6A7 !important; }

.green-200-border-right {
  border-right-color: #A5D6A7 !important; }

.green-200-border-bottom {
  border-bottom-color: #A5D6A7 !important; }

.green-200-border-left {
  border-left-color: #A5D6A7 !important; }

.green-300-bg {
  background-color: #81C784 !important; }

.md-green-300-bg {
  background-color: #81C784 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-300-fg {
  color: #81C784 !important; }

.green-300-border {
  border-color: #81C784 !important; }

.green-300-border-top {
  border-top-color: #81C784 !important; }

.green-300-border-right {
  border-right-color: #81C784 !important; }

.green-300-border-bottom {
  border-bottom-color: #81C784 !important; }

.green-300-border-left {
  border-left-color: #81C784 !important; }

.green-400-bg {
  background-color: #66BB6A !important; }

.md-green-400-bg {
  background-color: #66BB6A !important;
  color: rgba(0, 0, 0, 0.87); }

.green-400-fg {
  color: #66BB6A !important; }

.green-400-border {
  border-color: #66BB6A !important; }

.green-400-border-top {
  border-top-color: #66BB6A !important; }

.green-400-border-right {
  border-right-color: #66BB6A !important; }

.green-400-border-bottom {
  border-bottom-color: #66BB6A !important; }

.green-400-border-left {
  border-left-color: #66BB6A !important; }

.green-500-bg {
  background-color: #4CAF50 !important; }

.md-green-500-bg {
  background-color: #4CAF50 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-500-fg {
  color: #4CAF50 !important; }

.green-500-border {
  border-color: #4CAF50 !important; }

.green-500-border-top {
  border-top-color: #4CAF50 !important; }

.green-500-border-right {
  border-right-color: #4CAF50 !important; }

.green-500-border-bottom {
  border-bottom-color: #4CAF50 !important; }

.green-500-border-left {
  border-left-color: #4CAF50 !important; }

.md-green-bg {
  background-color: #4CAF50 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-bg {
  background-color: #4CAF50 !important; }

.green-fg {
  color: #4CAF50 !important; }

.green-border {
  border-color: #4CAF50 !important; }

.green-border-top {
  border-top-color: #4CAF50 !important; }

.green-border-right {
  border-right-color: #4CAF50 !important; }

.green-border-bottom {
  border-bottom-color: #4CAF50 !important; }

.green-border-left {
  border-left-color: #4CAF50 !important; }

.green-600-bg {
  background-color: #43A047 !important; }

.md-green-600-bg {
  background-color: #43A047 !important;
  color: white; }

.green-600-fg {
  color: #43A047 !important; }

.green-600-border {
  border-color: #43A047 !important; }

.green-600-border-top {
  border-top-color: #43A047 !important; }

.green-600-border-right {
  border-right-color: #43A047 !important; }

.green-600-border-bottom {
  border-bottom-color: #43A047 !important; }

.green-600-border-left {
  border-left-color: #43A047 !important; }

.green-700-bg {
  background-color: #388E3C !important; }

.md-green-700-bg {
  background-color: #388E3C !important;
  color: white; }

.green-700-fg {
  color: #388E3C !important; }

.green-700-border {
  border-color: #388E3C !important; }

.green-700-border-top {
  border-top-color: #388E3C !important; }

.green-700-border-right {
  border-right-color: #388E3C !important; }

.green-700-border-bottom {
  border-bottom-color: #388E3C !important; }

.green-700-border-left {
  border-left-color: #388E3C !important; }

.green-800-bg {
  background-color: #2E7D32 !important; }

.md-green-800-bg {
  background-color: #2E7D32 !important;
  color: rgba(255, 255, 255, 0.87); }

.green-800-fg {
  color: #2E7D32 !important; }

.green-800-border {
  border-color: #2E7D32 !important; }

.green-800-border-top {
  border-top-color: #2E7D32 !important; }

.green-800-border-right {
  border-right-color: #2E7D32 !important; }

.green-800-border-bottom {
  border-bottom-color: #2E7D32 !important; }

.green-800-border-left {
  border-left-color: #2E7D32 !important; }

.green-900-bg {
  background-color: #1B5E20 !important; }

.md-green-900-bg {
  background-color: #1B5E20 !important;
  color: rgba(255, 255, 255, 0.87); }

.green-900-fg {
  color: #1B5E20 !important; }

.green-900-border {
  border-color: #1B5E20 !important; }

.green-900-border-top {
  border-top-color: #1B5E20 !important; }

.green-900-border-right {
  border-right-color: #1B5E20 !important; }

.green-900-border-bottom {
  border-bottom-color: #1B5E20 !important; }

.green-900-border-left {
  border-left-color: #1B5E20 !important; }

.green-A100-bg {
  background-color: #B9F6CA !important; }

.md-green-A100-bg {
  background-color: #B9F6CA !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A100-fg {
  color: #B9F6CA !important; }

.green-A100-border {
  border-color: #B9F6CA !important; }

.green-A100-border-top {
  border-top-color: #B9F6CA !important; }

.green-A100-border-right {
  border-right-color: #B9F6CA !important; }

.green-A100-border-bottom {
  border-bottom-color: #B9F6CA !important; }

.green-A100-border-left {
  border-left-color: #B9F6CA !important; }

.green-A200-bg {
  background-color: #69F0AE !important; }

.md-green-A200-bg {
  background-color: #69F0AE !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A200-fg {
  color: #69F0AE !important; }

.green-A200-border {
  border-color: #69F0AE !important; }

.green-A200-border-top {
  border-top-color: #69F0AE !important; }

.green-A200-border-right {
  border-right-color: #69F0AE !important; }

.green-A200-border-bottom {
  border-bottom-color: #69F0AE !important; }

.green-A200-border-left {
  border-left-color: #69F0AE !important; }

.green-A400-bg {
  background-color: #00E676 !important; }

.md-green-A400-bg {
  background-color: #00E676 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A400-fg {
  color: #00E676 !important; }

.green-A400-border {
  border-color: #00E676 !important; }

.green-A400-border-top {
  border-top-color: #00E676 !important; }

.green-A400-border-right {
  border-right-color: #00E676 !important; }

.green-A400-border-bottom {
  border-bottom-color: #00E676 !important; }

.green-A400-border-left {
  border-left-color: #00E676 !important; }

.green-A700-bg {
  background-color: #00C853 !important; }

.md-green-A700-bg {
  background-color: #00C853 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A700-fg {
  color: #00C853 !important; }

.green-A700-border {
  border-color: #00C853 !important; }

.green-A700-border-top {
  border-top-color: #00C853 !important; }

.green-A700-border-right {
  border-right-color: #00C853 !important; }

.green-A700-border-bottom {
  border-bottom-color: #00C853 !important; }

.green-A700-border-left {
  border-left-color: #00C853 !important; }

.light-green-50-bg {
  background-color: #F1F8E9 !important; }

.md-light-green-50-bg {
  background-color: #F1F8E9 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-50-fg {
  color: #F1F8E9 !important; }

.light-green-50-border {
  border-color: #F1F8E9 !important; }

.light-green-50-border-top {
  border-top-color: #F1F8E9 !important; }

.light-green-50-border-right {
  border-right-color: #F1F8E9 !important; }

.light-green-50-border-bottom {
  border-bottom-color: #F1F8E9 !important; }

.light-green-50-border-left {
  border-left-color: #F1F8E9 !important; }

.light-green-100-bg {
  background-color: #DCEDC8 !important; }

.md-light-green-100-bg {
  background-color: #DCEDC8 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-100-fg {
  color: #DCEDC8 !important; }

.light-green-100-border {
  border-color: #DCEDC8 !important; }

.light-green-100-border-top {
  border-top-color: #DCEDC8 !important; }

.light-green-100-border-right {
  border-right-color: #DCEDC8 !important; }

.light-green-100-border-bottom {
  border-bottom-color: #DCEDC8 !important; }

.light-green-100-border-left {
  border-left-color: #DCEDC8 !important; }

.light-green-200-bg {
  background-color: #C5E1A5 !important; }

.md-light-green-200-bg {
  background-color: #C5E1A5 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-200-fg {
  color: #C5E1A5 !important; }

.light-green-200-border {
  border-color: #C5E1A5 !important; }

.light-green-200-border-top {
  border-top-color: #C5E1A5 !important; }

.light-green-200-border-right {
  border-right-color: #C5E1A5 !important; }

.light-green-200-border-bottom {
  border-bottom-color: #C5E1A5 !important; }

.light-green-200-border-left {
  border-left-color: #C5E1A5 !important; }

.light-green-300-bg {
  background-color: #AED581 !important; }

.md-light-green-300-bg {
  background-color: #AED581 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-300-fg {
  color: #AED581 !important; }

.light-green-300-border {
  border-color: #AED581 !important; }

.light-green-300-border-top {
  border-top-color: #AED581 !important; }

.light-green-300-border-right {
  border-right-color: #AED581 !important; }

.light-green-300-border-bottom {
  border-bottom-color: #AED581 !important; }

.light-green-300-border-left {
  border-left-color: #AED581 !important; }

.light-green-400-bg {
  background-color: #9CCC65 !important; }

.md-light-green-400-bg {
  background-color: #9CCC65 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-400-fg {
  color: #9CCC65 !important; }

.light-green-400-border {
  border-color: #9CCC65 !important; }

.light-green-400-border-top {
  border-top-color: #9CCC65 !important; }

.light-green-400-border-right {
  border-right-color: #9CCC65 !important; }

.light-green-400-border-bottom {
  border-bottom-color: #9CCC65 !important; }

.light-green-400-border-left {
  border-left-color: #9CCC65 !important; }

.light-green-500-bg {
  background-color: #8BC34A !important; }

.md-light-green-500-bg {
  background-color: #8BC34A !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-500-fg {
  color: #8BC34A !important; }

.light-green-500-border {
  border-color: #8BC34A !important; }

.light-green-500-border-top {
  border-top-color: #8BC34A !important; }

.light-green-500-border-right {
  border-right-color: #8BC34A !important; }

.light-green-500-border-bottom {
  border-bottom-color: #8BC34A !important; }

.light-green-500-border-left {
  border-left-color: #8BC34A !important; }

.md-light-green-bg {
  background-color: #8BC34A !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-bg {
  background-color: #8BC34A !important; }

.light-green-fg {
  color: #8BC34A !important; }

.light-green-border {
  border-color: #8BC34A !important; }

.light-green-border-top {
  border-top-color: #8BC34A !important; }

.light-green-border-right {
  border-right-color: #8BC34A !important; }

.light-green-border-bottom {
  border-bottom-color: #8BC34A !important; }

.light-green-border-left {
  border-left-color: #8BC34A !important; }

.light-green-600-bg {
  background-color: #7CB342 !important; }

.md-light-green-600-bg {
  background-color: #7CB342 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-600-fg {
  color: #7CB342 !important; }

.light-green-600-border {
  border-color: #7CB342 !important; }

.light-green-600-border-top {
  border-top-color: #7CB342 !important; }

.light-green-600-border-right {
  border-right-color: #7CB342 !important; }

.light-green-600-border-bottom {
  border-bottom-color: #7CB342 !important; }

.light-green-600-border-left {
  border-left-color: #7CB342 !important; }

.light-green-700-bg {
  background-color: #689F38 !important; }

.md-light-green-700-bg {
  background-color: #689F38 !important;
  color: white; }

.light-green-700-fg {
  color: #689F38 !important; }

.light-green-700-border {
  border-color: #689F38 !important; }

.light-green-700-border-top {
  border-top-color: #689F38 !important; }

.light-green-700-border-right {
  border-right-color: #689F38 !important; }

.light-green-700-border-bottom {
  border-bottom-color: #689F38 !important; }

.light-green-700-border-left {
  border-left-color: #689F38 !important; }

.light-green-800-bg {
  background-color: #558B2F !important; }

.md-light-green-800-bg {
  background-color: #558B2F !important;
  color: white; }

.light-green-800-fg {
  color: #558B2F !important; }

.light-green-800-border {
  border-color: #558B2F !important; }

.light-green-800-border-top {
  border-top-color: #558B2F !important; }

.light-green-800-border-right {
  border-right-color: #558B2F !important; }

.light-green-800-border-bottom {
  border-bottom-color: #558B2F !important; }

.light-green-800-border-left {
  border-left-color: #558B2F !important; }

.light-green-900-bg {
  background-color: #33691E !important; }

.md-light-green-900-bg {
  background-color: #33691E !important;
  color: white; }

.light-green-900-fg {
  color: #33691E !important; }

.light-green-900-border {
  border-color: #33691E !important; }

.light-green-900-border-top {
  border-top-color: #33691E !important; }

.light-green-900-border-right {
  border-right-color: #33691E !important; }

.light-green-900-border-bottom {
  border-bottom-color: #33691E !important; }

.light-green-900-border-left {
  border-left-color: #33691E !important; }

.light-green-A100-bg {
  background-color: #CCFF90 !important; }

.md-light-green-A100-bg {
  background-color: #CCFF90 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A100-fg {
  color: #CCFF90 !important; }

.light-green-A100-border {
  border-color: #CCFF90 !important; }

.light-green-A100-border-top {
  border-top-color: #CCFF90 !important; }

.light-green-A100-border-right {
  border-right-color: #CCFF90 !important; }

.light-green-A100-border-bottom {
  border-bottom-color: #CCFF90 !important; }

.light-green-A100-border-left {
  border-left-color: #CCFF90 !important; }

.light-green-A200-bg {
  background-color: #B2FF59 !important; }

.md-light-green-A200-bg {
  background-color: #B2FF59 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A200-fg {
  color: #B2FF59 !important; }

.light-green-A200-border {
  border-color: #B2FF59 !important; }

.light-green-A200-border-top {
  border-top-color: #B2FF59 !important; }

.light-green-A200-border-right {
  border-right-color: #B2FF59 !important; }

.light-green-A200-border-bottom {
  border-bottom-color: #B2FF59 !important; }

.light-green-A200-border-left {
  border-left-color: #B2FF59 !important; }

.light-green-A400-bg {
  background-color: #76FF03 !important; }

.md-light-green-A400-bg {
  background-color: #76FF03 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A400-fg {
  color: #76FF03 !important; }

.light-green-A400-border {
  border-color: #76FF03 !important; }

.light-green-A400-border-top {
  border-top-color: #76FF03 !important; }

.light-green-A400-border-right {
  border-right-color: #76FF03 !important; }

.light-green-A400-border-bottom {
  border-bottom-color: #76FF03 !important; }

.light-green-A400-border-left {
  border-left-color: #76FF03 !important; }

.light-green-A700-bg {
  background-color: #64DD17 !important; }

.md-light-green-A700-bg {
  background-color: #64DD17 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A700-fg {
  color: #64DD17 !important; }

.light-green-A700-border {
  border-color: #64DD17 !important; }

.light-green-A700-border-top {
  border-top-color: #64DD17 !important; }

.light-green-A700-border-right {
  border-right-color: #64DD17 !important; }

.light-green-A700-border-bottom {
  border-bottom-color: #64DD17 !important; }

.light-green-A700-border-left {
  border-left-color: #64DD17 !important; }

.lime-50-bg {
  background-color: #F9FBE7 !important; }

.md-lime-50-bg {
  background-color: #F9FBE7 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-50-fg {
  color: #F9FBE7 !important; }

.lime-50-border {
  border-color: #F9FBE7 !important; }

.lime-50-border-top {
  border-top-color: #F9FBE7 !important; }

.lime-50-border-right {
  border-right-color: #F9FBE7 !important; }

.lime-50-border-bottom {
  border-bottom-color: #F9FBE7 !important; }

.lime-50-border-left {
  border-left-color: #F9FBE7 !important; }

.lime-100-bg {
  background-color: #F0F4C3 !important; }

.md-lime-100-bg {
  background-color: #F0F4C3 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-100-fg {
  color: #F0F4C3 !important; }

.lime-100-border {
  border-color: #F0F4C3 !important; }

.lime-100-border-top {
  border-top-color: #F0F4C3 !important; }

.lime-100-border-right {
  border-right-color: #F0F4C3 !important; }

.lime-100-border-bottom {
  border-bottom-color: #F0F4C3 !important; }

.lime-100-border-left {
  border-left-color: #F0F4C3 !important; }

.lime-200-bg {
  background-color: #E6EE9C !important; }

.md-lime-200-bg {
  background-color: #E6EE9C !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-200-fg {
  color: #E6EE9C !important; }

.lime-200-border {
  border-color: #E6EE9C !important; }

.lime-200-border-top {
  border-top-color: #E6EE9C !important; }

.lime-200-border-right {
  border-right-color: #E6EE9C !important; }

.lime-200-border-bottom {
  border-bottom-color: #E6EE9C !important; }

.lime-200-border-left {
  border-left-color: #E6EE9C !important; }

.lime-300-bg {
  background-color: #DCE775 !important; }

.md-lime-300-bg {
  background-color: #DCE775 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-300-fg {
  color: #DCE775 !important; }

.lime-300-border {
  border-color: #DCE775 !important; }

.lime-300-border-top {
  border-top-color: #DCE775 !important; }

.lime-300-border-right {
  border-right-color: #DCE775 !important; }

.lime-300-border-bottom {
  border-bottom-color: #DCE775 !important; }

.lime-300-border-left {
  border-left-color: #DCE775 !important; }

.lime-400-bg {
  background-color: #D4E157 !important; }

.md-lime-400-bg {
  background-color: #D4E157 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-400-fg {
  color: #D4E157 !important; }

.lime-400-border {
  border-color: #D4E157 !important; }

.lime-400-border-top {
  border-top-color: #D4E157 !important; }

.lime-400-border-right {
  border-right-color: #D4E157 !important; }

.lime-400-border-bottom {
  border-bottom-color: #D4E157 !important; }

.lime-400-border-left {
  border-left-color: #D4E157 !important; }

.lime-500-bg {
  background-color: #CDDC39 !important; }

.md-lime-500-bg {
  background-color: #CDDC39 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-500-fg {
  color: #CDDC39 !important; }

.lime-500-border {
  border-color: #CDDC39 !important; }

.lime-500-border-top {
  border-top-color: #CDDC39 !important; }

.lime-500-border-right {
  border-right-color: #CDDC39 !important; }

.lime-500-border-bottom {
  border-bottom-color: #CDDC39 !important; }

.lime-500-border-left {
  border-left-color: #CDDC39 !important; }

.md-lime-bg {
  background-color: #CDDC39 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-bg {
  background-color: #CDDC39 !important; }

.lime-fg {
  color: #CDDC39 !important; }

.lime-border {
  border-color: #CDDC39 !important; }

.lime-border-top {
  border-top-color: #CDDC39 !important; }

.lime-border-right {
  border-right-color: #CDDC39 !important; }

.lime-border-bottom {
  border-bottom-color: #CDDC39 !important; }

.lime-border-left {
  border-left-color: #CDDC39 !important; }

.lime-600-bg {
  background-color: #C0CA33 !important; }

.md-lime-600-bg {
  background-color: #C0CA33 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-600-fg {
  color: #C0CA33 !important; }

.lime-600-border {
  border-color: #C0CA33 !important; }

.lime-600-border-top {
  border-top-color: #C0CA33 !important; }

.lime-600-border-right {
  border-right-color: #C0CA33 !important; }

.lime-600-border-bottom {
  border-bottom-color: #C0CA33 !important; }

.lime-600-border-left {
  border-left-color: #C0CA33 !important; }

.lime-700-bg {
  background-color: #AFB42B !important; }

.md-lime-700-bg {
  background-color: #AFB42B !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-700-fg {
  color: #AFB42B !important; }

.lime-700-border {
  border-color: #AFB42B !important; }

.lime-700-border-top {
  border-top-color: #AFB42B !important; }

.lime-700-border-right {
  border-right-color: #AFB42B !important; }

.lime-700-border-bottom {
  border-bottom-color: #AFB42B !important; }

.lime-700-border-left {
  border-left-color: #AFB42B !important; }

.lime-800-bg {
  background-color: #9E9D24 !important; }

.md-lime-800-bg {
  background-color: #9E9D24 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-800-fg {
  color: #9E9D24 !important; }

.lime-800-border {
  border-color: #9E9D24 !important; }

.lime-800-border-top {
  border-top-color: #9E9D24 !important; }

.lime-800-border-right {
  border-right-color: #9E9D24 !important; }

.lime-800-border-bottom {
  border-bottom-color: #9E9D24 !important; }

.lime-800-border-left {
  border-left-color: #9E9D24 !important; }

.lime-900-bg {
  background-color: #827717 !important; }

.md-lime-900-bg {
  background-color: #827717 !important;
  color: white; }

.lime-900-fg {
  color: #827717 !important; }

.lime-900-border {
  border-color: #827717 !important; }

.lime-900-border-top {
  border-top-color: #827717 !important; }

.lime-900-border-right {
  border-right-color: #827717 !important; }

.lime-900-border-bottom {
  border-bottom-color: #827717 !important; }

.lime-900-border-left {
  border-left-color: #827717 !important; }

.lime-A100-bg {
  background-color: #F4FF81 !important; }

.md-lime-A100-bg {
  background-color: #F4FF81 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A100-fg {
  color: #F4FF81 !important; }

.lime-A100-border {
  border-color: #F4FF81 !important; }

.lime-A100-border-top {
  border-top-color: #F4FF81 !important; }

.lime-A100-border-right {
  border-right-color: #F4FF81 !important; }

.lime-A100-border-bottom {
  border-bottom-color: #F4FF81 !important; }

.lime-A100-border-left {
  border-left-color: #F4FF81 !important; }

.lime-A200-bg {
  background-color: #EEFF41 !important; }

.md-lime-A200-bg {
  background-color: #EEFF41 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A200-fg {
  color: #EEFF41 !important; }

.lime-A200-border {
  border-color: #EEFF41 !important; }

.lime-A200-border-top {
  border-top-color: #EEFF41 !important; }

.lime-A200-border-right {
  border-right-color: #EEFF41 !important; }

.lime-A200-border-bottom {
  border-bottom-color: #EEFF41 !important; }

.lime-A200-border-left {
  border-left-color: #EEFF41 !important; }

.lime-A400-bg {
  background-color: #C6FF00 !important; }

.md-lime-A400-bg {
  background-color: #C6FF00 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A400-fg {
  color: #C6FF00 !important; }

.lime-A400-border {
  border-color: #C6FF00 !important; }

.lime-A400-border-top {
  border-top-color: #C6FF00 !important; }

.lime-A400-border-right {
  border-right-color: #C6FF00 !important; }

.lime-A400-border-bottom {
  border-bottom-color: #C6FF00 !important; }

.lime-A400-border-left {
  border-left-color: #C6FF00 !important; }

.lime-A700-bg {
  background-color: #AEEA00 !important; }

.md-lime-A700-bg {
  background-color: #AEEA00 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A700-fg {
  color: #AEEA00 !important; }

.lime-A700-border {
  border-color: #AEEA00 !important; }

.lime-A700-border-top {
  border-top-color: #AEEA00 !important; }

.lime-A700-border-right {
  border-right-color: #AEEA00 !important; }

.lime-A700-border-bottom {
  border-bottom-color: #AEEA00 !important; }

.lime-A700-border-left {
  border-left-color: #AEEA00 !important; }

.yellow-50-bg {
  background-color: #FFFDE7 !important; }

.md-yellow-50-bg {
  background-color: #FFFDE7 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-50-fg {
  color: #FFFDE7 !important; }

.yellow-50-border {
  border-color: #FFFDE7 !important; }

.yellow-50-border-top {
  border-top-color: #FFFDE7 !important; }

.yellow-50-border-right {
  border-right-color: #FFFDE7 !important; }

.yellow-50-border-bottom {
  border-bottom-color: #FFFDE7 !important; }

.yellow-50-border-left {
  border-left-color: #FFFDE7 !important; }

.yellow-100-bg {
  background-color: #FFF9C4 !important; }

.md-yellow-100-bg {
  background-color: #FFF9C4 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-100-fg {
  color: #FFF9C4 !important; }

.yellow-100-border {
  border-color: #FFF9C4 !important; }

.yellow-100-border-top {
  border-top-color: #FFF9C4 !important; }

.yellow-100-border-right {
  border-right-color: #FFF9C4 !important; }

.yellow-100-border-bottom {
  border-bottom-color: #FFF9C4 !important; }

.yellow-100-border-left {
  border-left-color: #FFF9C4 !important; }

.yellow-200-bg {
  background-color: #FFF59D !important; }

.md-yellow-200-bg {
  background-color: #FFF59D !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-200-fg {
  color: #FFF59D !important; }

.yellow-200-border {
  border-color: #FFF59D !important; }

.yellow-200-border-top {
  border-top-color: #FFF59D !important; }

.yellow-200-border-right {
  border-right-color: #FFF59D !important; }

.yellow-200-border-bottom {
  border-bottom-color: #FFF59D !important; }

.yellow-200-border-left {
  border-left-color: #FFF59D !important; }

.yellow-300-bg {
  background-color: #FFF176 !important; }

.md-yellow-300-bg {
  background-color: #FFF176 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-300-fg {
  color: #FFF176 !important; }

.yellow-300-border {
  border-color: #FFF176 !important; }

.yellow-300-border-top {
  border-top-color: #FFF176 !important; }

.yellow-300-border-right {
  border-right-color: #FFF176 !important; }

.yellow-300-border-bottom {
  border-bottom-color: #FFF176 !important; }

.yellow-300-border-left {
  border-left-color: #FFF176 !important; }

.yellow-400-bg {
  background-color: #FFEE58 !important; }

.md-yellow-400-bg {
  background-color: #FFEE58 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-400-fg {
  color: #FFEE58 !important; }

.yellow-400-border {
  border-color: #FFEE58 !important; }

.yellow-400-border-top {
  border-top-color: #FFEE58 !important; }

.yellow-400-border-right {
  border-right-color: #FFEE58 !important; }

.yellow-400-border-bottom {
  border-bottom-color: #FFEE58 !important; }

.yellow-400-border-left {
  border-left-color: #FFEE58 !important; }

.yellow-500-bg {
  background-color: #FFEB3B !important; }

.md-yellow-500-bg {
  background-color: #FFEB3B !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-500-fg {
  color: #FFEB3B !important; }

.yellow-500-border {
  border-color: #FFEB3B !important; }

.yellow-500-border-top {
  border-top-color: #FFEB3B !important; }

.yellow-500-border-right {
  border-right-color: #FFEB3B !important; }

.yellow-500-border-bottom {
  border-bottom-color: #FFEB3B !important; }

.yellow-500-border-left {
  border-left-color: #FFEB3B !important; }

.md-yellow-bg {
  background-color: #FFEB3B !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-bg {
  background-color: #FFEB3B !important; }

.yellow-fg {
  color: #FFEB3B !important; }

.yellow-border {
  border-color: #FFEB3B !important; }

.yellow-border-top {
  border-top-color: #FFEB3B !important; }

.yellow-border-right {
  border-right-color: #FFEB3B !important; }

.yellow-border-bottom {
  border-bottom-color: #FFEB3B !important; }

.yellow-border-left {
  border-left-color: #FFEB3B !important; }

.yellow-600-bg {
  background-color: #FDD835 !important; }

.md-yellow-600-bg {
  background-color: #FDD835 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-600-fg {
  color: #FDD835 !important; }

.yellow-600-border {
  border-color: #FDD835 !important; }

.yellow-600-border-top {
  border-top-color: #FDD835 !important; }

.yellow-600-border-right {
  border-right-color: #FDD835 !important; }

.yellow-600-border-bottom {
  border-bottom-color: #FDD835 !important; }

.yellow-600-border-left {
  border-left-color: #FDD835 !important; }

.yellow-700-bg {
  background-color: #FBC02D !important; }

.md-yellow-700-bg {
  background-color: #FBC02D !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-700-fg {
  color: #FBC02D !important; }

.yellow-700-border {
  border-color: #FBC02D !important; }

.yellow-700-border-top {
  border-top-color: #FBC02D !important; }

.yellow-700-border-right {
  border-right-color: #FBC02D !important; }

.yellow-700-border-bottom {
  border-bottom-color: #FBC02D !important; }

.yellow-700-border-left {
  border-left-color: #FBC02D !important; }

.yellow-800-bg {
  background-color: #F9A825 !important; }

.md-yellow-800-bg {
  background-color: #F9A825 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-800-fg {
  color: #F9A825 !important; }

.yellow-800-border {
  border-color: #F9A825 !important; }

.yellow-800-border-top {
  border-top-color: #F9A825 !important; }

.yellow-800-border-right {
  border-right-color: #F9A825 !important; }

.yellow-800-border-bottom {
  border-bottom-color: #F9A825 !important; }

.yellow-800-border-left {
  border-left-color: #F9A825 !important; }

.yellow-900-bg {
  background-color: #F57F17 !important; }

.md-yellow-900-bg {
  background-color: #F57F17 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-900-fg {
  color: #F57F17 !important; }

.yellow-900-border {
  border-color: #F57F17 !important; }

.yellow-900-border-top {
  border-top-color: #F57F17 !important; }

.yellow-900-border-right {
  border-right-color: #F57F17 !important; }

.yellow-900-border-bottom {
  border-bottom-color: #F57F17 !important; }

.yellow-900-border-left {
  border-left-color: #F57F17 !important; }

.yellow-A100-bg {
  background-color: #FFFF8D !important; }

.md-yellow-A100-bg {
  background-color: #FFFF8D !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A100-fg {
  color: #FFFF8D !important; }

.yellow-A100-border {
  border-color: #FFFF8D !important; }

.yellow-A100-border-top {
  border-top-color: #FFFF8D !important; }

.yellow-A100-border-right {
  border-right-color: #FFFF8D !important; }

.yellow-A100-border-bottom {
  border-bottom-color: #FFFF8D !important; }

.yellow-A100-border-left {
  border-left-color: #FFFF8D !important; }

.yellow-A200-bg {
  background-color: #FFFF00 !important; }

.md-yellow-A200-bg {
  background-color: #FFFF00 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A200-fg {
  color: #FFFF00 !important; }

.yellow-A200-border {
  border-color: #FFFF00 !important; }

.yellow-A200-border-top {
  border-top-color: #FFFF00 !important; }

.yellow-A200-border-right {
  border-right-color: #FFFF00 !important; }

.yellow-A200-border-bottom {
  border-bottom-color: #FFFF00 !important; }

.yellow-A200-border-left {
  border-left-color: #FFFF00 !important; }

.yellow-A400-bg {
  background-color: #FFEA00 !important; }

.md-yellow-A400-bg {
  background-color: #FFEA00 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A400-fg {
  color: #FFEA00 !important; }

.yellow-A400-border {
  border-color: #FFEA00 !important; }

.yellow-A400-border-top {
  border-top-color: #FFEA00 !important; }

.yellow-A400-border-right {
  border-right-color: #FFEA00 !important; }

.yellow-A400-border-bottom {
  border-bottom-color: #FFEA00 !important; }

.yellow-A400-border-left {
  border-left-color: #FFEA00 !important; }

.yellow-A700-bg {
  background-color: #FFD600 !important; }

.md-yellow-A700-bg {
  background-color: #FFD600 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A700-fg {
  color: #FFD600 !important; }

.yellow-A700-border {
  border-color: #FFD600 !important; }

.yellow-A700-border-top {
  border-top-color: #FFD600 !important; }

.yellow-A700-border-right {
  border-right-color: #FFD600 !important; }

.yellow-A700-border-bottom {
  border-bottom-color: #FFD600 !important; }

.yellow-A700-border-left {
  border-left-color: #FFD600 !important; }

.amber-50-bg {
  background-color: #FFF8E1 !important; }

.md-amber-50-bg {
  background-color: #FFF8E1 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-50-fg {
  color: #FFF8E1 !important; }

.amber-50-border {
  border-color: #FFF8E1 !important; }

.amber-50-border-top {
  border-top-color: #FFF8E1 !important; }

.amber-50-border-right {
  border-right-color: #FFF8E1 !important; }

.amber-50-border-bottom {
  border-bottom-color: #FFF8E1 !important; }

.amber-50-border-left {
  border-left-color: #FFF8E1 !important; }

.amber-100-bg {
  background-color: #FFECB3 !important; }

.md-amber-100-bg {
  background-color: #FFECB3 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-100-fg {
  color: #FFECB3 !important; }

.amber-100-border {
  border-color: #FFECB3 !important; }

.amber-100-border-top {
  border-top-color: #FFECB3 !important; }

.amber-100-border-right {
  border-right-color: #FFECB3 !important; }

.amber-100-border-bottom {
  border-bottom-color: #FFECB3 !important; }

.amber-100-border-left {
  border-left-color: #FFECB3 !important; }

.amber-200-bg {
  background-color: #FFE082 !important; }

.md-amber-200-bg {
  background-color: #FFE082 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-200-fg {
  color: #FFE082 !important; }

.amber-200-border {
  border-color: #FFE082 !important; }

.amber-200-border-top {
  border-top-color: #FFE082 !important; }

.amber-200-border-right {
  border-right-color: #FFE082 !important; }

.amber-200-border-bottom {
  border-bottom-color: #FFE082 !important; }

.amber-200-border-left {
  border-left-color: #FFE082 !important; }

.amber-300-bg {
  background-color: #FFD54F !important; }

.md-amber-300-bg {
  background-color: #FFD54F !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-300-fg {
  color: #FFD54F !important; }

.amber-300-border {
  border-color: #FFD54F !important; }

.amber-300-border-top {
  border-top-color: #FFD54F !important; }

.amber-300-border-right {
  border-right-color: #FFD54F !important; }

.amber-300-border-bottom {
  border-bottom-color: #FFD54F !important; }

.amber-300-border-left {
  border-left-color: #FFD54F !important; }

.amber-400-bg {
  background-color: #FFCA28 !important; }

.md-amber-400-bg {
  background-color: #FFCA28 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-400-fg {
  color: #FFCA28 !important; }

.amber-400-border {
  border-color: #FFCA28 !important; }

.amber-400-border-top {
  border-top-color: #FFCA28 !important; }

.amber-400-border-right {
  border-right-color: #FFCA28 !important; }

.amber-400-border-bottom {
  border-bottom-color: #FFCA28 !important; }

.amber-400-border-left {
  border-left-color: #FFCA28 !important; }

.amber-500-bg {
  background-color: #FFC107 !important; }

.md-amber-500-bg {
  background-color: #FFC107 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-500-fg {
  color: #FFC107 !important; }

.amber-500-border {
  border-color: #FFC107 !important; }

.amber-500-border-top {
  border-top-color: #FFC107 !important; }

.amber-500-border-right {
  border-right-color: #FFC107 !important; }

.amber-500-border-bottom {
  border-bottom-color: #FFC107 !important; }

.amber-500-border-left {
  border-left-color: #FFC107 !important; }

.md-amber-bg {
  background-color: #FFC107 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-bg {
  background-color: #FFC107 !important; }

.amber-fg {
  color: #FFC107 !important; }

.amber-border {
  border-color: #FFC107 !important; }

.amber-border-top {
  border-top-color: #FFC107 !important; }

.amber-border-right {
  border-right-color: #FFC107 !important; }

.amber-border-bottom {
  border-bottom-color: #FFC107 !important; }

.amber-border-left {
  border-left-color: #FFC107 !important; }

.amber-600-bg {
  background-color: #FFB300 !important; }

.md-amber-600-bg {
  background-color: #FFB300 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-600-fg {
  color: #FFB300 !important; }

.amber-600-border {
  border-color: #FFB300 !important; }

.amber-600-border-top {
  border-top-color: #FFB300 !important; }

.amber-600-border-right {
  border-right-color: #FFB300 !important; }

.amber-600-border-bottom {
  border-bottom-color: #FFB300 !important; }

.amber-600-border-left {
  border-left-color: #FFB300 !important; }

.amber-700-bg {
  background-color: #FFA000 !important; }

.md-amber-700-bg {
  background-color: #FFA000 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-700-fg {
  color: #FFA000 !important; }

.amber-700-border {
  border-color: #FFA000 !important; }

.amber-700-border-top {
  border-top-color: #FFA000 !important; }

.amber-700-border-right {
  border-right-color: #FFA000 !important; }

.amber-700-border-bottom {
  border-bottom-color: #FFA000 !important; }

.amber-700-border-left {
  border-left-color: #FFA000 !important; }

.amber-800-bg {
  background-color: #FF8F00 !important; }

.md-amber-800-bg {
  background-color: #FF8F00 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-800-fg {
  color: #FF8F00 !important; }

.amber-800-border {
  border-color: #FF8F00 !important; }

.amber-800-border-top {
  border-top-color: #FF8F00 !important; }

.amber-800-border-right {
  border-right-color: #FF8F00 !important; }

.amber-800-border-bottom {
  border-bottom-color: #FF8F00 !important; }

.amber-800-border-left {
  border-left-color: #FF8F00 !important; }

.amber-900-bg {
  background-color: #FF6F00 !important; }

.md-amber-900-bg {
  background-color: #FF6F00 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-900-fg {
  color: #FF6F00 !important; }

.amber-900-border {
  border-color: #FF6F00 !important; }

.amber-900-border-top {
  border-top-color: #FF6F00 !important; }

.amber-900-border-right {
  border-right-color: #FF6F00 !important; }

.amber-900-border-bottom {
  border-bottom-color: #FF6F00 !important; }

.amber-900-border-left {
  border-left-color: #FF6F00 !important; }

.amber-A100-bg {
  background-color: #FFE57F !important; }

.md-amber-A100-bg {
  background-color: #FFE57F !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A100-fg {
  color: #FFE57F !important; }

.amber-A100-border {
  border-color: #FFE57F !important; }

.amber-A100-border-top {
  border-top-color: #FFE57F !important; }

.amber-A100-border-right {
  border-right-color: #FFE57F !important; }

.amber-A100-border-bottom {
  border-bottom-color: #FFE57F !important; }

.amber-A100-border-left {
  border-left-color: #FFE57F !important; }

.amber-A200-bg {
  background-color: #FFD740 !important; }

.md-amber-A200-bg {
  background-color: #FFD740 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A200-fg {
  color: #FFD740 !important; }

.amber-A200-border {
  border-color: #FFD740 !important; }

.amber-A200-border-top {
  border-top-color: #FFD740 !important; }

.amber-A200-border-right {
  border-right-color: #FFD740 !important; }

.amber-A200-border-bottom {
  border-bottom-color: #FFD740 !important; }

.amber-A200-border-left {
  border-left-color: #FFD740 !important; }

.amber-A400-bg {
  background-color: #FFC400 !important; }

.md-amber-A400-bg {
  background-color: #FFC400 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A400-fg {
  color: #FFC400 !important; }

.amber-A400-border {
  border-color: #FFC400 !important; }

.amber-A400-border-top {
  border-top-color: #FFC400 !important; }

.amber-A400-border-right {
  border-right-color: #FFC400 !important; }

.amber-A400-border-bottom {
  border-bottom-color: #FFC400 !important; }

.amber-A400-border-left {
  border-left-color: #FFC400 !important; }

.amber-A700-bg {
  background-color: #FFAB00 !important; }

.md-amber-A700-bg {
  background-color: #FFAB00 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A700-fg {
  color: #FFAB00 !important; }

.amber-A700-border {
  border-color: #FFAB00 !important; }

.amber-A700-border-top {
  border-top-color: #FFAB00 !important; }

.amber-A700-border-right {
  border-right-color: #FFAB00 !important; }

.amber-A700-border-bottom {
  border-bottom-color: #FFAB00 !important; }

.amber-A700-border-left {
  border-left-color: #FFAB00 !important; }

.orange-50-bg {
  background-color: #FFF3E0 !important; }

.md-orange-50-bg {
  background-color: #FFF3E0 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-50-fg {
  color: #FFF3E0 !important; }

.orange-50-border {
  border-color: #FFF3E0 !important; }

.orange-50-border-top {
  border-top-color: #FFF3E0 !important; }

.orange-50-border-right {
  border-right-color: #FFF3E0 !important; }

.orange-50-border-bottom {
  border-bottom-color: #FFF3E0 !important; }

.orange-50-border-left {
  border-left-color: #FFF3E0 !important; }

.orange-100-bg {
  background-color: #FFE0B2 !important; }

.md-orange-100-bg {
  background-color: #FFE0B2 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-100-fg {
  color: #FFE0B2 !important; }

.orange-100-border {
  border-color: #FFE0B2 !important; }

.orange-100-border-top {
  border-top-color: #FFE0B2 !important; }

.orange-100-border-right {
  border-right-color: #FFE0B2 !important; }

.orange-100-border-bottom {
  border-bottom-color: #FFE0B2 !important; }

.orange-100-border-left {
  border-left-color: #FFE0B2 !important; }

.orange-200-bg {
  background-color: #FFCC80 !important; }

.md-orange-200-bg {
  background-color: #FFCC80 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-200-fg {
  color: #FFCC80 !important; }

.orange-200-border {
  border-color: #FFCC80 !important; }

.orange-200-border-top {
  border-top-color: #FFCC80 !important; }

.orange-200-border-right {
  border-right-color: #FFCC80 !important; }

.orange-200-border-bottom {
  border-bottom-color: #FFCC80 !important; }

.orange-200-border-left {
  border-left-color: #FFCC80 !important; }

.orange-300-bg {
  background-color: #FFB74D !important; }

.md-orange-300-bg {
  background-color: #FFB74D !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-300-fg {
  color: #FFB74D !important; }

.orange-300-border {
  border-color: #FFB74D !important; }

.orange-300-border-top {
  border-top-color: #FFB74D !important; }

.orange-300-border-right {
  border-right-color: #FFB74D !important; }

.orange-300-border-bottom {
  border-bottom-color: #FFB74D !important; }

.orange-300-border-left {
  border-left-color: #FFB74D !important; }

.orange-400-bg {
  background-color: #FFA726 !important; }

.md-orange-400-bg {
  background-color: #FFA726 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-400-fg {
  color: #FFA726 !important; }

.orange-400-border {
  border-color: #FFA726 !important; }

.orange-400-border-top {
  border-top-color: #FFA726 !important; }

.orange-400-border-right {
  border-right-color: #FFA726 !important; }

.orange-400-border-bottom {
  border-bottom-color: #FFA726 !important; }

.orange-400-border-left {
  border-left-color: #FFA726 !important; }

.orange-500-bg {
  background-color: #FF9800 !important; }

.md-orange-500-bg {
  background-color: #FF9800 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-500-fg {
  color: #FF9800 !important; }

.orange-500-border {
  border-color: #FF9800 !important; }

.orange-500-border-top {
  border-top-color: #FF9800 !important; }

.orange-500-border-right {
  border-right-color: #FF9800 !important; }

.orange-500-border-bottom {
  border-bottom-color: #FF9800 !important; }

.orange-500-border-left {
  border-left-color: #FF9800 !important; }

.md-orange-bg {
  background-color: #FF9800 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-bg {
  background-color: #FF9800 !important; }

.orange-fg {
  color: #FF9800 !important; }

.orange-border {
  border-color: #FF9800 !important; }

.orange-border-top {
  border-top-color: #FF9800 !important; }

.orange-border-right {
  border-right-color: #FF9800 !important; }

.orange-border-bottom {
  border-bottom-color: #FF9800 !important; }

.orange-border-left {
  border-left-color: #FF9800 !important; }

.orange-600-bg {
  background-color: #FB8C00 !important; }

.md-orange-600-bg {
  background-color: #FB8C00 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-600-fg {
  color: #FB8C00 !important; }

.orange-600-border {
  border-color: #FB8C00 !important; }

.orange-600-border-top {
  border-top-color: #FB8C00 !important; }

.orange-600-border-right {
  border-right-color: #FB8C00 !important; }

.orange-600-border-bottom {
  border-bottom-color: #FB8C00 !important; }

.orange-600-border-left {
  border-left-color: #FB8C00 !important; }

.orange-700-bg {
  background-color: #F57C00 !important; }

.md-orange-700-bg {
  background-color: #F57C00 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-700-fg {
  color: #F57C00 !important; }

.orange-700-border {
  border-color: #F57C00 !important; }

.orange-700-border-top {
  border-top-color: #F57C00 !important; }

.orange-700-border-right {
  border-right-color: #F57C00 !important; }

.orange-700-border-bottom {
  border-bottom-color: #F57C00 !important; }

.orange-700-border-left {
  border-left-color: #F57C00 !important; }

.orange-800-bg {
  background-color: #EF6C00 !important; }

.md-orange-800-bg {
  background-color: #EF6C00 !important;
  color: white; }

.orange-800-fg {
  color: #EF6C00 !important; }

.orange-800-border {
  border-color: #EF6C00 !important; }

.orange-800-border-top {
  border-top-color: #EF6C00 !important; }

.orange-800-border-right {
  border-right-color: #EF6C00 !important; }

.orange-800-border-bottom {
  border-bottom-color: #EF6C00 !important; }

.orange-800-border-left {
  border-left-color: #EF6C00 !important; }

.orange-900-bg {
  background-color: #E65100 !important; }

.md-orange-900-bg {
  background-color: #E65100 !important;
  color: white; }

.orange-900-fg {
  color: #E65100 !important; }

.orange-900-border {
  border-color: #E65100 !important; }

.orange-900-border-top {
  border-top-color: #E65100 !important; }

.orange-900-border-right {
  border-right-color: #E65100 !important; }

.orange-900-border-bottom {
  border-bottom-color: #E65100 !important; }

.orange-900-border-left {
  border-left-color: #E65100 !important; }

.orange-A100-bg {
  background-color: #FFD180 !important; }

.md-orange-A100-bg {
  background-color: #FFD180 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A100-fg {
  color: #FFD180 !important; }

.orange-A100-border {
  border-color: #FFD180 !important; }

.orange-A100-border-top {
  border-top-color: #FFD180 !important; }

.orange-A100-border-right {
  border-right-color: #FFD180 !important; }

.orange-A100-border-bottom {
  border-bottom-color: #FFD180 !important; }

.orange-A100-border-left {
  border-left-color: #FFD180 !important; }

.orange-A200-bg {
  background-color: #FFAB40 !important; }

.md-orange-A200-bg {
  background-color: #FFAB40 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A200-fg {
  color: #FFAB40 !important; }

.orange-A200-border {
  border-color: #FFAB40 !important; }

.orange-A200-border-top {
  border-top-color: #FFAB40 !important; }

.orange-A200-border-right {
  border-right-color: #FFAB40 !important; }

.orange-A200-border-bottom {
  border-bottom-color: #FFAB40 !important; }

.orange-A200-border-left {
  border-left-color: #FFAB40 !important; }

.orange-A400-bg {
  background-color: #FF9100 !important; }

.md-orange-A400-bg {
  background-color: #FF9100 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A400-fg {
  color: #FF9100 !important; }

.orange-A400-border {
  border-color: #FF9100 !important; }

.orange-A400-border-top {
  border-top-color: #FF9100 !important; }

.orange-A400-border-right {
  border-right-color: #FF9100 !important; }

.orange-A400-border-bottom {
  border-bottom-color: #FF9100 !important; }

.orange-A400-border-left {
  border-left-color: #FF9100 !important; }

.orange-A700-bg {
  background-color: #FF6D00 !important; }

.md-orange-A700-bg {
  background-color: #FF6D00 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A700-fg {
  color: #FF6D00 !important; }

.orange-A700-border {
  border-color: #FF6D00 !important; }

.orange-A700-border-top {
  border-top-color: #FF6D00 !important; }

.orange-A700-border-right {
  border-right-color: #FF6D00 !important; }

.orange-A700-border-bottom {
  border-bottom-color: #FF6D00 !important; }

.orange-A700-border-left {
  border-left-color: #FF6D00 !important; }

.deep-orange-50-bg {
  background-color: #FBE9E7 !important; }

.md-deep-orange-50-bg {
  background-color: #FBE9E7 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-50-fg {
  color: #FBE9E7 !important; }

.deep-orange-50-border {
  border-color: #FBE9E7 !important; }

.deep-orange-50-border-top {
  border-top-color: #FBE9E7 !important; }

.deep-orange-50-border-right {
  border-right-color: #FBE9E7 !important; }

.deep-orange-50-border-bottom {
  border-bottom-color: #FBE9E7 !important; }

.deep-orange-50-border-left {
  border-left-color: #FBE9E7 !important; }

.deep-orange-100-bg {
  background-color: #FFCCBC !important; }

.md-deep-orange-100-bg {
  background-color: #FFCCBC !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-100-fg {
  color: #FFCCBC !important; }

.deep-orange-100-border {
  border-color: #FFCCBC !important; }

.deep-orange-100-border-top {
  border-top-color: #FFCCBC !important; }

.deep-orange-100-border-right {
  border-right-color: #FFCCBC !important; }

.deep-orange-100-border-bottom {
  border-bottom-color: #FFCCBC !important; }

.deep-orange-100-border-left {
  border-left-color: #FFCCBC !important; }

.deep-orange-200-bg {
  background-color: #FFAB91 !important; }

.md-deep-orange-200-bg {
  background-color: #FFAB91 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-200-fg {
  color: #FFAB91 !important; }

.deep-orange-200-border {
  border-color: #FFAB91 !important; }

.deep-orange-200-border-top {
  border-top-color: #FFAB91 !important; }

.deep-orange-200-border-right {
  border-right-color: #FFAB91 !important; }

.deep-orange-200-border-bottom {
  border-bottom-color: #FFAB91 !important; }

.deep-orange-200-border-left {
  border-left-color: #FFAB91 !important; }

.deep-orange-300-bg {
  background-color: #FF8A65 !important; }

.md-deep-orange-300-bg {
  background-color: #FF8A65 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-300-fg {
  color: #FF8A65 !important; }

.deep-orange-300-border {
  border-color: #FF8A65 !important; }

.deep-orange-300-border-top {
  border-top-color: #FF8A65 !important; }

.deep-orange-300-border-right {
  border-right-color: #FF8A65 !important; }

.deep-orange-300-border-bottom {
  border-bottom-color: #FF8A65 !important; }

.deep-orange-300-border-left {
  border-left-color: #FF8A65 !important; }

.deep-orange-400-bg {
  background-color: #FF7043 !important; }

.md-deep-orange-400-bg {
  background-color: #FF7043 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-400-fg {
  color: #FF7043 !important; }

.deep-orange-400-border {
  border-color: #FF7043 !important; }

.deep-orange-400-border-top {
  border-top-color: #FF7043 !important; }

.deep-orange-400-border-right {
  border-right-color: #FF7043 !important; }

.deep-orange-400-border-bottom {
  border-bottom-color: #FF7043 !important; }

.deep-orange-400-border-left {
  border-left-color: #FF7043 !important; }

.deep-orange-500-bg {
  background-color: #FF5722 !important; }

.md-deep-orange-500-bg {
  background-color: #FF5722 !important;
  color: white; }

.deep-orange-500-fg {
  color: #FF5722 !important; }

.deep-orange-500-border {
  border-color: #FF5722 !important; }

.deep-orange-500-border-top {
  border-top-color: #FF5722 !important; }

.deep-orange-500-border-right {
  border-right-color: #FF5722 !important; }

.deep-orange-500-border-bottom {
  border-bottom-color: #FF5722 !important; }

.deep-orange-500-border-left {
  border-left-color: #FF5722 !important; }

.md-deep-orange-bg {
  background-color: #FF5722 !important;
  color: white; }

.deep-orange-bg {
  background-color: #FF5722 !important; }

.deep-orange-fg {
  color: #FF5722 !important; }

.deep-orange-border {
  border-color: #FF5722 !important; }

.deep-orange-border-top {
  border-top-color: #FF5722 !important; }

.deep-orange-border-right {
  border-right-color: #FF5722 !important; }

.deep-orange-border-bottom {
  border-bottom-color: #FF5722 !important; }

.deep-orange-border-left {
  border-left-color: #FF5722 !important; }

.deep-orange-600-bg {
  background-color: #F4511E !important; }

.md-deep-orange-600-bg {
  background-color: #F4511E !important;
  color: white; }

.deep-orange-600-fg {
  color: #F4511E !important; }

.deep-orange-600-border {
  border-color: #F4511E !important; }

.deep-orange-600-border-top {
  border-top-color: #F4511E !important; }

.deep-orange-600-border-right {
  border-right-color: #F4511E !important; }

.deep-orange-600-border-bottom {
  border-bottom-color: #F4511E !important; }

.deep-orange-600-border-left {
  border-left-color: #F4511E !important; }

.deep-orange-700-bg {
  background-color: #E64A19 !important; }

.md-deep-orange-700-bg {
  background-color: #E64A19 !important;
  color: white; }

.deep-orange-700-fg {
  color: #E64A19 !important; }

.deep-orange-700-border {
  border-color: #E64A19 !important; }

.deep-orange-700-border-top {
  border-top-color: #E64A19 !important; }

.deep-orange-700-border-right {
  border-right-color: #E64A19 !important; }

.deep-orange-700-border-bottom {
  border-bottom-color: #E64A19 !important; }

.deep-orange-700-border-left {
  border-left-color: #E64A19 !important; }

.deep-orange-800-bg {
  background-color: #D84315 !important; }

.md-deep-orange-800-bg {
  background-color: #D84315 !important;
  color: white; }

.deep-orange-800-fg {
  color: #D84315 !important; }

.deep-orange-800-border {
  border-color: #D84315 !important; }

.deep-orange-800-border-top {
  border-top-color: #D84315 !important; }

.deep-orange-800-border-right {
  border-right-color: #D84315 !important; }

.deep-orange-800-border-bottom {
  border-bottom-color: #D84315 !important; }

.deep-orange-800-border-left {
  border-left-color: #D84315 !important; }

.deep-orange-900-bg {
  background-color: #BF360C !important; }

.md-deep-orange-900-bg {
  background-color: #BF360C !important;
  color: white; }

.deep-orange-900-fg {
  color: #BF360C !important; }

.deep-orange-900-border {
  border-color: #BF360C !important; }

.deep-orange-900-border-top {
  border-top-color: #BF360C !important; }

.deep-orange-900-border-right {
  border-right-color: #BF360C !important; }

.deep-orange-900-border-bottom {
  border-bottom-color: #BF360C !important; }

.deep-orange-900-border-left {
  border-left-color: #BF360C !important; }

.deep-orange-A100-bg {
  background-color: #FF9E80 !important; }

.md-deep-orange-A100-bg {
  background-color: #FF9E80 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-A100-fg {
  color: #FF9E80 !important; }

.deep-orange-A100-border {
  border-color: #FF9E80 !important; }

.deep-orange-A100-border-top {
  border-top-color: #FF9E80 !important; }

.deep-orange-A100-border-right {
  border-right-color: #FF9E80 !important; }

.deep-orange-A100-border-bottom {
  border-bottom-color: #FF9E80 !important; }

.deep-orange-A100-border-left {
  border-left-color: #FF9E80 !important; }

.deep-orange-A200-bg {
  background-color: #FF6E40 !important; }

.md-deep-orange-A200-bg {
  background-color: #FF6E40 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-A200-fg {
  color: #FF6E40 !important; }

.deep-orange-A200-border {
  border-color: #FF6E40 !important; }

.deep-orange-A200-border-top {
  border-top-color: #FF6E40 !important; }

.deep-orange-A200-border-right {
  border-right-color: #FF6E40 !important; }

.deep-orange-A200-border-bottom {
  border-bottom-color: #FF6E40 !important; }

.deep-orange-A200-border-left {
  border-left-color: #FF6E40 !important; }

.deep-orange-A400-bg {
  background-color: #FF3D00 !important; }

.md-deep-orange-A400-bg {
  background-color: #FF3D00 !important;
  color: white; }

.deep-orange-A400-fg {
  color: #FF3D00 !important; }

.deep-orange-A400-border {
  border-color: #FF3D00 !important; }

.deep-orange-A400-border-top {
  border-top-color: #FF3D00 !important; }

.deep-orange-A400-border-right {
  border-right-color: #FF3D00 !important; }

.deep-orange-A400-border-bottom {
  border-bottom-color: #FF3D00 !important; }

.deep-orange-A400-border-left {
  border-left-color: #FF3D00 !important; }

.deep-orange-A700-bg {
  background-color: #DD2C00 !important; }

.md-deep-orange-A700-bg {
  background-color: #DD2C00 !important;
  color: white; }

.deep-orange-A700-fg {
  color: #DD2C00 !important; }

.deep-orange-A700-border {
  border-color: #DD2C00 !important; }

.deep-orange-A700-border-top {
  border-top-color: #DD2C00 !important; }

.deep-orange-A700-border-right {
  border-right-color: #DD2C00 !important; }

.deep-orange-A700-border-bottom {
  border-bottom-color: #DD2C00 !important; }

.deep-orange-A700-border-left {
  border-left-color: #DD2C00 !important; }

.brown-50-bg {
  background-color: #EFEBE9 !important; }

.md-brown-50-bg {
  background-color: #EFEBE9 !important;
  color: rgba(0, 0, 0, 0.87); }

.brown-50-fg {
  color: #EFEBE9 !important; }

.brown-50-border {
  border-color: #EFEBE9 !important; }

.brown-50-border-top {
  border-top-color: #EFEBE9 !important; }

.brown-50-border-right {
  border-right-color: #EFEBE9 !important; }

.brown-50-border-bottom {
  border-bottom-color: #EFEBE9 !important; }

.brown-50-border-left {
  border-left-color: #EFEBE9 !important; }

.brown-100-bg {
  background-color: #D7CCC8 !important; }

.md-brown-100-bg {
  background-color: #D7CCC8 !important;
  color: rgba(0, 0, 0, 0.87); }

.brown-100-fg {
  color: #D7CCC8 !important; }

.brown-100-border {
  border-color: #D7CCC8 !important; }

.brown-100-border-top {
  border-top-color: #D7CCC8 !important; }

.brown-100-border-right {
  border-right-color: #D7CCC8 !important; }

.brown-100-border-bottom {
  border-bottom-color: #D7CCC8 !important; }

.brown-100-border-left {
  border-left-color: #D7CCC8 !important; }

.brown-200-bg {
  background-color: #BCAAA4 !important; }

.md-brown-200-bg {
  background-color: #BCAAA4 !important;
  color: rgba(0, 0, 0, 0.87); }

.brown-200-fg {
  color: #BCAAA4 !important; }

.brown-200-border {
  border-color: #BCAAA4 !important; }

.brown-200-border-top {
  border-top-color: #BCAAA4 !important; }

.brown-200-border-right {
  border-right-color: #BCAAA4 !important; }

.brown-200-border-bottom {
  border-bottom-color: #BCAAA4 !important; }

.brown-200-border-left {
  border-left-color: #BCAAA4 !important; }

.brown-300-bg {
  background-color: #A1887F !important; }

.md-brown-300-bg {
  background-color: #A1887F !important;
  color: white; }

.brown-300-fg {
  color: #A1887F !important; }

.brown-300-border {
  border-color: #A1887F !important; }

.brown-300-border-top {
  border-top-color: #A1887F !important; }

.brown-300-border-right {
  border-right-color: #A1887F !important; }

.brown-300-border-bottom {
  border-bottom-color: #A1887F !important; }

.brown-300-border-left {
  border-left-color: #A1887F !important; }

.brown-400-bg {
  background-color: #8D6E63 !important; }

.md-brown-400-bg {
  background-color: #8D6E63 !important;
  color: white; }

.brown-400-fg {
  color: #8D6E63 !important; }

.brown-400-border {
  border-color: #8D6E63 !important; }

.brown-400-border-top {
  border-top-color: #8D6E63 !important; }

.brown-400-border-right {
  border-right-color: #8D6E63 !important; }

.brown-400-border-bottom {
  border-bottom-color: #8D6E63 !important; }

.brown-400-border-left {
  border-left-color: #8D6E63 !important; }

.brown-500-bg {
  background-color: #795548 !important; }

.md-brown-500-bg {
  background-color: #795548 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-500-fg {
  color: #795548 !important; }

.brown-500-border {
  border-color: #795548 !important; }

.brown-500-border-top {
  border-top-color: #795548 !important; }

.brown-500-border-right {
  border-right-color: #795548 !important; }

.brown-500-border-bottom {
  border-bottom-color: #795548 !important; }

.brown-500-border-left {
  border-left-color: #795548 !important; }

.md-brown-bg {
  background-color: #795548 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-bg {
  background-color: #795548 !important; }

.brown-fg {
  color: #795548 !important; }

.brown-border {
  border-color: #795548 !important; }

.brown-border-top {
  border-top-color: #795548 !important; }

.brown-border-right {
  border-right-color: #795548 !important; }

.brown-border-bottom {
  border-bottom-color: #795548 !important; }

.brown-border-left {
  border-left-color: #795548 !important; }

.brown-600-bg {
  background-color: #6D4C41 !important; }

.md-brown-600-bg {
  background-color: #6D4C41 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-600-fg {
  color: #6D4C41 !important; }

.brown-600-border {
  border-color: #6D4C41 !important; }

.brown-600-border-top {
  border-top-color: #6D4C41 !important; }

.brown-600-border-right {
  border-right-color: #6D4C41 !important; }

.brown-600-border-bottom {
  border-bottom-color: #6D4C41 !important; }

.brown-600-border-left {
  border-left-color: #6D4C41 !important; }

.brown-700-bg {
  background-color: #5D4037 !important; }

.md-brown-700-bg {
  background-color: #5D4037 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-700-fg {
  color: #5D4037 !important; }

.brown-700-border {
  border-color: #5D4037 !important; }

.brown-700-border-top {
  border-top-color: #5D4037 !important; }

.brown-700-border-right {
  border-right-color: #5D4037 !important; }

.brown-700-border-bottom {
  border-bottom-color: #5D4037 !important; }

.brown-700-border-left {
  border-left-color: #5D4037 !important; }

.brown-800-bg {
  background-color: #4E342E !important; }

.md-brown-800-bg {
  background-color: #4E342E !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-800-fg {
  color: #4E342E !important; }

.brown-800-border {
  border-color: #4E342E !important; }

.brown-800-border-top {
  border-top-color: #4E342E !important; }

.brown-800-border-right {
  border-right-color: #4E342E !important; }

.brown-800-border-bottom {
  border-bottom-color: #4E342E !important; }

.brown-800-border-left {
  border-left-color: #4E342E !important; }

.brown-900-bg {
  background-color: #3E2723 !important; }

.md-brown-900-bg {
  background-color: #3E2723 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-900-fg {
  color: #3E2723 !important; }

.brown-900-border {
  border-color: #3E2723 !important; }

.brown-900-border-top {
  border-top-color: #3E2723 !important; }

.brown-900-border-right {
  border-right-color: #3E2723 !important; }

.brown-900-border-bottom {
  border-bottom-color: #3E2723 !important; }

.brown-900-border-left {
  border-left-color: #3E2723 !important; }

.brown-A100-bg {
  background-color: #D7CCC8 !important; }

.md-brown-A100-bg {
  background-color: #D7CCC8 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A100-fg {
  color: #D7CCC8 !important; }

.brown-A100-border {
  border-color: #D7CCC8 !important; }

.brown-A100-border-top {
  border-top-color: #D7CCC8 !important; }

.brown-A100-border-right {
  border-right-color: #D7CCC8 !important; }

.brown-A100-border-bottom {
  border-bottom-color: #D7CCC8 !important; }

.brown-A100-border-left {
  border-left-color: #D7CCC8 !important; }

.brown-A200-bg {
  background-color: #BCAAA4 !important; }

.md-brown-A200-bg {
  background-color: #BCAAA4 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A200-fg {
  color: #BCAAA4 !important; }

.brown-A200-border {
  border-color: #BCAAA4 !important; }

.brown-A200-border-top {
  border-top-color: #BCAAA4 !important; }

.brown-A200-border-right {
  border-right-color: #BCAAA4 !important; }

.brown-A200-border-bottom {
  border-bottom-color: #BCAAA4 !important; }

.brown-A200-border-left {
  border-left-color: #BCAAA4 !important; }

.brown-A400-bg {
  background-color: #8D6E63 !important; }

.md-brown-A400-bg {
  background-color: #8D6E63 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A400-fg {
  color: #8D6E63 !important; }

.brown-A400-border {
  border-color: #8D6E63 !important; }

.brown-A400-border-top {
  border-top-color: #8D6E63 !important; }

.brown-A400-border-right {
  border-right-color: #8D6E63 !important; }

.brown-A400-border-bottom {
  border-bottom-color: #8D6E63 !important; }

.brown-A400-border-left {
  border-left-color: #8D6E63 !important; }

.brown-A700-bg {
  background-color: #5D4037 !important; }

.md-brown-A700-bg {
  background-color: #5D4037 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A700-fg {
  color: #5D4037 !important; }

.brown-A700-border {
  border-color: #5D4037 !important; }

.brown-A700-border-top {
  border-top-color: #5D4037 !important; }

.brown-A700-border-right {
  border-right-color: #5D4037 !important; }

.brown-A700-border-bottom {
  border-bottom-color: #5D4037 !important; }

.brown-A700-border-left {
  border-left-color: #5D4037 !important; }

.grey-50-bg {
  background-color: #FAFAFA !important; }

.md-grey-50-bg {
  background-color: #FAFAFA !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-50-fg {
  color: #FAFAFA !important; }

.grey-50-border {
  border-color: #FAFAFA !important; }

.grey-50-border-top {
  border-top-color: #FAFAFA !important; }

.grey-50-border-right {
  border-right-color: #FAFAFA !important; }

.grey-50-border-bottom {
  border-bottom-color: #FAFAFA !important; }

.grey-50-border-left {
  border-left-color: #FAFAFA !important; }

.grey-100-bg {
  background-color: #F5F5F5 !important; }

.md-grey-100-bg {
  background-color: #F5F5F5 !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-100-fg {
  color: #F5F5F5 !important; }

.grey-100-border {
  border-color: #F5F5F5 !important; }

.grey-100-border-top {
  border-top-color: #F5F5F5 !important; }

.grey-100-border-right {
  border-right-color: #F5F5F5 !important; }

.grey-100-border-bottom {
  border-bottom-color: #F5F5F5 !important; }

.grey-100-border-left {
  border-left-color: #F5F5F5 !important; }

.grey-200-bg {
  background-color: #EEEEEE !important; }

.md-grey-200-bg {
  background-color: #EEEEEE !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-200-fg {
  color: #EEEEEE !important; }

.grey-200-border {
  border-color: #EEEEEE !important; }

.grey-200-border-top {
  border-top-color: #EEEEEE !important; }

.grey-200-border-right {
  border-right-color: #EEEEEE !important; }

.grey-200-border-bottom {
  border-bottom-color: #EEEEEE !important; }

.grey-200-border-left {
  border-left-color: #EEEEEE !important; }

.grey-300-bg {
  background-color: #E0E0E0 !important; }

.md-grey-300-bg {
  background-color: #E0E0E0 !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-300-fg {
  color: #E0E0E0 !important; }

.grey-300-border {
  border-color: #E0E0E0 !important; }

.grey-300-border-top {
  border-top-color: #E0E0E0 !important; }

.grey-300-border-right {
  border-right-color: #E0E0E0 !important; }

.grey-300-border-bottom {
  border-bottom-color: #E0E0E0 !important; }

.grey-300-border-left {
  border-left-color: #E0E0E0 !important; }

.grey-400-bg {
  background-color: #BDBDBD !important; }

.md-grey-400-bg {
  background-color: #BDBDBD !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-400-fg {
  color: #BDBDBD !important; }

.grey-400-border {
  border-color: #BDBDBD !important; }

.grey-400-border-top {
  border-top-color: #BDBDBD !important; }

.grey-400-border-right {
  border-right-color: #BDBDBD !important; }

.grey-400-border-bottom {
  border-bottom-color: #BDBDBD !important; }

.grey-400-border-left {
  border-left-color: #BDBDBD !important; }

.grey-500-bg {
  background-color: #9E9E9E !important; }

.md-grey-500-bg {
  background-color: #9E9E9E !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-500-fg {
  color: #9E9E9E !important; }

.grey-500-border {
  border-color: #9E9E9E !important; }

.grey-500-border-top {
  border-top-color: #9E9E9E !important; }

.grey-500-border-right {
  border-right-color: #9E9E9E !important; }

.grey-500-border-bottom {
  border-bottom-color: #9E9E9E !important; }

.grey-500-border-left {
  border-left-color: #9E9E9E !important; }

.md-grey-bg {
  background-color: #9E9E9E !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-bg {
  background-color: #9E9E9E !important; }

.grey-fg {
  color: #9E9E9E !important; }

.grey-border {
  border-color: #9E9E9E !important; }

.grey-border-top {
  border-top-color: #9E9E9E !important; }

.grey-border-right {
  border-right-color: #9E9E9E !important; }

.grey-border-bottom {
  border-bottom-color: #9E9E9E !important; }

.grey-border-left {
  border-left-color: #9E9E9E !important; }

.grey-600-bg {
  background-color: #757575 !important; }

.md-grey-600-bg {
  background-color: #757575 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-600-fg {
  color: #757575 !important; }

.grey-600-border {
  border-color: #757575 !important; }

.grey-600-border-top {
  border-top-color: #757575 !important; }

.grey-600-border-right {
  border-right-color: #757575 !important; }

.grey-600-border-bottom {
  border-bottom-color: #757575 !important; }

.grey-600-border-left {
  border-left-color: #757575 !important; }

.grey-700-bg {
  background-color: #616161 !important; }

.md-grey-700-bg {
  background-color: #616161 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-700-fg {
  color: #616161 !important; }

.grey-700-border {
  border-color: #616161 !important; }

.grey-700-border-top {
  border-top-color: #616161 !important; }

.grey-700-border-right {
  border-right-color: #616161 !important; }

.grey-700-border-bottom {
  border-bottom-color: #616161 !important; }

.grey-700-border-left {
  border-left-color: #616161 !important; }

.grey-800-bg {
  background-color: #424242 !important; }

.md-grey-800-bg {
  background-color: #424242 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-800-fg {
  color: #424242 !important; }

.grey-800-border {
  border-color: #424242 !important; }

.grey-800-border-top {
  border-top-color: #424242 !important; }

.grey-800-border-right {
  border-right-color: #424242 !important; }

.grey-800-border-bottom {
  border-bottom-color: #424242 !important; }

.grey-800-border-left {
  border-left-color: #424242 !important; }

.grey-900-bg {
  background-color: #212121 !important; }

.md-grey-900-bg {
  background-color: #212121 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-900-fg {
  color: #212121 !important; }

.grey-900-border {
  border-color: #212121 !important; }

.grey-900-border-top {
  border-top-color: #212121 !important; }

.grey-900-border-right {
  border-right-color: #212121 !important; }

.grey-900-border-bottom {
  border-bottom-color: #212121 !important; }

.grey-900-border-left {
  border-left-color: #212121 !important; }

.grey-1000-bg {
  background-color: #000000 !important; }

.md-grey-1000-bg {
  background-color: #000000 !important; }

.grey-1000-fg {
  color: #000000 !important; }

.grey-1000-border {
  border-color: #000000 !important; }

.grey-1000-border-top {
  border-top-color: #000000 !important; }

.grey-1000-border-right {
  border-right-color: #000000 !important; }

.grey-1000-border-bottom {
  border-bottom-color: #000000 !important; }

.grey-1000-border-left {
  border-left-color: #000000 !important; }

.grey-A100-bg {
  background-color: #FFFFFF !important; }

.md-grey-A100-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A100-fg {
  color: #FFFFFF !important; }

.grey-A100-border {
  border-color: #FFFFFF !important; }

.grey-A100-border-top {
  border-top-color: #FFFFFF !important; }

.grey-A100-border-right {
  border-right-color: #FFFFFF !important; }

.grey-A100-border-bottom {
  border-bottom-color: #FFFFFF !important; }

.grey-A100-border-left {
  border-left-color: #FFFFFF !important; }

.grey-A200-bg {
  background-color: #EEEEEE !important; }

.md-grey-A200-bg {
  background-color: #EEEEEE !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A200-fg {
  color: #EEEEEE !important; }

.grey-A200-border {
  border-color: #EEEEEE !important; }

.grey-A200-border-top {
  border-top-color: #EEEEEE !important; }

.grey-A200-border-right {
  border-right-color: #EEEEEE !important; }

.grey-A200-border-bottom {
  border-bottom-color: #EEEEEE !important; }

.grey-A200-border-left {
  border-left-color: #EEEEEE !important; }

.grey-A400-bg {
  background-color: #BDBDBD !important; }

.md-grey-A400-bg {
  background-color: #BDBDBD !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A400-fg {
  color: #BDBDBD !important; }

.grey-A400-border {
  border-color: #BDBDBD !important; }

.grey-A400-border-top {
  border-top-color: #BDBDBD !important; }

.grey-A400-border-right {
  border-right-color: #BDBDBD !important; }

.grey-A400-border-bottom {
  border-bottom-color: #BDBDBD !important; }

.grey-A400-border-left {
  border-left-color: #BDBDBD !important; }

.grey-A700-bg {
  background-color: #616161 !important; }

.md-grey-A700-bg {
  background-color: #616161 !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A700-fg {
  color: #616161 !important; }

.grey-A700-border {
  border-color: #616161 !important; }

.grey-A700-border-top {
  border-top-color: #616161 !important; }

.grey-A700-border-right {
  border-right-color: #616161 !important; }

.grey-A700-border-bottom {
  border-bottom-color: #616161 !important; }

.grey-A700-border-left {
  border-left-color: #616161 !important; }

.blue-grey-50-bg {
  background-color: #ECEFF1 !important; }

.md-blue-grey-50-bg {
  background-color: #ECEFF1 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-50-fg {
  color: #ECEFF1 !important; }

.blue-grey-50-border {
  border-color: #ECEFF1 !important; }

.blue-grey-50-border-top {
  border-top-color: #ECEFF1 !important; }

.blue-grey-50-border-right {
  border-right-color: #ECEFF1 !important; }

.blue-grey-50-border-bottom {
  border-bottom-color: #ECEFF1 !important; }

.blue-grey-50-border-left {
  border-left-color: #ECEFF1 !important; }

.blue-grey-100-bg {
  background-color: #CFD8DC !important; }

.md-blue-grey-100-bg {
  background-color: #CFD8DC !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-100-fg {
  color: #CFD8DC !important; }

.blue-grey-100-border {
  border-color: #CFD8DC !important; }

.blue-grey-100-border-top {
  border-top-color: #CFD8DC !important; }

.blue-grey-100-border-right {
  border-right-color: #CFD8DC !important; }

.blue-grey-100-border-bottom {
  border-bottom-color: #CFD8DC !important; }

.blue-grey-100-border-left {
  border-left-color: #CFD8DC !important; }

.blue-grey-200-bg {
  background-color: #B0BEC5 !important; }

.md-blue-grey-200-bg {
  background-color: #B0BEC5 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-200-fg {
  color: #B0BEC5 !important; }

.blue-grey-200-border {
  border-color: #B0BEC5 !important; }

.blue-grey-200-border-top {
  border-top-color: #B0BEC5 !important; }

.blue-grey-200-border-right {
  border-right-color: #B0BEC5 !important; }

.blue-grey-200-border-bottom {
  border-bottom-color: #B0BEC5 !important; }

.blue-grey-200-border-left {
  border-left-color: #B0BEC5 !important; }

.blue-grey-300-bg {
  background-color: #90A4AE !important; }

.md-blue-grey-300-bg {
  background-color: #90A4AE !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-300-fg {
  color: #90A4AE !important; }

.blue-grey-300-border {
  border-color: #90A4AE !important; }

.blue-grey-300-border-top {
  border-top-color: #90A4AE !important; }

.blue-grey-300-border-right {
  border-right-color: #90A4AE !important; }

.blue-grey-300-border-bottom {
  border-bottom-color: #90A4AE !important; }

.blue-grey-300-border-left {
  border-left-color: #90A4AE !important; }

.blue-grey-400-bg {
  background-color: #78909C !important; }

.md-blue-grey-400-bg {
  background-color: #78909C !important;
  color: white; }

.blue-grey-400-fg {
  color: #78909C !important; }

.blue-grey-400-border {
  border-color: #78909C !important; }

.blue-grey-400-border-top {
  border-top-color: #78909C !important; }

.blue-grey-400-border-right {
  border-right-color: #78909C !important; }

.blue-grey-400-border-bottom {
  border-bottom-color: #78909C !important; }

.blue-grey-400-border-left {
  border-left-color: #78909C !important; }

.blue-grey-500-bg {
  background-color: #607D8B !important; }

.md-blue-grey-500-bg {
  background-color: #607D8B !important;
  color: white; }

.blue-grey-500-fg {
  color: #607D8B !important; }

.blue-grey-500-border {
  border-color: #607D8B !important; }

.blue-grey-500-border-top {
  border-top-color: #607D8B !important; }

.blue-grey-500-border-right {
  border-right-color: #607D8B !important; }

.blue-grey-500-border-bottom {
  border-bottom-color: #607D8B !important; }

.blue-grey-500-border-left {
  border-left-color: #607D8B !important; }

.md-blue-grey-bg {
  background-color: #607D8B !important;
  color: white; }

.blue-grey-bg {
  background-color: #607D8B !important; }

.blue-grey-fg {
  color: #607D8B !important; }

.blue-grey-border {
  border-color: #607D8B !important; }

.blue-grey-border-top {
  border-top-color: #607D8B !important; }

.blue-grey-border-right {
  border-right-color: #607D8B !important; }

.blue-grey-border-bottom {
  border-bottom-color: #607D8B !important; }

.blue-grey-border-left {
  border-left-color: #607D8B !important; }

.blue-grey-600-bg {
  background-color: #546E7A !important; }

.md-blue-grey-600-bg {
  background-color: #546E7A !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-600-fg {
  color: #546E7A !important; }

.blue-grey-600-border {
  border-color: #546E7A !important; }

.blue-grey-600-border-top {
  border-top-color: #546E7A !important; }

.blue-grey-600-border-right {
  border-right-color: #546E7A !important; }

.blue-grey-600-border-bottom {
  border-bottom-color: #546E7A !important; }

.blue-grey-600-border-left {
  border-left-color: #546E7A !important; }

.blue-grey-700-bg {
  background-color: #455A64 !important; }

.md-blue-grey-700-bg {
  background-color: #455A64 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-700-fg {
  color: #455A64 !important; }

.blue-grey-700-border {
  border-color: #455A64 !important; }

.blue-grey-700-border-top {
  border-top-color: #455A64 !important; }

.blue-grey-700-border-right {
  border-right-color: #455A64 !important; }

.blue-grey-700-border-bottom {
  border-bottom-color: #455A64 !important; }

.blue-grey-700-border-left {
  border-left-color: #455A64 !important; }

.blue-grey-800-bg {
  background-color: #37474F !important; }

.md-blue-grey-800-bg {
  background-color: #37474F !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-800-fg {
  color: #37474F !important; }

.blue-grey-800-border {
  border-color: #37474F !important; }

.blue-grey-800-border-top {
  border-top-color: #37474F !important; }

.blue-grey-800-border-right {
  border-right-color: #37474F !important; }

.blue-grey-800-border-bottom {
  border-bottom-color: #37474F !important; }

.blue-grey-800-border-left {
  border-left-color: #37474F !important; }

.blue-grey-900-bg {
  background-color: #263238 !important; }

.md-blue-grey-900-bg {
  background-color: #263238 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-900-fg {
  color: #263238 !important; }

.blue-grey-900-border {
  border-color: #263238 !important; }

.blue-grey-900-border-top {
  border-top-color: #263238 !important; }

.blue-grey-900-border-right {
  border-right-color: #263238 !important; }

.blue-grey-900-border-bottom {
  border-bottom-color: #263238 !important; }

.blue-grey-900-border-left {
  border-left-color: #263238 !important; }

.blue-grey-A100-bg {
  background-color: #CFD8DC !important; }

.md-blue-grey-A100-bg {
  background-color: #CFD8DC !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A100-fg {
  color: #CFD8DC !important; }

.blue-grey-A100-border {
  border-color: #CFD8DC !important; }

.blue-grey-A100-border-top {
  border-top-color: #CFD8DC !important; }

.blue-grey-A100-border-right {
  border-right-color: #CFD8DC !important; }

.blue-grey-A100-border-bottom {
  border-bottom-color: #CFD8DC !important; }

.blue-grey-A100-border-left {
  border-left-color: #CFD8DC !important; }

.blue-grey-A200-bg {
  background-color: #B0BEC5 !important; }

.md-blue-grey-A200-bg {
  background-color: #B0BEC5 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A200-fg {
  color: #B0BEC5 !important; }

.blue-grey-A200-border {
  border-color: #B0BEC5 !important; }

.blue-grey-A200-border-top {
  border-top-color: #B0BEC5 !important; }

.blue-grey-A200-border-right {
  border-right-color: #B0BEC5 !important; }

.blue-grey-A200-border-bottom {
  border-bottom-color: #B0BEC5 !important; }

.blue-grey-A200-border-left {
  border-left-color: #B0BEC5 !important; }

.blue-grey-A400-bg {
  background-color: #78909C !important; }

.md-blue-grey-A400-bg {
  background-color: #78909C !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A400-fg {
  color: #78909C !important; }

.blue-grey-A400-border {
  border-color: #78909C !important; }

.blue-grey-A400-border-top {
  border-top-color: #78909C !important; }

.blue-grey-A400-border-right {
  border-right-color: #78909C !important; }

.blue-grey-A400-border-bottom {
  border-bottom-color: #78909C !important; }

.blue-grey-A400-border-left {
  border-left-color: #78909C !important; }

.blue-grey-A700-bg {
  background-color: #455A64 !important; }

.md-blue-grey-A700-bg {
  background-color: #455A64 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A700-fg {
  color: #455A64 !important; }

.blue-grey-A700-border {
  border-color: #455A64 !important; }

.blue-grey-A700-border-top {
  border-top-color: #455A64 !important; }

.blue-grey-A700-border-right {
  border-right-color: #455A64 !important; }

.blue-grey-A700-border-bottom {
  border-bottom-color: #455A64 !important; }

.blue-grey-A700-border-left {
  border-left-color: #455A64 !important; }

.white-500-bg {
  background-color: #FFFFFF !important; }

.md-white-500-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.white-500-fg {
  color: #FFFFFF !important; }

.white-500-border {
  border-color: #FFFFFF !important; }

.white-500-border-top {
  border-top-color: #FFFFFF !important; }

.white-500-border-right {
  border-right-color: #FFFFFF !important; }

.white-500-border-bottom {
  border-bottom-color: #FFFFFF !important; }

.white-500-border-left {
  border-left-color: #FFFFFF !important; }

.md-white-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.white-bg {
  background-color: #FFFFFF !important; }

.white-fg {
  color: #FFFFFF !important; }

.white-border {
  border-color: #FFFFFF !important; }

.white-border-top {
  border-top-color: #FFFFFF !important; }

.white-border-right {
  border-right-color: #FFFFFF !important; }

.white-border-bottom {
  border-bottom-color: #FFFFFF !important; }

.white-border-left {
  border-left-color: #FFFFFF !important; }

.black-500-bg {
  background-color: #000000 !important; }

.md-black-500-bg {
  background-color: #000000 !important;
  color: rgba(255, 255, 255, 0.87); }

.black-500-fg {
  color: #000000 !important; }

.black-500-border {
  border-color: #000000 !important; }

.black-500-border-top {
  border-top-color: #000000 !important; }

.black-500-border-right {
  border-right-color: #000000 !important; }

.black-500-border-bottom {
  border-bottom-color: #000000 !important; }

.black-500-border-left {
  border-left-color: #000000 !important; }

.md-black-bg {
  background-color: #000000 !important;
  color: rgba(255, 255, 255, 0.87); }

.black-bg {
  background-color: #000000 !important; }

.black-fg {
  color: #000000 !important; }

.black-border {
  border-color: #000000 !important; }

.black-border-top {
  border-top-color: #000000 !important; }

.black-border-right {
  border-right-color: #000000 !important; }

.black-border-bottom {
  border-bottom-color: #000000 !important; }

.black-border-left {
  border-left-color: #000000 !important; }

/*----------------------------------------------------------------*/
/*  Icons
/*----------------------------------------------------------------*/
md-icon[md-font-icon],
i {
  font-size: 24px;
  width: 24px;
  height: 24px;
  line-height: 24px; }
  md-icon[md-font-icon].s4,
  i.s4 {
    font-size: 4px !important;
    width: 4px !important;
    height: 4px !important;
    line-height: 4px !important; }
  md-icon[md-font-icon].s6,
  i.s6 {
    font-size: 6px !important;
    width: 6px !important;
    height: 6px !important;
    line-height: 6px !important; }
  md-icon[md-font-icon].s8,
  i.s8 {
    font-size: 8px !important;
    width: 8px !important;
    height: 8px !important;
    line-height: 8px !important; }
  md-icon[md-font-icon].s10,
  i.s10 {
    font-size: 10px !important;
    width: 10px !important;
    height: 10px !important;
    line-height: 10px !important; }
  md-icon[md-font-icon].s12,
  i.s12 {
    font-size: 12px !important;
    width: 12px !important;
    height: 12px !important;
    line-height: 12px !important; }
  md-icon[md-font-icon].s14,
  i.s14 {
    font-size: 14px !important;
    width: 14px !important;
    height: 14px !important;
    line-height: 14px !important; }
  md-icon[md-font-icon].s16,
  i.s16 {
    font-size: 16px !important;
    width: 16px !important;
    height: 16px !important;
    line-height: 16px !important; }
  md-icon[md-font-icon].s18,
  i.s18 {
    font-size: 18px !important;
    width: 18px !important;
    height: 18px !important;
    line-height: 18px !important; }
  md-icon[md-font-icon].s20,
  i.s20 {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
    line-height: 20px !important; }
  md-icon[md-font-icon].s22,
  i.s22 {
    font-size: 22px !important;
    width: 22px !important;
    height: 22px !important;
    line-height: 22px !important; }
  md-icon[md-font-icon].s24,
  i.s24 {
    font-size: 24px !important;
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important; }
  md-icon[md-font-icon].s26,
  i.s26 {
    font-size: 26px !important;
    width: 26px !important;
    height: 26px !important;
    line-height: 26px !important; }
  md-icon[md-font-icon].s28,
  i.s28 {
    font-size: 28px !important;
    width: 28px !important;
    height: 28px !important;
    line-height: 28px !important; }
  md-icon[md-font-icon].s30,
  i.s30 {
    font-size: 30px !important;
    width: 30px !important;
    height: 30px !important;
    line-height: 30px !important; }
  md-icon[md-font-icon].s32,
  i.s32 {
    font-size: 32px !important;
    width: 32px !important;
    height: 32px !important;
    line-height: 32px !important; }
  md-icon[md-font-icon].s34,
  i.s34 {
    font-size: 34px !important;
    width: 34px !important;
    height: 34px !important;
    line-height: 34px !important; }
  md-icon[md-font-icon].s36,
  i.s36 {
    font-size: 36px !important;
    width: 36px !important;
    height: 36px !important;
    line-height: 36px !important; }
  md-icon[md-font-icon].s38,
  i.s38 {
    font-size: 38px !important;
    width: 38px !important;
    height: 38px !important;
    line-height: 38px !important; }
  md-icon[md-font-icon].s40,
  i.s40 {
    font-size: 40px !important;
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important; }
  md-icon[md-font-icon].s42,
  i.s42 {
    font-size: 42px !important;
    width: 42px !important;
    height: 42px !important;
    line-height: 42px !important; }
  md-icon[md-font-icon].s44,
  i.s44 {
    font-size: 44px !important;
    width: 44px !important;
    height: 44px !important;
    line-height: 44px !important; }
  md-icon[md-font-icon].s46,
  i.s46 {
    font-size: 46px !important;
    width: 46px !important;
    height: 46px !important;
    line-height: 46px !important; }
  md-icon[md-font-icon].s48,
  i.s48 {
    font-size: 48px !important;
    width: 48px !important;
    height: 48px !important;
    line-height: 48px !important; }
  md-icon[md-font-icon].s50,
  i.s50 {
    font-size: 50px !important;
    width: 50px !important;
    height: 50px !important;
    line-height: 50px !important; }
  md-icon[md-font-icon].s52,
  i.s52 {
    font-size: 52px !important;
    width: 52px !important;
    height: 52px !important;
    line-height: 52px !important; }
  md-icon[md-font-icon].s54,
  i.s54 {
    font-size: 54px !important;
    width: 54px !important;
    height: 54px !important;
    line-height: 54px !important; }
  md-icon[md-font-icon].s56,
  i.s56 {
    font-size: 56px !important;
    width: 56px !important;
    height: 56px !important;
    line-height: 56px !important; }
  md-icon[md-font-icon].s58,
  i.s58 {
    font-size: 58px !important;
    width: 58px !important;
    height: 58px !important;
    line-height: 58px !important; }
  md-icon[md-font-icon].s60,
  i.s60 {
    font-size: 60px !important;
    width: 60px !important;
    height: 60px !important;
    line-height: 60px !important; }
  md-icon[md-font-icon].s62,
  i.s62 {
    font-size: 62px !important;
    width: 62px !important;
    height: 62px !important;
    line-height: 62px !important; }
  md-icon[md-font-icon].s64,
  i.s64 {
    font-size: 64px !important;
    width: 64px !important;
    height: 64px !important;
    line-height: 64px !important; }
  md-icon[md-font-icon].s66,
  i.s66 {
    font-size: 66px !important;
    width: 66px !important;
    height: 66px !important;
    line-height: 66px !important; }
  md-icon[md-font-icon].s68,
  i.s68 {
    font-size: 68px !important;
    width: 68px !important;
    height: 68px !important;
    line-height: 68px !important; }
  md-icon[md-font-icon].s70,
  i.s70 {
    font-size: 70px !important;
    width: 70px !important;
    height: 70px !important;
    line-height: 70px !important; }
  md-icon[md-font-icon].s72,
  i.s72 {
    font-size: 72px !important;
    width: 72px !important;
    height: 72px !important;
    line-height: 72px !important; }
  md-icon[md-font-icon].s74,
  i.s74 {
    font-size: 74px !important;
    width: 74px !important;
    height: 74px !important;
    line-height: 74px !important; }
  md-icon[md-font-icon].s76,
  i.s76 {
    font-size: 76px !important;
    width: 76px !important;
    height: 76px !important;
    line-height: 76px !important; }
  md-icon[md-font-icon].s78,
  i.s78 {
    font-size: 78px !important;
    width: 78px !important;
    height: 78px !important;
    line-height: 78px !important; }
  md-icon[md-font-icon].s80,
  i.s80 {
    font-size: 80px !important;
    width: 80px !important;
    height: 80px !important;
    line-height: 80px !important; }
  md-icon[md-font-icon].s82,
  i.s82 {
    font-size: 82px !important;
    width: 82px !important;
    height: 82px !important;
    line-height: 82px !important; }
  md-icon[md-font-icon].s84,
  i.s84 {
    font-size: 84px !important;
    width: 84px !important;
    height: 84px !important;
    line-height: 84px !important; }
  md-icon[md-font-icon].s86,
  i.s86 {
    font-size: 86px !important;
    width: 86px !important;
    height: 86px !important;
    line-height: 86px !important; }
  md-icon[md-font-icon].s88,
  i.s88 {
    font-size: 88px !important;
    width: 88px !important;
    height: 88px !important;
    line-height: 88px !important; }
  md-icon[md-font-icon].s90,
  i.s90 {
    font-size: 90px !important;
    width: 90px !important;
    height: 90px !important;
    line-height: 90px !important; }
  md-icon[md-font-icon].s92,
  i.s92 {
    font-size: 92px !important;
    width: 92px !important;
    height: 92px !important;
    line-height: 92px !important; }
  md-icon[md-font-icon].s94,
  i.s94 {
    font-size: 94px !important;
    width: 94px !important;
    height: 94px !important;
    line-height: 94px !important; }
  md-icon[md-font-icon].s96,
  i.s96 {
    font-size: 96px !important;
    width: 96px !important;
    height: 96px !important;
    line-height: 96px !important; }
  md-icon[md-font-icon].s98,
  i.s98 {
    font-size: 98px !important;
    width: 98px !important;
    height: 98px !important;
    line-height: 98px !important; }
  md-icon[md-font-icon].s100,
  i.s100 {
    font-size: 100px !important;
    width: 100px !important;
    height: 100px !important;
    line-height: 100px !important; }
  md-icon[md-font-icon].s102,
  i.s102 {
    font-size: 102px !important;
    width: 102px !important;
    height: 102px !important;
    line-height: 102px !important; }
  md-icon[md-font-icon].s104,
  i.s104 {
    font-size: 104px !important;
    width: 104px !important;
    height: 104px !important;
    line-height: 104px !important; }
  md-icon[md-font-icon].s106,
  i.s106 {
    font-size: 106px !important;
    width: 106px !important;
    height: 106px !important;
    line-height: 106px !important; }
  md-icon[md-font-icon].s108,
  i.s108 {
    font-size: 108px !important;
    width: 108px !important;
    height: 108px !important;
    line-height: 108px !important; }
  md-icon[md-font-icon].s110,
  i.s110 {
    font-size: 110px !important;
    width: 110px !important;
    height: 110px !important;
    line-height: 110px !important; }
  md-icon[md-font-icon].s112,
  i.s112 {
    font-size: 112px !important;
    width: 112px !important;
    height: 112px !important;
    line-height: 112px !important; }
  md-icon[md-font-icon].s114,
  i.s114 {
    font-size: 114px !important;
    width: 114px !important;
    height: 114px !important;
    line-height: 114px !important; }
  md-icon[md-font-icon].s116,
  i.s116 {
    font-size: 116px !important;
    width: 116px !important;
    height: 116px !important;
    line-height: 116px !important; }
  md-icon[md-font-icon].s118,
  i.s118 {
    font-size: 118px !important;
    width: 118px !important;
    height: 118px !important;
    line-height: 118px !important; }
  md-icon[md-font-icon].s120,
  i.s120 {
    font-size: 120px !important;
    width: 120px !important;
    height: 120px !important;
    line-height: 120px !important; }
  md-icon[md-font-icon].s122,
  i.s122 {
    font-size: 122px !important;
    width: 122px !important;
    height: 122px !important;
    line-height: 122px !important; }
  md-icon[md-font-icon].s124,
  i.s124 {
    font-size: 124px !important;
    width: 124px !important;
    height: 124px !important;
    line-height: 124px !important; }
  md-icon[md-font-icon].s126,
  i.s126 {
    font-size: 126px !important;
    width: 126px !important;
    height: 126px !important;
    line-height: 126px !important; }
  md-icon[md-font-icon].s128,
  i.s128 {
    font-size: 128px !important;
    width: 128px !important;
    height: 128px !important;
    line-height: 128px !important; }
  md-icon[md-font-icon].s130,
  i.s130 {
    font-size: 130px !important;
    width: 130px !important;
    height: 130px !important;
    line-height: 130px !important; }
  md-icon[md-font-icon].s132,
  i.s132 {
    font-size: 132px !important;
    width: 132px !important;
    height: 132px !important;
    line-height: 132px !important; }
  md-icon[md-font-icon].s134,
  i.s134 {
    font-size: 134px !important;
    width: 134px !important;
    height: 134px !important;
    line-height: 134px !important; }
  md-icon[md-font-icon].s136,
  i.s136 {
    font-size: 136px !important;
    width: 136px !important;
    height: 136px !important;
    line-height: 136px !important; }
  md-icon[md-font-icon].s138,
  i.s138 {
    font-size: 138px !important;
    width: 138px !important;
    height: 138px !important;
    line-height: 138px !important; }
  md-icon[md-font-icon].s140,
  i.s140 {
    font-size: 140px !important;
    width: 140px !important;
    height: 140px !important;
    line-height: 140px !important; }
  md-icon[md-font-icon].s142,
  i.s142 {
    font-size: 142px !important;
    width: 142px !important;
    height: 142px !important;
    line-height: 142px !important; }
  md-icon[md-font-icon].s144,
  i.s144 {
    font-size: 144px !important;
    width: 144px !important;
    height: 144px !important;
    line-height: 144px !important; }
  md-icon[md-font-icon].s146,
  i.s146 {
    font-size: 146px !important;
    width: 146px !important;
    height: 146px !important;
    line-height: 146px !important; }
  md-icon[md-font-icon].s148,
  i.s148 {
    font-size: 148px !important;
    width: 148px !important;
    height: 148px !important;
    line-height: 148px !important; }
  md-icon[md-font-icon].s150,
  i.s150 {
    font-size: 150px !important;
    width: 150px !important;
    height: 150px !important;
    line-height: 150px !important; }
  md-icon[md-font-icon].s152,
  i.s152 {
    font-size: 152px !important;
    width: 152px !important;
    height: 152px !important;
    line-height: 152px !important; }
  md-icon[md-font-icon].s154,
  i.s154 {
    font-size: 154px !important;
    width: 154px !important;
    height: 154px !important;
    line-height: 154px !important; }
  md-icon[md-font-icon].s156,
  i.s156 {
    font-size: 156px !important;
    width: 156px !important;
    height: 156px !important;
    line-height: 156px !important; }
  md-icon[md-font-icon].s158,
  i.s158 {
    font-size: 158px !important;
    width: 158px !important;
    height: 158px !important;
    line-height: 158px !important; }
  md-icon[md-font-icon].s160,
  i.s160 {
    font-size: 160px !important;
    width: 160px !important;
    height: 160px !important;
    line-height: 160px !important; }
  md-icon[md-font-icon].s162,
  i.s162 {
    font-size: 162px !important;
    width: 162px !important;
    height: 162px !important;
    line-height: 162px !important; }
  md-icon[md-font-icon].s164,
  i.s164 {
    font-size: 164px !important;
    width: 164px !important;
    height: 164px !important;
    line-height: 164px !important; }
  md-icon[md-font-icon].s166,
  i.s166 {
    font-size: 166px !important;
    width: 166px !important;
    height: 166px !important;
    line-height: 166px !important; }
  md-icon[md-font-icon].s168,
  i.s168 {
    font-size: 168px !important;
    width: 168px !important;
    height: 168px !important;
    line-height: 168px !important; }
  md-icon[md-font-icon].s170,
  i.s170 {
    font-size: 170px !important;
    width: 170px !important;
    height: 170px !important;
    line-height: 170px !important; }
  md-icon[md-font-icon].s172,
  i.s172 {
    font-size: 172px !important;
    width: 172px !important;
    height: 172px !important;
    line-height: 172px !important; }
  md-icon[md-font-icon].s174,
  i.s174 {
    font-size: 174px !important;
    width: 174px !important;
    height: 174px !important;
    line-height: 174px !important; }
  md-icon[md-font-icon].s176,
  i.s176 {
    font-size: 176px !important;
    width: 176px !important;
    height: 176px !important;
    line-height: 176px !important; }
  md-icon[md-font-icon].s178,
  i.s178 {
    font-size: 178px !important;
    width: 178px !important;
    height: 178px !important;
    line-height: 178px !important; }
  md-icon[md-font-icon].s180,
  i.s180 {
    font-size: 180px !important;
    width: 180px !important;
    height: 180px !important;
    line-height: 180px !important; }
  md-icon[md-font-icon].s182,
  i.s182 {
    font-size: 182px !important;
    width: 182px !important;
    height: 182px !important;
    line-height: 182px !important; }
  md-icon[md-font-icon].s184,
  i.s184 {
    font-size: 184px !important;
    width: 184px !important;
    height: 184px !important;
    line-height: 184px !important; }
  md-icon[md-font-icon].s186,
  i.s186 {
    font-size: 186px !important;
    width: 186px !important;
    height: 186px !important;
    line-height: 186px !important; }
  md-icon[md-font-icon].s188,
  i.s188 {
    font-size: 188px !important;
    width: 188px !important;
    height: 188px !important;
    line-height: 188px !important; }
  md-icon[md-font-icon].s190,
  i.s190 {
    font-size: 190px !important;
    width: 190px !important;
    height: 190px !important;
    line-height: 190px !important; }
  md-icon[md-font-icon].s192,
  i.s192 {
    font-size: 192px !important;
    width: 192px !important;
    height: 192px !important;
    line-height: 192px !important; }
  md-icon[md-font-icon].s194,
  i.s194 {
    font-size: 194px !important;
    width: 194px !important;
    height: 194px !important;
    line-height: 194px !important; }
  md-icon[md-font-icon].s196,
  i.s196 {
    font-size: 196px !important;
    width: 196px !important;
    height: 196px !important;
    line-height: 196px !important; }
  md-icon[md-font-icon].s198,
  i.s198 {
    font-size: 198px !important;
    width: 198px !important;
    height: 198px !important;
    line-height: 198px !important; }
  md-icon[md-font-icon].s200,
  i.s200 {
    font-size: 200px !important;
    width: 200px !important;
    height: 200px !important;
    line-height: 200px !important; }
  md-icon[md-font-icon].s202,
  i.s202 {
    font-size: 202px !important;
    width: 202px !important;
    height: 202px !important;
    line-height: 202px !important; }
  md-icon[md-font-icon].s204,
  i.s204 {
    font-size: 204px !important;
    width: 204px !important;
    height: 204px !important;
    line-height: 204px !important; }
  md-icon[md-font-icon].s206,
  i.s206 {
    font-size: 206px !important;
    width: 206px !important;
    height: 206px !important;
    line-height: 206px !important; }
  md-icon[md-font-icon].s208,
  i.s208 {
    font-size: 208px !important;
    width: 208px !important;
    height: 208px !important;
    line-height: 208px !important; }
  md-icon[md-font-icon].s210,
  i.s210 {
    font-size: 210px !important;
    width: 210px !important;
    height: 210px !important;
    line-height: 210px !important; }
  md-icon[md-font-icon].s212,
  i.s212 {
    font-size: 212px !important;
    width: 212px !important;
    height: 212px !important;
    line-height: 212px !important; }
  md-icon[md-font-icon].s214,
  i.s214 {
    font-size: 214px !important;
    width: 214px !important;
    height: 214px !important;
    line-height: 214px !important; }
  md-icon[md-font-icon].s216,
  i.s216 {
    font-size: 216px !important;
    width: 216px !important;
    height: 216px !important;
    line-height: 216px !important; }
  md-icon[md-font-icon].s218,
  i.s218 {
    font-size: 218px !important;
    width: 218px !important;
    height: 218px !important;
    line-height: 218px !important; }
  md-icon[md-font-icon].s220,
  i.s220 {
    font-size: 220px !important;
    width: 220px !important;
    height: 220px !important;
    line-height: 220px !important; }
  md-icon[md-font-icon].s222,
  i.s222 {
    font-size: 222px !important;
    width: 222px !important;
    height: 222px !important;
    line-height: 222px !important; }
  md-icon[md-font-icon].s224,
  i.s224 {
    font-size: 224px !important;
    width: 224px !important;
    height: 224px !important;
    line-height: 224px !important; }
  md-icon[md-font-icon].s226,
  i.s226 {
    font-size: 226px !important;
    width: 226px !important;
    height: 226px !important;
    line-height: 226px !important; }
  md-icon[md-font-icon].s228,
  i.s228 {
    font-size: 228px !important;
    width: 228px !important;
    height: 228px !important;
    line-height: 228px !important; }
  md-icon[md-font-icon].s230,
  i.s230 {
    font-size: 230px !important;
    width: 230px !important;
    height: 230px !important;
    line-height: 230px !important; }
  md-icon[md-font-icon].s232,
  i.s232 {
    font-size: 232px !important;
    width: 232px !important;
    height: 232px !important;
    line-height: 232px !important; }
  md-icon[md-font-icon].s234,
  i.s234 {
    font-size: 234px !important;
    width: 234px !important;
    height: 234px !important;
    line-height: 234px !important; }
  md-icon[md-font-icon].s236,
  i.s236 {
    font-size: 236px !important;
    width: 236px !important;
    height: 236px !important;
    line-height: 236px !important; }
  md-icon[md-font-icon].s238,
  i.s238 {
    font-size: 238px !important;
    width: 238px !important;
    height: 238px !important;
    line-height: 238px !important; }
  md-icon[md-font-icon].s240,
  i.s240 {
    font-size: 240px !important;
    width: 240px !important;
    height: 240px !important;
    line-height: 240px !important; }
  md-icon[md-font-icon].s242,
  i.s242 {
    font-size: 242px !important;
    width: 242px !important;
    height: 242px !important;
    line-height: 242px !important; }
  md-icon[md-font-icon].s244,
  i.s244 {
    font-size: 244px !important;
    width: 244px !important;
    height: 244px !important;
    line-height: 244px !important; }
  md-icon[md-font-icon].s246,
  i.s246 {
    font-size: 246px !important;
    width: 246px !important;
    height: 246px !important;
    line-height: 246px !important; }
  md-icon[md-font-icon].s248,
  i.s248 {
    font-size: 248px !important;
    width: 248px !important;
    height: 248px !important;
    line-height: 248px !important; }
  md-icon[md-font-icon].s250,
  i.s250 {
    font-size: 250px !important;
    width: 250px !important;
    height: 250px !important;
    line-height: 250px !important; }
  md-icon[md-font-icon].s252,
  i.s252 {
    font-size: 252px !important;
    width: 252px !important;
    height: 252px !important;
    line-height: 252px !important; }
  md-icon[md-font-icon].s254,
  i.s254 {
    font-size: 254px !important;
    width: 254px !important;
    height: 254px !important;
    line-height: 254px !important; }
  md-icon[md-font-icon].s256,
  i.s256 {
    font-size: 256px !important;
    width: 256px !important;
    height: 256px !important;
    line-height: 256px !important; }

@font-face {
  font-family: 'icomoon';
  src: url("../assets/icons/fonts/icomoon.eot?5km1tg");
  src: url("../assets/icons/fonts/icomoon.eot?#iefix5km1tg") format("embedded-opentype"), url("../assets/icons/fonts/icomoon.ttf?5km1tg") format("truetype"), url("../assets/icons/fonts/icomoon.woff?5km1tg") format("woff"), url("../assets/icons/fonts/icomoon.svg?5km1tg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-account-alert:before {
  content: "\e600"; }

.icon-account-box-outline:before {
  content: "\e601"; }

.icon-account-box:before {
  content: "\e602"; }

.icon-account-check:before {
  content: "\e603"; }

.icon-account-circle:before {
  content: "\e604"; }

.icon-account-key:before {
  content: "\e605"; }

.icon-account-location:before {
  content: "\e606"; }

.icon-account-minus:before {
  content: "\e607"; }

.icon-account-multiple-outline:before {
  content: "\e608"; }

.icon-account-multiple-plus:before {
  content: "\e609"; }

.icon-account-multiple:before {
  content: "\e60a"; }

.icon-account-network:before {
  content: "\e60b"; }

.icon-account-outline:before {
  content: "\e60c"; }

.icon-account-plus:before {
  content: "\e60d"; }

.icon-account-remove:before {
  content: "\e60e"; }

.icon-account-search:before {
  content: "\e60f"; }

.icon-account-switch:before {
  content: "\e610"; }

.icon-account:before {
  content: "\e611"; }

.icon-airballoon:before {
  content: "\e612"; }

.icon-airplane-off:before {
  content: "\e613"; }

.icon-airplane:before {
  content: "\e614"; }

.icon-alarm-check:before {
  content: "\e615"; }

.icon-alarm-multiple:before {
  content: "\e616"; }

.icon-alarm-off:before {
  content: "\e617"; }

.icon-alarm-plus:before {
  content: "\e618"; }

.icon-alarm:before {
  content: "\e619"; }

.icon-album:before {
  content: "\e61a"; }

.icon-alert-box:before {
  content: "\e61b"; }

.icon-alert-circle:before {
  content: "\e61c"; }

.icon-alert-octagon:before {
  content: "\e61d"; }

.icon-alert:before {
  content: "\e61e"; }

.icon-alpha:before {
  content: "\e61f"; }

.icon-alphabetical:before {
  content: "\e620"; }

.icon-amazon-clouddrive:before {
  content: "\e621"; }

.icon-amazon:before {
  content: "\e622"; }

.icon-ambulance:before {
  content: "\e623"; }

.icon-android-debug-bridge:before {
  content: "\e624"; }

.icon-android-studio:before {
  content: "\e625"; }

.icon-android:before {
  content: "\e626"; }

.icon-apple-finder:before {
  content: "\e627"; }

.icon-apple-ios:before {
  content: "\e628"; }

.icon-apple-mobileme:before {
  content: "\e629"; }

.icon-apple-safari:before {
  content: "\e62a"; }

.icon-apple:before {
  content: "\e62b"; }

.icon-appnet:before {
  content: "\e62c"; }

.icon-apps:before {
  content: "\e62d"; }

.icon-archive:before {
  content: "\e62e"; }

.icon-arrange-bring-forward:before {
  content: "\e62f"; }

.icon-arrange-bring-to-front:before {
  content: "\e630"; }

.icon-arrange-send-backward:before {
  content: "\e631"; }

.icon-arrange-send-to-back:before {
  content: "\e632"; }

.icon-arrow-all:before {
  content: "\e633"; }

.icon-arrow-bottom-left:before {
  content: "\e634"; }

.icon-arrow-bottom-right:before {
  content: "\e635"; }

.icon-arrow-collapse:before {
  content: "\e636"; }

.icon-arrow-down-bold-circle-outline:before {
  content: "\e637"; }

.icon-arrow-down-bold-circle:before {
  content: "\e638"; }

.icon-arrow-down-bold-hexagon-outline:before {
  content: "\e639"; }

.icon-arrow-down-bold:before {
  content: "\e63a"; }

.icon-arrow-down:before {
  content: "\e63b"; }

.icon-arrow-expand:before {
  content: "\e63c"; }

.icon-arrow-left-bold-circle-outline:before {
  content: "\e63d"; }

.icon-arrow-left-bold-circle:before {
  content: "\e63e"; }

.icon-arrow-left-bold-hexagon-outline:before {
  content: "\e63f"; }

.icon-arrow-left-bold:before {
  content: "\e640"; }

.icon-arrow-left:before {
  content: "\e641"; }

.icon-arrow-right-bold-circle-outline:before {
  content: "\e642"; }

.icon-arrow-right-bold-circle:before {
  content: "\e643"; }

.icon-arrow-right-bold-hexagon-outline:before {
  content: "\e644"; }

.icon-arrow-right-bold:before {
  content: "\e645"; }

.icon-arrow-right:before {
  content: "\e646"; }

.icon-arrow-top-left:before {
  content: "\e647"; }

.icon-arrow-top-right:before {
  content: "\e648"; }

.icon-arrow-up-bold-circle-outline:before {
  content: "\e649"; }

.icon-arrow-up-bold-circle:before {
  content: "\e64a"; }

.icon-arrow-up-bold-hexagon-outline:before {
  content: "\e64b"; }

.icon-arrow-up-bold:before {
  content: "\e64c"; }

.icon-arrow-up:before {
  content: "\e64d"; }

.icon-at:before {
  content: "\e64e"; }

.icon-attachment:before {
  content: "\e64f"; }

.icon-audiobook:before {
  content: "\e650"; }

.icon-auto-fix:before {
  content: "\e651"; }

.icon-auto-upload:before {
  content: "\e652"; }

.icon-baby:before {
  content: "\e653"; }

.icon-backburger:before {
  content: "\e654"; }

.icon-backup-restore:before {
  content: "\e655"; }

.icon-bank:before {
  content: "\e656"; }

.icon-barcode:before {
  content: "\e657"; }

.icon-barley:before {
  content: "\e658"; }

.icon-barrel:before {
  content: "\e659"; }

.icon-basecamp:before {
  content: "\e65a"; }

.icon-basket-fill:before {
  content: "\e65b"; }

.icon-basket-unfill:before {
  content: "\e65c"; }

.icon-basket:before {
  content: "\e65d"; }

.icon-battery-10:before {
  content: "\e65e"; }

.icon-battery-20:before {
  content: "\e65f"; }

.icon-battery-30:before {
  content: "\e660"; }

.icon-battery-40:before {
  content: "\e661"; }

.icon-battery-50:before {
  content: "\e662"; }

.icon-battery-60:before {
  content: "\e663"; }

.icon-battery-70:before {
  content: "\e664"; }

.icon-battery-80:before {
  content: "\e665"; }

.icon-battery-90:before {
  content: "\e666"; }

.icon-battery-alert:before {
  content: "\e667"; }

.icon-battery-charging-20:before {
  content: "\e668"; }

.icon-battery-charging-30:before {
  content: "\e669"; }

.icon-battery-charging-40:before {
  content: "\e66a"; }

.icon-battery-charging-60:before {
  content: "\e66b"; }

.icon-battery-charging-80:before {
  content: "\e66c"; }

.icon-battery-charging-90:before {
  content: "\e66d"; }

.icon-battery-charging-100:before {
  content: "\e66e"; }

.icon-battery-minus:before {
  content: "\e66f"; }

.icon-battery-negative:before {
  content: "\e670"; }

.icon-battery-outline:before {
  content: "\e671"; }

.icon-battery-plus:before {
  content: "\e672"; }

.icon-battery-positive:before {
  content: "\e673"; }

.icon-battery-standard:before {
  content: "\e674"; }

.icon-battery-unknown:before {
  content: "\e675"; }

.icon-battery:before {
  content: "\e676"; }

.icon-beach:before {
  content: "\e677"; }

.icon-beaker-empty-outline:before {
  content: "\e678"; }

.icon-beaker-empty:before {
  content: "\e679"; }

.icon-beaker-outline:before {
  content: "\e67a"; }

.icon-beaker:before {
  content: "\e67b"; }

.icon-beats:before {
  content: "\e67c"; }

.icon-beer:before {
  content: "\e67d"; }

.icon-behance:before {
  content: "\e67e"; }

.icon-bell-off:before {
  content: "\e67f"; }

.icon-bell-outline:before {
  content: "\e680"; }

.icon-bell-ring-outline:before {
  content: "\e681"; }

.icon-bell-ring:before {
  content: "\e682"; }

.icon-bell-sleep:before {
  content: "\e683"; }

.icon-bell:before {
  content: "\e684"; }

.icon-beta:before {
  content: "\e685"; }

.icon-bike:before {
  content: "\e686"; }

.icon-bing:before {
  content: "\e687"; }

.icon-binoculars:before {
  content: "\e688"; }

.icon-bio:before {
  content: "\e689"; }

.icon-bitbucket:before {
  content: "\e68a"; }

.icon-black-mesa:before {
  content: "\e68b"; }

.icon-blackberry:before {
  content: "\e68c"; }

.icon-blinds:before {
  content: "\e68d"; }

.icon-block-helper:before {
  content: "\e68e"; }

.icon-blogger:before {
  content: "\e68f"; }

.icon-bluetooth-audio:before {
  content: "\e690"; }

.icon-bluetooth-connect:before {
  content: "\e691"; }

.icon-bluetooth-settings:before {
  content: "\e692"; }

.icon-bluetooth-transfer:before {
  content: "\e693"; }

.icon-bluetooth:before {
  content: "\e694"; }

.icon-blur-linear:before {
  content: "\e695"; }

.icon-blur-off:before {
  content: "\e696"; }

.icon-blur-radial:before {
  content: "\e697"; }

.icon-blur:before {
  content: "\e698"; }

.icon-bone:before {
  content: "\e699"; }

.icon-book-multiple-variant:before {
  content: "\e69a"; }

.icon-book-multiple:before {
  content: "\e69b"; }

.icon-book-open:before {
  content: "\e69c"; }

.icon-book-variant:before {
  content: "\e69d"; }

.icon-book:before {
  content: "\e69e"; }

.icon-bookmark-check:before {
  content: "\e69f"; }

.icon-bookmark-music:before {
  content: "\e6a0"; }

.icon-bookmark-outline-plus:before {
  content: "\e6a1"; }

.icon-bookmark-outline:before {
  content: "\e6a2"; }

.icon-bookmark-plus:before {
  content: "\e6a3"; }

.icon-bookmark-remove:before {
  content: "\e6a4"; }

.icon-bookmark:before {
  content: "\e6a5"; }

.icon-border-all:before {
  content: "\e6a6"; }

.icon-border-bottom:before {
  content: "\e6a7"; }

.icon-border-color:before {
  content: "\e6a8"; }

.icon-border-horizontal:before {
  content: "\e6a9"; }

.icon-border-inside:before {
  content: "\e6aa"; }

.icon-border-left:before {
  content: "\e6ab"; }

.icon-border-none:before {
  content: "\e6ac"; }

.icon-border-outside:before {
  content: "\e6ad"; }

.icon-border-right:before {
  content: "\e6ae"; }

.icon-border-top:before {
  content: "\e6af"; }

.icon-border-vertical:before {
  content: "\e6b0"; }

.icon-bowling:before {
  content: "\e6b1"; }

.icon-box-download:before {
  content: "\e6b2"; }

.icon-box-upload:before {
  content: "\e6b3"; }

.icon-box:before {
  content: "\e6b4"; }

.icon-briefcase-checked:before {
  content: "\e6b5"; }

.icon-briefcase-download:before {
  content: "\e6b6"; }

.icon-briefcase-upload:before {
  content: "\e6b7"; }

.icon-briefcase:before {
  content: "\e6b8"; }

.icon-brightness-1:before {
  content: "\e6b9"; }

.icon-brightness-2:before {
  content: "\e6ba"; }

.icon-brightness-3:before {
  content: "\e6bb"; }

.icon-brightness-4:before {
  content: "\e6bc"; }

.icon-brightness-5:before {
  content: "\e6bd"; }

.icon-brightness-6:before {
  content: "\e6be"; }

.icon-brightness-7:before {
  content: "\e6bf"; }

.icon-brightness-auto:before {
  content: "\e6c0"; }

.icon-brightness:before {
  content: "\e6c1"; }

.icon-broom:before {
  content: "\e6c2"; }

.icon-brush:before {
  content: "\e6c3"; }

.icon-bug:before {
  content: "\e6c4"; }

.icon-bulletin-board:before {
  content: "\e6c5"; }

.icon-bullhorn:before {
  content: "\e6c6"; }

.icon-bus:before {
  content: "\e6c7"; }

.icon-cake-variant:before {
  content: "\e6c8"; }

.icon-cake:before {
  content: "\e6c9"; }

.icon-calculator:before {
  content: "\e6ca"; }

.icon-calendar-blank:before {
  content: "\e6cb"; }

.icon-calendar-check-multiple:before {
  content: "\e6cc"; }

.icon-calendar-check:before {
  content: "\e6cd"; }

.icon-calendar-clock:before {
  content: "\e6ce"; }

.icon-calendar-multiple:before {
  content: "\e6cf"; }

.icon-calendar-plus:before {
  content: "\e6d0"; }

.icon-calendar-remove:before {
  content: "\e6d1"; }

.icon-calendar-select:before {
  content: "\e6d2"; }

.icon-calendar-text:before {
  content: "\e6d3"; }

.icon-calendar-today:before {
  content: "\e6d4"; }

.icon-calendar:before {
  content: "\e6d5"; }

.icon-camcorder-box-off:before {
  content: "\e6d6"; }

.icon-camcorder-box:before {
  content: "\e6d7"; }

.icon-camcorder-off:before {
  content: "\e6d8"; }

.icon-camcorder:before {
  content: "\e6d9"; }

.icon-camera-front-variant:before {
  content: "\e6da"; }

.icon-camera-front:before {
  content: "\e6db"; }

.icon-camera-iris:before {
  content: "\e6dc"; }

.icon-camera-party-mode:before {
  content: "\e6dd"; }

.icon-camera-rear-variant:before {
  content: "\e6de"; }

.icon-camera-rear:before {
  content: "\e6df"; }

.icon-camera-switch:before {
  content: "\e6e0"; }

.icon-camera-timer:before {
  content: "\e6e1"; }

.icon-camera:before {
  content: "\e6e2"; }

.icon-cancel:before {
  content: "\e6e3"; }

.icon-candycane:before {
  content: "\e6e4"; }

.icon-car-wash:before {
  content: "\e6e5"; }

.icon-car:before {
  content: "\e6e6"; }

.icon-carrot:before {
  content: "\e6e7"; }

.icon-cart-outline:before {
  content: "\e6e8"; }

.icon-cart:before {
  content: "\e6e9"; }

.icon-cash-100:before {
  content: "\e6ea"; }

.icon-cash-multiple:before {
  content: "\e6eb"; }

.icon-cash-usd:before {
  content: "\e6ec"; }

.icon-cash:before {
  content: "\e6ed"; }

.icon-cast-connected:before {
  content: "\e6ee"; }

.icon-cast:before {
  content: "\e6ef"; }

.icon-castle:before {
  content: "\e6f0"; }

.icon-cat:before {
  content: "\e6f1"; }

.icon-cellphone-android:before {
  content: "\e6f2"; }

.icon-cellphone-dock:before {
  content: "\e6f3"; }

.icon-cellphone-iphone:before {
  content: "\e6f4"; }

.icon-cellphone-link-off:before {
  content: "\e6f5"; }

.icon-cellphone-link:before {
  content: "\e6f6"; }

.icon-cellphone-settings:before {
  content: "\e6f7"; }

.icon-cellphone:before {
  content: "\e6f8"; }

.icon-chair-school:before {
  content: "\e6f9"; }

.icon-chart-arc:before {
  content: "\e6fa"; }

.icon-chart-areaspline:before {
  content: "\e6fb"; }

.icon-chart-bar:before {
  content: "\e6fc"; }

.icon-chart-histogram:before {
  content: "\e6fd"; }

.icon-chart-line:before {
  content: "\e6fe"; }

.icon-chart-pie:before {
  content: "\e6ff"; }

.icon-check-all:before {
  content: "\e700"; }

.icon-check-bookmark:before {
  content: "\e701"; }

.icon-check-circle:before {
  content: "\e702"; }

.icon-check:before {
  content: "\e703"; }

.icon-checkbox-blank-circle-outline:before {
  content: "\e704"; }

.icon-checkbox-blank-circle:before {
  content: "\e705"; }

.icon-checkbox-blank-outline:before {
  content: "\e706"; }

.icon-checkbox-blank:before {
  content: "\e707"; }

.icon-checkbox-marked-circle-outline:before {
  content: "\e708"; }

.icon-checkbox-marked-circle:before {
  content: "\e709"; }

.icon-checkbox-marked-outline:before {
  content: "\e70a"; }

.icon-checkbox-marked:before {
  content: "\e70b"; }

.icon-checkbox-multiple-blank-outline:before {
  content: "\e70c"; }

.icon-checkbox-multiple-blank:before {
  content: "\e70d"; }

.icon-checkbox-multiple-marked-outline:before {
  content: "\e70e"; }

.icon-checkbox-multiple-marked:before {
  content: "\e70f"; }

.icon-checkerboard:before {
  content: "\e710"; }

.icon-chevron-double-down:before {
  content: "\e711"; }

.icon-chevron-double-left:before {
  content: "\e712"; }

.icon-chevron-double-right:before {
  content: "\e713"; }

.icon-chevron-double-up:before {
  content: "\e714"; }

.icon-chevron-down:before {
  content: "\e715"; }

.icon-chevron-left:before {
  content: "\e716"; }

.icon-chevron-right:before {
  content: "\e717"; }

.icon-chevron-up:before {
  content: "\e718"; }

.icon-church:before {
  content: "\e719"; }

.icon-cisco-webex:before {
  content: "\e71a"; }

.icon-city:before {
  content: "\e71b"; }

.icon-clapperboard:before {
  content: "\e71c"; }

.icon-clipboard-account:before {
  content: "\e71d"; }

.icon-clipboard-alert:before {
  content: "\e71e"; }

.icon-clipboard-arrow-down:before {
  content: "\e71f"; }

.icon-clipboard-arrow-left:before {
  content: "\e720"; }

.icon-clipboard-check:before {
  content: "\e721"; }

.icon-clipboard-outline:before {
  content: "\e722"; }

.icon-clipboard-text:before {
  content: "\e723"; }

.icon-clipboard:before {
  content: "\e724"; }

.icon-clippy:before {
  content: "\e725"; }

.icon-clock-fast:before {
  content: "\e726"; }

.icon-clock:before {
  content: "\e727"; }

.icon-close-circle-outline:before {
  content: "\e728"; }

.icon-close-circle:before {
  content: "\e729"; }

.icon-close-network:before {
  content: "\e72a"; }

.icon-close:before {
  content: "\e72b"; }

.icon-closed-caption:before {
  content: "\e72c"; }

.icon-cloud-check:before {
  content: "\e72d"; }

.icon-cloud-circle:before {
  content: "\e72e"; }

.icon-cloud-download:before {
  content: "\e72f"; }

.icon-cloud-outline-off:before {
  content: "\e730"; }

.icon-cloud-outline:before {
  content: "\e731"; }

.icon-cloud-upload:before {
  content: "\e732"; }

.icon-cloud:before {
  content: "\e733"; }

.icon-code-array:before {
  content: "\e734"; }

.icon-code-braces:before {
  content: "\e735"; }

.icon-code-equal:before {
  content: "\e736"; }

.icon-code-greater-than-or-equal:before {
  content: "\e737"; }

.icon-code-greater-than:before {
  content: "\e738"; }

.icon-code-less-than-or-equal:before {
  content: "\e739"; }

.icon-code-less-than:before {
  content: "\e73a"; }

.icon-code-not-equal-variant:before {
  content: "\e73b"; }

.icon-code-not-equal:before {
  content: "\e73c"; }

.icon-code-string:before {
  content: "\e73d"; }

.icon-code-tags:before {
  content: "\e73e"; }

.icon-codepen:before {
  content: "\e73f"; }

.icon-coffee-to-go:before {
  content: "\e740"; }

.icon-coffee:before {
  content: "\e741"; }

.icon-cog-box:before {
  content: "\e742"; }

.icon-cog:before {
  content: "\e743"; }

.icon-coin:before {
  content: "\e744"; }

.icon-color-helper:before {
  content: "\e745"; }

.icon-comment-account-outline:before {
  content: "\e746"; }

.icon-comment-account:before {
  content: "\e747"; }

.icon-comment-alert-outline:before {
  content: "\e748"; }

.icon-comment-alert:before {
  content: "\e749"; }

.icon-comment-check-outline:before {
  content: "\e74a"; }

.icon-comment-check:before {
  content: "\e74b"; }

.icon-comment-multipe-outline:before {
  content: "\e74c"; }

.icon-comment-outline:before {
  content: "\e74d"; }

.icon-comment-plus-outline:before {
  content: "\e74e"; }

.icon-comment-processing-outline:before {
  content: "\e74f"; }

.icon-comment-processing:before {
  content: "\e750"; }

.icon-comment-remove-outline:before {
  content: "\e751"; }

.icon-comment-text-outline:before {
  content: "\e752"; }

.icon-comment-text:before {
  content: "\e753"; }

.icon-comment:before {
  content: "\e754"; }

.icon-compare:before {
  content: "\e755"; }

.icon-compass-outline:before {
  content: "\e756"; }

.icon-compass:before {
  content: "\e757"; }

.icon-console:before {
  content: "\e758"; }

.icon-content-copy:before {
  content: "\e759"; }

.icon-content-cut:before {
  content: "\e75a"; }

.icon-content-duplicate:before {
  content: "\e75b"; }

.icon-content-paste:before {
  content: "\e75c"; }

.icon-content-save-all:before {
  content: "\e75d"; }

.icon-content-save:before {
  content: "\e75e"; }

.icon-contrast-box:before {
  content: "\e75f"; }

.icon-contrast-circle:before {
  content: "\e760"; }

.icon-contrast:before {
  content: "\e761"; }

.icon-controller-xbox:before {
  content: "\e762"; }

.icon-cow:before {
  content: "\e763"; }

.icon-credit-card-multiple:before {
  content: "\e764"; }

.icon-credit-card:before {
  content: "\e765"; }

.icon-crop-free:before {
  content: "\e766"; }

.icon-crop-landscape:before {
  content: "\e767"; }

.icon-crop-portrait:before {
  content: "\e768"; }

.icon-crop-square:before {
  content: "\e769"; }

.icon-crop:before {
  content: "\e76a"; }

.icon-crosshairs-gps:before {
  content: "\e76b"; }

.icon-crosshairs:before {
  content: "\e76c"; }

.icon-crown:before {
  content: "\e76d"; }

.icon-cube-outline:before {
  content: "\e76e"; }

.icon-cube-unfolded:before {
  content: "\e76f"; }

.icon-cube:before {
  content: "\e770"; }

.icon-cup-water:before {
  content: "\e771"; }

.icon-cup:before {
  content: "\e772"; }

.icon-currency-btc:before {
  content: "\e773"; }

.icon-currency-eur:before {
  content: "\e774"; }

.icon-currency-gbp:before {
  content: "\e775"; }

.icon-currency-inr:before {
  content: "\e776"; }

.icon-currency-rub:before {
  content: "\e777"; }

.icon-currency-try:before {
  content: "\e778"; }

.icon-currency-usd:before {
  content: "\e779"; }

.icon-cursor-default-outline:before {
  content: "\e77a"; }

.icon-cursor-default:before {
  content: "\e77b"; }

.icon-cursor-move:before {
  content: "\e77c"; }

.icon-cursor-pointer:before {
  content: "\e77d"; }

.icon-data:before {
  content: "\e77e"; }

.icon-debug-step-into:before {
  content: "\e77f"; }

.icon-debug-step-out:before {
  content: "\e780"; }

.icon-debug-step-over:before {
  content: "\e781"; }

.icon-decimal-decrease:before {
  content: "\e782"; }

.icon-decimal-increase:before {
  content: "\e783"; }

.icon-delete-variant:before {
  content: "\e784"; }

.icon-delete:before {
  content: "\e785"; }

.icon-deskphone:before {
  content: "\e786"; }

.icon-desktop-mac:before {
  content: "\e787"; }

.icon-desktop-tower:before {
  content: "\e788"; }

.icon-details:before {
  content: "\e789"; }

.icon-deviantart:before {
  content: "\e78a"; }

.icon-diamond:before {
  content: "\e78b"; }

.icon-dice-1:before {
  content: "\e78c"; }

.icon-dice-2:before {
  content: "\e78d"; }

.icon-dice-3:before {
  content: "\e78e"; }

.icon-dice-4:before {
  content: "\e78f"; }

.icon-dice-5:before {
  content: "\e790"; }

.icon-dice-6:before {
  content: "\e791"; }

.icon-dice:before {
  content: "\e792"; }

.icon-directions:before {
  content: "\e793"; }

.icon-disk-alert:before {
  content: "\e794"; }

.icon-disqus-outline:before {
  content: "\e795"; }

.icon-disqus:before {
  content: "\e796"; }

.icon-division-box:before {
  content: "\e797"; }

.icon-division:before {
  content: "\e798"; }

.icon-dns:before {
  content: "\e799"; }

.icon-document:before {
  content: "\e79a"; }

.icon-domain:before {
  content: "\e79b"; }

.icon-dots-horizontal:before {
  content: "\e79c"; }

.icon-dots-vertical:before {
  content: "\e79d"; }

.icon-download:before {
  content: "\e79e"; }

.icon-drag-horizontal:before {
  content: "\e79f"; }

.icon-drag-vertical:before {
  content: "\e7a0"; }

.icon-drag:before {
  content: "\e7a1"; }

.icon-drawing-box:before {
  content: "\e7a2"; }

.icon-drawing:before {
  content: "\e7a3"; }

.icon-dribbble-box:before {
  content: "\e7a4"; }

.icon-dribbble:before {
  content: "\e7a5"; }

.icon-drone:before {
  content: "\e7a6"; }

.icon-dropbox:before {
  content: "\e7a7"; }

.icon-drupal:before {
  content: "\e7a8"; }

.icon-duck:before {
  content: "\e7a9"; }

.icon-dumbbell:before {
  content: "\e7aa"; }

.icon-earth-off:before {
  content: "\e7ab"; }

.icon-earth:before {
  content: "\e7ac"; }

.icon-ebay:before {
  content: "\e7ad"; }

.icon-edge:before {
  content: "\e7ae"; }

.icon-eject:before {
  content: "\e7af"; }

.icon-elevation-decline:before {
  content: "\e7b0"; }

.icon-elevation-rise:before {
  content: "\e7b1"; }

.icon-elevator:before {
  content: "\e7b2"; }

.icon-email-open:before {
  content: "\e7b3"; }

.icon-email-secure:before {
  content: "\e7b4"; }

.icon-email:before {
  content: "\e7b5"; }

.icon-emoticon-cool:before {
  content: "\e7b6"; }

.icon-emoticon-devil:before {
  content: "\e7b7"; }

.icon-emoticon-happy:before {
  content: "\e7b8"; }

.icon-emoticon-neutral:before {
  content: "\e7b9"; }

.icon-emoticon-poop:before {
  content: "\e7ba"; }

.icon-emoticon-sad:before {
  content: "\e7bb"; }

.icon-emoticon-tongue:before {
  content: "\e7bc"; }

.icon-emoticon:before {
  content: "\e7bd"; }

.icon-engine-outline:before {
  content: "\e7be"; }

.icon-engine:before {
  content: "\e7bf"; }

.icon-equal-box:before {
  content: "\e7c0"; }

.icon-equal:before {
  content: "\e7c1"; }

.icon-eraser:before {
  content: "\e7c2"; }

.icon-escalator:before {
  content: "\e7c3"; }

.icon-etsy:before {
  content: "\e7c4"; }

.icon-evernote:before {
  content: "\e7c5"; }

.icon-exclamation:before {
  content: "\e7c6"; }

.icon-exit-to-app:before {
  content: "\e7c7"; }

.icon-export:before {
  content: "\e7c8"; }

.icon-eye-off:before {
  content: "\e7c9"; }

.icon-eye:before {
  content: "\e7ca"; }

.icon-eyedropper-variant:before {
  content: "\e7cb"; }

.icon-eyedropper:before {
  content: "\e7cc"; }

.icon-facebook-box:before {
  content: "\e7cd"; }

.icon-facebook:before {
  content: "\e7ce"; }

.icon-factory:before {
  content: "\e7cf"; }

.icon-fan:before {
  content: "\e7d0"; }

.icon-fast-forward:before {
  content: "\e7d1"; }

.icon-ferry:before {
  content: "\e7d2"; }

.icon-file-cloud:before {
  content: "\e7d3"; }

.icon-file-delimited:before {
  content: "\e7d4"; }

.icon-file-document-box:before {
  content: "\e7d5"; }

.icon-file-document:before {
  content: "\e7d6"; }

.icon-file-excel-box:before {
  content: "\e7d7"; }

.icon-file-excel:before {
  content: "\e7d8"; }

.icon-file-find:before {
  content: "\e7d9"; }

.icon-file-hidden:before {
  content: "\e7da"; }

.icon-file-image-box:before {
  content: "\e7db"; }

.icon-file-image:before {
  content: "\e7dc"; }

.icon-file-multiple:before {
  content: "\e7dd"; }

.icon-file-music:before {
  content: "\e7de"; }

.icon-file-outline:before {
  content: "\e7df"; }

.icon-file-pdf-box:before {
  content: "\e7e0"; }

.icon-file-pdf:before {
  content: "\e7e1"; }

.icon-file-powerpoint-box:before {
  content: "\e7e2"; }

.icon-file-powerpoint:before {
  content: "\e7e3"; }

.icon-file-presentation-box:before {
  content: "\e7e4"; }

.icon-file-video:before {
  content: "\e7e5"; }

.icon-file-word-box:before {
  content: "\e7e6"; }

.icon-file-word:before {
  content: "\e7e7"; }

.icon-file-xml:before {
  content: "\e7e8"; }

.icon-file:before {
  content: "\e7e9"; }

.icon-fill:before {
  content: "\e7ea"; }

.icon-film:before {
  content: "\e7eb"; }

.icon-filmstrip-off:before {
  content: "\e7ec"; }

.icon-filmstrip:before {
  content: "\e7ed"; }

.icon-filter-outline:before {
  content: "\e7ee"; }

.icon-filter-remove-outline:before {
  content: "\e7ef"; }

.icon-filter-remove:before {
  content: "\e7f0"; }

.icon-filter-variant:before {
  content: "\e7f1"; }

.icon-filter:before {
  content: "\e7f2"; }

.icon-fire:before {
  content: "\e7f3"; }

.icon-fish:before {
  content: "\e7f4"; }

.icon-flag-checkered-variant:before {
  content: "\e7f5"; }

.icon-flag-checkered:before {
  content: "\e7f6"; }

.icon-flag-outline-variant:before {
  content: "\e7f7"; }

.icon-flag-outline:before {
  content: "\e7f8"; }

.icon-flag-triangle:before {
  content: "\e7f9"; }

.icon-flag-variant:before {
  content: "\e7fa"; }

.icon-flag:before {
  content: "\e7fb"; }

.icon-flash-auto:before {
  content: "\e7fc"; }

.icon-flash-off:before {
  content: "\e7fd"; }

.icon-flash:before {
  content: "\e7fe"; }

.icon-flashlight-off:before {
  content: "\e7ff"; }

.icon-flashlight:before {
  content: "\e800"; }

.icon-flattr:before {
  content: "\e801"; }

.icon-flickr-after:before {
  content: "\e802"; }

.icon-flickr-before:before {
  content: "\e803"; }

.icon-flip-to-back:before {
  content: "\e804"; }

.icon-flip-to-front:before {
  content: "\e805"; }

.icon-floppy:before {
  content: "\e806"; }

.icon-flower:before {
  content: "\e807"; }

.icon-folder-account:before {
  content: "\e808"; }

.icon-folder-download:before {
  content: "\e809"; }

.icon-folder-google-drive:before {
  content: "\e80a"; }

.icon-folder-image:before {
  content: "\e80b"; }

.icon-folder-lock-open:before {
  content: "\e80c"; }

.icon-folder-lock:before {
  content: "\e80d"; }

.icon-folder-move:before {
  content: "\e80e"; }

.icon-folder-multiple-image:before {
  content: "\e80f"; }

.icon-folder-multiple-outline:before {
  content: "\e810"; }

.icon-folder-multiple:before {
  content: "\e811"; }

.icon-folder-outline-lock:before {
  content: "\e812"; }

.icon-folder-outline:before {
  content: "\e813"; }

.icon-folder-plus:before {
  content: "\e814"; }

.icon-folder-remove:before {
  content: "\e815"; }

.icon-folder-upload:before {
  content: "\e816"; }

.icon-folder:before {
  content: "\e817"; }

.icon-food-apple:before {
  content: "\e818"; }

.icon-food-variant:before {
  content: "\e819"; }

.icon-food:before {
  content: "\e81a"; }

.icon-football-helmet:before {
  content: "\e81b"; }

.icon-football:before {
  content: "\e81c"; }

.icon-format-align-center:before {
  content: "\e81d"; }

.icon-format-align-justify:before {
  content: "\e81e"; }

.icon-format-align-left:before {
  content: "\e81f"; }

.icon-format-align-right:before {
  content: "\e820"; }

.icon-format-bold:before {
  content: "\e821"; }

.icon-format-clear:before {
  content: "\e822"; }

.icon-format-color-fill:before {
  content: "\e823"; }

.icon-format-color:before {
  content: "\e824"; }

.icon-format-float-center:before {
  content: "\e825"; }

.icon-format-float-left:before {
  content: "\e826"; }

.icon-format-float-none:before {
  content: "\e827"; }

.icon-format-float-right:before {
  content: "\e828"; }

.icon-format-header-1:before {
  content: "\e829"; }

.icon-format-header-2:before {
  content: "\e82a"; }

.icon-format-header-3:before {
  content: "\e82b"; }

.icon-format-header-4:before {
  content: "\e82c"; }

.icon-format-header-5:before {
  content: "\e82d"; }

.icon-format-header-6:before {
  content: "\e82e"; }

.icon-format-header-decrease:before {
  content: "\e82f"; }

.icon-format-header-down:before {
  content: "\e830"; }

.icon-format-header-equal:before {
  content: "\e831"; }

.icon-format-header-increase:before {
  content: "\e832"; }

.icon-format-header-pound:before {
  content: "\e833"; }

.icon-format-header-up:before {
  content: "\e834"; }

.icon-format-indent-decrease:before {
  content: "\e835"; }

.icon-format-indent-increase:before {
  content: "\e836"; }

.icon-format-italic:before {
  content: "\e837"; }

.icon-format-line-spacing:before {
  content: "\e838"; }

.icon-format-list-bulleted:before {
  content: "\e839"; }

.icon-format-list-numbers:before {
  content: "\e83a"; }

.icon-format-object-inline:before {
  content: "\e83b"; }

.icon-format-object-square:before {
  content: "\e83c"; }

.icon-format-object-tight:before {
  content: "\e83d"; }

.icon-format-object-top-bottom:before {
  content: "\e83e"; }

.icon-format-paint:before {
  content: "\e83f"; }

.icon-format-paragraph:before {
  content: "\e840"; }

.icon-format-quote:before {
  content: "\e841"; }

.icon-format-size:before {
  content: "\e842"; }

.icon-format-strikethrough:before {
  content: "\e843"; }

.icon-format-subscript:before {
  content: "\e844"; }

.icon-format-superscript:before {
  content: "\e845"; }

.icon-format-text:before {
  content: "\e846"; }

.icon-format-textdirection-l-to-r:before {
  content: "\e847"; }

.icon-format-textdirection-r-to-l:before {
  content: "\e848"; }

.icon-format-underline:before {
  content: "\e849"; }

.icon-forum:before {
  content: "\e84a"; }

.icon-forward:before {
  content: "\e84b"; }

.icon-foursquare:before {
  content: "\e84c"; }

.icon-fridge:before {
  content: "\e84d"; }

.icon-fullscreen-exit:before {
  content: "\e84e"; }

.icon-fullscreen:before {
  content: "\e84f"; }

.icon-function:before {
  content: "\e850"; }

.icon-gamepad-variant:before {
  content: "\e851"; }

.icon-gamepad:before {
  content: "\e852"; }

.icon-gas-station:before {
  content: "\e853"; }

.icon-gavel:before {
  content: "\e854"; }

.icon-gender-female:before {
  content: "\e855"; }

.icon-gender-male-female:before {
  content: "\e856"; }

.icon-gender-male:before {
  content: "\e857"; }

.icon-gender-transgender:before {
  content: "\e858"; }

.icon-gift:before {
  content: "\e859"; }

.icon-git:before {
  content: "\e85a"; }

.icon-github-box:before {
  content: "\e85b"; }

.icon-github:before {
  content: "\e85c"; }

.icon-glass-flute:before {
  content: "\e85d"; }

.icon-glass-mug:before {
  content: "\e85e"; }

.icon-glass-stange:before {
  content: "\e85f"; }

.icon-glass-tulip:before {
  content: "\e860"; }

.icon-glasses:before {
  content: "\e861"; }

.icon-gmail:before {
  content: "\e862"; }

.icon-google-chrome:before {
  content: "\e863"; }

.icon-google-circles-communities:before {
  content: "\e864"; }

.icon-google-circles-extended:before {
  content: "\e865"; }

.icon-google-circles-invite:before {
  content: "\e866"; }

.icon-google-circles:before {
  content: "\e867"; }

.icon-google-controller-off:before {
  content: "\e868"; }

.icon-google-controller:before {
  content: "\e869"; }

.icon-google-drive:before {
  content: "\e86a"; }

.icon-google-earth:before {
  content: "\e86b"; }

.icon-google-glass:before {
  content: "\e86c"; }

.icon-google-maps:before {
  content: "\e86d"; }

.icon-google-pages:before {
  content: "\e86e"; }

.icon-google-play:before {
  content: "\e86f"; }

.icon-google-plus-box:before {
  content: "\e870"; }

.icon-google-plus:before {
  content: "\e871"; }

.icon-google:before {
  content: "\e872"; }

.icon-grid-off:before {
  content: "\e873"; }

.icon-grid:before {
  content: "\e874"; }

.icon-group:before {
  content: "\e875"; }

.icon-hand-pointing-right:before {
  content: "\e876"; }

.icon-hanger:before {
  content: "\e877"; }

.icon-hangouts:before {
  content: "\e878"; }

.icon-harddisk:before {
  content: "\e879"; }

.icon-headphones-box:before {
  content: "\e87a"; }

.icon-headphones-dots:before {
  content: "\e87b"; }

.icon-headphones:before {
  content: "\e87c"; }

.icon-headset-dock:before {
  content: "\e87d"; }

.icon-headset-off:before {
  content: "\e87e"; }

.icon-headset:before {
  content: "\e87f"; }

.icon-heart-box-outline:before {
  content: "\e880"; }

.icon-heart-box:before {
  content: "\e881"; }

.icon-heart-broken:before {
  content: "\e882"; }

.icon-heart-outline:before {
  content: "\e883"; }

.icon-heart:before {
  content: "\e884"; }

.icon-help-circle:before {
  content: "\e885"; }

.icon-help:before {
  content: "\e886"; }

.icon-hexagon-outline:before {
  content: "\e887"; }

.icon-hexagon:before {
  content: "\e888"; }

.icon-history:before {
  content: "\e889"; }

.icon-hololens:before {
  content: "\e88a"; }

.icon-home-modern:before {
  content: "\e88b"; }

.icon-home-variant:before {
  content: "\e88c"; }

.icon-home:before {
  content: "\e88d"; }

.icon-hops:before {
  content: "\e88e"; }

.icon-hospital-building:before {
  content: "\e88f"; }

.icon-hospital-marker:before {
  content: "\e890"; }

.icon-hospital:before {
  content: "\e891"; }

.icon-hotel:before {
  content: "\e892"; }

.icon-houzz-box:before {
  content: "\e893"; }

.icon-human-child:before {
  content: "\e894"; }

.icon-human-male-female:before {
  content: "\e895"; }

.icon-human:before {
  content: "\e896"; }

.icon-image-album:before {
  content: "\e897"; }

.icon-image-area-close:before {
  content: "\e898"; }

.icon-image-area:before {
  content: "\e899"; }

.icon-image-broken:before {
  content: "\e89a"; }

.icon-image-filter-black-white:before {
  content: "\e89b"; }

.icon-image-filter-center-focus:before {
  content: "\e89c"; }

.icon-image-filter-drama:before {
  content: "\e89d"; }

.icon-image-filter-frames:before {
  content: "\e89e"; }

.icon-image-filter-hdr:before {
  content: "\e89f"; }

.icon-image-filter-none:before {
  content: "\e8a0"; }

.icon-image-filter-tilt-shift:before {
  content: "\e8a1"; }

.icon-image-filter-vintage:before {
  content: "\e8a2"; }

.icon-image-filter:before {
  content: "\e8a3"; }

.icon-image:before {
  content: "\e8a4"; }

.icon-import:before {
  content: "\e8a5"; }

.icon-inbox:before {
  content: "\e8a6"; }

.icon-indent:before {
  content: "\e8a7"; }

.icon-information-outline:before {
  content: "\e8a8"; }

.icon-information:before {
  content: "\e8a9"; }

.icon-instagram:before {
  content: "\e8aa"; }

.icon-instapaper:before {
  content: "\e8ab"; }

.icon-internet-explorer:before {
  content: "\e8ac"; }

.icon-invert-colors:before {
  content: "\e8ad"; }

.icon-jira:before {
  content: "\e8ae"; }

.icon-jsfiddle:before {
  content: "\e8af"; }

.icon-keg:before {
  content: "\e8b0"; }

.icon-key-change:before {
  content: "\e8b1"; }

.icon-key-minus:before {
  content: "\e8b2"; }

.icon-key-plus:before {
  content: "\e8b3"; }

.icon-key-remove:before {
  content: "\e8b4"; }

.icon-key-variant:before {
  content: "\e8b5"; }

.icon-key:before {
  content: "\e8b6"; }

.icon-keyboard-backspace:before {
  content: "\e8b7"; }

.icon-keyboard-caps:before {
  content: "\e8b8"; }

.icon-keyboard-close:before {
  content: "\e8b9"; }

.icon-keyboard-off:before {
  content: "\e8ba"; }

.icon-keyboard-return:before {
  content: "\e8bb"; }

.icon-keyboard-tab:before {
  content: "\e8bc"; }

.icon-keyboard-variant:before {
  content: "\e8bd"; }

.icon-keyboard:before {
  content: "\e8be"; }

.icon-label-outline:before {
  content: "\e8bf"; }

.icon-label:before {
  content: "\e8c0"; }

.icon-language-csharp:before {
  content: "\e8c1"; }

.icon-language-css3:before {
  content: "\e8c2"; }

.icon-language-html5:before {
  content: "\e8c3"; }

.icon-language-javascript:before {
  content: "\e8c4"; }

.icon-language-python-text:before {
  content: "\e8c5"; }

.icon-language-python:before {
  content: "\e8c6"; }

.icon-laptop-chromebook:before {
  content: "\e8c7"; }

.icon-laptop-mac:before {
  content: "\e8c8"; }

.icon-laptop-windows:before {
  content: "\e8c9"; }

.icon-laptop:before {
  content: "\e8ca"; }

.icon-lastfm:before {
  content: "\e8cb"; }

.icon-launch:before {
  content: "\e8cc"; }

.icon-layers-off:before {
  content: "\e8cd"; }

.icon-layers:before {
  content: "\e8ce"; }

.icon-leaf:before {
  content: "\e8cf"; }

.icon-library-books:before {
  content: "\e8d0"; }

.icon-library-music:before {
  content: "\e8d1"; }

.icon-library-plus:before {
  content: "\e8d2"; }

.icon-library:before {
  content: "\e8d3"; }

.icon-lightbulb-outline:before {
  content: "\e8d4"; }

.icon-lightbulb:before {
  content: "\e8d5"; }

.icon-link-off:before {
  content: "\e8d6"; }

.icon-link-variant-off:before {
  content: "\e8d7"; }

.icon-link-variant:before {
  content: "\e8d8"; }

.icon-link:before {
  content: "\e8d9"; }

.icon-linkedin-box:before {
  content: "\e8da"; }

.icon-linkedin:before {
  content: "\e8db"; }

.icon-linode:before {
  content: "\e8dc"; }

.icon-linux:before {
  content: "\e8dd"; }

.icon-lock-outline:before {
  content: "\e8de"; }

.icon-lock-unlocked-outline:before {
  content: "\e8df"; }

.icon-lock-unlocked:before {
  content: "\e8e0"; }

.icon-lock:before {
  content: "\e8e1"; }

.icon-login:before {
  content: "\e8e2"; }

.icon-logout:before {
  content: "\e8e3"; }

.icon-looks:before {
  content: "\e8e4"; }

.icon-loupe:before {
  content: "\e8e5"; }

.icon-lumx:before {
  content: "\e8e6"; }

.icon-magnet-on:before {
  content: "\e8e7"; }

.icon-magnet:before {
  content: "\e8e8"; }

.icon-magnify-minus:before {
  content: "\e8e9"; }

.icon-magnify-plus:before {
  content: "\e8ea"; }

.icon-magnify:before {
  content: "\e8eb"; }

.icon-mail-ru:before {
  content: "\e8ec"; }

.icon-map-marker-circle:before {
  content: "\e8ed"; }

.icon-map-marker-multiple:before {
  content: "\e8ee"; }

.icon-map-marker-off:before {
  content: "\e8ef"; }

.icon-map-marker-radius:before {
  content: "\e8f0"; }

.icon-map-marker:before {
  content: "\e8f1"; }

.icon-map:before {
  content: "\e8f2"; }

.icon-margin:before {
  content: "\e8f3"; }

.icon-markdown:before {
  content: "\e8f4"; }

.icon-marker-check:before {
  content: "\e8f5"; }

.icon-martini:before {
  content: "\e8f6"; }

.icon-material-ui:before {
  content: "\e8f7"; }

.icon-maxcdn:before {
  content: "\e8f8"; }

.icon-medium:before {
  content: "\e8f9"; }

.icon-memory:before {
  content: "\e8fa"; }

.icon-menu-down:before {
  content: "\e8fb"; }

.icon-menu-left:before {
  content: "\e8fc"; }

.icon-menu-right:before {
  content: "\e8fd"; }

.icon-menu-up:before {
  content: "\e8fe"; }

.icon-menu:before {
  content: "\e8ff"; }

.icon-message-alert:before {
  content: "\e900"; }

.icon-message-draw:before {
  content: "\e901"; }

.icon-message-image:before {
  content: "\e902"; }

.icon-message-processing:before {
  content: "\e903"; }

.icon-message-reply:before {
  content: "\e904"; }

.icon-message-text-outline:before {
  content: "\e905"; }

.icon-message-text:before {
  content: "\e906"; }

.icon-message-video:before {
  content: "\e907"; }

.icon-message:before {
  content: "\e908"; }

.icon-microphone-dots:before {
  content: "\e909"; }

.icon-microphone-off:before {
  content: "\e90a"; }

.icon-microphone-outline:before {
  content: "\e90b"; }

.icon-microphone-settings:before {
  content: "\e90c"; }

.icon-microphone-variant-off:before {
  content: "\e90d"; }

.icon-microphone-variant:before {
  content: "\e90e"; }

.icon-microphone:before {
  content: "\e90f"; }

.icon-minus-box:before {
  content: "\e910"; }

.icon-minus-circle-outline:before {
  content: "\e911"; }

.icon-minus-circle:before {
  content: "\e912"; }

.icon-minus-network:before {
  content: "\e913"; }

.icon-minus:before {
  content: "\e914"; }

.icon-monitor-multiple:before {
  content: "\e915"; }

.icon-monitor:before {
  content: "\e916"; }

.icon-more:before {
  content: "\e917"; }

.icon-mouse-off:before {
  content: "\e918"; }

.icon-mouse-variant-off:before {
  content: "\e919"; }

.icon-mouse-variant:before {
  content: "\e91a"; }

.icon-mouse:before {
  content: "\e91b"; }

.icon-movie:before {
  content: "\e91c"; }

.icon-multiplication-box:before {
  content: "\e91d"; }

.icon-multiplication:before {
  content: "\e91e"; }

.icon-music-box-outline:before {
  content: "\e91f"; }

.icon-music-box:before {
  content: "\e920"; }

.icon-music-circle:before {
  content: "\e921"; }

.icon-music-note-eighth:before {
  content: "\e922"; }

.icon-music-note-half:before {
  content: "\e923"; }

.icon-music-note-off:before {
  content: "\e924"; }

.icon-music-note-quarter:before {
  content: "\e925"; }

.icon-music-note-sixteenth:before {
  content: "\e926"; }

.icon-music-note-whole:before {
  content: "\e927"; }

.icon-music-note:before {
  content: "\e928"; }

.icon-nature-people:before {
  content: "\e929"; }

.icon-nature:before {
  content: "\e92a"; }

.icon-navigation:before {
  content: "\e92b"; }

.icon-needle:before {
  content: "\e92c"; }

.icon-nest-protect:before {
  content: "\e92d"; }

.icon-nest-thermostat:before {
  content: "\e92e"; }

.icon-newspaper:before {
  content: "\e92f"; }

.icon-nfc-tap:before {
  content: "\e930"; }

.icon-nfc-variant:before {
  content: "\e931"; }

.icon-nfc:before {
  content: "\e932"; }

.icon-no:before {
  content: "\e933"; }

.icon-not-equal:before {
  content: "\e934"; }

.icon-note-outline:before {
  content: "\e935"; }

.icon-note-text:before {
  content: "\e936"; }

.icon-note:before {
  content: "\e937"; }

.icon-numeric-0-box-multiple-outline:before {
  content: "\e938"; }

.icon-numeric-0-box-outline:before {
  content: "\e939"; }

.icon-numeric-0-box:before {
  content: "\e93a"; }

.icon-numeric-1-box-multiple-outline:before {
  content: "\e93b"; }

.icon-numeric-1-box-outline:before {
  content: "\e93c"; }

.icon-numeric-1-box:before {
  content: "\e93d"; }

.icon-numeric-2-box-multiple-outline:before {
  content: "\e93e"; }

.icon-numeric-2-box-outline:before {
  content: "\e93f"; }

.icon-numeric-2-box:before {
  content: "\e940"; }

.icon-numeric-3-box-multiple-outline:before {
  content: "\e941"; }

.icon-numeric-3-box-outline:before {
  content: "\e942"; }

.icon-numeric-3-box:before {
  content: "\e943"; }

.icon-numeric-4-box-multiple-outline:before {
  content: "\e944"; }

.icon-numeric-4-box-outline:before {
  content: "\e945"; }

.icon-numeric-4-box:before {
  content: "\e946"; }

.icon-numeric-5-box-multiple-outline:before {
  content: "\e947"; }

.icon-numeric-5-box-outline:before {
  content: "\e948"; }

.icon-numeric-5-box:before {
  content: "\e949"; }

.icon-numeric-6-box-multiple-outline:before {
  content: "\e94a"; }

.icon-numeric-6-box-outline:before {
  content: "\e94b"; }

.icon-numeric-6-box:before {
  content: "\e94c"; }

.icon-numeric-7-box-multiple-outline:before {
  content: "\e94d"; }

.icon-numeric-7-box-outline:before {
  content: "\e94e"; }

.icon-numeric-7-box:before {
  content: "\e94f"; }

.icon-numeric-8-box-multiple-outline:before {
  content: "\e950"; }

.icon-numeric-8-box-outline:before {
  content: "\e951"; }

.icon-numeric-8-box:before {
  content: "\e952"; }

.icon-numeric-9-box-multiple-outline:before {
  content: "\e953"; }

.icon-numeric-9-box-outline:before {
  content: "\e954"; }

.icon-numeric-9-box:before {
  content: "\e955"; }

.icon-numeric-9-plus-box-multiple-outline:before {
  content: "\e956"; }

.icon-numeric-9-plus-box-outline:before {
  content: "\e957"; }

.icon-numeric-9-plus-box:before {
  content: "\e958"; }

.icon-numeric:before {
  content: "\e959"; }

.icon-nutriton:before {
  content: "\e95a"; }

.icon-odnoklassniki:before {
  content: "\e95b"; }

.icon-office:before {
  content: "\e95c"; }

.icon-oil:before {
  content: "\e95d"; }

.icon-omega:before {
  content: "\e95e"; }

.icon-onedrive:before {
  content: "\e95f"; }

.icon-open-in-app:before {
  content: "\e960"; }

.icon-open-in-new:before {
  content: "\e961"; }

.icon-ornament-variant:before {
  content: "\e962"; }

.icon-ornament:before {
  content: "\e963"; }

.icon-outbox:before {
  content: "\e964"; }

.icon-outdent:before {
  content: "\e965"; }

.icon-owl:before {
  content: "\e966"; }

.icon-package-down:before {
  content: "\e967"; }

.icon-package-up:before {
  content: "\e968"; }

.icon-package-variant-closed:before {
  content: "\e969"; }

.icon-package-variant:before {
  content: "\e96a"; }

.icon-package:before {
  content: "\e96b"; }

.icon-palette-advanced:before {
  content: "\e96c"; }

.icon-palette:before {
  content: "\e96d"; }

.icon-panda:before {
  content: "\e96e"; }

.icon-pandora:before {
  content: "\e96f"; }

.icon-panorama-fisheye:before {
  content: "\e970"; }

.icon-panorama-horizontal:before {
  content: "\e971"; }

.icon-panorama-vertical:before {
  content: "\e972"; }

.icon-panorama-wide-angle:before {
  content: "\e973"; }

.icon-panorama:before {
  content: "\e974"; }

.icon-paper-cut-vertical:before {
  content: "\e975"; }

.icon-paperclip:before {
  content: "\e976"; }

.icon-parking:before {
  content: "\e977"; }

.icon-pause-circle-outline:before {
  content: "\e978"; }

.icon-pause-circle:before {
  content: "\e979"; }

.icon-pause-octagon-outline:before {
  content: "\e97a"; }

.icon-pause-octagon:before {
  content: "\e97b"; }

.icon-pause:before {
  content: "\e97c"; }

.icon-paw:before {
  content: "\e97d"; }

.icon-pen:before {
  content: "\e97e"; }

.icon-pencil-box-outline:before {
  content: "\e97f"; }

.icon-pencil-box:before {
  content: "\e980"; }

.icon-pencil:before {
  content: "\e981"; }

.icon-people:before {
  content: "\e982"; }

.icon-percent:before {
  content: "\e983"; }

.icon-person-box:before {
  content: "\e984"; }

.icon-person-minus:before {
  content: "\e985"; }

.icon-person-plus:before {
  content: "\e986"; }

.icon-pharmacy:before {
  content: "\e987"; }

.icon-phone-bluetooth:before {
  content: "\e988"; }

.icon-phone-dots:before {
  content: "\e989"; }

.icon-phone-forward:before {
  content: "\e98a"; }

.icon-phone-hangup:before {
  content: "\e98b"; }

.icon-phone-in-talk:before {
  content: "\e98c"; }

.icon-phone-incoming:before {
  content: "\e98d"; }

.icon-phone-locked:before {
  content: "\e98e"; }

.icon-phone-log:before {
  content: "\e98f"; }

.icon-phone-missed:before {
  content: "\e990"; }

.icon-phone-outgoing:before {
  content: "\e991"; }

.icon-phone-paused:before {
  content: "\e992"; }

.icon-phone:before {
  content: "\e993"; }

.icon-picture:before {
  content: "\e994"; }

.icon-pig:before {
  content: "\e995"; }

.icon-pill:before {
  content: "\e996"; }

.icon-pin-off:before {
  content: "\e997"; }

.icon-pin:before {
  content: "\e998"; }

.icon-pine-tree-box:before {
  content: "\e999"; }

.icon-pine-tree:before {
  content: "\e99a"; }

.icon-pinterest-box:before {
  content: "\e99b"; }

.icon-pinterest:before {
  content: "\e99c"; }

.icon-pizza:before {
  content: "\e99d"; }

.icon-plane:before {
  content: "\e99e"; }

.icon-play-box-outline:before {
  content: "\e99f"; }

.icon-play-circle-outline:before {
  content: "\e9a0"; }

.icon-play-circle:before {
  content: "\e9a1"; }

.icon-play:before {
  content: "\e9a2"; }

.icon-playlist-minus:before {
  content: "\e9a3"; }

.icon-playlist-plus:before {
  content: "\e9a4"; }

.icon-playstation:before {
  content: "\e9a5"; }

.icon-plus-box:before {
  content: "\e9a6"; }

.icon-plus-circle-outline:before {
  content: "\e9a7"; }

.icon-plus-circle:before {
  content: "\e9a8"; }

.icon-plus-network:before {
  content: "\e9a9"; }

.icon-plus-one:before {
  content: "\e9aa"; }

.icon-plus:before {
  content: "\e9ab"; }

.icon-pocket:before {
  content: "\e9ac"; }

.icon-poll-box:before {
  content: "\e9ad"; }

.icon-poll:before {
  content: "\e9ae"; }

.icon-polymer:before {
  content: "\e9af"; }

.icon-popcorn:before {
  content: "\e9b0"; }

.icon-pound-box:before {
  content: "\e9b1"; }

.icon-pound:before {
  content: "\e9b2"; }

.icon-power-settings:before {
  content: "\e9b3"; }

.icon-power-socket:before {
  content: "\e9b4"; }

.icon-power:before {
  content: "\e9b5"; }

.icon-presentation-play:before {
  content: "\e9b6"; }

.icon-presentation:before {
  content: "\e9b7"; }

.icon-printer-3d:before {
  content: "\e9b8"; }

.icon-printer:before {
  content: "\e9b9"; }

.icon-pulse:before {
  content: "\e9ba"; }

.icon-puzzle:before {
  content: "\e9bb"; }

.icon-qrcode:before {
  content: "\e9bc"; }

.icon-quadcopter:before {
  content: "\e9bd"; }

.icon-quality-high:before {
  content: "\e9be"; }

.icon-question-mark-circle:before {
  content: "\e9bf"; }

.icon-quick-reply:before {
  content: "\e9c0"; }

.icon-quicktime:before {
  content: "\e9c1"; }

.icon-rabbit:before {
  content: "\e9c2"; }

.icon-radiator:before {
  content: "\e9c3"; }

.icon-radio-tower:before {
  content: "\e9c4"; }

.icon-radio:before {
  content: "\e9c5"; }

.icon-radioactive:before {
  content: "\e9c6"; }

.icon-radiobox-blank:before {
  content: "\e9c7"; }

.icon-radiobox-marked:before {
  content: "\e9c8"; }

.icon-raspberrypi:before {
  content: "\e9c9"; }

.icon-rdio:before {
  content: "\e9ca"; }

.icon-read:before {
  content: "\e9cb"; }

.icon-readability:before {
  content: "\e9cc"; }

.icon-receipt:before {
  content: "\e9cd"; }

.icon-recycle:before {
  content: "\e9ce"; }

.icon-reddit:before {
  content: "\e9cf"; }

.icon-redo-variant:before {
  content: "\e9d0"; }

.icon-redo:before {
  content: "\e9d1"; }

.icon-refresh:before {
  content: "\e9d2"; }

.icon-relative-scale:before {
  content: "\e9d3"; }

.icon-reload:before {
  content: "\e9d4"; }

.icon-remote:before {
  content: "\e9d5"; }

.icon-rename-box:before {
  content: "\e9d6"; }

.icon-repeat-off:before {
  content: "\e9d7"; }

.icon-repeat-once:before {
  content: "\e9d8"; }

.icon-repeat:before {
  content: "\e9d9"; }

.icon-replay:before {
  content: "\e9da"; }

.icon-reply-all:before {
  content: "\e9db"; }

.icon-reply:before {
  content: "\e9dc"; }

.icon-reproduction:before {
  content: "\e9dd"; }

.icon-resize-bottom-right:before {
  content: "\e9de"; }

.icon-responsive:before {
  content: "\e9df"; }

.icon-restore:before {
  content: "\e9e0"; }

.icon-rewind:before {
  content: "\e9e1"; }

.icon-ribbon:before {
  content: "\e9e2"; }

.icon-road:before {
  content: "\e9e3"; }

.icon-rocket:before {
  content: "\e9e4"; }

.icon-rotate-3d:before {
  content: "\e9e5"; }

.icon-rotate-left-variant:before {
  content: "\e9e6"; }

.icon-rotate-left:before {
  content: "\e9e7"; }

.icon-rotate-right-variant:before {
  content: "\e9e8"; }

.icon-rotate-right:before {
  content: "\e9e9"; }

.icon-routes:before {
  content: "\e9ea"; }

.icon-rss-box:before {
  content: "\e9eb"; }

.icon-ruler:before {
  content: "\e9ec"; }

.icon-run:before {
  content: "\e9ed"; }

.icon-sale:before {
  content: "\e9ee"; }

.icon-satellite-variant:before {
  content: "\e9ef"; }

.icon-satellite:before {
  content: "\e9f0"; }

.icon-scale-bathroom:before {
  content: "\e9f1"; }

.icon-scale:before {
  content: "\e9f2"; }

.icon-school:before {
  content: "\e9f3"; }

.icon-screen-rotation-lock:before {
  content: "\e9f4"; }

.icon-screen-rotation:before {
  content: "\e9f5"; }

.icon-script:before {
  content: "\e9f6"; }

.icon-security-network:before {
  content: "\e9f7"; }

.icon-security:before {
  content: "\e9f8"; }

.icon-select-all:before {
  content: "\e9f9"; }

.icon-select-inverse:before {
  content: "\e9fa"; }

.icon-select-off:before {
  content: "\e9fb"; }

.icon-select:before {
  content: "\e9fc"; }

.icon-send:before {
  content: "\e9fd"; }

.icon-server-minus:before {
  content: "\e9fe"; }

.icon-server-network-off:before {
  content: "\e9ff"; }

.icon-server-network:before {
  content: "\ea00"; }

.icon-server-off:before {
  content: "\ea01"; }

.icon-server-plus:before {
  content: "\ea02"; }

.icon-server-remove:before {
  content: "\ea03"; }

.icon-server-security:before {
  content: "\ea04"; }

.icon-server:before {
  content: "\ea05"; }

.icon-shape-plus:before {
  content: "\ea06"; }

.icon-share-variant:before {
  content: "\ea07"; }

.icon-share:before {
  content: "\ea08"; }

.icon-shield-outline:before {
  content: "\ea09"; }

.icon-shield:before {
  content: "\ea0a"; }

.icon-shopping-music:before {
  content: "\ea0b"; }

.icon-shopping:before {
  content: "\ea0c"; }

.icon-shuffle:before {
  content: "\ea0d"; }

.icon-sigma:before {
  content: "\ea0e"; }

.icon-sign-caution:before {
  content: "\ea0f"; }

.icon-signal:before {
  content: "\ea10"; }

.icon-silverware-fork:before {
  content: "\ea11"; }

.icon-silverware-spoon:before {
  content: "\ea12"; }

.icon-silverware-variant:before {
  content: "\ea13"; }

.icon-silverware:before {
  content: "\ea14"; }

.icon-sim-alert:before {
  content: "\ea15"; }

.icon-sim:before {
  content: "\ea16"; }

.icon-sitemap:before {
  content: "\ea17"; }

.icon-skip-next:before {
  content: "\ea18"; }

.icon-skip-previous:before {
  content: "\ea19"; }

.icon-skype-business:before {
  content: "\ea1a"; }

.icon-skype:before {
  content: "\ea1b"; }

.icon-sleep-off:before {
  content: "\ea1c"; }

.icon-sleep:before {
  content: "\ea1d"; }

.icon-smoking-off:before {
  content: "\ea1e"; }

.icon-smoking:before {
  content: "\ea1f"; }

.icon-snapchat:before {
  content: "\ea20"; }

.icon-snowman:before {
  content: "\ea21"; }

.icon-sofa:before {
  content: "\ea22"; }

.icon-sort-alphabetical:before {
  content: "\ea23"; }

.icon-sort-ascending:before {
  content: "\ea24"; }

.icon-sort-descending:before {
  content: "\ea25"; }

.icon-sort-numeric:before {
  content: "\ea26"; }

.icon-sort-variant:before {
  content: "\ea27"; }

.icon-sort:before {
  content: "\ea28"; }

.icon-soundcloud:before {
  content: "\ea29"; }

.icon-source-fork:before {
  content: "\ea2a"; }

.icon-source-pull:before {
  content: "\ea2b"; }

.icon-speaker-off:before {
  content: "\ea2c"; }

.icon-speaker:before {
  content: "\ea2d"; }

.icon-speedometer:before {
  content: "\ea2e"; }

.icon-spellcheck:before {
  content: "\ea2f"; }

.icon-spotify:before {
  content: "\ea30"; }

.icon-spotlight-beam:before {
  content: "\ea31"; }

.icon-spotlight:before {
  content: "\ea32"; }

.icon-spreadsheet:before {
  content: "\ea33"; }

.icon-square-inc-cash:before {
  content: "\ea34"; }

.icon-square-inc:before {
  content: "\ea35"; }

.icon-stackoverflow:before {
  content: "\ea36"; }

.icon-star-circle:before {
  content: "\ea37"; }

.icon-star-half:before {
  content: "\ea38"; }

.icon-star-of-david:before {
  content: "\ea39"; }

.icon-star-outline:before {
  content: "\ea3a"; }

.icon-star:before {
  content: "\ea3b"; }

.icon-steam:before {
  content: "\ea3c"; }

.icon-stethoscope:before {
  content: "\ea3d"; }

.icon-stocking:before {
  content: "\ea3e"; }

.icon-stop:before {
  content: "\ea3f"; }

.icon-store-24-hour:before {
  content: "\ea40"; }

.icon-store:before {
  content: "\ea41"; }

.icon-stove:before {
  content: "\ea42"; }

.icon-subway:before {
  content: "\ea43"; }

.icon-sunglasses:before {
  content: "\ea44"; }

.icon-swap-horizontal:before {
  content: "\ea45"; }

.icon-swap-vertical:before {
  content: "\ea46"; }

.icon-swim:before {
  content: "\ea47"; }

.icon-sword:before {
  content: "\ea48"; }

.icon-sync-alert:before {
  content: "\ea49"; }

.icon-sync-off:before {
  content: "\ea4a"; }

.icon-sync:before {
  content: "\ea4b"; }

.icon-tab-unselected:before {
  content: "\ea4c"; }

.icon-tab:before {
  content: "\ea4d"; }

.icon-table-column-plus-after:before {
  content: "\ea4e"; }

.icon-table-column-plus-before:before {
  content: "\ea4f"; }

.icon-table-column-remove:before {
  content: "\ea50"; }

.icon-table-column-width:before {
  content: "\ea51"; }

.icon-table-edit:before {
  content: "\ea52"; }

.icon-table-large:before {
  content: "\ea53"; }

.icon-table-row-height:before {
  content: "\ea54"; }

.icon-table-row-plus-after:before {
  content: "\ea55"; }

.icon-table-row-plus-before:before {
  content: "\ea56"; }

.icon-table-row-remove:before {
  content: "\ea57"; }

.icon-table:before {
  content: "\ea58"; }

.icon-tablet-android:before {
  content: "\ea59"; }

.icon-tablet-ipad:before {
  content: "\ea5a"; }

.icon-tablet:before {
  content: "\ea5b"; }

.icon-tag-faces:before {
  content: "\ea5c"; }

.icon-tag-multiple:before {
  content: "\ea5d"; }

.icon-tag-outline:before {
  content: "\ea5e"; }

.icon-tag-text-outline:before {
  content: "\ea5f"; }

.icon-tag:before {
  content: "\ea60"; }

.icon-taxi:before {
  content: "\ea61"; }

.icon-teamviewer:before {
  content: "\ea62"; }

.icon-telegram:before {
  content: "\ea63"; }

.icon-television-guide:before {
  content: "\ea64"; }

.icon-television:before {
  content: "\ea65"; }

.icon-temperature-celsius:before {
  content: "\ea66"; }

.icon-temperature-fahrenheit:before {
  content: "\ea67"; }

.icon-temperature-kelvin:before {
  content: "\ea68"; }

.icon-tennis:before {
  content: "\ea69"; }

.icon-tent:before {
  content: "\ea6a"; }

.icon-terrain:before {
  content: "\ea6b"; }

.icon-text-to-speech-off:before {
  content: "\ea6c"; }

.icon-text-to-speech:before {
  content: "\ea6d"; }

.icon-texture:before {
  content: "\ea6e"; }

.icon-theater:before {
  content: "\ea6f"; }

.icon-theme-light-dark:before {
  content: "\ea70"; }

.icon-thermometer-lines:before {
  content: "\ea71"; }

.icon-thermometer:before {
  content: "\ea72"; }

.icon-thumb-down-outline:before {
  content: "\ea73"; }

.icon-thumb-down:before {
  content: "\ea74"; }

.icon-thumb-up-outline:before {
  content: "\ea75"; }

.icon-thumb-up:before {
  content: "\ea76"; }

.icon-thumbs-up-down:before {
  content: "\ea77"; }

.icon-ticket-account:before {
  content: "\ea78"; }

.icon-ticket:before {
  content: "\ea79"; }

.icon-tie:before {
  content: "\ea7a"; }

.icon-tile-four:before {
  content: "\ea7b"; }

.icon-timelapse:before {
  content: "\ea7c"; }

.icon-timer-3:before {
  content: "\ea7d"; }

.icon-timer-10:before {
  content: "\ea7e"; }

.icon-timer-off:before {
  content: "\ea7f"; }

.icon-timer-sand:before {
  content: "\ea80"; }

.icon-timer:before {
  content: "\ea81"; }

.icon-timetable:before {
  content: "\ea82"; }

.icon-toggle-switch-off:before {
  content: "\ea83"; }

.icon-toggle-switch-on:before {
  content: "\ea84"; }

.icon-tooltip-edit:before {
  content: "\ea85"; }

.icon-tooltip-image:before {
  content: "\ea86"; }

.icon-tooltip-outline-plus:before {
  content: "\ea87"; }

.icon-tooltip-outline:before {
  content: "\ea88"; }

.icon-tooltip-text:before {
  content: "\ea89"; }

.icon-tooltip:before {
  content: "\ea8a"; }

.icon-tor:before {
  content: "\ea8b"; }

.icon-traffic-light:before {
  content: "\ea8c"; }

.icon-train:before {
  content: "\ea8d"; }

.icon-tram:before {
  content: "\ea8e"; }

.icon-transcribe-close:before {
  content: "\ea8f"; }

.icon-transcribe:before {
  content: "\ea90"; }

.icon-transfer:before {
  content: "\ea91"; }

.icon-trash:before {
  content: "\ea92"; }

.icon-tree:before {
  content: "\ea93"; }

.icon-trello:before {
  content: "\ea94"; }

.icon-trending-down:before {
  content: "\ea95"; }

.icon-trending-neutral:before {
  content: "\ea96"; }

.icon-trending-up:before {
  content: "\ea97"; }

.icon-truck:before {
  content: "\ea98"; }

.icon-tshirt-crew:before {
  content: "\ea99"; }

.icon-tshirt-v:before {
  content: "\ea9a"; }

.icon-tumblr-reblog:before {
  content: "\ea9b"; }

.icon-tumblr:before {
  content: "\ea9c"; }

.icon-twitch:before {
  content: "\ea9d"; }

.icon-twitter-box:before {
  content: "\ea9e"; }

.icon-twitter-circle:before {
  content: "\ea9f"; }

.icon-twitter-retweet:before {
  content: "\eaa0"; }

.icon-twitter:before {
  content: "\eaa1"; }

.icon-ubuntu:before {
  content: "\eaa2"; }

.icon-umbrella-outline:before {
  content: "\eaa3"; }

.icon-umbrella:before {
  content: "\eaa4"; }

.icon-undo-variant:before {
  content: "\eaa5"; }

.icon-undo:before {
  content: "\eaa6"; }

.icon-unfold-less:before {
  content: "\eaa7"; }

.icon-unfold-more:before {
  content: "\eaa8"; }

.icon-ungroup:before {
  content: "\eaa9"; }

.icon-untappd:before {
  content: "\eaaa"; }

.icon-upload:before {
  content: "\eaab"; }

.icon-usb:before {
  content: "\eaac"; }

.icon-vector-curve:before {
  content: "\eaad"; }

.icon-vector-point:before {
  content: "\eaae"; }

.icon-vector-square:before {
  content: "\eaaf"; }

.icon-verified:before {
  content: "\eab0"; }

.icon-vibration:before {
  content: "\eab1"; }

.icon-video-off:before {
  content: "\eab2"; }

.icon-video-switch:before {
  content: "\eab3"; }

.icon-video:before {
  content: "\eab4"; }

.icon-view-agenda:before {
  content: "\eab5"; }

.icon-view-array:before {
  content: "\eab6"; }

.icon-view-carousel:before {
  content: "\eab7"; }

.icon-view-column:before {
  content: "\eab8"; }

.icon-view-dashboard:before {
  content: "\eab9"; }

.icon-view-day:before {
  content: "\eaba"; }

.icon-view-headline:before {
  content: "\eabb"; }

.icon-view-list:before {
  content: "\eabc"; }

.icon-view-module:before {
  content: "\eabd"; }

.icon-view-quilt:before {
  content: "\eabe"; }

.icon-view-stream:before {
  content: "\eabf"; }

.icon-view-week:before {
  content: "\eac0"; }

.icon-vimeo:before {
  content: "\eac1"; }

.icon-vine:before {
  content: "\eac2"; }

.icon-vk-box:before {
  content: "\eac3"; }

.icon-vk-circle:before {
  content: "\eac4"; }

.icon-vk:before {
  content: "\eac5"; }

.icon-voicemail:before {
  content: "\eac6"; }

.icon-volume-high:before {
  content: "\eac7"; }

.icon-volume-low:before {
  content: "\eac8"; }

.icon-volume-medium:before {
  content: "\eac9"; }

.icon-volume-off:before {
  content: "\eaca"; }

.icon-volume:before {
  content: "\eacb"; }

.icon-vpn:before {
  content: "\eacc"; }

.icon-walk:before {
  content: "\eacd"; }

.icon-wallet-giftcard:before {
  content: "\eace"; }

.icon-wallet-membership:before {
  content: "\eacf"; }

.icon-wallet-travel:before {
  content: "\ead0"; }

.icon-wallet:before {
  content: "\ead1"; }

.icon-washing-machine:before {
  content: "\ead2"; }

.icon-watch-export:before {
  content: "\ead3"; }

.icon-watch-import:before {
  content: "\ead4"; }

.icon-watch:before {
  content: "\ead5"; }

.icon-water-off:before {
  content: "\ead6"; }

.icon-water-pump:before {
  content: "\ead7"; }

.icon-water:before {
  content: "\ead8"; }

.icon-weather-cloudy:before {
  content: "\ead9"; }

.icon-weather-fog:before {
  content: "\eada"; }

.icon-weather-hail:before {
  content: "\eadb"; }

.icon-weather-lightning:before {
  content: "\eadc"; }

.icon-weather-night:before {
  content: "\eadd"; }

.icon-weather-partlycloudy:before {
  content: "\eade"; }

.icon-weather-pouring:before {
  content: "\eadf"; }

.icon-weather-rainy:before {
  content: "\eae0"; }

.icon-weather-snowy:before {
  content: "\eae1"; }

.icon-weather-sunny:before {
  content: "\eae2"; }

.icon-weather-sunset-down:before {
  content: "\eae3"; }

.icon-weather-sunset-up:before {
  content: "\eae4"; }

.icon-weather-sunset:before {
  content: "\eae5"; }

.icon-weather-windy-variant:before {
  content: "\eae6"; }

.icon-weather-windy:before {
  content: "\eae7"; }

.icon-web:before {
  content: "\eae8"; }

.icon-webcam:before {
  content: "\eae9"; }

.icon-weight-kilogram:before {
  content: "\eaea"; }

.icon-weight:before {
  content: "\eaeb"; }

.icon-whatsapp:before {
  content: "\eaec"; }

.icon-wheelchair-accessibility:before {
  content: "\eaed"; }

.icon-white-balance-auto:before {
  content: "\eaee"; }

.icon-white-balance-incandescent:before {
  content: "\eaef"; }

.icon-white-balance-irradescent:before {
  content: "\eaf0"; }

.icon-white-balance-sunny:before {
  content: "\eaf1"; }

.icon-wifi:before {
  content: "\eaf2"; }

.icon-wii:before {
  content: "\eaf3"; }

.icon-wikipedia:before {
  content: "\eaf4"; }

.icon-window-close:before {
  content: "\eaf5"; }

.icon-window-closed:before {
  content: "\eaf6"; }

.icon-window-maximize:before {
  content: "\eaf7"; }

.icon-window-minimize:before {
  content: "\eaf8"; }

.icon-window-open:before {
  content: "\eaf9"; }

.icon-window-restore:before {
  content: "\eafa"; }

.icon-windows:before {
  content: "\eafb"; }

.icon-wordpress:before {
  content: "\eafc"; }

.icon-worker:before {
  content: "\eafd"; }

.icon-wunderlist:before {
  content: "\eafe"; }

.icon-xbox-controller-off:before {
  content: "\eaff"; }

.icon-xbox-controller:before {
  content: "\eb00"; }

.icon-xbox:before {
  content: "\eb01"; }

.icon-xda:before {
  content: "\eb02"; }

.icon-xml:before {
  content: "\eb03"; }

.icon-yeast:before {
  content: "\eb04"; }

.icon-yelp:before {
  content: "\eb05"; }

.icon-youtube-play:before {
  content: "\eb06"; }

.icon-youtube:before {
  content: "\eb07"; }

.icon-zip-box:before {
  content: "\eb08"; }

/*-----------------------------------------------------------------------*/
/* Contains custom elements, components and general styles that
/* follows Google(TM) Material Design(TM) specs
/*
/* https://www.google.com/design/spec/material-design/introduction.html
/*-----------------------------------------------------------------------*/
/*----------------------------------------------------------------*/
/*  Avatars
/*----------------------------------------------------------------*/
.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #FFFFFF; }
  .avatar.square {
    border-radius: 0; }
  .avatar.small {
    width: 20px;
    min-width: 20px;
    height: 20px;
    line-height: 20px; }
  .avatar.big {
    width: 72px;
    min-width: 72px;
    height: 72px;
    line-height: 72px; }
  .avatar.huge {
    width: 96px;
    min-width: 96px;
    height: 96px;
    line-height: 96px; }

/*----------------------------------------------------------------*/
/*  Forms
/*----------------------------------------------------------------*/
.form-wrapper {
  background: #FFFFFF;
  padding: 16px; }
  .form-wrapper .form-title {
    font-size: 21px;
    padding: 8px 0; }

/*----------------------------------------------------------------*/
/*  Navigation - Simple
/*----------------------------------------------------------------*/
.navigation-simple .item {
  position: relative;
  cursor: pointer;
  text-align: left;
  margin: 0;
  padding: 0 24px;
  text-transform: none;
  line-height: 48px;
  max-height: 48px;
  height: 48px; }
  .navigation-simple .item md-icon {
    margin: 0 16px 0 0; }
  .navigation-simple .item .title {
    font-size: 13px;
    font-weight: 500;
    line-height: 1; }

.navigation-simple .subheader {
  font-size: 13px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: 500;
  margin-top: 8px; }
  .navigation-simple .subheader.light {
    border-top: 1px solid rgba(255, 255, 255, 0.12); }

.navigation-simple md-divider {
  margin: 8px 0; }

/*----------------------------------------------------------------*/
/*  Pagination
/*----------------------------------------------------------------*/
.simple-pagination {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  background-color: #FFFFFF;
  padding: 0 8px; }
  .simple-pagination .pagination-item {
    min-width: 48px;
    min-height: 56px;
    line-height: 56px;
    border-radius: 0;
    margin: 0;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.54); }
    .simple-pagination .pagination-item:hover {
      color: rgba(0, 0, 0, 0.87); }
    .simple-pagination .pagination-item.active {
      cursor: default;
      color: rgba(0, 0, 0, 0.87); }
    .simple-pagination .pagination-item.disabled {
      cursor: default;
      color: rgba(0, 0, 0, 0.26); }

/*----------------------------------------------------------------*/
/*  Price Tables
/*----------------------------------------------------------------*/
.price-tables .price-table {
  position: relative;
  background-color: #FFFFFF;
  width: 280px;
  border-radius: 2px;
  margin: 24px 24px 0 0;
  overflow: hidden; }
  .price-tables .price-table.style-1 .package-type {
    font-size: 17px;
    padding: 16px 24px; }
    .price-tables .price-table.style-1 .package-type .sale {
      font-size: 13px;
      font-weight: 600; }
  .price-tables .price-table.style-1 .price {
    padding: 32px 32px 16px 32px; }
    .price-tables .price-table.style-1 .price .currency {
      padding-right: 4px;
      font-size: 24px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.54); }
    .price-tables .price-table.style-1 .price .value {
      font-size: 72px;
      font-weight: 300;
      line-height: 1; }
    .price-tables .price-table.style-1 .price .period {
      padding: 0 0 5px 4px;
      font-size: 17px;
      color: rgba(0, 0, 0, 0.54); }
  .price-tables .price-table.style-1 md-divider {
    margin: 16px 32px; }
  .price-tables .price-table.style-1 .terms {
    padding: 16px 32px;
    font-size: 15px; }
  .price-tables .price-table.style-1 .cta-button {
    margin: 16px auto 32px auto;
    width: 128px; }
  .price-tables .price-table.style-2 .badge {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 11px;
    font-weight: 500;
    padding: 6px 8px; }
  .price-tables .price-table.style-2 .package-type {
    padding: 48px 32px 24px 32px;
    font-size: 20px;
    font-weight: 500;
    text-align: center; }
  .price-tables .price-table.style-2 .price {
    padding: 0 32px 8px 32px; }
    .price-tables .price-table.style-2 .price .currency {
      padding-right: 4px;
      font-size: 24px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.54); }
    .price-tables .price-table.style-2 .price .value {
      font-size: 56px;
      font-weight: 300;
      line-height: 1; }
  .price-tables .price-table.style-2 .period {
    padding: 0 32px;
    font-size: 15px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    text-align: center; }
  .price-tables .price-table.style-2 .terms {
    padding: 32px;
    font-size: 15px; }
    .price-tables .price-table.style-2 .terms .term {
      padding-bottom: 8px; }
      .price-tables .price-table.style-2 .terms .term:last-child {
        padding-bottom: 0; }
  .price-tables .price-table.style-2 .cta-button {
    margin: 8px auto 32px auto;
    width: 128px; }
  .price-tables .price-table.style-3 .package-type {
    padding: 32px;
    text-align: center; }
    .price-tables .price-table.style-3 .package-type .title {
      font-size: 34px; }
    .price-tables .price-table.style-3 .package-type .subtitle {
      font-size: 17px;
      color: rgba(0, 0, 0, 0.54);
      font-weight: 500; }
  .price-tables .price-table.style-3 .price {
    padding: 16px 32px; }
    .price-tables .price-table.style-3 .price .currency {
      padding-right: 4px;
      font-size: 15px;
      font-weight: 500; }
    .price-tables .price-table.style-3 .price .value {
      font-size: 34px;
      font-weight: 300;
      line-height: 1; }
    .price-tables .price-table.style-3 .price .period {
      padding-left: 4px;
      text-align: center; }
  .price-tables .price-table.style-3 .terms {
    margin: 32px;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.54); }
    .price-tables .price-table.style-3 .terms .term {
      padding-bottom: 16px; }
      .price-tables .price-table.style-3 .terms .term:last-child {
        padding-bottom: 0; }
  .price-tables .price-table.style-3 .cta-button {
    margin: 8px 32px; }
  .price-tables .price-table.style-3 .note {
    padding: 8px 32px 16px 32px;
    text-align: center;
    color: rgba(0, 0, 0, 0.54); }

/*----------------------------------------------------------------*/
/*  Table - Simple
/*----------------------------------------------------------------*/
.simple-table-container {
  background: #FFFFFF; }
  .simple-table-container .table-title {
    font-size: 20px;
    padding: 24px; }

table.simple {
  width: 100%;
  border: none;
  border-spacing: 0;
  text-align: left; }
  table.simple thead tr th {
    padding: 16px 8px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    white-space: nowrap;
    min-width: 120px; }
    table.simple thead tr th:first-child {
      padding-left: 24px; }
    table.simple thead tr th:last-child {
      padding-right: 24px; }
  table.simple tbody tr td {
    padding: 16px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    table.simple tbody tr td:first-child {
      padding-left: 24px; }
    table.simple tbody tr td:last-child {
      padding-right: 24px; }
  table.simple tbody tr:last-child td {
    border-bottom: none; }
  table.simple.clickable tbody tr {
    cursor: pointer; }
    table.simple.clickable tbody tr:hover {
      background: rgba(0, 0, 0, 0.03); }

/*----------------------------------------------------------------*/
/*  Typography
/*----------------------------------------------------------------*/
html {
  font-size: 62.5%;
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
  line-height: 1.4;
  letter-spacing: -0.1px; }

body {
  font-size: 1.3rem; }

html, body {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto; }

form {
  font-size: 1.6rem; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: normal; }

h1, .h1 {
  font-size: 2.4rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.6rem; }

h4, .h4 {
  font-size: 1.4rem; }

h5, .h5 {
  font-size: 1.3rem; }

h6, .h6 {
  font-size: 1.2rem; }

a {
  text-decoration: none; }
  a:hover, a:active {
    text-decoration: underline; }

abbr {
  cursor: help;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.54); }

blockquote {
  border-left: 3px solid rgba(0, 0, 0, 0.12);
  font-style: italic;
  margin: 1em 0;
  padding-left: 16px; }
  blockquote footer {
    font-style: normal; }
    blockquote footer:before {
      content: '\2014 \00A0'; }
  blockquote.reverse {
    border-left: none;
    border-right: 3px solid rgba(0, 0, 0, 0.12);
    text-align: right;
    padding-left: 0;
    padding-right: 16px; }
    blockquote.reverse footer:before {
      content: ''; }
    blockquote.reverse footer:after {
      content: '\2014 \00A0'; }

code {
  font-family: "Monaco", "Menlo", "Consolas", "Ubuntu Mono", monospace; }
  code:not(.highlight) {
    background: rgba(0, 0, 0, 0.065);
    color: #106CC8;
    margin: 0 1px;
    padding: 2px 3px;
    border-radius: 2px; }

dl dt {
  font-weight: bold; }

dl dd {
  margin-bottom: 1em; }

mark {
  background: #F7F49A; }

pre {
  line-height: 1.6;
  margin: 8px 16px;
  white-space: pre-wrap; }

small {
  font-size: 80%; }

table thead tr th {
  text-align: left; }

.text-italic {
  font-style: italic; }

.text-semibold {
  font-weight: 600; }

.text-bold, strong {
  font-weight: 700; }

.text-strike {
  text-decoration: line-through; }

.text-super {
  vertical-align: super; }

.text-sub {
  vertical-align: sub; }

.text-capitalize {
  text-transform: capitalize; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase {
  text-transform: uppercase; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.font-weight-100 {
  font-weight: 100; }

.font-weight-200 {
  font-weight: 200; }

.font-weight-300 {
  font-weight: 300; }

.font-weight-400 {
  font-weight: 400; }

.font-weight-500 {
  font-weight: 500; }

.font-weight-600 {
  font-weight: 600; }

.font-weight-700 {
  font-weight: 700; }

.font-weight-800 {
  font-weight: 800; }

.font-weight-900 {
  font-weight: 900; }

.font-size-2 {
  font-size: 2px; }

.font-size-4 {
  font-size: 4px; }

.font-size-6 {
  font-size: 6px; }

.font-size-8 {
  font-size: 8px; }

.font-size-10 {
  font-size: 10px; }

.font-size-12 {
  font-size: 12px; }

.font-size-14 {
  font-size: 14px; }

.font-size-16 {
  font-size: 16px; }

.font-size-18 {
  font-size: 18px; }

.font-size-20 {
  font-size: 20px; }

.font-size-22 {
  font-size: 22px; }

.font-size-24 {
  font-size: 24px; }

.font-size-26 {
  font-size: 26px; }

.font-size-28 {
  font-size: 28px; }

.font-size-30 {
  font-size: 30px; }

.font-size-32 {
  font-size: 32px; }

.font-size-34 {
  font-size: 34px; }

.font-size-36 {
  font-size: 36px; }

.font-size-38 {
  font-size: 38px; }

.font-size-40 {
  font-size: 40px; }

.font-size-42 {
  font-size: 42px; }

.font-size-44 {
  font-size: 44px; }

.font-size-46 {
  font-size: 46px; }

.font-size-48 {
  font-size: 48px; }

.font-size-50 {
  font-size: 50px; }

.font-size-52 {
  font-size: 52px; }

.font-size-54 {
  font-size: 54px; }

.font-size-56 {
  font-size: 56px; }

.font-size-58 {
  font-size: 58px; }

.font-size-60 {
  font-size: 60px; }

.font-size-62 {
  font-size: 62px; }

.font-size-64 {
  font-size: 64px; }

.font-size-66 {
  font-size: 66px; }

.font-size-68 {
  font-size: 68px; }

.font-size-70 {
  font-size: 70px; }

.font-size-72 {
  font-size: 72px; }

.font-size-74 {
  font-size: 74px; }

.font-size-76 {
  font-size: 76px; }

.font-size-78 {
  font-size: 78px; }

.font-size-80 {
  font-size: 80px; }

.font-size-82 {
  font-size: 82px; }

.font-size-84 {
  font-size: 84px; }

.font-size-86 {
  font-size: 86px; }

.font-size-88 {
  font-size: 88px; }

.font-size-90 {
  font-size: 90px; }

.font-size-92 {
  font-size: 92px; }

.font-size-94 {
  font-size: 94px; }

.font-size-96 {
  font-size: 96px; }

.font-size-98 {
  font-size: 98px; }

.font-size-100 {
  font-size: 100px; }

.font-size-102 {
  font-size: 102px; }

.font-size-104 {
  font-size: 104px; }

.font-size-106 {
  font-size: 106px; }

.font-size-108 {
  font-size: 108px; }

.font-size-110 {
  font-size: 110px; }

.font-size-112 {
  font-size: 112px; }

.font-size-114 {
  font-size: 114px; }

.font-size-116 {
  font-size: 116px; }

.font-size-118 {
  font-size: 118px; }

.font-size-120 {
  font-size: 120px; }

.line-height-2 {
  line-height: 2px; }

.line-height-4 {
  line-height: 4px; }

.line-height-6 {
  line-height: 6px; }

.line-height-8 {
  line-height: 8px; }

.line-height-10 {
  line-height: 10px; }

.line-height-12 {
  line-height: 12px; }

.line-height-14 {
  line-height: 14px; }

.line-height-16 {
  line-height: 16px; }

.line-height-18 {
  line-height: 18px; }

.line-height-20 {
  line-height: 20px; }

.line-height-22 {
  line-height: 22px; }

.line-height-24 {
  line-height: 24px; }

.line-height-26 {
  line-height: 26px; }

.line-height-28 {
  line-height: 28px; }

.line-height-30 {
  line-height: 30px; }

.line-height-32 {
  line-height: 32px; }

.line-height-34 {
  line-height: 34px; }

.line-height-36 {
  line-height: 36px; }

.line-height-38 {
  line-height: 38px; }

.line-height-40 {
  line-height: 40px; }

.line-height-42 {
  line-height: 42px; }

.line-height-44 {
  line-height: 44px; }

.line-height-46 {
  line-height: 46px; }

.line-height-48 {
  line-height: 48px; }

.line-height-50 {
  line-height: 50px; }

.line-height-52 {
  line-height: 52px; }

.line-height-54 {
  line-height: 54px; }

.line-height-56 {
  line-height: 56px; }

.line-height-58 {
  line-height: 58px; }

.line-height-60 {
  line-height: 60px; }

.line-height-62 {
  line-height: 62px; }

.line-height-64 {
  line-height: 64px; }

.line-height-66 {
  line-height: 66px; }

.line-height-68 {
  line-height: 68px; }

.line-height-70 {
  line-height: 70px; }

.line-height-72 {
  line-height: 72px; }

.line-height-74 {
  line-height: 74px; }

.line-height-76 {
  line-height: 76px; }

.line-height-78 {
  line-height: 78px; }

.line-height-80 {
  line-height: 80px; }

.line-height-82 {
  line-height: 82px; }

.line-height-84 {
  line-height: 84px; }

.line-height-86 {
  line-height: 86px; }

.line-height-88 {
  line-height: 88px; }

.line-height-90 {
  line-height: 90px; }

.line-height-92 {
  line-height: 92px; }

.line-height-94 {
  line-height: 94px; }

.line-height-96 {
  line-height: 96px; }

.line-height-98 {
  line-height: 98px; }

.line-height-100 {
  line-height: 100px; }

.line-height-102 {
  line-height: 102px; }

.line-height-104 {
  line-height: 104px; }

.line-height-106 {
  line-height: 106px; }

.line-height-108 {
  line-height: 108px; }

.line-height-110 {
  line-height: 110px; }

.line-height-112 {
  line-height: 112px; }

.line-height-114 {
  line-height: 114px; }

.line-height-116 {
  line-height: 116px; }

.line-height-118 {
  line-height: 118px; }

.line-height-120 {
  line-height: 120px; }

.text-boxed, .text-boxed-light {
  border-radius: 2px;
  padding: 4px 8px;
  margin: 0 8px;
  font-size: 11px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  background-color: rgba(0, 0, 0, 0.12);
  white-space: nowrap; }

.text-boxed-light {
  background-color: rgba(255, 255, 255, 0.7); }

.text-truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-nowrap {
  white-space: nowrap; }

.angular-google-map-container {
  height: 400px; }

.moment-picker .moment-picker-container {
  width: 296px;
  border: none;
  border-radius: 2px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  margin-left: 0;
  padding: 8px; }
  .moment-picker .moment-picker-container th,
  .moment-picker .moment-picker-container td {
    min-width: 0;
    width: 40px;
    height: 40px;
    padding: 8px;
    text-shadow: none;
    border-radius: 35px; }
    .moment-picker .moment-picker-container th:hover,
    .moment-picker .moment-picker-container td:hover {
      background: #F5F5F5;
      background-image: none; }
  .moment-picker .moment-picker-container td.selected {
    background: #03A9F4;
    background-image: none; }
  .moment-picker .moment-picker-container .header-view th {
    border-radius: 0;
    white-space: nowrap;
    width: auto; }
    .moment-picker .moment-picker-container .header-view th:first-child, .moment-picker .moment-picker-container .header-view th:last-child {
      width: 20%; }

/*----------------------------------------------------------------*/
/*  @ Chart C3
/*----------------------------------------------------------------*/
.c3 {
  transition: all 300ms ease;
  /*-- Chart --*/
  /*-- Line --*/
  /*-- Point --*/
  /*-- Axis --*/
  /*-- Grid --*/
  /*-- Text on Chart --*/
  /*-- Bar --*/
  /*-- Focus --*/
  /*-- Region --*/
  /*-- Brush --*/
  /*-- Select - Drag --*/
  /*-- Legend --*/
  /*-- Tooltip --*/
  /*-- Area --*/
  /*-- Arc --*/ }
  .c3 svg {
    font: 12px RobotoDraft, Roboto, 'Helvetica Neue', sans-serif; }
  .c3 path, .c3 line {
    fill: none;
    stroke: #000; }
  .c3 text {
    font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
    user-select: none; }
  .c3 .c3-legend-item-tile, .c3 .c3-xgrid-focus, .c3 .c3-ygrid, .c3 .c3-event-rect, .c3 .c3-bars path {
    shape-rendering: crispEdges; }
  .c3 .c3-chart-line {
    transition: all 300ms ease; }
  .c3 .c3-line {
    stroke-width: 1px; }
  .c3.stroke-3 .c3-line {
    stroke-width: 3px; }
  .c3.stroke-2 .c3-line {
    stroke-width: 2px; }
  .c3 .c3-circle {
    transition: all 300ms ease;
    stroke: white;
    stroke-width: 2; }
    .c3 .c3-circle._expanded_ {
      stroke-width: 3;
      stroke: white; }
  .c3 .c3-chart-arc path {
    stroke: #fff; }
  .c3 .c3-chart-arc text {
    fill: #fff;
    font-size: 13px; }
  .c3 .c3-grid line {
    stroke: rgba(0, 0, 0, 0.04);
    stroke-width: 1px;
    stroke-dasharray: 0; }
  .c3 .c3-grid text {
    fill: #aaa; }
  .c3 .c3-axis .tick {
    stroke-width: 0; }
  .c3 .c3-axis text {
    fill: rgba(0, 0, 0, 0.33); }
  .c3 .c3-axis line, .c3 .c3-axis path {
    stroke: rgba(0, 0, 0, 0.04); }
  .c3 .c3-xgrid, .c3 .c3-ygrid {
    stroke-dasharray: 0 0; }
  .c3 .c3-text.c3-empty {
    fill: #808080;
    font-size: 2em; }
  .c3 .c3-bar {
    stroke-width: 0; }
    .c3 .c3-bar._expanded_ {
      fill-opacity: 0.75; }
  .c3 .c3-target.c3-focused {
    opacity: 1; }
    .c3 .c3-target.c3-focused path.c3-line, .c3 .c3-target.c3-focused .c3-target.c3-focused path.c3-step {
      stroke-width: 3px;
      transition: all 300ms ease; }
  .c3 .c3-target.c3-defocused {
    opacity: 0.3 !important; }
  .c3 .c3-region {
    fill: steelblue;
    fill-opacity: 0.1; }
  .c3 .c3-brush .extent {
    fill-opacity: 0.1; }
  .c3 .c3-legend-item {
    font-size: 12px; }
  .c3 .c3-legend-item-hidden {
    opacity: 0.15; }
  .c3 .c3-legend-background {
    opacity: 0.75;
    fill: white;
    stroke: lightgray;
    stroke-width: 1; }
  .c3 .c3-tooltip-container {
    z-index: 10; }
  .c3 .c3-tooltip {
    border-collapse: collapse;
    border-spacing: 0;
    background-color: #fff;
    empty-cells: show;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.084);
    opacity: 1;
    border-radius: 2px;
    overflow: hidden;
    padding: 4px 0; }
    .c3 .c3-tooltip tr {
      border: none; }
    .c3 .c3-tooltip th {
      background-color: transparent;
      font-size: 14px;
      font-weight: 400;
      padding: 8px 12px;
      text-align: left;
      color: rgba(0, 0, 0, 0.54); }
    .c3 .c3-tooltip td {
      font-size: 13px;
      padding: 4px 12px !important;
      background-color: #fff;
      border-left: none;
      color: rgba(0, 0, 0, 0.84); }
      .c3 .c3-tooltip td > span {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 6px; }
      .c3 .c3-tooltip td.value {
        font-size: 14px !important;
        text-align: right;
        color: rgba(0, 0, 0, 0.84); }
  .c3 .c3-area {
    stroke-width: 0;
    opacity: 0.2; }
  .c3 .c3-chart-arcs-title {
    dominant-baseline: middle;
    font-size: 1.3em; }
  .c3 .c3-chart-arcs .c3-chart-arcs-background {
    fill: #e0e0e0;
    stroke: none; }
  .c3 .c3-chart-arcs .c3-chart-arcs-gauge-unit {
    fill: #000;
    font-size: 16px; }
  .c3 .c3-chart-arcs .c3-chart-arcs-gauge-max {
    fill: #777; }
  .c3 .c3-chart-arcs .c3-chart-arcs-gauge-min {
    fill: #777; }
  .c3 .c3-chart-arc .c3-gauge-value {
    fill: #000; }

.ct-label {
  font-size: 13px; }

.ct-chart-donut .ct-label,
.ct-chart-pie .ct-label {
  font-size: 13px; }

.chart-legend,
.bar-legend,
.line-legend,
.pie-legend,
.radar-legend,
.polararea-legend,
.doughnut-legend {
  margin-top: 15px; }

table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-spacing: 0; }
  table.dataTable thead tr {
    background: #FFFFFF; }
  table.dataTable thead th {
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 16px 8px; }
    table.dataTable thead th:first-child {
      padding-left: 24px; }
    table.dataTable thead th:last-child {
      border-right: none;
      padding-right: 24px; }
    table.dataTable thead th:active {
      outline: none; }
    table.dataTable thead th.dt-head-left {
      text-align: left; }
    table.dataTable thead th.dt-head-center {
      text-align: center; }
    table.dataTable thead th.dt-head-right {
      text-align: right; }
    table.dataTable thead th.dt-head-justify {
      text-align: justify; }
    table.dataTable thead th.dt-head-nowrap {
      white-space: nowrap; }
    table.dataTable thead th .table-header .column-title {
      display: block;
      font-weight: 600;
      white-space: nowrap; }
    table.dataTable thead th .table-header .selectize-control {
      margin-top: 15px; }
      table.dataTable thead th .table-header .selectize-control.single .selectize-input {
        padding: 7px 10px; }
        table.dataTable thead th .table-header .selectize-control.single .selectize-input:after {
          right: 10px;
          border-width: 5px 4px 0 4px;
          border-color: rgba(0, 0, 0, 0.12) transparent transparent transparent; }
    table.dataTable thead th .table-header .column-search-input {
      display: inline-block;
      margin-top: 15px;
      width: 100%;
      height: 33px; }
  table.dataTable thead td {
    padding: 15px;
    vertical-align: middle; }
    table.dataTable thead td:active {
      outline: none; }
    table.dataTable thead td.dt-head-left {
      text-align: left; }
    table.dataTable thead td.dt-head-center {
      text-align: center; }
    table.dataTable thead td.dt-head-right {
      text-align: right; }
    table.dataTable thead td.dt-head-justify {
      text-align: justify; }
    table.dataTable thead td.dt-head-nowrap {
      white-space: nowrap; }
  table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc {
    background: none; }
    table.dataTable thead .sorting .table-header .column-title, table.dataTable thead .sorting_asc .table-header .column-title, table.dataTable thead .sorting_desc .table-header .column-title {
      cursor: pointer; }
      table.dataTable thead .sorting .table-header .column-title:after, table.dataTable thead .sorting_asc .table-header .column-title:after, table.dataTable thead .sorting_desc .table-header .column-title:after {
        position: relative;
        font-family: 'icomoon';
        font-weight: normal;
        margin-left: 10px;
        top: 2px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.54); }
  table.dataTable thead .sorting .table-header .column-title:after {
    content: '\eaa8'; }
  table.dataTable thead .sorting_asc .table-header .column-title:after {
    content: '\e718'; }
  table.dataTable thead .sorting_desc .table-header .column-title:after {
    content: '\e715'; }
  table.dataTable tfoot th {
    font-weight: 600;
    text-align: left;
    padding: 10px 18px 6px 18px;
    border-top: 1px solid #111111; }
    table.dataTable tfoot th.dt-head-left {
      text-align: left; }
    table.dataTable tfoot th.dt-head-center {
      text-align: center; }
    table.dataTable tfoot th.dt-head-right {
      text-align: right; }
    table.dataTable tfoot th.dt-head-justify {
      text-align: justify; }
    table.dataTable tfoot th.dt-head-nowrap {
      white-space: nowrap; }
  table.dataTable tfoot td {
    padding: 10px 18px 6px 18px;
    border-top: 1px solid #111111; }
    table.dataTable tfoot td.dt-head-left {
      text-align: left; }
    table.dataTable tfoot td.dt-head-center {
      text-align: center; }
    table.dataTable tfoot td.dt-head-right {
      text-align: right; }
    table.dataTable tfoot td.dt-head-justify {
      text-align: justify; }
    table.dataTable tfoot td.dt-head-nowrap {
      white-space: nowrap; }
  table.dataTable tbody tr {
    background-color: #FFFFFF; }
    table.dataTable tbody tr.selected {
      background-color: #B0BED9; }
    table.dataTable tbody tr.details-open td.detail-column i {
      background: #FFFFFF; }
    table.dataTable tbody tr.details-row.odd tr {
      background: #FFFFFF; }
    table.dataTable tbody tr.details-row > td {
      padding: 0; }
  table.dataTable tbody th {
    padding: 14px 15px;
    vertical-align: middle; }
    table.dataTable tbody th.dt-body-left {
      text-align: left; }
    table.dataTable tbody th.dt-body-center {
      text-align: center; }
    table.dataTable tbody th.dt-body-right {
      text-align: right; }
    table.dataTable tbody th.dt-body-justify {
      text-align: justify; }
    table.dataTable tbody th.dt-body-nowrap {
      white-space: nowrap; }
  table.dataTable tbody td {
    padding: 16px 8px;
    vertical-align: middle; }
    table.dataTable tbody td:first-child {
      padding-left: 24px; }
    table.dataTable tbody td:last-child {
      padding-right: 24px; }
    table.dataTable tbody td.dt-body-left {
      text-align: left; }
    table.dataTable tbody td.dt-body-center {
      text-align: center; }
    table.dataTable tbody td.dt-body-right {
      text-align: right; }
    table.dataTable tbody td.dt-body-justify {
      text-align: justify; }
    table.dataTable tbody td.dt-body-nowrap {
      white-space: nowrap; }
  table.dataTable th.dt-left {
    text-align: left; }
  table.dataTable th.dt-center {
    text-align: center; }
  table.dataTable th.dt-right {
    text-align: right; }
  table.dataTable th.dt-justify {
    text-align: justify; }
  table.dataTable th.dt-nowrap {
    white-space: nowrap; }
  table.dataTable td.dt-left {
    text-align: left; }
  table.dataTable td.dt-center {
    text-align: center; }
  table.dataTable td.dataTables_empty {
    height: 51px;
    text-align: center; }
  table.dataTable td.dt-right {
    text-align: right; }
  table.dataTable td.dt-justify {
    text-align: justify; }
  table.dataTable td.dt-nowrap {
    white-space: nowrap; }
  table.dataTable.row-border thead {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  table.dataTable.row-border tbody tr:first-child th {
    border-top: none; }
  table.dataTable.row-border tbody tr:first-child td {
    border-top: none; }
  table.dataTable.row-border tbody tr:last-child td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  table.dataTable.row-border tbody th {
    border-top: 1px solid rgba(0, 0, 0, 0.12); }
  table.dataTable.row-border tbody td {
    border-top: 1px solid rgba(0, 0, 0, 0.12); }
  table.dataTable.cell-border tbody tr:first-child th {
    border-top: none; }
  table.dataTable.cell-border tbody tr:first-child td {
    border-top: none; }
  table.dataTable.cell-border tbody th {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12); }
    table.dataTable.cell-border tbody th:last-child {
      border-right: none; }
  table.dataTable.cell-border tbody td {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12); }
    table.dataTable.cell-border tbody td:last-child {
      border-right: none; }
  table.dataTable.stripe tbody tr.odd {
    background-color: #FFFFFF; }
    table.dataTable.stripe tbody tr.odd.selected {
      background-color: #ABB9D3; }
  table.dataTable.hover tbody tr:hover {
    background-color: whitesmoke; }
    table.dataTable.hover tbody tr:hover.selected {
      background-color: #A9B7D1; }
  table.dataTable.hover tbody tr.odd:hover {
    background-color: whitesmoke; }
    table.dataTable.hover tbody tr.odd:hover.selected {
      background-color: #A9B7D1; }
  table.dataTable.hover tbody tr.even:hover {
    background-color: whitesmoke; }
    table.dataTable.hover tbody tr.even:hover.selected {
      background-color: #A9B7D1; }
  table.dataTable.order-column tbody tr > .sorting_1 {
    background-color: #F9F9F9; }
  table.dataTable.order-column tbody tr > .sorting_2 {
    background-color: #F9F9F9; }
  table.dataTable.order-column tbody tr > .sorting_3 {
    background-color: #F9F9F9; }
  table.dataTable.order-column tbody tr.selected > .sorting_1 {
    background-color: #ACBAD4; }
  table.dataTable.order-column tbody tr.selected > .sorting_2 {
    background-color: #ACBAD4; }
  table.dataTable.order-column tbody tr.selected > .sorting_3 {
    background-color: #ACBAD4; }
  table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
    background-color: #F1F1F1; }
  table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
    background-color: #F3F3F3; }
  table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
    background-color: whitesmoke; }
  table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
    background-color: #A6B3CD; }
  table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
    background-color: #A7B5CE; }
  table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
    background-color: #A9B6D0; }
  table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
    background-color: #F9F9F9; }
  table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
    background-color: #FBFBFB; }
  table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
    background-color: #FDFDFD; }
  table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
    background-color: #ACBAD4; }
  table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
    background-color: #ADBBD6; }
  table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
    background-color: #AFBDD8; }
  table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
    background-color: #EAEAEA; }
  table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
    background-color: #EBEBEB; }
  table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
    background-color: #EEEEEE; }
  table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
    background-color: #A1AEC7; }
  table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
    background-color: #A2AFC8; }
  table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
    background-color: #A4B2CB; }
  table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_1 {
    background-color: #EAEAEA; }
  table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_2 {
    background-color: #EBEBEB; }
  table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_3 {
    background-color: #EEEEEE; }
  table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_1 {
    background-color: #A1AEC7; }
  table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_2 {
    background-color: #A2AFC8; }
  table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_3 {
    background-color: #A4B2CB; }
  table.dataTable.order-column.hover tbody tr.even:hover > .sorting_1 {
    background-color: #EAEAEA; }
  table.dataTable.order-column.hover tbody tr.even:hover > .sorting_2 {
    background-color: #EBEBEB; }
  table.dataTable.order-column.hover tbody tr.even:hover > .sorting_3 {
    background-color: #EEEEEE; }
  table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_1 {
    background-color: #A1AEC7; }
  table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_2 {
    background-color: #A2AFC8; }
  table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_3 {
    background-color: #A4B2CB; }
  table.dataTable.no-footer {
    border-bottom: none; }
  table.dataTable.nowrap th {
    white-space: nowrap; }
  table.dataTable.nowrap td {
    white-space: nowrap; }
  table.dataTable.compact thead th {
    padding: 5px 9px; }
  table.dataTable.compact thead td {
    padding: 5px 9px; }
  table.dataTable.compact tfoot th {
    padding: 5px 9px 3px 9px; }
  table.dataTable.compact tfoot td {
    padding: 5px 9px 3px 9px; }
  table.dataTable.compact tbody th {
    padding: 4px 5px; }
  table.dataTable.compact tbody td {
    padding: 4px 5px; }

/*
 * Control feature layout
 */
.dataTables_wrapper {
  display: flex;
  flex-direction: column; }
  .dataTables_wrapper .top {
    padding: 16px 24px; }
  .dataTables_wrapper .bottom {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    padding: 16px 8px; }
    .dataTables_wrapper .bottom .left {
      display: flex;
      flex: 0 0 50%;
      max-width: 50%;
      align-self: center;
      padding: 0 8px; }
      .dataTables_wrapper .bottom .left .length {
        display: block; }
    .dataTables_wrapper .bottom .right {
      display: flex;
      flex: 0 0 50%;
      max-width: 50%;
      align-self: center;
      justify-content: flex-end;
      padding: 0 8px; }
      .dataTables_wrapper .bottom .right .info {
        display: block;
        align-self: center; }
        .dataTables_wrapper .bottom .right .info .dataTables_info {
          padding: 0 8px;
          font-weight: 600; }
      .dataTables_wrapper .bottom .right .pagination {
        display: block;
        align-self: center;
        white-space: nowrap; }
        .dataTables_wrapper .bottom .right .pagination .dataTables_paginate {
          padding: 0;
          margin: 0 !important; }
  .dataTables_wrapper .dataTables_filter {
    float: right; }
    .dataTables_wrapper .dataTables_filter input {
      margin-left: 0.5em;
      border: 1px solid rgba(0, 0, 0, 0.12);
      height: 24px;
      padding: 4px 8px; }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    background: #FFFFFF;
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 9px 12px;
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
    margin: 0 2px; }
    .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
      box-shadow: inset 999em 0 0 0 rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.87) !important;
      border: 1px solid rgba(0, 0, 0, 0.12) !important;
      background: none; }
    .dataTables_wrapper .dataTables_paginate .paginate_button:active {
      box-shadow: inset 999em 0 0 0 rgba(0, 0, 0, 0.08), inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(255, 255, 255, 0.35); }
    .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
      color: rgba(0, 0, 0, 0.54) !important;
      border-color: rgba(0, 0, 0, 0.12) !important;
      background: #FFFFFF !important;
      box-shadow: none !important;
      cursor: default; }
  .dataTables_wrapper .dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 40px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* FF3.6+ */
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* IE10+ */
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* Opera 11.10+ */
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* W3C */ }
  .dataTables_wrapper .dataTables_scroll {
    display: flex;
    flex: 1;
    flex-direction: column;
    clear: both;
    /* Clear the table border & shadows */ }
    .dataTables_wrapper .dataTables_scroll table.dataTable {
      box-shadow: none; }
      .dataTables_wrapper .dataTables_scroll table.dataTable.row-border thead {
        border: none; }
    .dataTables_wrapper .dataTables_scroll .dataTables_scrollHead {
      display: flex;
      min-height: 95px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.095), 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 2px 1px 0 rgba(0, 0, 0, 0.05);
      background: #FFFFFF;
      overflow: visible !important; }
      .dataTables_wrapper .dataTables_scroll .dataTables_scrollHead tr th:last-child .table-header .column-title {
        position: relative;
        left: 7px; }
    .dataTables_wrapper .dataTables_scroll .dataTables_scrollBody {
      display: flex;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.095), 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 2px 1px 0 rgba(0, 0, 0, 0.05);
      overflow-x: hidden !important;
      overflow-y: scroll !important;
      -webkit-overflow-scrolling: touch; }
      .dataTables_wrapper .dataTables_scroll .dataTables_scrollBody th > .dataTables_sizing {
        height: 0;
        overflow: hidden;
        margin: 0 !important;
        padding: 0 !important; }
      .dataTables_wrapper .dataTables_scroll .dataTables_scrollBody td > .dataTables_sizing {
        height: 0;
        overflow: hidden;
        margin: 0 !important;
        padding: 0 !important; }
  .dataTables_wrapper.no-footer .dataTables_scrollHead table,
  .dataTables_wrapper.no-footer .dataTables_scrollBody table {
    border-bottom: none; }
  .dataTables_wrapper:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0; }

/* DataTable Specific Content Rules & Fixes */
table.dataTable .show-details {
  padding: 5px 10px;
  font-weight: bold; }

table.dataTable .action-buttons {
  display: flex;
  justify-content: center; }
  table.dataTable .action-buttons .button {
    padding: 8px; }

/* Responsive datatable */
table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr > td:first-child,
table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr > th:first-child {
  padding-left: 40px; }
  table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr > td:first-child:before,
  table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr > th:first-child:before {
    content: '+';
    width: 15px;
    height: 15px;
    line-height: 15px;
    top: 50%;
    margin-top: -7px;
    left: 8px;
    color: rgba(0, 0, 0, 0.54);
    background: none;
    border: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.54);
    font-weight: bold; }

table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  background: none; }

table.dataTable.dtr-inline.collapsed > tbody > tr.child ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 8px 0; }
  table.dataTable.dtr-inline.collapsed > tbody > tr.child ul li:first-child {
    padding-top: 0; }
  table.dataTable.dtr-inline.collapsed > tbody > tr.child ul li:last-child {
    border-bottom: none;
    padding-bottom: 0; }

@media screen and (max-width: 1280px) {
  .dataTables_wrapper .bottom {
    flex-wrap: wrap; }
    .dataTables_wrapper .bottom .left {
      flex: 0 0 100%;
      max-width: none;
      padding-top: 8px;
      padding-bottom: 8px; }
    .dataTables_wrapper .bottom .right {
      flex: 0 0 100%;
      max-width: none;
      padding-top: 8px;
      padding-bottom: 8px; } }

/*

github.com style (c) Vasily Polovnyov <vast@whiteants.net>

*/
hljs,
[hljs] {
  display: block;
  overflow-x: auto;
  color: #333;
  background: #f8f8f8;
  -webkit-text-size-adjust: none; }

.hljs-comment,
.diff .hljs-header {
  color: #998;
  font-style: italic; }

.hljs-keyword,
.css .rule .hljs-keyword,
.hljs-winutils,
.nginx .hljs-title,
.hljs-subst,
.hljs-request,
.hljs-status {
  color: #333;
  font-weight: bold; }

.hljs-number,
.hljs-hexcolor,
.ruby .hljs-constant {
  color: #008080; }

.hljs-string,
.hljs-tag .hljs-value,
.hljs-doctag,
.tex .hljs-formula {
  color: #d14; }

.hljs-title,
.hljs-id,
.scss .hljs-preprocessor {
  color: #900;
  font-weight: bold; }

.hljs-list .hljs-keyword,
.hljs-subst {
  font-weight: normal; }

.hljs-class .hljs-title,
.hljs-type,
.vhdl .hljs-literal,
.tex .hljs-command {
  color: #458;
  font-weight: bold; }

.hljs-tag,
.hljs-tag .hljs-title,
.hljs-rule .hljs-property,
.django .hljs-tag .hljs-keyword {
  color: #000080;
  font-weight: normal; }

.hljs-attribute,
.hljs-variable,
.lisp .hljs-body,
.hljs-name {
  color: #008080; }

.hljs-regexp {
  color: #009926; }

.hljs-symbol,
.ruby .hljs-symbol .hljs-string,
.lisp .hljs-keyword,
.clojure .hljs-keyword,
.scheme .hljs-keyword,
.tex .hljs-special,
.hljs-prompt {
  color: #990073; }

.hljs-built_in {
  color: #0086b3; }

.hljs-preprocessor,
.hljs-pragma,
.hljs-pi,
.hljs-doctype,
.hljs-shebang,
.hljs-cdata {
  color: #999;
  font-weight: bold; }

.hljs-deletion {
  background: #fdd; }

.hljs-addition {
  background: #dfd; }

.diff .hljs-change {
  background: #0086b3; }

.hljs-chunk {
  color: #aaa; }

nvd3 {
  display: block;
  width: 100%;
  height: 100%; }
  nvd3.remove-x-lines .nv-x .tick line {
    display: none; }
  nvd3.remove-y-lines .nv-y .tick line {
    display: none; }
  nvd3.remove-line-stroke .nv-groups path.nv-line {
    stroke-width: 0 !important; }
  nvd3.remove-opacity .nv-groups .nv-group {
    fill-opacity: 1 !important; }
  nvd3.show-line-points .nv-line .nv-scatter .nv-groups .nv-point {
    fill-opacity: 1 !important;
    stroke-opacity: 1 !important; }

.nvd3 text {
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif; }

.nvd3 line.nv-guideline {
  stroke: rgba(0, 0, 0, 0.54); }

.nvd3 .nv-groups .nv-point.hover {
  stroke-width: 3px !important;
  fill-opacity: 1 !important;
  stroke-opacity: 1 !important; }

.nvtooltip {
  background: none;
  color: white;
  padding: 0;
  border: none; }
  .nvtooltip.gravity-n:after {
    display: block;
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    left: 50%;
    bottom: 100%;
    margin-left: -5px;
    border: 5px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.87); }
  .nvtooltip.gravity-s:after {
    display: block;
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border: 5px solid transparent;
    border-top-color: rgba(0, 0, 0, 0.87); }
  .nvtooltip.gravity-e:after {
    display: block;
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 50%;
    right: 0;
    margin-top: -6px;
    margin-right: -11px;
    border: 6px solid transparent;
    border-left-color: rgba(0, 0, 0, 0.87); }
  .nvtooltip.gravity-w:after {
    display: block;
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 50%;
    margin-top: -6px;
    margin-left: -11px;
    border: 6px solid transparent;
    border-right-color: rgba(0, 0, 0, 0.87); }
  .nvtooltip table {
    background: rgba(0, 0, 0, 0.87);
    padding: 8px 12px;
    margin: 0;
    border-radius: 2px; }
    .nvtooltip table tbody tr td.legend-color-guide div {
      border: none; }
    .nvtooltip table tbody tr td:last-child {
      padding-right: 0; }

.ps-container:hover .ps-in-scrolling {
  pointer-events: auto; }

.ps-container .ps-in-scrolling {
  pointer-events: auto; }

.ps-container > .ps-scrollbar-x-rail {
  background: none !important;
  margin: 4px 0;
  height: 4px;
  z-index: 49; }
  .ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
    height: 6px;
    transition: height 0.25s; }
  .ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x:hover {
    height: 1em; }

.ps-container > .ps-scrollbar-y-rail {
  background: none !important;
  margin: 4px 0;
  width: 4px;
  z-index: 49; }
  .ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    width: 6px;
    transition: width 0.25s; }
  .ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y:hover {
    width: 1em; }

.ta-scroll-window.form-control {
  border: 1px solid rgba(0, 0, 0, 0.12); }

.ta-group {
  margin-bottom: 16px; }
  .ta-group .md-button {
    width: 40px;
    min-width: 40px;
    line-height: 40px;
    height: 40px;
    min-height: 40px;
    padding: 8px; }

/*----------------------------------------------------------------*/
/*  Helpers
/*----------------------------------------------------------------*/
.no-margin {
  margin-right: 0; }

.no-radius {
  border-radius: 0; }

.divider-vertical {
  background: rgba(0, 0, 0, 0.12);
  height: 24px;
  width: 1px;
  margin: 0 8px; }

.hidden {
  visibility: hidden;
  opacity: 0; }

[fill-width] {
  min-width: 100%; }

[fill-height] {
  min-height: 100%; }

.full-height {
  height: 100% !important; }

a img {
  display: block;
  outline: none; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  border: none; }

.scrollable {
  display: block;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .scrollable.only-x {
    overflow-x: auto;
    overflow-y: hidden; }
  .scrollable.only-y {
    overflow-x: hidden;
    overflow-y: auto; }

.flex-scrollable {
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .flex-scrollable.only-x {
    overflow-x: auto;
    overflow-y: hidden; }
  .flex-scrollable.only-y {
    overflow-x: hidden;
    overflow-y: auto; }

.background-image-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1; }

.align-vertical:before {
  display: inline-block;
  height: 100%;
  content: '';
  vertical-align: middle; }

.align-vertical .align-vertical-top,
.align-vertical .align-vertical-middle,
.align-vertical .align-vertical-bottom {
  display: inline-block; }

.align-vertical .align-vertical-top {
  vertical-align: top; }

.align-vertical .align-vertical-middle {
  vertical-align: middle; }

.align-vertical .align-vertical-bottom {
  vertical-align: bottom; }

.padding-0,
.p-0 {
  padding: 0px; }

.padding-top-0,
.pt-0 {
  padding-top: 0px; }

.padding-right-0,
.pr-0 {
  padding-right: 0px; }

.padding-bottom-0,
.pb-0 {
  padding-bottom: 0px; }

.padding-left-0,
.pl-0 {
  padding-left: 0px; }

.padding-horizontal-0,
.ph-0 {
  padding-left: 0px;
  padding-right: 0px; }

.padding-vertical-0,
.pv-0 {
  padding-top: 0px;
  padding-bottom: 0px; }

.padding-5,
.p-5 {
  padding: 5px; }

.padding-top-5,
.pt-5 {
  padding-top: 5px; }

.padding-right-5,
.pr-5 {
  padding-right: 5px; }

.padding-bottom-5,
.pb-5 {
  padding-bottom: 5px; }

.padding-left-5,
.pl-5 {
  padding-left: 5px; }

.padding-horizontal-5,
.ph-5 {
  padding-left: 5px;
  padding-right: 5px; }

.padding-vertical-5,
.pv-5 {
  padding-top: 5px;
  padding-bottom: 5px; }

.padding-10,
.p-10 {
  padding: 10px; }

.padding-top-10,
.pt-10 {
  padding-top: 10px; }

.padding-right-10,
.pr-10 {
  padding-right: 10px; }

.padding-bottom-10,
.pb-10 {
  padding-bottom: 10px; }

.padding-left-10,
.pl-10 {
  padding-left: 10px; }

.padding-horizontal-10,
.ph-10 {
  padding-left: 10px;
  padding-right: 10px; }

.padding-vertical-10,
.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px; }

.padding-15,
.p-15 {
  padding: 15px; }

.padding-top-15,
.pt-15 {
  padding-top: 15px; }

.padding-right-15,
.pr-15 {
  padding-right: 15px; }

.padding-bottom-15,
.pb-15 {
  padding-bottom: 15px; }

.padding-left-15,
.pl-15 {
  padding-left: 15px; }

.padding-horizontal-15,
.ph-15 {
  padding-left: 15px;
  padding-right: 15px; }

.padding-vertical-15,
.pv-15 {
  padding-top: 15px;
  padding-bottom: 15px; }

.padding-20,
.p-20 {
  padding: 20px; }

.padding-top-20,
.pt-20 {
  padding-top: 20px; }

.padding-right-20,
.pr-20 {
  padding-right: 20px; }

.padding-bottom-20,
.pb-20 {
  padding-bottom: 20px; }

.padding-left-20,
.pl-20 {
  padding-left: 20px; }

.padding-horizontal-20,
.ph-20 {
  padding-left: 20px;
  padding-right: 20px; }

.padding-vertical-20,
.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px; }

.padding-25,
.p-25 {
  padding: 25px; }

.padding-top-25,
.pt-25 {
  padding-top: 25px; }

.padding-right-25,
.pr-25 {
  padding-right: 25px; }

.padding-bottom-25,
.pb-25 {
  padding-bottom: 25px; }

.padding-left-25,
.pl-25 {
  padding-left: 25px; }

.padding-horizontal-25,
.ph-25 {
  padding-left: 25px;
  padding-right: 25px; }

.padding-vertical-25,
.pv-25 {
  padding-top: 25px;
  padding-bottom: 25px; }

.padding-30,
.p-30 {
  padding: 30px; }

.padding-top-30,
.pt-30 {
  padding-top: 30px; }

.padding-right-30,
.pr-30 {
  padding-right: 30px; }

.padding-bottom-30,
.pb-30 {
  padding-bottom: 30px; }

.padding-left-30,
.pl-30 {
  padding-left: 30px; }

.padding-horizontal-30,
.ph-30 {
  padding-left: 30px;
  padding-right: 30px; }

.padding-vertical-30,
.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px; }

.padding-35,
.p-35 {
  padding: 35px; }

.padding-top-35,
.pt-35 {
  padding-top: 35px; }

.padding-right-35,
.pr-35 {
  padding-right: 35px; }

.padding-bottom-35,
.pb-35 {
  padding-bottom: 35px; }

.padding-left-35,
.pl-35 {
  padding-left: 35px; }

.padding-horizontal-35,
.ph-35 {
  padding-left: 35px;
  padding-right: 35px; }

.padding-vertical-35,
.pv-35 {
  padding-top: 35px;
  padding-bottom: 35px; }

.padding-40,
.p-40 {
  padding: 40px; }

.padding-top-40,
.pt-40 {
  padding-top: 40px; }

.padding-right-40,
.pr-40 {
  padding-right: 40px; }

.padding-bottom-40,
.pb-40 {
  padding-bottom: 40px; }

.padding-left-40,
.pl-40 {
  padding-left: 40px; }

.padding-horizontal-40,
.ph-40 {
  padding-left: 40px;
  padding-right: 40px; }

.padding-vertical-40,
.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px; }

.padding-45,
.p-45 {
  padding: 45px; }

.padding-top-45,
.pt-45 {
  padding-top: 45px; }

.padding-right-45,
.pr-45 {
  padding-right: 45px; }

.padding-bottom-45,
.pb-45 {
  padding-bottom: 45px; }

.padding-left-45,
.pl-45 {
  padding-left: 45px; }

.padding-horizontal-45,
.ph-45 {
  padding-left: 45px;
  padding-right: 45px; }

.padding-vertical-45,
.pv-45 {
  padding-top: 45px;
  padding-bottom: 45px; }

.padding-50,
.p-50 {
  padding: 50px; }

.padding-top-50,
.pt-50 {
  padding-top: 50px; }

.padding-right-50,
.pr-50 {
  padding-right: 50px; }

.padding-bottom-50,
.pb-50 {
  padding-bottom: 50px; }

.padding-left-50,
.pl-50 {
  padding-left: 50px; }

.padding-horizontal-50,
.ph-50 {
  padding-left: 50px;
  padding-right: 50px; }

.padding-vertical-50,
.pv-50 {
  padding-top: 50px;
  padding-bottom: 50px; }

.padding-55,
.p-55 {
  padding: 55px; }

.padding-top-55,
.pt-55 {
  padding-top: 55px; }

.padding-right-55,
.pr-55 {
  padding-right: 55px; }

.padding-bottom-55,
.pb-55 {
  padding-bottom: 55px; }

.padding-left-55,
.pl-55 {
  padding-left: 55px; }

.padding-horizontal-55,
.ph-55 {
  padding-left: 55px;
  padding-right: 55px; }

.padding-vertical-55,
.pv-55 {
  padding-top: 55px;
  padding-bottom: 55px; }

.padding-60,
.p-60 {
  padding: 60px; }

.padding-top-60,
.pt-60 {
  padding-top: 60px; }

.padding-right-60,
.pr-60 {
  padding-right: 60px; }

.padding-bottom-60,
.pb-60 {
  padding-bottom: 60px; }

.padding-left-60,
.pl-60 {
  padding-left: 60px; }

.padding-horizontal-60,
.ph-60 {
  padding-left: 60px;
  padding-right: 60px; }

.padding-vertical-60,
.pv-60 {
  padding-top: 60px;
  padding-bottom: 60px; }

.padding-65,
.p-65 {
  padding: 65px; }

.padding-top-65,
.pt-65 {
  padding-top: 65px; }

.padding-right-65,
.pr-65 {
  padding-right: 65px; }

.padding-bottom-65,
.pb-65 {
  padding-bottom: 65px; }

.padding-left-65,
.pl-65 {
  padding-left: 65px; }

.padding-horizontal-65,
.ph-65 {
  padding-left: 65px;
  padding-right: 65px; }

.padding-vertical-65,
.pv-65 {
  padding-top: 65px;
  padding-bottom: 65px; }

.padding-70,
.p-70 {
  padding: 70px; }

.padding-top-70,
.pt-70 {
  padding-top: 70px; }

.padding-right-70,
.pr-70 {
  padding-right: 70px; }

.padding-bottom-70,
.pb-70 {
  padding-bottom: 70px; }

.padding-left-70,
.pl-70 {
  padding-left: 70px; }

.padding-horizontal-70,
.ph-70 {
  padding-left: 70px;
  padding-right: 70px; }

.padding-vertical-70,
.pv-70 {
  padding-top: 70px;
  padding-bottom: 70px; }

.padding-75,
.p-75 {
  padding: 75px; }

.padding-top-75,
.pt-75 {
  padding-top: 75px; }

.padding-right-75,
.pr-75 {
  padding-right: 75px; }

.padding-bottom-75,
.pb-75 {
  padding-bottom: 75px; }

.padding-left-75,
.pl-75 {
  padding-left: 75px; }

.padding-horizontal-75,
.ph-75 {
  padding-left: 75px;
  padding-right: 75px; }

.padding-vertical-75,
.pv-75 {
  padding-top: 75px;
  padding-bottom: 75px; }

.padding-80,
.p-80 {
  padding: 80px; }

.padding-top-80,
.pt-80 {
  padding-top: 80px; }

.padding-right-80,
.pr-80 {
  padding-right: 80px; }

.padding-bottom-80,
.pb-80 {
  padding-bottom: 80px; }

.padding-left-80,
.pl-80 {
  padding-left: 80px; }

.padding-horizontal-80,
.ph-80 {
  padding-left: 80px;
  padding-right: 80px; }

.padding-vertical-80,
.pv-80 {
  padding-top: 80px;
  padding-bottom: 80px; }

.padding-85,
.p-85 {
  padding: 85px; }

.padding-top-85,
.pt-85 {
  padding-top: 85px; }

.padding-right-85,
.pr-85 {
  padding-right: 85px; }

.padding-bottom-85,
.pb-85 {
  padding-bottom: 85px; }

.padding-left-85,
.pl-85 {
  padding-left: 85px; }

.padding-horizontal-85,
.ph-85 {
  padding-left: 85px;
  padding-right: 85px; }

.padding-vertical-85,
.pv-85 {
  padding-top: 85px;
  padding-bottom: 85px; }

.padding-90,
.p-90 {
  padding: 90px; }

.padding-top-90,
.pt-90 {
  padding-top: 90px; }

.padding-right-90,
.pr-90 {
  padding-right: 90px; }

.padding-bottom-90,
.pb-90 {
  padding-bottom: 90px; }

.padding-left-90,
.pl-90 {
  padding-left: 90px; }

.padding-horizontal-90,
.ph-90 {
  padding-left: 90px;
  padding-right: 90px; }

.padding-vertical-90,
.pv-90 {
  padding-top: 90px;
  padding-bottom: 90px; }

.padding-95,
.p-95 {
  padding: 95px; }

.padding-top-95,
.pt-95 {
  padding-top: 95px; }

.padding-right-95,
.pr-95 {
  padding-right: 95px; }

.padding-bottom-95,
.pb-95 {
  padding-bottom: 95px; }

.padding-left-95,
.pl-95 {
  padding-left: 95px; }

.padding-horizontal-95,
.ph-95 {
  padding-left: 95px;
  padding-right: 95px; }

.padding-vertical-95,
.pv-95 {
  padding-top: 95px;
  padding-bottom: 95px; }

.padding-100,
.p-100 {
  padding: 100px; }

.padding-top-100,
.pt-100 {
  padding-top: 100px; }

.padding-right-100,
.pr-100 {
  padding-right: 100px; }

.padding-bottom-100,
.pb-100 {
  padding-bottom: 100px; }

.padding-left-100,
.pl-100 {
  padding-left: 100px; }

.padding-horizontal-100,
.ph-100 {
  padding-left: 100px;
  padding-right: 100px; }

.padding-vertical-100,
.pv-100 {
  padding-top: 100px;
  padding-bottom: 100px; }

.padding-8,
.p-8 {
  padding: 8px; }

.padding-top-8,
.pt-8 {
  padding-top: 8px; }

.padding-right-8,
.pr-8 {
  padding-right: 8px; }

.padding-bottom-8,
.pb-8 {
  padding-bottom: 8px; }

.padding-left-8,
.pl-8 {
  padding-left: 8px; }

.padding-horizontal-8,
.ph-8 {
  padding-left: 8px;
  padding-right: 8px; }

.padding-vertical-8,
.pv-8 {
  padding-top: 8px;
  padding-bottom: 8px; }

.padding-16,
.p-16 {
  padding: 16px; }

.padding-top-16,
.pt-16 {
  padding-top: 16px; }

.padding-right-16,
.pr-16 {
  padding-right: 16px; }

.padding-bottom-16,
.pb-16 {
  padding-bottom: 16px; }

.padding-left-16,
.pl-16 {
  padding-left: 16px; }

.padding-horizontal-16,
.ph-16 {
  padding-left: 16px;
  padding-right: 16px; }

.padding-vertical-16,
.pv-16 {
  padding-top: 16px;
  padding-bottom: 16px; }

.padding-24,
.p-24 {
  padding: 24px; }

.padding-top-24,
.pt-24 {
  padding-top: 24px; }

.padding-right-24,
.pr-24 {
  padding-right: 24px; }

.padding-bottom-24,
.pb-24 {
  padding-bottom: 24px; }

.padding-left-24,
.pl-24 {
  padding-left: 24px; }

.padding-horizontal-24,
.ph-24 {
  padding-left: 24px;
  padding-right: 24px; }

.padding-vertical-24,
.pv-24 {
  padding-top: 24px;
  padding-bottom: 24px; }

.padding-32,
.p-32 {
  padding: 32px; }

.padding-top-32,
.pt-32 {
  padding-top: 32px; }

.padding-right-32,
.pr-32 {
  padding-right: 32px; }

.padding-bottom-32,
.pb-32 {
  padding-bottom: 32px; }

.padding-left-32,
.pl-32 {
  padding-left: 32px; }

.padding-horizontal-32,
.ph-32 {
  padding-left: 32px;
  padding-right: 32px; }

.padding-vertical-32,
.pv-32 {
  padding-top: 32px;
  padding-bottom: 32px; }

.padding-48,
.p-48 {
  padding: 48px; }

.padding-top-48,
.pt-48 {
  padding-top: 48px; }

.padding-right-48,
.pr-48 {
  padding-right: 48px; }

.padding-bottom-48,
.pb-48 {
  padding-bottom: 48px; }

.padding-left-48,
.pl-48 {
  padding-left: 48px; }

.padding-horizontal-48,
.ph-48 {
  padding-left: 48px;
  padding-right: 48px; }

.padding-vertical-48,
.pv-48 {
  padding-top: 48px;
  padding-bottom: 48px; }

.padding-56,
.p-56 {
  padding: 56px; }

.padding-top-56,
.pt-56 {
  padding-top: 56px; }

.padding-right-56,
.pr-56 {
  padding-right: 56px; }

.padding-bottom-56,
.pb-56 {
  padding-bottom: 56px; }

.padding-left-56,
.pl-56 {
  padding-left: 56px; }

.padding-horizontal-56,
.ph-56 {
  padding-left: 56px;
  padding-right: 56px; }

.padding-vertical-56,
.pv-56 {
  padding-top: 56px;
  padding-bottom: 56px; }

.padding-64,
.p-64 {
  padding: 64px; }

.padding-top-64,
.pt-64 {
  padding-top: 64px; }

.padding-right-64,
.pr-64 {
  padding-right: 64px; }

.padding-bottom-64,
.pb-64 {
  padding-bottom: 64px; }

.padding-left-64,
.pl-64 {
  padding-left: 64px; }

.padding-horizontal-64,
.ph-64 {
  padding-left: 64px;
  padding-right: 64px; }

.padding-vertical-64,
.pv-64 {
  padding-top: 64px;
  padding-bottom: 64px; }

.padding-72,
.p-72 {
  padding: 72px; }

.padding-top-72,
.pt-72 {
  padding-top: 72px; }

.padding-right-72,
.pr-72 {
  padding-right: 72px; }

.padding-bottom-72,
.pb-72 {
  padding-bottom: 72px; }

.padding-left-72,
.pl-72 {
  padding-left: 72px; }

.padding-horizontal-72,
.ph-72 {
  padding-left: 72px;
  padding-right: 72px; }

.padding-vertical-72,
.pv-72 {
  padding-top: 72px;
  padding-bottom: 72px; }

.padding-88,
.p-88 {
  padding: 88px; }

.padding-top-88,
.pt-88 {
  padding-top: 88px; }

.padding-right-88,
.pr-88 {
  padding-right: 88px; }

.padding-bottom-88,
.pb-88 {
  padding-bottom: 88px; }

.padding-left-88,
.pl-88 {
  padding-left: 88px; }

.padding-horizontal-88,
.ph-88 {
  padding-left: 88px;
  padding-right: 88px; }

.padding-vertical-88,
.pv-88 {
  padding-top: 88px;
  padding-bottom: 88px; }

.padding-96,
.p-96 {
  padding: 96px; }

.padding-top-96,
.pt-96 {
  padding-top: 96px; }

.padding-right-96,
.pr-96 {
  padding-right: 96px; }

.padding-bottom-96,
.pb-96 {
  padding-bottom: 96px; }

.padding-left-96,
.pl-96 {
  padding-left: 96px; }

.padding-horizontal-96,
.ph-96 {
  padding-left: 96px;
  padding-right: 96px; }

.padding-vertical-96,
.pv-96 {
  padding-top: 96px;
  padding-bottom: 96px; }

.margin-0,
.m-0 {
  margin: 0px; }

.margin-top-0,
.mt-0 {
  margin-top: 0px; }

.margin-right-0,
.mr-0 {
  margin-right: 0px; }

.margin-bottom-0,
.mb-0 {
  margin-bottom: 0px; }

.margin-left-0,
.ml-0 {
  margin-left: 0px; }

.margin-horizontal-0,
.mh-0 {
  margin-left: 0px;
  margin-right: 0px; }

.margin-vertical-0,
.mv-0 {
  margin-top: 0px;
  margin-bottom: 0px; }

.margin-5,
.m-5 {
  margin: 5px; }

.margin-top-5,
.mt-5 {
  margin-top: 5px; }

.margin-right-5,
.mr-5 {
  margin-right: 5px; }

.margin-bottom-5,
.mb-5 {
  margin-bottom: 5px; }

.margin-left-5,
.ml-5 {
  margin-left: 5px; }

.margin-horizontal-5,
.mh-5 {
  margin-left: 5px;
  margin-right: 5px; }

.margin-vertical-5,
.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px; }

.margin-10,
.m-10 {
  margin: 10px; }

.margin-top-10,
.mt-10 {
  margin-top: 10px; }

.margin-right-10,
.mr-10 {
  margin-right: 10px; }

.margin-bottom-10,
.mb-10 {
  margin-bottom: 10px; }

.margin-left-10,
.ml-10 {
  margin-left: 10px; }

.margin-horizontal-10,
.mh-10 {
  margin-left: 10px;
  margin-right: 10px; }

.margin-vertical-10,
.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.margin-15,
.m-15 {
  margin: 15px; }

.margin-top-15,
.mt-15 {
  margin-top: 15px; }

.margin-right-15,
.mr-15 {
  margin-right: 15px; }

.margin-bottom-15,
.mb-15 {
  margin-bottom: 15px; }

.margin-left-15,
.ml-15 {
  margin-left: 15px; }

.margin-horizontal-15,
.mh-15 {
  margin-left: 15px;
  margin-right: 15px; }

.margin-vertical-15,
.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.margin-20,
.m-20 {
  margin: 20px; }

.margin-top-20,
.mt-20 {
  margin-top: 20px; }

.margin-right-20,
.mr-20 {
  margin-right: 20px; }

.margin-bottom-20,
.mb-20 {
  margin-bottom: 20px; }

.margin-left-20,
.ml-20 {
  margin-left: 20px; }

.margin-horizontal-20,
.mh-20 {
  margin-left: 20px;
  margin-right: 20px; }

.margin-vertical-20,
.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.margin-25,
.m-25 {
  margin: 25px; }

.margin-top-25,
.mt-25 {
  margin-top: 25px; }

.margin-right-25,
.mr-25 {
  margin-right: 25px; }

.margin-bottom-25,
.mb-25 {
  margin-bottom: 25px; }

.margin-left-25,
.ml-25 {
  margin-left: 25px; }

.margin-horizontal-25,
.mh-25 {
  margin-left: 25px;
  margin-right: 25px; }

.margin-vertical-25,
.mv-25 {
  margin-top: 25px;
  margin-bottom: 25px; }

.margin-30,
.m-30 {
  margin: 30px; }

.margin-top-30,
.mt-30 {
  margin-top: 30px; }

.margin-right-30,
.mr-30 {
  margin-right: 30px; }

.margin-bottom-30,
.mb-30 {
  margin-bottom: 30px; }

.margin-left-30,
.ml-30 {
  margin-left: 30px; }

.margin-horizontal-30,
.mh-30 {
  margin-left: 30px;
  margin-right: 30px; }

.margin-vertical-30,
.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px; }

.margin-35,
.m-35 {
  margin: 35px; }

.margin-top-35,
.mt-35 {
  margin-top: 35px; }

.margin-right-35,
.mr-35 {
  margin-right: 35px; }

.margin-bottom-35,
.mb-35 {
  margin-bottom: 35px; }

.margin-left-35,
.ml-35 {
  margin-left: 35px; }

.margin-horizontal-35,
.mh-35 {
  margin-left: 35px;
  margin-right: 35px; }

.margin-vertical-35,
.mv-35 {
  margin-top: 35px;
  margin-bottom: 35px; }

.margin-40,
.m-40 {
  margin: 40px; }

.margin-top-40,
.mt-40 {
  margin-top: 40px; }

.margin-right-40,
.mr-40 {
  margin-right: 40px; }

.margin-bottom-40,
.mb-40 {
  margin-bottom: 40px; }

.margin-left-40,
.ml-40 {
  margin-left: 40px; }

.margin-horizontal-40,
.mh-40 {
  margin-left: 40px;
  margin-right: 40px; }

.margin-vertical-40,
.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px; }

.margin-45,
.m-45 {
  margin: 45px; }

.margin-top-45,
.mt-45 {
  margin-top: 45px; }

.margin-right-45,
.mr-45 {
  margin-right: 45px; }

.margin-bottom-45,
.mb-45 {
  margin-bottom: 45px; }

.margin-left-45,
.ml-45 {
  margin-left: 45px; }

.margin-horizontal-45,
.mh-45 {
  margin-left: 45px;
  margin-right: 45px; }

.margin-vertical-45,
.mv-45 {
  margin-top: 45px;
  margin-bottom: 45px; }

.margin-50,
.m-50 {
  margin: 50px; }

.margin-top-50,
.mt-50 {
  margin-top: 50px; }

.margin-right-50,
.mr-50 {
  margin-right: 50px; }

.margin-bottom-50,
.mb-50 {
  margin-bottom: 50px; }

.margin-left-50,
.ml-50 {
  margin-left: 50px; }

.margin-horizontal-50,
.mh-50 {
  margin-left: 50px;
  margin-right: 50px; }

.margin-vertical-50,
.mv-50 {
  margin-top: 50px;
  margin-bottom: 50px; }

.margin-55,
.m-55 {
  margin: 55px; }

.margin-top-55,
.mt-55 {
  margin-top: 55px; }

.margin-right-55,
.mr-55 {
  margin-right: 55px; }

.margin-bottom-55,
.mb-55 {
  margin-bottom: 55px; }

.margin-left-55,
.ml-55 {
  margin-left: 55px; }

.margin-horizontal-55,
.mh-55 {
  margin-left: 55px;
  margin-right: 55px; }

.margin-vertical-55,
.mv-55 {
  margin-top: 55px;
  margin-bottom: 55px; }

.margin-60,
.m-60 {
  margin: 60px; }

.margin-top-60,
.mt-60 {
  margin-top: 60px; }

.margin-right-60,
.mr-60 {
  margin-right: 60px; }

.margin-bottom-60,
.mb-60 {
  margin-bottom: 60px; }

.margin-left-60,
.ml-60 {
  margin-left: 60px; }

.margin-horizontal-60,
.mh-60 {
  margin-left: 60px;
  margin-right: 60px; }

.margin-vertical-60,
.mv-60 {
  margin-top: 60px;
  margin-bottom: 60px; }

.margin-65,
.m-65 {
  margin: 65px; }

.margin-top-65,
.mt-65 {
  margin-top: 65px; }

.margin-right-65,
.mr-65 {
  margin-right: 65px; }

.margin-bottom-65,
.mb-65 {
  margin-bottom: 65px; }

.margin-left-65,
.ml-65 {
  margin-left: 65px; }

.margin-horizontal-65,
.mh-65 {
  margin-left: 65px;
  margin-right: 65px; }

.margin-vertical-65,
.mv-65 {
  margin-top: 65px;
  margin-bottom: 65px; }

.margin-70,
.m-70 {
  margin: 70px; }

.margin-top-70,
.mt-70 {
  margin-top: 70px; }

.margin-right-70,
.mr-70 {
  margin-right: 70px; }

.margin-bottom-70,
.mb-70 {
  margin-bottom: 70px; }

.margin-left-70,
.ml-70 {
  margin-left: 70px; }

.margin-horizontal-70,
.mh-70 {
  margin-left: 70px;
  margin-right: 70px; }

.margin-vertical-70,
.mv-70 {
  margin-top: 70px;
  margin-bottom: 70px; }

.margin-75,
.m-75 {
  margin: 75px; }

.margin-top-75,
.mt-75 {
  margin-top: 75px; }

.margin-right-75,
.mr-75 {
  margin-right: 75px; }

.margin-bottom-75,
.mb-75 {
  margin-bottom: 75px; }

.margin-left-75,
.ml-75 {
  margin-left: 75px; }

.margin-horizontal-75,
.mh-75 {
  margin-left: 75px;
  margin-right: 75px; }

.margin-vertical-75,
.mv-75 {
  margin-top: 75px;
  margin-bottom: 75px; }

.margin-80,
.m-80 {
  margin: 80px; }

.margin-top-80,
.mt-80 {
  margin-top: 80px; }

.margin-right-80,
.mr-80 {
  margin-right: 80px; }

.margin-bottom-80,
.mb-80 {
  margin-bottom: 80px; }

.margin-left-80,
.ml-80 {
  margin-left: 80px; }

.margin-horizontal-80,
.mh-80 {
  margin-left: 80px;
  margin-right: 80px; }

.margin-vertical-80,
.mv-80 {
  margin-top: 80px;
  margin-bottom: 80px; }

.margin-85,
.m-85 {
  margin: 85px; }

.margin-top-85,
.mt-85 {
  margin-top: 85px; }

.margin-right-85,
.mr-85 {
  margin-right: 85px; }

.margin-bottom-85,
.mb-85 {
  margin-bottom: 85px; }

.margin-left-85,
.ml-85 {
  margin-left: 85px; }

.margin-horizontal-85,
.mh-85 {
  margin-left: 85px;
  margin-right: 85px; }

.margin-vertical-85,
.mv-85 {
  margin-top: 85px;
  margin-bottom: 85px; }

.margin-90,
.m-90 {
  margin: 90px; }

.margin-top-90,
.mt-90 {
  margin-top: 90px; }

.margin-right-90,
.mr-90 {
  margin-right: 90px; }

.margin-bottom-90,
.mb-90 {
  margin-bottom: 90px; }

.margin-left-90,
.ml-90 {
  margin-left: 90px; }

.margin-horizontal-90,
.mh-90 {
  margin-left: 90px;
  margin-right: 90px; }

.margin-vertical-90,
.mv-90 {
  margin-top: 90px;
  margin-bottom: 90px; }

.margin-95,
.m-95 {
  margin: 95px; }

.margin-top-95,
.mt-95 {
  margin-top: 95px; }

.margin-right-95,
.mr-95 {
  margin-right: 95px; }

.margin-bottom-95,
.mb-95 {
  margin-bottom: 95px; }

.margin-left-95,
.ml-95 {
  margin-left: 95px; }

.margin-horizontal-95,
.mh-95 {
  margin-left: 95px;
  margin-right: 95px; }

.margin-vertical-95,
.mv-95 {
  margin-top: 95px;
  margin-bottom: 95px; }

.margin-100,
.m-100 {
  margin: 100px; }

.margin-top-100,
.mt-100 {
  margin-top: 100px; }

.margin-right-100,
.mr-100 {
  margin-right: 100px; }

.margin-bottom-100,
.mb-100 {
  margin-bottom: 100px; }

.margin-left-100,
.ml-100 {
  margin-left: 100px; }

.margin-horizontal-100,
.mh-100 {
  margin-left: 100px;
  margin-right: 100px; }

.margin-vertical-100,
.mv-100 {
  margin-top: 100px;
  margin-bottom: 100px; }

.margin-8,
.m-8 {
  margin: 8px; }

.margin-top-8,
.mt-8 {
  margin-top: 8px; }

.margin-right-8,
.mr-8 {
  margin-right: 8px; }

.margin-bottom-8,
.mb-8 {
  margin-bottom: 8px; }

.margin-left-8,
.ml-8 {
  margin-left: 8px; }

.margin-horizontal-8,
.mh-8 {
  margin-left: 8px;
  margin-right: 8px; }

.margin-vertical-8,
.mv-8 {
  margin-top: 8px;
  margin-bottom: 8px; }

.margin-16,
.m-16 {
  margin: 16px; }

.margin-top-16,
.mt-16 {
  margin-top: 16px; }

.margin-right-16,
.mr-16 {
  margin-right: 16px; }

.margin-bottom-16,
.mb-16 {
  margin-bottom: 16px; }

.margin-left-16,
.ml-16 {
  margin-left: 16px; }

.margin-horizontal-16,
.mh-16 {
  margin-left: 16px;
  margin-right: 16px; }

.margin-vertical-16,
.mv-16 {
  margin-top: 16px;
  margin-bottom: 16px; }

.margin-24,
.m-24 {
  margin: 24px; }

.margin-top-24,
.mt-24 {
  margin-top: 24px; }

.margin-right-24,
.mr-24 {
  margin-right: 24px; }

.margin-bottom-24,
.mb-24 {
  margin-bottom: 24px; }

.margin-left-24,
.ml-24 {
  margin-left: 24px; }

.margin-horizontal-24,
.mh-24 {
  margin-left: 24px;
  margin-right: 24px; }

.margin-vertical-24,
.mv-24 {
  margin-top: 24px;
  margin-bottom: 24px; }

.margin-32,
.m-32 {
  margin: 32px; }

.margin-top-32,
.mt-32 {
  margin-top: 32px; }

.margin-right-32,
.mr-32 {
  margin-right: 32px; }

.margin-bottom-32,
.mb-32 {
  margin-bottom: 32px; }

.margin-left-32,
.ml-32 {
  margin-left: 32px; }

.margin-horizontal-32,
.mh-32 {
  margin-left: 32px;
  margin-right: 32px; }

.margin-vertical-32,
.mv-32 {
  margin-top: 32px;
  margin-bottom: 32px; }

.margin-48,
.m-48 {
  margin: 48px; }

.margin-top-48,
.mt-48 {
  margin-top: 48px; }

.margin-right-48,
.mr-48 {
  margin-right: 48px; }

.margin-bottom-48,
.mb-48 {
  margin-bottom: 48px; }

.margin-left-48,
.ml-48 {
  margin-left: 48px; }

.margin-horizontal-48,
.mh-48 {
  margin-left: 48px;
  margin-right: 48px; }

.margin-vertical-48,
.mv-48 {
  margin-top: 48px;
  margin-bottom: 48px; }

.margin-56,
.m-56 {
  margin: 56px; }

.margin-top-56,
.mt-56 {
  margin-top: 56px; }

.margin-right-56,
.mr-56 {
  margin-right: 56px; }

.margin-bottom-56,
.mb-56 {
  margin-bottom: 56px; }

.margin-left-56,
.ml-56 {
  margin-left: 56px; }

.margin-horizontal-56,
.mh-56 {
  margin-left: 56px;
  margin-right: 56px; }

.margin-vertical-56,
.mv-56 {
  margin-top: 56px;
  margin-bottom: 56px; }

.margin-64,
.m-64 {
  margin: 64px; }

.margin-top-64,
.mt-64 {
  margin-top: 64px; }

.margin-right-64,
.mr-64 {
  margin-right: 64px; }

.margin-bottom-64,
.mb-64 {
  margin-bottom: 64px; }

.margin-left-64,
.ml-64 {
  margin-left: 64px; }

.margin-horizontal-64,
.mh-64 {
  margin-left: 64px;
  margin-right: 64px; }

.margin-vertical-64,
.mv-64 {
  margin-top: 64px;
  margin-bottom: 64px; }

.margin-72,
.m-72 {
  margin: 72px; }

.margin-top-72,
.mt-72 {
  margin-top: 72px; }

.margin-right-72,
.mr-72 {
  margin-right: 72px; }

.margin-bottom-72,
.mb-72 {
  margin-bottom: 72px; }

.margin-left-72,
.ml-72 {
  margin-left: 72px; }

.margin-horizontal-72,
.mh-72 {
  margin-left: 72px;
  margin-right: 72px; }

.margin-vertical-72,
.mv-72 {
  margin-top: 72px;
  margin-bottom: 72px; }

.margin-88,
.m-88 {
  margin: 88px; }

.margin-top-88,
.mt-88 {
  margin-top: 88px; }

.margin-right-88,
.mr-88 {
  margin-right: 88px; }

.margin-bottom-88,
.mb-88 {
  margin-bottom: 88px; }

.margin-left-88,
.ml-88 {
  margin-left: 88px; }

.margin-horizontal-88,
.mh-88 {
  margin-left: 88px;
  margin-right: 88px; }

.margin-vertical-88,
.mv-88 {
  margin-top: 88px;
  margin-bottom: 88px; }

.margin-96,
.m-96 {
  margin: 96px; }

.margin-top-96,
.mt-96 {
  margin-top: 96px; }

.margin-right-96,
.mr-96 {
  margin-right: 96px; }

.margin-bottom-96,
.mb-96 {
  margin-bottom: 96px; }

.margin-left-96,
.ml-96 {
  margin-left: 96px; }

.margin-horizontal-96,
.mh-96 {
  margin-left: 96px;
  margin-right: 96px; }

.margin-vertical-96,
.mv-96 {
  margin-top: 96px;
  margin-bottom: 96px; }

.height-0,
.h-0 {
  height: 0px !important;
  min-height: 0px !important;
  max-height: 0px !important; }

.width-0,
.w-0 {
  width: 0px !important;
  min-width: 0px !important;
  max-width: 0px !important; }

.height-5,
.h-5 {
  height: 5px !important;
  min-height: 5px !important;
  max-height: 5px !important; }

.width-5,
.w-5 {
  width: 5px !important;
  min-width: 5px !important;
  max-width: 5px !important; }

.height-10,
.h-10 {
  height: 10px !important;
  min-height: 10px !important;
  max-height: 10px !important; }

.width-10,
.w-10 {
  width: 10px !important;
  min-width: 10px !important;
  max-width: 10px !important; }

.height-15,
.h-15 {
  height: 15px !important;
  min-height: 15px !important;
  max-height: 15px !important; }

.width-15,
.w-15 {
  width: 15px !important;
  min-width: 15px !important;
  max-width: 15px !important; }

.height-20,
.h-20 {
  height: 20px !important;
  min-height: 20px !important;
  max-height: 20px !important; }

.width-20,
.w-20 {
  width: 20px !important;
  min-width: 20px !important;
  max-width: 20px !important; }

.height-25,
.h-25 {
  height: 25px !important;
  min-height: 25px !important;
  max-height: 25px !important; }

.width-25,
.w-25 {
  width: 25px !important;
  min-width: 25px !important;
  max-width: 25px !important; }

.height-30,
.h-30 {
  height: 30px !important;
  min-height: 30px !important;
  max-height: 30px !important; }

.width-30,
.w-30 {
  width: 30px !important;
  min-width: 30px !important;
  max-width: 30px !important; }

.height-35,
.h-35 {
  height: 35px !important;
  min-height: 35px !important;
  max-height: 35px !important; }

.width-35,
.w-35 {
  width: 35px !important;
  min-width: 35px !important;
  max-width: 35px !important; }

.height-40,
.h-40 {
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important; }

.width-40,
.w-40 {
  width: 40px !important;
  min-width: 40px !important;
  max-width: 40px !important; }

.height-45,
.h-45 {
  height: 45px !important;
  min-height: 45px !important;
  max-height: 45px !important; }

.width-45,
.w-45 {
  width: 45px !important;
  min-width: 45px !important;
  max-width: 45px !important; }

.height-50,
.h-50 {
  height: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important; }

.width-50,
.w-50 {
  width: 50px !important;
  min-width: 50px !important;
  max-width: 50px !important; }

.height-55,
.h-55 {
  height: 55px !important;
  min-height: 55px !important;
  max-height: 55px !important; }

.width-55,
.w-55 {
  width: 55px !important;
  min-width: 55px !important;
  max-width: 55px !important; }

.height-60,
.h-60 {
  height: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important; }

.width-60,
.w-60 {
  width: 60px !important;
  min-width: 60px !important;
  max-width: 60px !important; }

.height-65,
.h-65 {
  height: 65px !important;
  min-height: 65px !important;
  max-height: 65px !important; }

.width-65,
.w-65 {
  width: 65px !important;
  min-width: 65px !important;
  max-width: 65px !important; }

.height-70,
.h-70 {
  height: 70px !important;
  min-height: 70px !important;
  max-height: 70px !important; }

.width-70,
.w-70 {
  width: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important; }

.height-75,
.h-75 {
  height: 75px !important;
  min-height: 75px !important;
  max-height: 75px !important; }

.width-75,
.w-75 {
  width: 75px !important;
  min-width: 75px !important;
  max-width: 75px !important; }

.height-80,
.h-80 {
  height: 80px !important;
  min-height: 80px !important;
  max-height: 80px !important; }

.width-80,
.w-80 {
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important; }

.height-85,
.h-85 {
  height: 85px !important;
  min-height: 85px !important;
  max-height: 85px !important; }

.width-85,
.w-85 {
  width: 85px !important;
  min-width: 85px !important;
  max-width: 85px !important; }

.height-90,
.h-90 {
  height: 90px !important;
  min-height: 90px !important;
  max-height: 90px !important; }

.width-90,
.w-90 {
  width: 90px !important;
  min-width: 90px !important;
  max-width: 90px !important; }

.height-95,
.h-95 {
  height: 95px !important;
  min-height: 95px !important;
  max-height: 95px !important; }

.width-95,
.w-95 {
  width: 95px !important;
  min-width: 95px !important;
  max-width: 95px !important; }

.height-100,
.h-100 {
  height: 100px !important;
  min-height: 100px !important;
  max-height: 100px !important; }

.width-100,
.w-100 {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important; }

.height-105,
.h-105 {
  height: 105px !important;
  min-height: 105px !important;
  max-height: 105px !important; }

.width-105,
.w-105 {
  width: 105px !important;
  min-width: 105px !important;
  max-width: 105px !important; }

.height-110,
.h-110 {
  height: 110px !important;
  min-height: 110px !important;
  max-height: 110px !important; }

.width-110,
.w-110 {
  width: 110px !important;
  min-width: 110px !important;
  max-width: 110px !important; }

.height-115,
.h-115 {
  height: 115px !important;
  min-height: 115px !important;
  max-height: 115px !important; }

.width-115,
.w-115 {
  width: 115px !important;
  min-width: 115px !important;
  max-width: 115px !important; }

.height-120,
.h-120 {
  height: 120px !important;
  min-height: 120px !important;
  max-height: 120px !important; }

.width-120,
.w-120 {
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important; }

.height-125,
.h-125 {
  height: 125px !important;
  min-height: 125px !important;
  max-height: 125px !important; }

.width-125,
.w-125 {
  width: 125px !important;
  min-width: 125px !important;
  max-width: 125px !important; }

.height-130,
.h-130 {
  height: 130px !important;
  min-height: 130px !important;
  max-height: 130px !important; }

.width-130,
.w-130 {
  width: 130px !important;
  min-width: 130px !important;
  max-width: 130px !important; }

.height-135,
.h-135 {
  height: 135px !important;
  min-height: 135px !important;
  max-height: 135px !important; }

.width-135,
.w-135 {
  width: 135px !important;
  min-width: 135px !important;
  max-width: 135px !important; }

.height-140,
.h-140 {
  height: 140px !important;
  min-height: 140px !important;
  max-height: 140px !important; }

.width-140,
.w-140 {
  width: 140px !important;
  min-width: 140px !important;
  max-width: 140px !important; }

.height-145,
.h-145 {
  height: 145px !important;
  min-height: 145px !important;
  max-height: 145px !important; }

.width-145,
.w-145 {
  width: 145px !important;
  min-width: 145px !important;
  max-width: 145px !important; }

.height-150,
.h-150 {
  height: 150px !important;
  min-height: 150px !important;
  max-height: 150px !important; }

.width-150,
.w-150 {
  width: 150px !important;
  min-width: 150px !important;
  max-width: 150px !important; }

.height-155,
.h-155 {
  height: 155px !important;
  min-height: 155px !important;
  max-height: 155px !important; }

.width-155,
.w-155 {
  width: 155px !important;
  min-width: 155px !important;
  max-width: 155px !important; }

.height-160,
.h-160 {
  height: 160px !important;
  min-height: 160px !important;
  max-height: 160px !important; }

.width-160,
.w-160 {
  width: 160px !important;
  min-width: 160px !important;
  max-width: 160px !important; }

.height-165,
.h-165 {
  height: 165px !important;
  min-height: 165px !important;
  max-height: 165px !important; }

.width-165,
.w-165 {
  width: 165px !important;
  min-width: 165px !important;
  max-width: 165px !important; }

.height-170,
.h-170 {
  height: 170px !important;
  min-height: 170px !important;
  max-height: 170px !important; }

.width-170,
.w-170 {
  width: 170px !important;
  min-width: 170px !important;
  max-width: 170px !important; }

.height-175,
.h-175 {
  height: 175px !important;
  min-height: 175px !important;
  max-height: 175px !important; }

.width-175,
.w-175 {
  width: 175px !important;
  min-width: 175px !important;
  max-width: 175px !important; }

.height-180,
.h-180 {
  height: 180px !important;
  min-height: 180px !important;
  max-height: 180px !important; }

.width-180,
.w-180 {
  width: 180px !important;
  min-width: 180px !important;
  max-width: 180px !important; }

.height-185,
.h-185 {
  height: 185px !important;
  min-height: 185px !important;
  max-height: 185px !important; }

.width-185,
.w-185 {
  width: 185px !important;
  min-width: 185px !important;
  max-width: 185px !important; }

.height-190,
.h-190 {
  height: 190px !important;
  min-height: 190px !important;
  max-height: 190px !important; }

.width-190,
.w-190 {
  width: 190px !important;
  min-width: 190px !important;
  max-width: 190px !important; }

.height-195,
.h-195 {
  height: 195px !important;
  min-height: 195px !important;
  max-height: 195px !important; }

.width-195,
.w-195 {
  width: 195px !important;
  min-width: 195px !important;
  max-width: 195px !important; }

.height-200,
.h-200 {
  height: 200px !important;
  min-height: 200px !important;
  max-height: 200px !important; }

.width-200,
.w-200 {
  width: 200px !important;
  min-width: 200px !important;
  max-width: 200px !important; }

.height-205,
.h-205 {
  height: 205px !important;
  min-height: 205px !important;
  max-height: 205px !important; }

.width-205,
.w-205 {
  width: 205px !important;
  min-width: 205px !important;
  max-width: 205px !important; }

.height-210,
.h-210 {
  height: 210px !important;
  min-height: 210px !important;
  max-height: 210px !important; }

.width-210,
.w-210 {
  width: 210px !important;
  min-width: 210px !important;
  max-width: 210px !important; }

.height-215,
.h-215 {
  height: 215px !important;
  min-height: 215px !important;
  max-height: 215px !important; }

.width-215,
.w-215 {
  width: 215px !important;
  min-width: 215px !important;
  max-width: 215px !important; }

.height-220,
.h-220 {
  height: 220px !important;
  min-height: 220px !important;
  max-height: 220px !important; }

.width-220,
.w-220 {
  width: 220px !important;
  min-width: 220px !important;
  max-width: 220px !important; }

.height-225,
.h-225 {
  height: 225px !important;
  min-height: 225px !important;
  max-height: 225px !important; }

.width-225,
.w-225 {
  width: 225px !important;
  min-width: 225px !important;
  max-width: 225px !important; }

.height-230,
.h-230 {
  height: 230px !important;
  min-height: 230px !important;
  max-height: 230px !important; }

.width-230,
.w-230 {
  width: 230px !important;
  min-width: 230px !important;
  max-width: 230px !important; }

.height-235,
.h-235 {
  height: 235px !important;
  min-height: 235px !important;
  max-height: 235px !important; }

.width-235,
.w-235 {
  width: 235px !important;
  min-width: 235px !important;
  max-width: 235px !important; }

.height-240,
.h-240 {
  height: 240px !important;
  min-height: 240px !important;
  max-height: 240px !important; }

.width-240,
.w-240 {
  width: 240px !important;
  min-width: 240px !important;
  max-width: 240px !important; }

.height-245,
.h-245 {
  height: 245px !important;
  min-height: 245px !important;
  max-height: 245px !important; }

.width-245,
.w-245 {
  width: 245px !important;
  min-width: 245px !important;
  max-width: 245px !important; }

.height-250,
.h-250 {
  height: 250px !important;
  min-height: 250px !important;
  max-height: 250px !important; }

.width-250,
.w-250 {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important; }

.height-255,
.h-255 {
  height: 255px !important;
  min-height: 255px !important;
  max-height: 255px !important; }

.width-255,
.w-255 {
  width: 255px !important;
  min-width: 255px !important;
  max-width: 255px !important; }

.height-260,
.h-260 {
  height: 260px !important;
  min-height: 260px !important;
  max-height: 260px !important; }

.width-260,
.w-260 {
  width: 260px !important;
  min-width: 260px !important;
  max-width: 260px !important; }

.height-265,
.h-265 {
  height: 265px !important;
  min-height: 265px !important;
  max-height: 265px !important; }

.width-265,
.w-265 {
  width: 265px !important;
  min-width: 265px !important;
  max-width: 265px !important; }

.height-270,
.h-270 {
  height: 270px !important;
  min-height: 270px !important;
  max-height: 270px !important; }

.width-270,
.w-270 {
  width: 270px !important;
  min-width: 270px !important;
  max-width: 270px !important; }

.height-275,
.h-275 {
  height: 275px !important;
  min-height: 275px !important;
  max-height: 275px !important; }

.width-275,
.w-275 {
  width: 275px !important;
  min-width: 275px !important;
  max-width: 275px !important; }

.height-280,
.h-280 {
  height: 280px !important;
  min-height: 280px !important;
  max-height: 280px !important; }

.width-280,
.w-280 {
  width: 280px !important;
  min-width: 280px !important;
  max-width: 280px !important; }

.height-285,
.h-285 {
  height: 285px !important;
  min-height: 285px !important;
  max-height: 285px !important; }

.width-285,
.w-285 {
  width: 285px !important;
  min-width: 285px !important;
  max-width: 285px !important; }

.height-290,
.h-290 {
  height: 290px !important;
  min-height: 290px !important;
  max-height: 290px !important; }

.width-290,
.w-290 {
  width: 290px !important;
  min-width: 290px !important;
  max-width: 290px !important; }

.height-295,
.h-295 {
  height: 295px !important;
  min-height: 295px !important;
  max-height: 295px !important; }

.width-295,
.w-295 {
  width: 295px !important;
  min-width: 295px !important;
  max-width: 295px !important; }

.height-300,
.h-300 {
  height: 300px !important;
  min-height: 300px !important;
  max-height: 300px !important; }

.width-300,
.w-300 {
  width: 300px !important;
  min-width: 300px !important;
  max-width: 300px !important; }

.height-305,
.h-305 {
  height: 305px !important;
  min-height: 305px !important;
  max-height: 305px !important; }

.width-305,
.w-305 {
  width: 305px !important;
  min-width: 305px !important;
  max-width: 305px !important; }

.height-310,
.h-310 {
  height: 310px !important;
  min-height: 310px !important;
  max-height: 310px !important; }

.width-310,
.w-310 {
  width: 310px !important;
  min-width: 310px !important;
  max-width: 310px !important; }

.height-315,
.h-315 {
  height: 315px !important;
  min-height: 315px !important;
  max-height: 315px !important; }

.width-315,
.w-315 {
  width: 315px !important;
  min-width: 315px !important;
  max-width: 315px !important; }

.height-320,
.h-320 {
  height: 320px !important;
  min-height: 320px !important;
  max-height: 320px !important; }

.width-320,
.w-320 {
  width: 320px !important;
  min-width: 320px !important;
  max-width: 320px !important; }

.height-325,
.h-325 {
  height: 325px !important;
  min-height: 325px !important;
  max-height: 325px !important; }

.width-325,
.w-325 {
  width: 325px !important;
  min-width: 325px !important;
  max-width: 325px !important; }

.height-330,
.h-330 {
  height: 330px !important;
  min-height: 330px !important;
  max-height: 330px !important; }

.width-330,
.w-330 {
  width: 330px !important;
  min-width: 330px !important;
  max-width: 330px !important; }

.height-335,
.h-335 {
  height: 335px !important;
  min-height: 335px !important;
  max-height: 335px !important; }

.width-335,
.w-335 {
  width: 335px !important;
  min-width: 335px !important;
  max-width: 335px !important; }

.height-340,
.h-340 {
  height: 340px !important;
  min-height: 340px !important;
  max-height: 340px !important; }

.width-340,
.w-340 {
  width: 340px !important;
  min-width: 340px !important;
  max-width: 340px !important; }

.height-345,
.h-345 {
  height: 345px !important;
  min-height: 345px !important;
  max-height: 345px !important; }

.width-345,
.w-345 {
  width: 345px !important;
  min-width: 345px !important;
  max-width: 345px !important; }

.height-350,
.h-350 {
  height: 350px !important;
  min-height: 350px !important;
  max-height: 350px !important; }

.width-350,
.w-350 {
  width: 350px !important;
  min-width: 350px !important;
  max-width: 350px !important; }

.height-355,
.h-355 {
  height: 355px !important;
  min-height: 355px !important;
  max-height: 355px !important; }

.width-355,
.w-355 {
  width: 355px !important;
  min-width: 355px !important;
  max-width: 355px !important; }

.height-360,
.h-360 {
  height: 360px !important;
  min-height: 360px !important;
  max-height: 360px !important; }

.width-360,
.w-360 {
  width: 360px !important;
  min-width: 360px !important;
  max-width: 360px !important; }

.height-365,
.h-365 {
  height: 365px !important;
  min-height: 365px !important;
  max-height: 365px !important; }

.width-365,
.w-365 {
  width: 365px !important;
  min-width: 365px !important;
  max-width: 365px !important; }

.height-370,
.h-370 {
  height: 370px !important;
  min-height: 370px !important;
  max-height: 370px !important; }

.width-370,
.w-370 {
  width: 370px !important;
  min-width: 370px !important;
  max-width: 370px !important; }

.height-375,
.h-375 {
  height: 375px !important;
  min-height: 375px !important;
  max-height: 375px !important; }

.width-375,
.w-375 {
  width: 375px !important;
  min-width: 375px !important;
  max-width: 375px !important; }

.height-380,
.h-380 {
  height: 380px !important;
  min-height: 380px !important;
  max-height: 380px !important; }

.width-380,
.w-380 {
  width: 380px !important;
  min-width: 380px !important;
  max-width: 380px !important; }

.height-385,
.h-385 {
  height: 385px !important;
  min-height: 385px !important;
  max-height: 385px !important; }

.width-385,
.w-385 {
  width: 385px !important;
  min-width: 385px !important;
  max-width: 385px !important; }

.height-390,
.h-390 {
  height: 390px !important;
  min-height: 390px !important;
  max-height: 390px !important; }

.width-390,
.w-390 {
  width: 390px !important;
  min-width: 390px !important;
  max-width: 390px !important; }

.height-395,
.h-395 {
  height: 395px !important;
  min-height: 395px !important;
  max-height: 395px !important; }

.width-395,
.w-395 {
  width: 395px !important;
  min-width: 395px !important;
  max-width: 395px !important; }

.height-400,
.h-400 {
  height: 400px !important;
  min-height: 400px !important;
  max-height: 400px !important; }

.width-400,
.w-400 {
  width: 400px !important;
  min-width: 400px !important;
  max-width: 400px !important; }

.height-405,
.h-405 {
  height: 405px !important;
  min-height: 405px !important;
  max-height: 405px !important; }

.width-405,
.w-405 {
  width: 405px !important;
  min-width: 405px !important;
  max-width: 405px !important; }

.height-410,
.h-410 {
  height: 410px !important;
  min-height: 410px !important;
  max-height: 410px !important; }

.width-410,
.w-410 {
  width: 410px !important;
  min-width: 410px !important;
  max-width: 410px !important; }

.height-415,
.h-415 {
  height: 415px !important;
  min-height: 415px !important;
  max-height: 415px !important; }

.width-415,
.w-415 {
  width: 415px !important;
  min-width: 415px !important;
  max-width: 415px !important; }

.height-420,
.h-420 {
  height: 420px !important;
  min-height: 420px !important;
  max-height: 420px !important; }

.width-420,
.w-420 {
  width: 420px !important;
  min-width: 420px !important;
  max-width: 420px !important; }

.height-425,
.h-425 {
  height: 425px !important;
  min-height: 425px !important;
  max-height: 425px !important; }

.width-425,
.w-425 {
  width: 425px !important;
  min-width: 425px !important;
  max-width: 425px !important; }

.height-430,
.h-430 {
  height: 430px !important;
  min-height: 430px !important;
  max-height: 430px !important; }

.width-430,
.w-430 {
  width: 430px !important;
  min-width: 430px !important;
  max-width: 430px !important; }

.height-435,
.h-435 {
  height: 435px !important;
  min-height: 435px !important;
  max-height: 435px !important; }

.width-435,
.w-435 {
  width: 435px !important;
  min-width: 435px !important;
  max-width: 435px !important; }

.height-440,
.h-440 {
  height: 440px !important;
  min-height: 440px !important;
  max-height: 440px !important; }

.width-440,
.w-440 {
  width: 440px !important;
  min-width: 440px !important;
  max-width: 440px !important; }

.height-445,
.h-445 {
  height: 445px !important;
  min-height: 445px !important;
  max-height: 445px !important; }

.width-445,
.w-445 {
  width: 445px !important;
  min-width: 445px !important;
  max-width: 445px !important; }

.height-450,
.h-450 {
  height: 450px !important;
  min-height: 450px !important;
  max-height: 450px !important; }

.width-450,
.w-450 {
  width: 450px !important;
  min-width: 450px !important;
  max-width: 450px !important; }

.height-455,
.h-455 {
  height: 455px !important;
  min-height: 455px !important;
  max-height: 455px !important; }

.width-455,
.w-455 {
  width: 455px !important;
  min-width: 455px !important;
  max-width: 455px !important; }

.height-460,
.h-460 {
  height: 460px !important;
  min-height: 460px !important;
  max-height: 460px !important; }

.width-460,
.w-460 {
  width: 460px !important;
  min-width: 460px !important;
  max-width: 460px !important; }

.height-465,
.h-465 {
  height: 465px !important;
  min-height: 465px !important;
  max-height: 465px !important; }

.width-465,
.w-465 {
  width: 465px !important;
  min-width: 465px !important;
  max-width: 465px !important; }

.height-470,
.h-470 {
  height: 470px !important;
  min-height: 470px !important;
  max-height: 470px !important; }

.width-470,
.w-470 {
  width: 470px !important;
  min-width: 470px !important;
  max-width: 470px !important; }

.height-475,
.h-475 {
  height: 475px !important;
  min-height: 475px !important;
  max-height: 475px !important; }

.width-475,
.w-475 {
  width: 475px !important;
  min-width: 475px !important;
  max-width: 475px !important; }

.height-480,
.h-480 {
  height: 480px !important;
  min-height: 480px !important;
  max-height: 480px !important; }

.width-480,
.w-480 {
  width: 480px !important;
  min-width: 480px !important;
  max-width: 480px !important; }

.height-485,
.h-485 {
  height: 485px !important;
  min-height: 485px !important;
  max-height: 485px !important; }

.width-485,
.w-485 {
  width: 485px !important;
  min-width: 485px !important;
  max-width: 485px !important; }

.height-490,
.h-490 {
  height: 490px !important;
  min-height: 490px !important;
  max-height: 490px !important; }

.width-490,
.w-490 {
  width: 490px !important;
  min-width: 490px !important;
  max-width: 490px !important; }

.height-495,
.h-495 {
  height: 495px !important;
  min-height: 495px !important;
  max-height: 495px !important; }

.width-495,
.w-495 {
  width: 495px !important;
  min-width: 495px !important;
  max-width: 495px !important; }

.height-500,
.h-500 {
  height: 500px !important;
  min-height: 500px !important;
  max-height: 500px !important; }

.width-500,
.w-500 {
  width: 500px !important;
  min-width: 500px !important;
  max-width: 500px !important; }

.height-8,
.h-8 {
  height: 8px !important;
  min-height: 8px !important;
  max-height: 8px !important; }

.width-8,
.w-8 {
  width: 8px !important;
  min-width: 8px !important;
  max-width: 8px !important; }

.height-16,
.h-16 {
  height: 16px !important;
  min-height: 16px !important;
  max-height: 16px !important; }

.width-16,
.w-16 {
  width: 16px !important;
  min-width: 16px !important;
  max-width: 16px !important; }

.height-24,
.h-24 {
  height: 24px !important;
  min-height: 24px !important;
  max-height: 24px !important; }

.width-24,
.w-24 {
  width: 24px !important;
  min-width: 24px !important;
  max-width: 24px !important; }

.height-32,
.h-32 {
  height: 32px !important;
  min-height: 32px !important;
  max-height: 32px !important; }

.width-32,
.w-32 {
  width: 32px !important;
  min-width: 32px !important;
  max-width: 32px !important; }

.height-48,
.h-48 {
  height: 48px !important;
  min-height: 48px !important;
  max-height: 48px !important; }

.width-48,
.w-48 {
  width: 48px !important;
  min-width: 48px !important;
  max-width: 48px !important; }

.height-56,
.h-56 {
  height: 56px !important;
  min-height: 56px !important;
  max-height: 56px !important; }

.width-56,
.w-56 {
  width: 56px !important;
  min-width: 56px !important;
  max-width: 56px !important; }

.height-64,
.h-64 {
  height: 64px !important;
  min-height: 64px !important;
  max-height: 64px !important; }

.width-64,
.w-64 {
  width: 64px !important;
  min-width: 64px !important;
  max-width: 64px !important; }

.height-72,
.h-72 {
  height: 72px !important;
  min-height: 72px !important;
  max-height: 72px !important; }

.width-72,
.w-72 {
  width: 72px !important;
  min-width: 72px !important;
  max-width: 72px !important; }

.height-88,
.h-88 {
  height: 88px !important;
  min-height: 88px !important;
  max-height: 88px !important; }

.width-88,
.w-88 {
  width: 88px !important;
  min-width: 88px !important;
  max-width: 88px !important; }

.height-96,
.h-96 {
  height: 96px !important;
  min-height: 96px !important;
  max-height: 96px !important; }

.width-96,
.w-96 {
  width: 96px !important;
  min-width: 96px !important;
  max-width: 96px !important; }

.height-104,
.h-104 {
  height: 104px !important;
  min-height: 104px !important;
  max-height: 104px !important; }

.width-104,
.w-104 {
  width: 104px !important;
  min-width: 104px !important;
  max-width: 104px !important; }

.height-112,
.h-112 {
  height: 112px !important;
  min-height: 112px !important;
  max-height: 112px !important; }

.width-112,
.w-112 {
  width: 112px !important;
  min-width: 112px !important;
  max-width: 112px !important; }

.height-128,
.h-128 {
  height: 128px !important;
  min-height: 128px !important;
  max-height: 128px !important; }

.width-128,
.w-128 {
  width: 128px !important;
  min-width: 128px !important;
  max-width: 128px !important; }

.height-136,
.h-136 {
  height: 136px !important;
  min-height: 136px !important;
  max-height: 136px !important; }

.width-136,
.w-136 {
  width: 136px !important;
  min-width: 136px !important;
  max-width: 136px !important; }

.height-144,
.h-144 {
  height: 144px !important;
  min-height: 144px !important;
  max-height: 144px !important; }

.width-144,
.w-144 {
  width: 144px !important;
  min-width: 144px !important;
  max-width: 144px !important; }

.height-152,
.h-152 {
  height: 152px !important;
  min-height: 152px !important;
  max-height: 152px !important; }

.width-152,
.w-152 {
  width: 152px !important;
  min-width: 152px !important;
  max-width: 152px !important; }

.height-168,
.h-168 {
  height: 168px !important;
  min-height: 168px !important;
  max-height: 168px !important; }

.width-168,
.w-168 {
  width: 168px !important;
  min-width: 168px !important;
  max-width: 168px !important; }

.height-176,
.h-176 {
  height: 176px !important;
  min-height: 176px !important;
  max-height: 176px !important; }

.width-176,
.w-176 {
  width: 176px !important;
  min-width: 176px !important;
  max-width: 176px !important; }

.height-184,
.h-184 {
  height: 184px !important;
  min-height: 184px !important;
  max-height: 184px !important; }

.width-184,
.w-184 {
  width: 184px !important;
  min-width: 184px !important;
  max-width: 184px !important; }

.height-192,
.h-192 {
  height: 192px !important;
  min-height: 192px !important;
  max-height: 192px !important; }

.width-192,
.w-192 {
  width: 192px !important;
  min-width: 192px !important;
  max-width: 192px !important; }

.height-208,
.h-208 {
  height: 208px !important;
  min-height: 208px !important;
  max-height: 208px !important; }

.width-208,
.w-208 {
  width: 208px !important;
  min-width: 208px !important;
  max-width: 208px !important; }

.height-216,
.h-216 {
  height: 216px !important;
  min-height: 216px !important;
  max-height: 216px !important; }

.width-216,
.w-216 {
  width: 216px !important;
  min-width: 216px !important;
  max-width: 216px !important; }

.height-224,
.h-224 {
  height: 224px !important;
  min-height: 224px !important;
  max-height: 224px !important; }

.width-224,
.w-224 {
  width: 224px !important;
  min-width: 224px !important;
  max-width: 224px !important; }

.height-232,
.h-232 {
  height: 232px !important;
  min-height: 232px !important;
  max-height: 232px !important; }

.width-232,
.w-232 {
  width: 232px !important;
  min-width: 232px !important;
  max-width: 232px !important; }

.height-248,
.h-248 {
  height: 248px !important;
  min-height: 248px !important;
  max-height: 248px !important; }

.width-248,
.w-248 {
  width: 248px !important;
  min-width: 248px !important;
  max-width: 248px !important; }

.height-256,
.h-256 {
  height: 256px !important;
  min-height: 256px !important;
  max-height: 256px !important; }

.width-256,
.w-256 {
  width: 256px !important;
  min-width: 256px !important;
  max-width: 256px !important; }

.height-264,
.h-264 {
  height: 264px !important;
  min-height: 264px !important;
  max-height: 264px !important; }

.width-264,
.w-264 {
  width: 264px !important;
  min-width: 264px !important;
  max-width: 264px !important; }

.height-272,
.h-272 {
  height: 272px !important;
  min-height: 272px !important;
  max-height: 272px !important; }

.width-272,
.w-272 {
  width: 272px !important;
  min-width: 272px !important;
  max-width: 272px !important; }

.height-288,
.h-288 {
  height: 288px !important;
  min-height: 288px !important;
  max-height: 288px !important; }

.width-288,
.w-288 {
  width: 288px !important;
  min-width: 288px !important;
  max-width: 288px !important; }

.height-296,
.h-296 {
  height: 296px !important;
  min-height: 296px !important;
  max-height: 296px !important; }

.width-296,
.w-296 {
  width: 296px !important;
  min-width: 296px !important;
  max-width: 296px !important; }

.height-304,
.h-304 {
  height: 304px !important;
  min-height: 304px !important;
  max-height: 304px !important; }

.width-304,
.w-304 {
  width: 304px !important;
  min-width: 304px !important;
  max-width: 304px !important; }

.height-312,
.h-312 {
  height: 312px !important;
  min-height: 312px !important;
  max-height: 312px !important; }

.width-312,
.w-312 {
  width: 312px !important;
  min-width: 312px !important;
  max-width: 312px !important; }

.height-328,
.h-328 {
  height: 328px !important;
  min-height: 328px !important;
  max-height: 328px !important; }

.width-328,
.w-328 {
  width: 328px !important;
  min-width: 328px !important;
  max-width: 328px !important; }

.height-336,
.h-336 {
  height: 336px !important;
  min-height: 336px !important;
  max-height: 336px !important; }

.width-336,
.w-336 {
  width: 336px !important;
  min-width: 336px !important;
  max-width: 336px !important; }

.height-344,
.h-344 {
  height: 344px !important;
  min-height: 344px !important;
  max-height: 344px !important; }

.width-344,
.w-344 {
  width: 344px !important;
  min-width: 344px !important;
  max-width: 344px !important; }

.height-352,
.h-352 {
  height: 352px !important;
  min-height: 352px !important;
  max-height: 352px !important; }

.width-352,
.w-352 {
  width: 352px !important;
  min-width: 352px !important;
  max-width: 352px !important; }

.height-368,
.h-368 {
  height: 368px !important;
  min-height: 368px !important;
  max-height: 368px !important; }

.width-368,
.w-368 {
  width: 368px !important;
  min-width: 368px !important;
  max-width: 368px !important; }

.height-376,
.h-376 {
  height: 376px !important;
  min-height: 376px !important;
  max-height: 376px !important; }

.width-376,
.w-376 {
  width: 376px !important;
  min-width: 376px !important;
  max-width: 376px !important; }

.height-384,
.h-384 {
  height: 384px !important;
  min-height: 384px !important;
  max-height: 384px !important; }

.width-384,
.w-384 {
  width: 384px !important;
  min-width: 384px !important;
  max-width: 384px !important; }

.height-392,
.h-392 {
  height: 392px !important;
  min-height: 392px !important;
  max-height: 392px !important; }

.width-392,
.w-392 {
  width: 392px !important;
  min-width: 392px !important;
  max-width: 392px !important; }

.height-408,
.h-408 {
  height: 408px !important;
  min-height: 408px !important;
  max-height: 408px !important; }

.width-408,
.w-408 {
  width: 408px !important;
  min-width: 408px !important;
  max-width: 408px !important; }

.height-416,
.h-416 {
  height: 416px !important;
  min-height: 416px !important;
  max-height: 416px !important; }

.width-416,
.w-416 {
  width: 416px !important;
  min-width: 416px !important;
  max-width: 416px !important; }

.height-424,
.h-424 {
  height: 424px !important;
  min-height: 424px !important;
  max-height: 424px !important; }

.width-424,
.w-424 {
  width: 424px !important;
  min-width: 424px !important;
  max-width: 424px !important; }

.height-432,
.h-432 {
  height: 432px !important;
  min-height: 432px !important;
  max-height: 432px !important; }

.width-432,
.w-432 {
  width: 432px !important;
  min-width: 432px !important;
  max-width: 432px !important; }

.height-448,
.h-448 {
  height: 448px !important;
  min-height: 448px !important;
  max-height: 448px !important; }

.width-448,
.w-448 {
  width: 448px !important;
  min-width: 448px !important;
  max-width: 448px !important; }

.height-456,
.h-456 {
  height: 456px !important;
  min-height: 456px !important;
  max-height: 456px !important; }

.width-456,
.w-456 {
  width: 456px !important;
  min-width: 456px !important;
  max-width: 456px !important; }

.height-464,
.h-464 {
  height: 464px !important;
  min-height: 464px !important;
  max-height: 464px !important; }

.width-464,
.w-464 {
  width: 464px !important;
  min-width: 464px !important;
  max-width: 464px !important; }

.height-472,
.h-472 {
  height: 472px !important;
  min-height: 472px !important;
  max-height: 472px !important; }

.width-472,
.w-472 {
  width: 472px !important;
  min-width: 472px !important;
  max-width: 472px !important; }

.height-488,
.h-488 {
  height: 488px !important;
  min-height: 488px !important;
  max-height: 488px !important; }

.width-488,
.w-488 {
  width: 488px !important;
  min-width: 488px !important;
  max-width: 488px !important; }

.height-496,
.h-496 {
  height: 496px !important;
  min-height: 496px !important;
  max-height: 496px !important; }

.width-496,
.w-496 {
  width: 496px !important;
  min-width: 496px !important;
  max-width: 496px !important; }

.height-504,
.h-504 {
  height: 504px !important;
  min-height: 504px !important;
  max-height: 504px !important; }

.width-504,
.w-504 {
  width: 504px !important;
  min-width: 504px !important;
  max-width: 504px !important; }

.height-512,
.h-512 {
  height: 512px !important;
  min-height: 512px !important;
  max-height: 512px !important; }

.width-512,
.w-512 {
  width: 512px !important;
  min-width: 512px !important;
  max-width: 512px !important; }

.height-528,
.h-528 {
  height: 528px !important;
  min-height: 528px !important;
  max-height: 528px !important; }

.width-528,
.w-528 {
  width: 528px !important;
  min-width: 528px !important;
  max-width: 528px !important; }

.height-536,
.h-536 {
  height: 536px !important;
  min-height: 536px !important;
  max-height: 536px !important; }

.width-536,
.w-536 {
  width: 536px !important;
  min-width: 536px !important;
  max-width: 536px !important; }

.height-544,
.h-544 {
  height: 544px !important;
  min-height: 544px !important;
  max-height: 544px !important; }

.width-544,
.w-544 {
  width: 544px !important;
  min-width: 544px !important;
  max-width: 544px !important; }

.height-552,
.h-552 {
  height: 552px !important;
  min-height: 552px !important;
  max-height: 552px !important; }

.width-552,
.w-552 {
  width: 552px !important;
  min-width: 552px !important;
  max-width: 552px !important; }

.height-568,
.h-568 {
  height: 568px !important;
  min-height: 568px !important;
  max-height: 568px !important; }

.width-568,
.w-568 {
  width: 568px !important;
  min-width: 568px !important;
  max-width: 568px !important; }

.height-576,
.h-576 {
  height: 576px !important;
  min-height: 576px !important;
  max-height: 576px !important; }

.width-576,
.w-576 {
  width: 576px !important;
  min-width: 576px !important;
  max-width: 576px !important; }

.height-584,
.h-584 {
  height: 584px !important;
  min-height: 584px !important;
  max-height: 584px !important; }

.width-584,
.w-584 {
  width: 584px !important;
  min-width: 584px !important;
  max-width: 584px !important; }

.height-592,
.h-592 {
  height: 592px !important;
  min-height: 592px !important;
  max-height: 592px !important; }

.width-592,
.w-592 {
  width: 592px !important;
  min-width: 592px !important;
  max-width: 592px !important; }

.height-608,
.h-608 {
  height: 608px !important;
  min-height: 608px !important;
  max-height: 608px !important; }

.width-608,
.w-608 {
  width: 608px !important;
  min-width: 608px !important;
  max-width: 608px !important; }

.height-616,
.h-616 {
  height: 616px !important;
  min-height: 616px !important;
  max-height: 616px !important; }

.width-616,
.w-616 {
  width: 616px !important;
  min-width: 616px !important;
  max-width: 616px !important; }

.height-624,
.h-624 {
  height: 624px !important;
  min-height: 624px !important;
  max-height: 624px !important; }

.width-624,
.w-624 {
  width: 624px !important;
  min-width: 624px !important;
  max-width: 624px !important; }

.height-632,
.h-632 {
  height: 632px !important;
  min-height: 632px !important;
  max-height: 632px !important; }

.width-632,
.w-632 {
  width: 632px !important;
  min-width: 632px !important;
  max-width: 632px !important; }

.height-648,
.h-648 {
  height: 648px !important;
  min-height: 648px !important;
  max-height: 648px !important; }

.width-648,
.w-648 {
  width: 648px !important;
  min-width: 648px !important;
  max-width: 648px !important; }

.height-656,
.h-656 {
  height: 656px !important;
  min-height: 656px !important;
  max-height: 656px !important; }

.width-656,
.w-656 {
  width: 656px !important;
  min-width: 656px !important;
  max-width: 656px !important; }

.height-664,
.h-664 {
  height: 664px !important;
  min-height: 664px !important;
  max-height: 664px !important; }

.width-664,
.w-664 {
  width: 664px !important;
  min-width: 664px !important;
  max-width: 664px !important; }

.height-672,
.h-672 {
  height: 672px !important;
  min-height: 672px !important;
  max-height: 672px !important; }

.width-672,
.w-672 {
  width: 672px !important;
  min-width: 672px !important;
  max-width: 672px !important; }

.height-688,
.h-688 {
  height: 688px !important;
  min-height: 688px !important;
  max-height: 688px !important; }

.width-688,
.w-688 {
  width: 688px !important;
  min-width: 688px !important;
  max-width: 688px !important; }

.height-696,
.h-696 {
  height: 696px !important;
  min-height: 696px !important;
  max-height: 696px !important; }

.width-696,
.w-696 {
  width: 696px !important;
  min-width: 696px !important;
  max-width: 696px !important; }

.height-704,
.h-704 {
  height: 704px !important;
  min-height: 704px !important;
  max-height: 704px !important; }

.width-704,
.w-704 {
  width: 704px !important;
  min-width: 704px !important;
  max-width: 704px !important; }

.height-712,
.h-712 {
  height: 712px !important;
  min-height: 712px !important;
  max-height: 712px !important; }

.width-712,
.w-712 {
  width: 712px !important;
  min-width: 712px !important;
  max-width: 712px !important; }

.height-728,
.h-728 {
  height: 728px !important;
  min-height: 728px !important;
  max-height: 728px !important; }

.width-728,
.w-728 {
  width: 728px !important;
  min-width: 728px !important;
  max-width: 728px !important; }

.height-736,
.h-736 {
  height: 736px !important;
  min-height: 736px !important;
  max-height: 736px !important; }

.width-736,
.w-736 {
  width: 736px !important;
  min-width: 736px !important;
  max-width: 736px !important; }

.height-744,
.h-744 {
  height: 744px !important;
  min-height: 744px !important;
  max-height: 744px !important; }

.width-744,
.w-744 {
  width: 744px !important;
  min-width: 744px !important;
  max-width: 744px !important; }

.height-752,
.h-752 {
  height: 752px !important;
  min-height: 752px !important;
  max-height: 752px !important; }

.width-752,
.w-752 {
  width: 752px !important;
  min-width: 752px !important;
  max-width: 752px !important; }

.height-768,
.h-768 {
  height: 768px !important;
  min-height: 768px !important;
  max-height: 768px !important; }

.width-768,
.w-768 {
  width: 768px !important;
  min-width: 768px !important;
  max-width: 768px !important; }

.height-776,
.h-776 {
  height: 776px !important;
  min-height: 776px !important;
  max-height: 776px !important; }

.width-776,
.w-776 {
  width: 776px !important;
  min-width: 776px !important;
  max-width: 776px !important; }

.height-784,
.h-784 {
  height: 784px !important;
  min-height: 784px !important;
  max-height: 784px !important; }

.width-784,
.w-784 {
  width: 784px !important;
  min-width: 784px !important;
  max-width: 784px !important; }

.height-792,
.h-792 {
  height: 792px !important;
  min-height: 792px !important;
  max-height: 792px !important; }

.width-792,
.w-792 {
  width: 792px !important;
  min-width: 792px !important;
  max-width: 792px !important; }

.border,
.b {
  border: 1px solid rgba(0, 0, 0, 0.12); }

.border-top,
.bt {
  border-top: 1px solid rgba(0, 0, 0, 0.12); }

.border-right,
.br {
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.border-bottom,
.bb {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.border-left,
.bl {
  border-left: 1px solid rgba(0, 0, 0, 0.12); }

.border-horizontal,
.bh {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.border-vertical,
.bv {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.brad-1 {
  border-radius: 1px; }

.brad-2 {
  border-radius: 2px; }

.brad-3 {
  border-radius: 3px; }

.brad-4 {
  border-radius: 4px; }

.brad-5 {
  border-radius: 5px; }

.brad-6 {
  border-radius: 6px; }

.brad-7 {
  border-radius: 7px; }

.brad-8 {
  border-radius: 8px; }

.brad-9 {
  border-radius: 9px; }

.brad-10 {
  border-radius: 10px; }

.brad-11 {
  border-radius: 11px; }

.brad-12 {
  border-radius: 12px; }

/*----------------------------------------------------------------*/
/*  Print
/*----------------------------------------------------------------*/
@media all {
  /* Never show page break in normal view */
  .page-break {
    display: none; } }

@media print {
  /* Page Styles */
  @page {}  /* Page break */
  .page-break {
    display: block;
    break-after: always;
    page-break-after: always; }
  /* General styles */
  #main #vertical-navigation {
    display: none; }
  #main #toolbar {
    display: none; }
  #main #quick-panel {
    display: none; }
  #main #content {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    background: none !important; }
  ms-theme-options {
    display: none; }
  .ps-scrollbar-x-rail,
  .ps-scrollbar-y-rail {
    display: none !important; }
  /* Printable page specific styles */
  .printable {
    overflow: visible !important; }
    .printable .template-layout {
      display: inline !important; } }

.ms-card .template-1 .media .image {
  width: 100%;
  border-radius: 2px 2px 0 0; }

.ms-card .template-10 .media {
  width: 80px;
  min-width: 80px;
  max-width: 80px; }

.ms-card .template-2 .header .avatar .image {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 20px; }

.ms-card .template-2 .media .image {
  width: 100%; }

.ms-card .template-4 .info {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.54); }

.ms-card .template-4 .media {
  z-index: 1; }
  .ms-card .template-4 .media .image {
    width: 100%; }

.ms-card .template-5 .media {
  width: 80px;
  min-width: 80px;
  max-width: 80px; }

.ms-card .template-5 .info, .ms-card .template-5 .media {
  -ms-flex: 0 1 auto; }

.ms-card .template-8 .media .image {
  width: 100%;
  border-radius: 2px 2px 0 0; }

.ms-card .template-9 .header .avatar .image {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 20px; }

.ms-card .template-9 .media .image {
  width: 100%; }

.ms-stepper .ms-stepper-horizontal {
  background: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation {
    height: 72px;
    min-height: 72px;
    max-height: 72px;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
    .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      height: 72px;
      min-height: 72px;
      max-height: 72px;
      line-height: 24px;
      margin: 0;
      padding: 24px;
      border-radius: 0;
      cursor: pointer;
      font-size: 14px;
      text-transform: none; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item:before, .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        width: 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item:before {
        left: 0; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item:after {
        right: 0; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item:first-child:before {
        display: none; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item:last-child:after {
        display: none; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item.current .title {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.87); }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item.disabled {
        cursor: default; }
        .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item.disabled .step {
          background: rgba(0, 0, 0, 0.12); }
        .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item.disabled .title {
          color: rgba(0, 0, 0, 0.27); }
        .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item.disabled .subtitle {
          color: rgba(0, 0, 0, 0.27); }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item.optional {
        padding: 16px 24px; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item .step {
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 50%;
        margin: 0 8px 0 16px;
        color: #FFFFFF; }
        .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item .step .icon {
          color: #FFFFFF;
          font-weight: bold;
          line-height: 24px !important; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item .title {
        padding-right: 16px;
        color: rgba(0, 0, 0, 0.54); }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item .subtitle {
        font-size: 12px;
        padding-right: 16px;
        color: rgba(0, 0, 0, 0.54);
        line-height: 1; }
  .ms-stepper .ms-stepper-horizontal .ms-stepper-steps {
    padding: 24px 16px; }
  .ms-stepper .ms-stepper-horizontal .ms-stepper-controls {
    padding: 0 16px 16px 16px; }
    .ms-stepper .ms-stepper-horizontal .ms-stepper-controls .ms-stepper-dots {
      display: none;
      align-items: center;
      justify-content: center;
      padding: 0 16px; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-controls .ms-stepper-dots span {
        width: 5px;
        height: 5px;
        margin: 0 4px;
        border-radius: 50%; }
        .ms-stepper .ms-stepper-horizontal .ms-stepper-controls .ms-stepper-dots span:not(.md-accent-bg) {
          background: rgba(0, 0, 0, 0.12); }
        .ms-stepper .ms-stepper-horizontal .ms-stepper-controls .ms-stepper-dots span.selected {
          width: 8px;
          height: 8px; }

@media screen and (max-width: 600px) {
  .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation {
    display: none; }
  .ms-stepper .ms-stepper-horizontal .ms-stepper-controls .ms-stepper-dots {
    display: flex; } }

.ms-theme-options-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 49; }

.ms-theme-options {
  position: fixed;
  top: 160px;
  right: 0;
  z-index: 50; }
  .ms-theme-options.open .ms-theme-options-panel {
    transform: translateX(0); }
    .ms-theme-options.open .ms-theme-options-panel .ms-theme-options-panel-button {
      opacity: 1;
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
  .ms-theme-options.open .ms-theme-options-list {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
  .ms-theme-options .ms-theme-options-panel {
    position: absolute;
    top: 0;
    right: 0;
    width: 268px;
    transform: translateX(220px);
    transition: transform 0.225s ease-in-out;
    pointer-events: none; }
    .ms-theme-options .ms-theme-options-panel .ms-theme-options-panel-button {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      width: 48px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      cursor: pointer;
      border-radius: 0;
      margin: 0;
      pointer-events: auto;
      opacity: 0.75; }
      .ms-theme-options .ms-theme-options-panel .ms-theme-options-panel-button:hover {
        opacity: 1; }
    .ms-theme-options .ms-theme-options-panel .ms-theme-options-list {
      width: 220px;
      min-width: 220px;
      max-width: 220px;
      background: #FFFFFF;
      z-index: 12;
      pointer-events: auto; }
      .ms-theme-options .ms-theme-options-panel .ms-theme-options-list .theme-option {
        padding: 16px; }
        .ms-theme-options .ms-theme-options-panel .ms-theme-options-list .theme-option md-radio-button {
          margin: 8px; }
        .ms-theme-options .ms-theme-options-panel .ms-theme-options-list .theme-option .option-title {
          font-size: 14px;
          font-weight: 500;
          padding: 0 8px 12px 8px; }
        .ms-theme-options .ms-theme-options-panel .ms-theme-options-list .theme-option .theme {
          padding: 4px 8px; }
          .ms-theme-options .ms-theme-options-panel .ms-theme-options-list .theme-option .theme .theme-button {
            border-right: 8px solid;
            text-align: left; }

/*----------------------------------------------------------------*/
/*  ms-card
/*----------------------------------------------------------------*/
.ms-card {
  display: flex;
  flex-direction: row;
  padding: 8px; }
  .ms-card .ms-card-content-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 400px;
    border-radius: 2px;
    background: white;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.098), 0px 1px 5px 0px rgba(0, 0, 0, 0.084); }

.ms-form-wizard {
  background: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  .ms-form-wizard md-tabs md-tabs-wrapper {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
    .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas {
      height: 72px; }
      .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper {
        height: 72px; }
        .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item {
          opacity: 1 !important;
          padding: 24px; }
          .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            right: 50%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            z-index: 8; }
          .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            right: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            z-index: 8; }
          .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item:first-of-type:before {
            display: none; }
          .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item:last-of-type:after {
            display: none; }
          .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item.md-disabled .ms-form-wizard-step-label .ms-form-wizard-step-number {
            background: rgba(0, 0, 0, 0.12) !important; }
          .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item .md-ripple-container {
            z-index: 11; }
      .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-ink-bar {
        display: none !important; }
  .ms-form-wizard .ms-form-wizard-step-label {
    display: inline-block;
    position: relative;
    background: #FFFFFF;
    z-index: 10;
    padding: 0 16px;
    line-height: 24px; }
    .ms-form-wizard .ms-form-wizard-step-label .ms-form-wizard-step-number {
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      margin-right: 12px;
      border-radius: 50%;
      color: #FFFFFF !important; }
      .ms-form-wizard .ms-form-wizard-step-label .ms-form-wizard-step-number i {
        line-height: 24px !important; }
    .ms-form-wizard .ms-form-wizard-step-label .ms-form-wizard-step-text {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      line-height: 24px; }
  .ms-form-wizard .ms-form-wizard-form {
    padding: 24px 16px; }
  .ms-form-wizard .navigation {
    padding: 16px; }
    .ms-form-wizard .navigation .steps {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px; }
      .ms-form-wizard .navigation .steps span {
        width: 5px;
        height: 5px;
        margin: 0 4px;
        border-radius: 50%; }
        .ms-form-wizard .navigation .steps span:not(.md-accent-bg) {
          background: rgba(0, 0, 0, 0.12); }
        .ms-form-wizard .navigation .steps span.selected {
          width: 8px;
          height: 8px; }

/*----------------------------------------------------------------*/
/*  ms-material-color-picker
/*----------------------------------------------------------------*/
ms-material-color-picker md-menu .md-button {
  background-color: #EEEEEE;
  font-size: 12px; }

md-menu-content.ms-material-color-picker-menu-content {
  width: 208px;
  max-width: 208px;
  min-width: 208px;
  padding: 0; }
  md-menu-content.ms-material-color-picker-menu-content header {
    height: 48px;
    text-transform: uppercase; }
    md-menu-content.ms-material-color-picker-menu-content header .md-button {
      display: flex;
      margin: 0; }
    md-menu-content.ms-material-color-picker-menu-content header > span {
      line-height: 13px; }
  md-menu-content.ms-material-color-picker-menu-content .colors {
    position: relative;
    width: 208px;
    height: 256px;
    padding: 8px; }
    md-menu-content.ms-material-color-picker-menu-content .colors .color {
      position: relative;
      width: 40px;
      height: 40px;
      margin: 4px;
      border-radius: 2px;
      cursor: pointer; }
      md-menu-content.ms-material-color-picker-menu-content .colors .color .label {
        padding: 2px;
        font-size: 10px; }
      md-menu-content.ms-material-color-picker-menu-content .colors .color .icon-check {
        position: absolute;
        top: 2px;
        right: 2px;
        font-size: 16px;
        opacity: 0.7; }

ms-nav {
  display: block;
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  user-select: none;
  z-index: 70; }
  ms-nav ms-nav-item {
    display: block;
    position: relative;
    line-height: 48px; }
    ms-nav ms-nav-item.ms-nav-title {
      line-height: 32px;
      font-size: 12px;
      font-weight: 500;
      margin-top: 8px;
      padding: 0 24px;
      width: 100%;
      color: rgba(255, 255, 255, 0.3);
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    ms-nav ms-nav-item .ms-nav-button {
      display: flex;
      align-self: flex-start;
      align-items: center;
      position: relative;
      cursor: pointer;
      padding: 0 48px 0 24px;
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none; }
      ms-nav ms-nav-item .ms-nav-button:not(.active):hover {
        background: rgba(0, 0, 0, 0.1);
        text-decoration: none; }
      ms-nav ms-nav-item .ms-nav-button .ms-nav-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: opacity 0.2s ease-in-out 0.1s; }
      ms-nav ms-nav-item .ms-nav-button .arrow {
        position: absolute;
        top: 16px;
        right: 24px;
        margin: 0;
        transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out 0.1s; }
      ms-nav ms-nav-item .ms-nav-button .ms-nav-badge {
        position: absolute;
        top: 50%;
        right: 24px;
        min-width: 20px;
        height: 20px;
        line-height: 20px;
        padding: 0 7px;
        font-size: 11px;
        font-weight: bold;
        border-radius: 20px;
        transform: translateY(-50%);
        transition: opacity 0.2s ease-in-out 0.1s; }
      ms-nav ms-nav-item .ms-nav-button.active {
        color: #FFFFFF !important; }
        ms-nav ms-nav-item .ms-nav-button.active .ms-nav-badge {
          background: #FFFFFF !important;
          color: rgba(0, 0, 0, 0.87) !important; }
        ms-nav ms-nav-item .ms-nav-button.active i {
          color: #FFFFFF; }
    ms-nav ms-nav-item i {
      color: rgba(255, 255, 255, 0.7);
      margin: 0 16px 0 0; }
  ms-nav .ms-nav-toggle ms-nav-toggle-items {
    display: none;
    overflow: hidden; }
    ms-nav .ms-nav-toggle ms-nav-toggle-items.expand-animation {
      transition: height 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    ms-nav .ms-nav-toggle ms-nav-toggle-items.collapse-animation {
      transition: height 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item {
      /* Level 1 */
      /* Level 2 */ }
      ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item .ms-nav-button {
        padding-left: 58px; }
      ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item {
        /* Level 3 */ }
        ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item .ms-nav-button {
          padding-left: 68px; }
        ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item {
          /* Level 4 */ }
          ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item .ms-nav-button {
            padding-left: 78px; }
          ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item {
            /* Level 5 */ }
            ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item .ms-nav-button {
              padding-left: 88px; }
            ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item .ms-nav-button {
              padding-left: 98px; }
  ms-nav .ms-nav-toggle.active > .ms-nav-button {
    color: white; }
    ms-nav .ms-nav-toggle.active > .ms-nav-button a,
    ms-nav .ms-nav-toggle.active > .ms-nav-button md-icon {
      color: white; }
  ms-nav .ms-nav-toggle[collapsed="false"] > .ms-nav-button > .arrow {
    transform: rotate(90deg); }
  ms-nav > .ms-nav-toggle[collapsed="false"] {
    background-color: rgba(0, 0, 0, 0.12); }

@media only screen and (min-width: 1280px) {
  .ms-nav-folded #ms-nav-fold-close-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    z-index: 998; }
  .ms-nav-folded #ms-nav-fold-open-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999; }
  .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item.ms-nav-title {
    height: 32px; }
    .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item.ms-nav-title:before {
      content: '';
      display: block;
      position: relative;
      top: 12px;
      width: 16px;
      border-top: 1px solid rgba(255, 255, 255, 0.12); }
    .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item.ms-nav-title span {
      display: none; }
  .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item .ms-nav-button {
    height: 48px; }
    .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item .ms-nav-button > .ms-nav-label,
    .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item .ms-nav-button > .ms-nav-badge,
    .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item .ms-nav-button > .arrow {
      opacity: 0;
      visibility: hidden; } }

/*----------------------------------------------------------------*/
/*  ms-navigation
/*----------------------------------------------------------------*/
.ms-navigation {
  display: block;
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  user-select: none; }
  .ms-navigation ul, .ms-navigation li {
    padding: 0;
    margin: 0; }
  .ms-navigation ul {
    display: block;
    overflow: hidden; }
    .ms-navigation ul li {
      position: relative; }
      .ms-navigation ul li.collapsed ul {
        display: none; }
  .ms-navigation .ms-navigation-node .ms-navigation-item {
    display: block;
    position: relative;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.7); }
    .ms-navigation .ms-navigation-node .ms-navigation-item:hover {
      background: rgba(0, 0, 0, 0.1); }
    .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button {
      display: flex;
      position: relative;
      width: 100%;
      height: 48px;
      line-height: 48px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-right: 16px;
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden; }
      .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button .icon {
        color: rgba(255, 255, 255, 0.7);
        margin: 0 16px 0 0; }
      .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button span {
        white-space: nowrap;
        overflow: hidden;
        opacity: 1;
        transition: opacity 0.25s ease-in-out 0.1s; }
      .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button a {
        white-space: nowrap;
        overflow: hidden;
        opacity: 1;
        transition: opacity 0.25s ease-in-out 0.1s; }
      .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button .arrow {
        margin: 0;
        opacity: 1;
        transition: transform 0.3s ease-in-out, opacity 0.25s ease-in-out 0.1s; }
      .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button .badge {
        position: absolute;
        top: 50%;
        right: 16px;
        min-width: 20px;
        height: 20px;
        line-height: 20px;
        padding: 0 7px;
        font-size: 11px;
        font-weight: bold;
        border-radius: 20px;
        transform: translateY(-50%);
        transition: opacity 0.2s ease-in-out 0.1s; }
      .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button.active {
        color: #FFFFFF; }
        .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button.active .icon {
          color: #FFFFFF; }
        .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button.active .badge {
          background: #FFFFFF !important;
          color: rgba(0, 0, 0, 0.87) !important; }
  .ms-navigation .ms-navigation-node:not(.collapsed) > .ms-navigation-item > .ms-navigation-button .arrow {
    transform: rotate(-270deg); }
  .ms-navigation .ms-navigation-node.group > .ms-navigation-item {
    font-size: 12px;
    font-weight: 500;
    margin-top: 8px;
    cursor: auto;
    background: none !important;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .ms-navigation .ms-navigation-node.group > .ms-navigation-item > .ms-navigation-button {
      height: 32px;
      line-height: 32px;
      color: rgba(255, 255, 255, 0.3); }
  .ms-navigation .ms-navigation-node:not(.group).has-children:not(.collapsed) {
    background-color: rgba(0, 0, 0, 0.12); }
  .ms-navigation .ms-navigation-node:not(.group).has-children.collapsing {
    background-color: rgba(0, 0, 0, 0.12); }
  .ms-navigation .ms-navigation-node:not(.group).has-children .has-children:not(.collapsed) {
    background: none; }
  .ms-navigation li .ms-navigation-button {
    padding-left: 24px; }
  .ms-navigation li li .ms-navigation-button {
    padding-left: 56px; }
  .ms-navigation li li li .ms-navigation-button {
    padding-left: 72px; }
  .ms-navigation li li li li .ms-navigation-button {
    padding-left: 88px; }
  .ms-navigation li li li li li .ms-navigation-button {
    padding-left: 104px; }
  .ms-navigation li.group .ms-navigation-button {
    padding-left: 24px; }
  .ms-navigation li.group li .ms-navigation-button {
    padding-left: 24px; }
  .ms-navigation li.group li li .ms-navigation-button {
    padding-left: 56px; }
  .ms-navigation li.group li li li .ms-navigation-button {
    padding-left: 72px; }
  .ms-navigation li.group li li li li .ms-navigation-button {
    padding-left: 88px; }

#horizontal-navigation {
  background: #FFFFFF;
  z-index: 54; }
  #horizontal-navigation .ms-navigation-horizontal {
    display: flex;
    position: relative;
    user-select: none; }
    #horizontal-navigation .ms-navigation-horizontal ul, #horizontal-navigation .ms-navigation-horizontal li {
      padding: 0;
      margin: 0;
      list-style: none; }
    #horizontal-navigation .ms-navigation-horizontal .horizontal {
      display: flex;
      flex: 1; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal li {
        position: relative;
        cursor: pointer; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal li:not(.active):hover {
          background: rgba(0, 0, 0, 0.12); }
      #horizontal-navigation .ms-navigation-horizontal .horizontal > li > .ms-navigation-horizontal-item > .ms-navigation-horizontal-button {
        padding: 20px 24px; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal > li > .ms-navigation-horizontal-item > .ms-navigation-horizontal-button .arrow {
          display: none; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal > li:last-child {
        margin-right: 0; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children ul {
        display: none;
        position: absolute;
        background: #FFFFFF;
        z-index: 999;
        min-width: 200px;
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        top: 0;
        left: 100%; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children:hover > ul {
        display: block; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal > .has-children > ul {
        top: 100%;
        left: 0; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button {
        display: flex;
        align-items: center;
        width: 100%;
        color: rgba(0, 0, 0, 0.87);
        white-space: nowrap;
        padding: 14px 16px;
        text-decoration: none; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button .icon {
          color: rgba(0, 0, 0, 0.54);
          margin-right: 8px; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button .badge {
          min-width: 20px;
          height: 20px;
          line-height: 20px;
          padding: 0 7px;
          font-size: 11px;
          font-weight: bold;
          border-radius: 20px;
          transition: opacity 0.2s ease-in-out 0.1s; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button .arrow {
          margin-left: 8px; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button.active {
          color: #FFFFFF; }
          #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button.active .badge {
            background: #FFFFFF !important;
            color: rgba(0, 0, 0, 0.87) !important; }
          #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button.active .icon {
            color: #FFFFFF !important; }

@media only screen and (min-width: 960px) {
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation {
    margin-bottom: auto; }
    body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .animate-height {
      transition: none; }
    body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node .ms-navigation-item .ms-navigation-button .icon {
      margin: 0; }
    body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node .ms-navigation-item .ms-navigation-button .title,
    body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node .ms-navigation-item .ms-navigation-button .arrow,
    body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node .ms-navigation-item .ms-navigation-button .badge {
      opacity: 0;
      visibility: hidden; }
    body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node.group > .ms-navigation-item .ms-navigation-button:before {
      content: '';
      display: block;
      position: relative;
      width: 16px;
      border-top: 1px solid rgba(255, 255, 255, 0.12); }
  body.ms-navigation-folded:not(.ms-navigation-folded-open) #ms-navigation-fold-expander {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999; }
  body.ms-navigation-folded #ms-navigation-fold-collapser {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 59; } }

@media only screen and (max-width: 960px) {
  body.ms-navigation-horizontal-mobile-menu-active #horizontal-navigation .navigation-toggle {
    height: 64px;
    margin-right: 16px; }
    body.ms-navigation-horizontal-mobile-menu-active #horizontal-navigation .navigation-toggle .md-button {
      margin: 0;
      width: 64px;
      height: 64px; }
  body.ms-navigation-horizontal-mobile-menu-active #horizontal-navigation .ms-navigation-horizontal {
    display: block !important;
    background: #FFFFFF; }
  #horizontal-navigation {
    z-index: 60; }
    #horizontal-navigation .ms-navigation-horizontal {
      position: absolute;
      display: none;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0 0 16px 0;
      z-index: 9998;
      overflow-x: hidden;
      overflow-y: scroll; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal {
        flex-direction: column;
        min-height: 100%;
        width: 100%; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal li:not(.active):hover {
          background: none; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal li:not(.active) .ms-navigation-horizontal-item {
          margin: 4px; }
          #horizontal-navigation .ms-navigation-horizontal .horizontal li:not(.active) .ms-navigation-horizontal-item:hover {
            background: rgba(0, 0, 0, 0.12); }
        #horizontal-navigation .ms-navigation-horizontal .horizontal > li {
          padding: 0 12px; }
          #horizontal-navigation .ms-navigation-horizontal .horizontal > li > .ms-navigation-horizontal-item > .ms-navigation-horizontal-button .arrow {
            display: block; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children ul {
          position: relative;
          top: 0;
          left: 0;
          box-shadow: none; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children:hover > ul {
          display: none; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children .expanded > .ms-navigation-horizontal-button .arrow {
          transform: rotate(90deg); }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children .expanded + ul {
          display: block;
          margin-left: 16px; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item {
          min-height: 48px;
          height: 48px; } }

/*----------------------------------------------------------------*/
/*  ms-responsive-table
/*----------------------------------------------------------------*/
.ms-responsive-table-wrapper {
  overflow-x: auto;
  overflow-y: hidden; }

/*----------------------------------------------------------------*/
/*  ms-scroll
/*----------------------------------------------------------------*/
.ms-scroll {
  overflow: hidden !important; }

/*----------------------------------------------------------------*/
/*  ms-search-bar
/*----------------------------------------------------------------*/
.ms-search-bar {
  height: 64px;
  font-size: 13px; }
  .ms-search-bar #ms-search-bar-expander,
  .ms-search-bar #ms-search-bar-collapser {
    cursor: pointer;
    padding: 0 20px;
    width: 64px !important;
    height: 64px !important;
    line-height: 64px !important; }
  .ms-search-bar #ms-search-bar-collapser {
    display: none; }
  .ms-search-bar #ms-search-bar-input {
    display: none;
    min-height: 64px;
    background-color: transparent; }
  .ms-search-bar.expanded {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 10; }
    .ms-search-bar.expanded #ms-search-bar-input {
      display: block; }
    .ms-search-bar.expanded #ms-search-bar-expander {
      display: none; }
    .ms-search-bar.expanded #ms-search-bar-collapser {
      display: block; }

@media screen and (max-width: 960px) {
  .ms-search-bar-label {
    padding: 0 8px 0 12px; } }

#splash-screen {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background-color: #3C4252;*/
  z-index: 99999;
  background: #3C4252;
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left top, #3C4252, black);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(bottom right, #3C4252, black);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(bottom right, #3C4252, black);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, #3C4252, black);
  /* Standard syntax */ }
  #splash-screen.ng-leave {
    opacity: 1;
    transition: all linear 200ms; }
  #splash-screen.ng-leave-active {
    opacity: 0; }
  #splash-screen .center {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  #splash-screen .logo {
    width: 96px;
    height: 96px;
    line-height: 96px;
    margin: 0 auto;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 56px;
    text-align: center;
    border-radius: 2px;
    background-color: #039BE5;
    color: #FFFFFF;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.22); }
  #splash-screen .spinner-wrapper {
    display: block;
    position: relative;
    width: 100%;
    min-height: 100px;
    height: 100px; }
    #splash-screen .spinner-wrapper .spinner {
      position: absolute;
      overflow: hidden;
      left: 50%;
      margin-left: -50px;
      animation: outer-rotate 2.91667s linear infinite; }
      #splash-screen .spinner-wrapper .spinner .inner {
        width: 100px;
        height: 100px;
        position: relative;
        animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite; }
        #splash-screen .spinner-wrapper .spinner .inner .gap {
          position: absolute;
          left: 49px;
          right: 49px;
          top: 0;
          bottom: 0;
          border-top: 10px solid;
          box-sizing: border-box; }
        #splash-screen .spinner-wrapper .spinner .inner .left,
        #splash-screen .spinner-wrapper .spinner .inner .right {
          position: absolute;
          top: 0;
          height: 100px;
          width: 50px;
          overflow: hidden; }
          #splash-screen .spinner-wrapper .spinner .inner .left .half-circle,
          #splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
            position: absolute;
            top: 0;
            width: 100px;
            height: 100px;
            box-sizing: border-box;
            border: 10px solid red;
            border-bottom-color: transparent;
            border-radius: 50%; }
        #splash-screen .spinner-wrapper .spinner .inner .left {
          left: 0; }
          #splash-screen .spinner-wrapper .spinner .inner .left .half-circle {
            left: 0;
            border-right-color: transparent;
            animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
            -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite; }
        #splash-screen .spinner-wrapper .spinner .inner .right {
          right: 0; }
          #splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
            right: 0;
            border-left-color: transparent;
            animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
            -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite; }

@keyframes outer-rotate {
  0% {
    transform: rotate(0deg) scale(0.5); }
  100% {
    transform: rotate(360deg) scale(0.5); } }

@keyframes left-wobble {
  0%, 100% {
    transform: rotate(130deg); }
  50% {
    transform: rotate(-5deg); } }

@keyframes right-wobble {
  0%, 100% {
    transform: rotate(-130deg); }
  50% {
    transform: rotate(5deg); } }

@keyframes sporadic-rotate {
  12.5% {
    transform: rotate(135deg); }
  25% {
    transform: rotate(270deg); }
  37.5% {
    transform: rotate(405deg); }
  50% {
    transform: rotate(540deg); }
  62.5% {
    transform: rotate(675deg); }
  75% {
    transform: rotate(810deg); }
  87.5% {
    transform: rotate(945deg); }
  100% {
    transform: rotate(1080deg); } }

.ms-timeline .ms-timeline-loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  margin: 0 auto;
  background: #FFFFFF;
  font-weight: 500;
  opacity: 0;
  z-index: 11;
  border-radius: 50%; }
  .ms-timeline .ms-timeline-loader.show {
    opacity: 1; }
  .ms-timeline .ms-timeline-loader .spinner {
    width: 24px;
    height: 24px;
    border: 3px solid rgba(255, 255, 255, 0.35);
    border-top-color: #FFFFFF;
    border-radius: 50%; }

/*----------------------------------------------------------------*/
/*  ms-widget
/*----------------------------------------------------------------*/
.ms-widget .ms-widget-front {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }

.ms-widget .ms-widget-back {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }

.ms-widget.sidenav-widget .ms-widget-front,
.ms-widget.sidenav-widget .ms-widget-back {
  box-shadow: none; }

.ms-widget .flip-to-front {
  position: absolute;
  top: 0;
  right: 0; }

.ms-widget .c3 {
  height: 100%; }

.ms-widget .chart-fill-8 {
  margin: 0 -8px -8px -8px; }

.ms-widget .chart-fill-16 {
  margin: 0 -16px -16px -16px; }

.ms-widget .chart-fill-24 {
  margin: 0 -24px -24px -24px; }

.ms-widget .chart-fill-32 {
  margin: 0 -32px -32px -32px; }

.ms-widget .chart-fill-40 {
  margin: 0 -40px -40px -40px; }

.ms-widget .chart-fill-48 {
  margin: 0 -48px -48px -48px; }

.ms-widget .chart-fill-56 {
  margin: 0 -56px -56px -56px; }

.ms-widget .chart-fill-64 {
  margin: 0 -64px -64px -64px; }

.ms-widget .chart-fill-72 {
  margin: 0 -72px -72px -72px; }

.ms-widget .chart-fill-80 {
  margin: 0 -80px -80px -80px; }

.ms-widget {
  position: relative;
  font-size: 1.3rem;
  perspective: 3000px;
  padding: 12px; }
  .ms-widget .ms-widget-front {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
    visibility: visible;
    width: 100%;
    opacity: 1;
    z-index: 10;
    border-radius: 2px;
    transition: transform 0.5s ease-out 0s, visibility 0s ease-in 0.2s, opacity 0s ease-in 0.2s;
    transform: rotateY(0deg);
    backface-visibility: hidden; }
  .ms-widget .ms-widget-back {
    display: block;
    position: absolute;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    top: 12px;
    right: 12px;
    bottom: 12px;
    left: 12px;
    border-radius: 2px;
    transition: transform 0.5s ease-out 0s, visibility 0s ease-in 0.2s, opacity 0s ease-in 0.2s;
    transform: rotateY(180deg);
    backface-visibility: hidden; }
  .ms-widget .md-button {
    margin: 0; }
    .ms-widget .md-button.md-icon-button {
      margin: 0; }
  .ms-widget.flipped .ms-widget-front {
    visibility: hidden;
    opacity: 0;
    transform: rotateY(180deg); }
  .ms-widget.flipped .ms-widget-back {
    display: block;
    visibility: visible;
    opacity: 1;
    transform: rotateY(360deg); }
  .ms-widget.sidenav-widget {
    padding: 0; }
  .ms-widget.ar-2-1 .widget {
    padding: 0;
    position: relative; }
    .ms-widget.ar-2-1 .widget:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 50%; }
    .ms-widget.ar-2-1 .widget > .widget {
      position: absolute;
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 8px; }
  .ms-widget.ar-1-1 .widget {
    padding: 0;
    position: relative; }
    .ms-widget.ar-1-1 .widget:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 100%; }
    .ms-widget.ar-1-1 .widget > .widget {
      position: absolute;
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 8px; }

#error401 {
  height: 100%; }
  #error401 img {
    max-height: 320px;
    max-width: 320px; }
  #error401 .error-code {
    font-size: 112px;
    text-align: center;
    line-height: 1;
    margin-bottom: 16px;
    font-weight: 500; }
  #error401 .message {
    font-size: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.54); }

#error404 {
  height: 100%; }
  #error404 img {
    max-height: 320px;
    max-width: 320px; }
  #error404 .error-code {
    font-size: 112px;
    text-align: center;
    line-height: 1;
    margin-bottom: 16px;
    font-weight: 500; }
  #error404 .message {
    font-size: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.54); }

#error500 {
  height: 100%; }
  #error500 img {
    max-height: 320px;
    max-width: 320px; }
  #error500 .error-code {
    font-size: 112px;
    line-height: 1;
    text-align: center;
    margin-bottom: 16px;
    font-weight: 500; }
  #error500 .message {
    font-size: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.54); }

#paquetes .uploadContainer {
  border: 2px solid lightgrey;
  border-radius: 10px; }

#paquetes .input_oculto {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0; }

#paquetes .pest {
  border: 1px solid lightgray;
  padding: 5px; }

#paquetes md-list .md-subheader {
  margin-bottom: 8px; }

#paquetes md-list-item {
  position: relative;
  transition: background-color 0.25s; }
  #paquetes md-list-item h4 {
    white-space: normal;
    max-height: 32px; }
  #paquetes md-list-item h3 span, #paquetes md-list-item h4 span {
    color: lightslategray;
    transition: color 0.25s; }
  #paquetes md-list-item > md-icon {
    position: absolute;
    top: 0;
    left: 0;
    color: limegreen; }
  #paquetes md-list-item img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-right: 20px; }
  #paquetes md-list-item.paquete img:nth-child(1) {
    display: block; }
  #paquetes md-list-item.paquete img:nth-child(2) {
    display: none; }

#paquetes md-list-item:hover {
  background-color: lightgray; }
  #paquetes md-list-item:hover h3 span, #paquetes md-list-item:hover h4 span {
    color: initial; }
  #paquetes md-list-item:hover.paquete img:nth-child(1) {
    display: none; }
  #paquetes md-list-item:hover.paquete img:nth-child(2) {
    display: block; }

#paquetes .md-3-line {
  align-items: center;
  min-height: auto;
  padding: 1em; }

#paquetes .price-table {
  width: auto;
  border-radius: 25px;
  margin: 8px; }

#paquetes .price_ {
  /* Copy styles from ng-messages */
  font-size: 12px;
  line-height: 14px;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  /* Set our own color */
  color: grey; }

#paquetes .image-md-menu label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px; }

#paquetes .image-md-menu img {
  max-width: 80px;
  max-height: 80px; }

#paquetes md-sidenav {
  width: 50%;
  max-width: 50%; }

#asignarServicio .servicio {
  min-width: 280px;
  transition: background 0.25s; }
  #asignarServicio .servicio md-checkbox {
    margin-bottom: 0;
    margin-left: 1em; }
  #asignarServicio .servicio .icon-star {
    color: darkgoldenrod; }

#asignarServicio .servicio:hover {
  background: lightgray; }

#asignarServicio section {
  border: 1px solid darkgrey;
  margin-top: 4px;
  box-shadow: 2px 2px 2px 0px lightgrey; }

#asignarServicio md-list-item {
  transition: background-color 0.25s; }
  #asignarServicio md-list-item h4 {
    white-space: normal;
    max-height: 32px;
    margin: 8px 0; }

#asignarServicio table {
  border-collapse: collapse;
  min-width: 100%; }

#asignarServicio table, #asignarServicio th, #asignarServicio td {
  border: 1px solid black; }

#asignarServicio .md-subheader {
  cursor: pointer; }
  #asignarServicio .md-subheader:hover {
    background-color: #e0e0e087; }

@media screen and (max-width: 960px) {
  #asignarServicio .servicio, #asignar-servicios .servicio {
    min-width: 0px !important; }
  #asignarServicio md-dialog-content, #asignar-servicios md-dialog-content {
    padding: 8px 16px; }
  #paquetes md-tab-item {
    padding: 12px !important; }
  #paquetes md-dialog-content {
    padding: 8px 16px; } }

#servicios md-badge {
  margin: 0 0 0 8px;
  /*background-color: #259b24;*/
  color: white;
  padding: 3px 6px;
  border-radius: 4px; }

#servicios .canal-list-item {
  min-width: 375px;
  transition: background 0.25s; }

#servicios .canal-list-item:hover {
  background: lightgray; }

#servicios .pest {
  border: 1px solid lightgray;
  padding: 5px; }

#servicios md-list-item {
  transition: background-color 0.25s;
  cursor: auto; }
  #servicios md-list-item img[ng-click] {
    cursor: pointer;
    transition: opacity 0.25s; }
    #servicios md-list-item img[ng-click]:hover {
      opacity: 0.6; }
  #servicios md-list-item h3, #servicios md-list-item h4 {
    white-space: normal;
    /*max-height: 32px;*/ }
    #servicios md-list-item h3 span, #servicios md-list-item h4 span {
      color: lightslategray;
      transition: color 0.25s; }
  #servicios md-list-item .iconSelected {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    color: royalblue; }
  #servicios md-list-item > md-icon {
    position: absolute;
    top: 0;
    left: 0;
    color: limegreen; }
  #servicios md-list-item .flag {
    position: absolute;
    top: 0;
    left: 50px;
    width: 24px;
    height: 24px; }

#servicios md-list > md-list-item:hover {
  background-color: lightgray; }
  #servicios md-list > md-list-item:hover h3 span, #servicios md-list > md-list-item:hover h4 span {
    color: initial; }

#servicios .md-3-line {
  align-items: center;
  min-height: auto;
  padding: 1em; }

#servicios .asig-list {
  height: 100%; }
  #servicios .asig-list .item {
    max-height: 30px; }

#servicios .listaCanales {
  margin: 5px;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
  background: white;
  min-width: 30%;
  max-width: 30%; }
  #servicios .listaCanales h4 {
    margin: 5px; }

#servicios .listaCanales:hover {
  background: lightgray; }

#servicios .lsta {
  min-width: 300px;
  max-width: 500px; }

#servicios .lsta-highlight {
  background: yellow;
  height: 29px;
  min-width: 300px; }

#servicios .tab {
  min-height: 70vh; }

#servicios .mini {
  width: 20px;
  height: 20px;
  min-height: 20px; }

#servicios .busqueda {
  position: fixed;
  z-index: 22;
  margin: 0;
  max-height: 80%;
  transition: all 0.2s; }
  #servicios .busqueda md-checkbox {
    margin: 0; }

#servicios .left {
  left: -195px; }

#servicios .left:hover {
  left: 0; }

#servicios .right {
  right: -195px; }

#servicios .right:hover {
  right: 0; }

#servicios .md-tab {
  overflow: initial; }

#servicios md-divider {
  border-top-style: dashed; }

#servicios .hint {
  position: initial; }
  #servicios .hint > div {
    cursor: pointer;
    margin-left: 3px; }
    #servicios .hint > div:hover {
      color: indigo; }

#servicios .image-md-menu label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px; }

#servicios .image-md-menu img {
  max-width: 80px;
  max-height: 80px; }

#servicios .orden-grupo-highlight {
  background: yellow;
  height: 29px;
  min-width: 150px; }

#servicios .listaOrdenGrupo {
  margin: 5px;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
  background: white;
  min-width: 150px;
  max-width: 300px; }
  #servicios .listaOrdenGrupo h4 {
    margin: 5px; }

#servicios .listaOrdenGrupo:hover {
  background: lightgray; }

#servicios .servicio-second-div {
  margin-left: 2em;
  max-width: 300px; }

#servicios .mainTab md-tabs-wrapper {
  display: none; }

#servicios .md-subheader {
  text-transform: uppercase; }

#servicios md-list-item img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-right: 20px; }

#samples .edicion, #calendar .edicion {
  padding: 10px; }
  #samples .edicion md-input-container .md-errors-spacer, #calendar .edicion md-input-container .md-errors-spacer {
    display: none; }
  #samples .edicion img, #calendar .edicion img {
    max-height: 18px; }
  #samples .edicion .pest, #calendar .edicion .pest {
    border: 1px solid lightgray;
    padding: 5px; }

#samples md-list, #calendar md-list {
  position: relative; }
  #samples md-list md-list-item:hover, #calendar md-list md-list-item:hover {
    background-color: lightgray; }
  #samples md-list .md-2-line .md-button, #calendar md-list .md-2-line .md-button {
    align-self: center; }

#samples .btn-abs, #calendar .btn-abs {
  position: absolute;
  top: 0;
  right: 0; }

#samples md-sidenav, #calendar md-sidenav {
  width: 60%;
  max-width: none; }

#samples .enlace-archivo {
  padding: 4px 12px;
  font-size: 16px;
  margin: 2px;
  border-radius: 6px; }

#samples .md-2-line .md-list-item-img {
  height: 72px;
  width: 72px;
  margin-right: 8px;
  display: flex; }
  #samples .md-2-line .md-list-item-img img {
    max-height: 100%;
    max-width: 100%;
    margin: auto; }

#samples .replicar {
  font-size: 12px;
  cursor: pointer; }
  #samples .replicar:hover {
    text-decoration: underline; }

#asignar-servicios .servicio {
  min-width: 280px;
  transition: background 0.25s; }
  #asignar-servicios .servicio md-checkbox {
    margin-bottom: 0;
    margin-left: 1em; }
  #asignar-servicios .servicio .icon-star {
    color: darkgoldenrod; }

#asignar-servicios .servicio:hover {
  background: lightgray; }

#asignar-servicios md-list-item {
  transition: background-color 0.25s; }
  #asignar-servicios md-list-item h4 {
    white-space: normal;
    max-height: 32px;
    margin: 8px 0; }

#asignar-servicios md-radio-button {
  margin-right: 32px; }

#asignar-servicios .md-subheader {
  cursor: pointer; }
  #asignar-servicios .md-subheader:hover {
    background-color: #e0e0e087; }

#radio-countries {
  /*    figure{
            cursor: pointer;
        }*/ }
  #radio-countries md-grid-tile-footer {
    background: rgba(0, 0, 0, 0.7); }
    #radio-countries md-grid-tile-footer figcaption {
      width: 100%;
      text-align: center; }
  #radio-countries .selected md-grid-tile-footer {
    background: rgba(43, 134, 1, 0.9); }

#modal-actions .pest {
  border: 1px solid lightgray;
  padding: 5px; }

#modal-actions .edicion {
  padding: 10px; }
  #modal-actions .edicion md-input-container .md-errors-spacer {
    display: none; }
  #modal-actions .edicion img {
    max-height: 18px; }

#modal-actions md-list {
  position: relative; }
  #modal-actions md-list md-list-item:hover {
    background-color: lightgray; }
  #modal-actions md-list .md-2-line .md-button {
    align-self: center; }

#modal-actions .btn-abs {
  position: absolute;
  top: 0;
  right: 0; }

#modal-actions md-sidenav {
  width: 60%;
  max-width: none; }

#imagenes-servicio table {
  border-collapse: collapse;
  width: 100%; }

#imagenes-servicio td, #imagenes-servicio th {
  border: 1px solid darkgray;
  text-align: left;
  padding: 8px;
  vertical-align: top; }

#imagenes-servicio td:not(:first-of-type) {
  background: #ebebeb;
  transition: background 0.25s;
  position: relative; }
  #imagenes-servicio td:not(:first-of-type):hover {
    background: #d6d6d6; }
  #imagenes-servicio td:not(:first-of-type) .md-button {
    position: absolute;
    top: 0;
    right: 0; }

#imagenes-servicio img {
  max-width: 50px;
  max-height: 50px; }

#export-servicios {
  padding: 0; }
  #export-servicios .servicio {
    min-width: 225px;
    margin: 4px 0; }
  #export-servicios md-checkbox {
    margin-bottom: 0;
    margin-left: 1em; }
  #export-servicios .md-subheader .md-button {
    margin: 0; }

@media screen and (max-width: 960px) {
  #servicios .servicio-second-div {
    margin-left: 0;
    max-width: none; }
  #servicios md-tab-item {
    padding: 12px !important; }
  #servicios md-dialog-content {
    padding: 8px 16px; }
  #servicios .listaCanales {
    min-width: 100%;
    max-width: 100%; }
  #samples md-sidenav, #calendar md-sidenav {
    width: 100%; } }

#quick-panel {
  width: 50%;
  min-width: 50%;
  max-width: 50%;
  z-index: 99; }
  #quick-panel md-content {
    height: 100%; }
  #quick-panel .incidencias md-card-title {
    padding: 8px; }
  #quick-panel .incidencias .descripcion {
    font-size: 16px; }
  #quick-panel #incidences-container h3, #quick-panel #prevents-container h3 {
    margin-left: 16px; }

@media screen and (max-width: 960px) {
  #quick-panel {
    width: 80%;
    min-width: 80%;
    max-width: 80%; } }

#toolbar {
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  z-index: 55 !important;
  background-image: url("/assets/images/backgrounds/header-bg.png");
  background-size: cover; }
  #toolbar .toolbarTitle {
    position: relative; }
    #toolbar .toolbarTitle .sectionName {
      font-size: 35px;
      color: white; }
    #toolbar .toolbarTitle .installationName {
      font-size: 22px;
      color: white;
      margin-left: 8px; }
  #toolbar #user-menu {
    height: 64px;
    padding: 0; }
    #toolbar #user-menu md-menu-item .md-button {
      font-size: 15px; }
      #toolbar #user-menu md-menu-item .md-button md-icon {
        margin: auto 16px auto 0; }
    #toolbar #user-menu md-menu-content.md-menu-bar-menu.md-dense {
      padding: 8px 0; }
      #toolbar #user-menu md-menu-content.md-menu-bar-menu.md-dense .md-button,
      #toolbar #user-menu md-menu-content.md-menu-bar-menu.md-dense md-menu-item {
        height: 48px;
        line-height: 48px; }
    #toolbar #user-menu .user-button {
      margin: 0;
      padding: 0 16px;
      height: 64px;
      text-transform: none; }
      #toolbar #user-menu .user-button .avatar-wrapper {
        position: relative; }
        #toolbar #user-menu .user-button .avatar-wrapper .status {
          position: absolute;
          bottom: -3px;
          right: 5px; }
      #toolbar #user-menu .user-button .username {
        margin: 0 12px 0 8px; }
  #toolbar #quick-panel-toggle:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.2); }
  #toolbar #language-menu {
    height: 64px; }
    #toolbar #language-menu .language-button {
      min-width: 64px;
      height: 64px;
      margin: 0;
      padding: 0 16px;
      border-radius: 0; }
      #toolbar #language-menu .language-button .iso {
        text-transform: uppercase; }
      #toolbar #language-menu .language-button .flag {
        margin-right: 8px; }
  #toolbar #quick-panel-toggle {
    width: 64px;
    height: 64px;
    margin: 0;
    border-radius: 0; }
  #toolbar .toolbar-separator {
    height: 64px;
    width: 1px;
    background: rgba(0, 0, 0, 0.12); }

#language-menu-content md-menu-item .flag {
  margin-right: 16px; }

#language-message {
  min-height: 88px;
  max-width: 600px; }
  #language-message .md-toast-content {
    height: 90px; }

@media screen and (max-width: 600px) {
  #toolbar .toolbarTitle .installationName {
    font-size: 18px; }
  #toolbar .button-cajeros {
    min-width: auto; }
  #toolbar #user-menu .user-button {
    min-width: 64px;
    padding: 0 2px 0 10px; } }

#second-toolbar {
  height: 46px; }
  #second-toolbar .section-name {
    line-height: 46px;
    font-size: 30px;
    text-align: center; }
  #second-toolbar #navigation-toggle {
    width: 46px;
    min-width: 46px;
    height: 46px;
    margin: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0; }

@media screen and (max-width: 960px) {
  #second-toolbar .section-name {
    /*margin-right: $secondToolbarHeight;*/
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 100%; } }

navigation-search {
  position: absolute;
  top: 3px;
  left: 3px; }
  navigation-search md-item-template {
    font-size: 15px;
    color: black;
    padding: 8px 0; }
    navigation-search md-item-template md-icon {
      color: gray !important;
      margin: 0 10px; }
    navigation-search md-item-template > span {
      align-self: center; }
  navigation-search input {
    transition: background 0.25s; }
    navigation-search input:focus {
      background: deepskyblue; }
  navigation-search md-autocomplete md-autocomplete-wrap button {
    align-self: center; }
  navigation-search md-autocomplete md-autocomplete-wrap input#navSearchInput {
    background: transparent; }
  navigation-search md-autocomplete md-autocomplete-wrap input#navSearchInput:focus {
    background: -webkit-linear-gradient(left, deepskyblue, transparent);
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(right, deepskyblue, transparent);
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, deepskyblue, transparent);
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, deepskyblue, transparent);
    /* Standard syntax */ }

#administradorCajeros .copy-button {
  min-width: 0; }

#administradorCajeros .input-copy {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
  pointer-events: none; }

#administradorCajeros .blue {
  background: #003180;
  border-radius: 7px;
  font-weight: bold;
  padding: .5em;
  color: white; }

#administradorCajeros .blueDark {
  background: #233056;
  border-radius: 7px;
  font-weight: bold;
  padding: .5em;
  color: white; }

#administradorCajeros .cajeros {
  display: inline-block;
  text-align: center; }
  #administradorCajeros .cajeros div {
    border: 3px solid black;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    background: blue;
    color: white;
    font-size: 1.3em;
    font-weight: bold;
    margin: 2em;
    width: 35%; }
  #administradorCajeros .cajeros a {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    padding: 2em; }

#administradorCajeros .dates {
  text-align: center; }
  #administradorCajeros .dates * {
    background: none; }

#administradorCajeros .disponibilidad md-switch {
  margin: 0 !important;
  display: inline-block;
  vertical-align: middle; }

#administradorCajeros .disponibilidad span {
  display: inline-block !important;
  width: auto !important; }

#administradorCajeros .empty {
  background: #9d9696 !important;
  color: black; }

#administradorCajeros .estado {
  border-radius: 7px;
  color: white;
  width: 200px !important;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  padding: .5em; }

#administradorCajeros .green {
  border-radius: 7px;
  font-weight: bold;
  padding: .5em;
  background: green;
  color: white;
  text-transform: uppercase; }

#administradorCajeros .info {
  font-size: 1.3em;
  padding: 0 1em; }
  #administradorCajeros .info span {
    text-align: center; }

#administradorCajeros .log {
  margin: 0 2em; }

#administradorCajeros .pagado {
  margin: .2em 0;
  border-radius: 7px;
  color: white;
  font-weight: bold; }

#administradorCajeros .nopagado {
  width: 8%; }

#administradorCajeros .nopagado-background {
  color: white;
  font-weight: bold;
  display: inline-block;
  border-radius: 7px;
  padding: .5em;
  width: 36%; }

#administradorCajeros .pantallaCarga {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999; }
  #administradorCajeros .pantallaCarga h1, #administradorCajeros .pantallaCarga h3 {
    color: #000; }
  #administradorCajeros .pantallaCarga .cargaCentral {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

#administradorCajeros .red {
  background: red; }

#administradorCajeros .saldo {
  text-align: center;
  padding: 0 1em; }
  #administradorCajeros .saldo .moneda {
    margin: 10px;
    position: relative; }
    #administradorCajeros .saldo .moneda img {
      width: 100px; }
    #administradorCajeros .saldo .moneda .cantidad {
      background: darkred;
      font-size: 22px;
      color: white;
      border-radius: 1em;
      position: absolute;
      top: 0;
      right: 0;
      min-width: 36px;
      height: 36px;
      line-height: 36px; }

#administradorCajeros .tolva {
  border-radius: 21px;
  background: #142297;
  color: white;
  display: inline-block;
  font-weight: bold;
  padding: .25em .5em;
  margin: .5em;
  text-align: center;
  width: auto; }

#administradorCajeros .trampilla md-switch {
  margin: 0 !important;
  display: inline-block;
  vertical-align: middle; }

#administradorCajeros .trampilla span {
  display: inline-block !important;
  width: auto !important; }

#administradorCajeros .actions {
  padding: 0 1em; }

#administradorCajeros .content-table {
  max-height: 200px; }

#dispensar-mandos md-dialog-content, #pagar-mandos md-dialog-content {
  padding: 8px 16px; }

#cajeros-logs .list {
  margin: 0 16px;
  font-size: 18px; }

#estadisticas input {
  background-color: #f7f7f7; }

#estadisticas md-input-container {
  margin: 0; }
  #estadisticas md-input-container h4 {
    margin-right: 10px; }

#estadisticas md-radio-group input {
  margin-left: 15px; }

#estadisticas .errorStats {
  text-align: center; }

#estadisticas .stats_shadow {
  cursor: pointer; }

#estadisticas .stats_shadow:hover {
  background: lightsteelblue; }

#estadisticas ol {
  margin: 0; }
  #estadisticas ol span, #estadisticas ol li {
    font-size: 15px; }

#estadisticas .ms-widget md-content {
  max-height: 50vh;
  overflow: overlay; }

#estadisticas .stat-box {
  background: lightgray;
  padding: 5px;
  margin: 6px;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
  border-radius: 5px; }
  #estadisticas .stat-box md-icon {
    margin-right: 6px; }
  #estadisticas .stat-box img {
    max-height: 24px;
    max-width: 24px;
    margin: auto 6px auto 0;
    object-fit: contain; }

#estadisticas #timeline-container:not(.complete) .advanced {
  display: none; }

#estadisticas .OFF {
  background: tomato; }

#estadisticas .TV_OPENING, #estadisticas .TV {
  background: lawngreen; }

#estadisticas .HOME {
  background: lightblue; }

#estadisticas md-datepicker {
  background: transparent;
  padding-right: 0;
  margin-right: 0; }

#lotes .md-3-line {
  padding-left: 20px;
  margin: 10px 0;
  border: 1px solid lightgray; }

#lotes .md-3-line:hover {
  background-color: rgba(255, 0, 0, 0.35);
  background: -webkit-linear-gradient(left, rgba(255, 0, 0, 0.35), rgba(0, 0, 0, 0));
  background: -o-linear-gradient(right, rgba(255, 0, 0, 0.35), rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(right, rgba(255, 0, 0, 0.35), rgba(0, 0, 0, 0));
  background: linear-gradient(to right, rgba(255, 0, 0, 0.35), rgba(0, 0, 0, 0)); }

#lotes md-radio-group md-radio-group {
  margin: 0 20px; }

#lotes md-radio-group md-radio-button {
  height: auto; }

#lotes .groupContainer {
  border: 1px solid black;
  margin: 10px;
  min-width: 270px; }
  #lotes .groupContainer .groupHeader {
    padding: 5px 0 5px 20px;
    background-color: black;
    color: white; }
  #lotes .groupContainer .groupBody {
    padding: 10px;
    max-height: 15em;
    position: relative;
    overflow: auto; }
    #lotes .groupContainer .groupBody md-checkbox, #lotes .groupContainer .groupBody md-checkbox:last-of-type {
      margin: 3px; }
    #lotes .groupContainer .groupBody > div {
      display: block;
      height: 23px;
      margin: 3px; }
    #lotes .groupContainer .groupBody .d {
      /*padding-left: 1em;*/
      font-size: 1.3rem;
      font-style: italic; }

#lotes .md-icon-button {
  margin: 0;
  padding: 0;
  height: auto; }
  #lotes .md-icon-button .icon {
    color: whitesmoke; }

#lotes .stb-filter-form {
  border-collapse: collapse; }
  #lotes .stb-filter-form span {
    width: 50%; }
  #lotes .stb-filter-form .icon-eye {
    cursor: pointer; }
    #lotes .stb-filter-form .icon-eye:hover {
      color: blue; }

#lotes .patch-attr {
  width: 100%; }
  #lotes .patch-attr md-checkbox {
    margin: 6px 0; }
  #lotes .patch-attr td {
    min-width: 50px; }
    #lotes .patch-attr td:nth-child(2) {
      background: lightgray;
      padding: 2px 8px; }
  #lotes .patch-attr md-select {
    margin: 0; }

#lotes .progress-log-container {
  border: 1px solid;
  margin-bottom: 1em;
  padding: 0; }
  #lotes .progress-log-container .progress-log-container-header {
    /*            md-icon {
                color: whitesmoke;
                margin: 10px 4px;
                cursor: pointer;
                
                &:hover {
                    color: yellow;
                }
            }*/ }
    #lotes .progress-log-container .progress-log-container-header p {
      margin-left: 4px; }

@media screen and (max-width: 600px) {
  #lotes form {
    padding: 0; } }

#precios h3 {
  margin: 0;
  padding: 0.5em; }

#precios .pkg {
  border: 1px solid black;
  margin: 10px; }
  #precios .pkg .pkgTitle {
    background: black;
    color: white;
    position: relative; }
    #precios .pkg .pkgTitle h3 {
      margin: 0;
      padding: 0.5em; }
    #precios .pkg .pkgTitle img {
      max-height: 50px;
      margin: 2px 1em; }
    #precios .pkg .pkgTitle md-icon {
      position: absolute;
      top: 0;
      right: 0;
      color: limegreen; }
  #precios .pkg ul li * {
    margin: 10px 0; }

#precios .srv {
  border: 1px solid black;
  margin: 10px;
  min-width: 200px; }
  #precios .srv .srvTitle {
    position: relative; }
    #precios .srv .srvTitle h3, #precios .srv .srvTitle h4 {
      margin: 0;
      padding: 0.5em; }
    #precios .srv .srvTitle img {
      max-height: 30px;
      margin: 2px; }

#principal {
  /*max-height:calc(100vh - 64px);*/
  /*    .md-padding{
            min-height: 400px;
        }*/ }
  #principal .buscador {
    border: 2px solid gray;
    width: 100%;
    border-radius: 2em;
    position: relative;
    background: whitesmoke; }
    #principal .buscador .md-button {
      position: absolute;
      top: -5px;
      left: -5px;
      margin: 0; }
    #principal .buscador md-input-container {
      /*background-color: #f7f7f7;*/
      margin: auto 40px;
      padding: 0; }
      #principal .buscador md-input-container .md-errors-spacer {
        display: none; }
  #principal .iconoSidenav {
    position: absolute;
    right: 0px; }
  #principal .cabeceraTexto {
    font-size: 35px;
    color: white; }
  #principal .hide-tab-label > md-tabs-wrapper {
    display: none; }
  #principal .group-box {
    border: 1px solid;
    margin: 2px;
    min-width: fit-content; }
    #principal .group-box .group-box-title {
      text-align: center;
      margin-bottom: 4px;
      font-size: 16px;
      text-transform: uppercase;
      height: 24px;
      line-height: 24px; }
    #principal .group-box .md-button {
      margin: 2px;
      font-size: 14px;
      line-height: 14px;
      padding: 6px;
      text-align: left;
      min-width: fit-content; }
  #principal .simpleList {
    /*        position: absolute;
                background: whitesmoke;
                top: 8px;
                bottom: 8px;
                left: 8px;
                right: 8px;
                z-index: 3;
                transition: transform 0.5s;
                transform-origin: left bottom;*/ }
    #principal .simpleList > .stb {
      margin: 3px;
      padding: 2px 4px 2px 2px;
      border: 1px solid black;
      border-radius: 1em 6px 6px 1em;
      max-width: 250px;
      min-width: 70px;
      position: relative; }
      #principal .simpleList > .stb .stateLed {
        /*position: relative;*/
        width: 20px;
        height: 20px;
        border-radius: 50%; }
      #principal .simpleList > .stb .Inactivo {
        background: #F44336; }
      #principal .simpleList > .stb .Activo {
        background: #4CAF50; }
      #principal .simpleList > .stb .Boton {
        background: orange; }
      #principal .simpleList > .stb .Bloqueado {
        background: #3e64ff; }
      #principal .simpleList > .stb .Desconocido {
        background: black; }
      #principal .simpleList > .stb span {
        margin-left: 2px; }
    #principal .simpleList .checkout {
      background: lightgray;
      cursor: pointer; }
    #principal .simpleList .checkin {
      background: #fefefe;
      cursor: pointer; }
    #principal .simpleList .buscandoAp {
      background: #a9fbff; }
  #principal .notification-incidences::after {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #32b2ec;
    position: absolute;
    border-radius: 50%;
    top: 1px;
    right: 1px;
    animation: blue-bg-anim 1s linear 2s infinite alternate;
    z-index: 999; }

@keyframes blue-bg-anim {
  0% {
    background-color: #32b2ec; }
  100% {
    background-color: #354388; } }
  #principal .notification-incidences.stb {
    padding-right: 14px; }
  #principal .notification-prevents::before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #ea1e63;
    position: absolute;
    border-radius: 50%;
    top: 1px;
    right: 1px;
    animation: pink-bg-anim 1s linear 2s infinite alternate;
    z-index: 999; }

@keyframes pink-bg-anim {
  0% {
    background-color: #ea1e63; }
  100% {
    background-color: #6d0e2e; } }
  #principal .notification-prevents.stb {
    padding-right: 14px; }
  #principal .notification-prevents.notification-incidences::before {
    top: 12px; }
  #principal .notification-prevents.stb.notification-incidences::before {
    top: unset;
    bottom: 1px; }
  #principal .flipButton {
    z-index: 999;
    opacity: 0.3;
    transition: opacity 0.25s; }
  #principal .flipButton:hover {
    opacity: 1; }
  #principal md-sidenav {
    overflow: visible; }
    #principal md-sidenav md-content {
      position: absolute;
      z-index: 2;
      top: 64px;
      bottom: 0;
      background: white;
      right: 0;
      left: 0;
      border-radius: 0; }
    #principal md-sidenav .sidenavServicios {
      position: absolute;
      top: 62px;
      bottom: 0;
      left: -3em;
      right: 0;
      border-left: 3em solid #2d323e;
      border-top: 2px solid #2d323e;
      background: wheat;
      transition: all 0.5s;
      overflow: visible; }
      #principal md-sidenav .sidenavServicios md-list-item {
        z-index: 0; }
      #principal md-sidenav .sidenavServicios .md-subheader {
        background: transparent; }
      #principal md-sidenav .sidenavServicios > div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -3em;
        width: 3em; }
        #principal md-sidenav .sidenavServicios > div md-icon {
          color: whitesmoke;
          transition: all 0.5s; }
    #principal md-sidenav .sidenavServicios:hover {
      transform: translateX(calc(40px - 100%)); }
      #principal md-sidenav .sidenavServicios:hover > div md-icon {
        transform: rotateZ(180deg); }
  #principal md-tabs .completeList {
    margin: 1px;
    width: 275px;
    min-height: auto;
    border: 1px solid lightgray;
    border-radius: 1em 0 0 1em;
    overflow-x: hidden; }
    #principal md-tabs .completeList h3 {
      margin: auto 0 auto 5px; }
    #principal md-tabs .completeList > :not(.lockIcon) {
      z-index: 3;
      /*padding-left: 0;*/
      padding-left: 5px;
      height: 100%; }
    #principal md-tabs .completeList .lockIcon {
      position: absolute;
      right: 5px;
      top: 5px;
      bottom: 5px;
      z-index: -1; }
      #principal md-tabs .completeList .lockIcon * {
        font-size: 26px;
        height: 26px;
        width: 26px;
        line-height: 100%;
        color: rgba(197, 197, 197, 0.6);
        margin-left: 4px; }
  #principal md-tabs .completeList-mini {
    width: 150px !important; }
  #principal md-tabs .locked {
    background: -webkit-linear-gradient(left, #3e64ff, transparent);
    background: -o-linear-gradient(right, #3e64ff, transparent);
    background: -moz-linear-gradient(right, #3e64ff, transparent);
    background: linear-gradient(to right, #3e64ff, transparent); }
  #principal md-tabs .checkout {
    background: lightgray; }
  #principal md-tabs .checkin {
    background: #fefefe; }
  #principal md-tabs .buscandoAp {
    background: #a9fbff; }
  #principal md-tabs .stateLed {
    /*position: absolute;*/
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%; }
  #principal md-tabs .Inactivo button {
    /*background: #F44336;*/
    border-left: 10px solid #F44336; }
  #principal md-tabs .Activo button {
    /*background: #4CAF50;*/
    border-left: 10px solid #4CAF50; }
  #principal md-tabs .Boton button {
    border-left: 10px solid orange; }
  #principal md-tabs .Desconocido button {
    border-left: 10px solid black; }
  #principal md-tabs .Bloqueado button {
    /*background: #3e64ff;*/
    border-left: 10px solid #3E64FF; }
  #principal .wdgt {
    margin: 4px;
    border-radius: 16px 4px;
    padding: 8px 14px;
    cursor: pointer; }
    #principal .wdgt .h4 {
      text-align: center; }
    #principal .wdgt h1 {
      margin: inherit; }
  #principal .wdgt-marcado {
    animation: border-animation 1s linear infinite alternate; }

@keyframes border-animation {
  0% {
    border: 3px solid yellow; }
  100% {
    border: 3px solid #58570c; } }
  #principal .wdgt-idiomas img {
    max-height: 40px;
    max-width: 40px;
    margin: 3px; }
  #principal .orange-bg {
    background-color: #e0710e !important; }
  #principal md-badge {
    margin: 0 0 0 3px;
    /*background-color: #4288bc;*/
    color: white;
    padding: 3px 6px;
    border-radius: 4px; }

#principal-details .strong {
  font-weight: bold; }

#principal-details .underline {
  text-decoration: underline; }

@media screen and (max-width: 600px) {
  #principal-details md-dialog-actions {
    min-height: fit-content;
    padding: 4px 16px;
    flex-wrap: wrap; }
    #principal-details md-dialog-actions .md-button {
      margin: 4px; } }

.cabecera {
  height: 64px;
  background-image: url("/assets/images/backgrounds/header-bg.png");
  background-size: cover;
  /*background-color: #039BE5;*/
  padding: 10px;
  font-size: 35px;
  color: white;
  display: none; }

._background_ {
  background: none !important; }
  ._background_ md-content {
    border-radius: 1em;
    background-color: rgba(255, 255, 255, 0.85); }
  ._background_ md-tabs, ._background_ ms-form-wizard, ._background_ .dataTables_wrapper {
    border-radius: 0.5em; }
  ._background_ .dataTables_wrapper {
    background-color: white; }

.hint {
  /* Position the hint */
  /*position: absolute;*/
  left: 2px;
  right: auto;
  bottom: 7px;
  /* Copy styles from ng-messages */
  font-size: 12px;
  line-height: 14px;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  /* Set our own color */
  color: grey; }

get-translate-json {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999; }
  get-translate-json md-icon {
    transition: color 0.25s;
    cursor: pointer; }
  get-translate-json md-icon:hover {
    color: white; }

.click-gif {
  width: 25px;
  height: 25px; }

::-webkit-scrollbar {
  width: 8px; }

/* Track */
::-webkit-scrollbar-track {
  background: transparent; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 1em; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777; }

stb-screenshot {
  cursor: pointer;
  background-color: black; }
  stb-screenshot > div {
    position: relative;
    min-height: 50px;
    min-width: 50px; }
    stb-screenshot > div > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(72, 72, 72, 0.4);
      cursor: progress; }
      stb-screenshot > div > div md-progress-circular .md-left .md-half-circle {
        border-left-color: white !important;
        border-top-color: white !important; }
      stb-screenshot > div > div md-progress-circular .md-right .md-half-circle {
        border-right-color: white !important;
        border-top-color: white !important; }

.signal-meter-container {
  height: 10px;
  min-width: 50px;
  overflow: hidden;
  border-radius: 2em;
  background-color: #dcdcdc;
  display: inline-block; }
  .signal-meter-container .signal-meter {
    min-height: 100%; }
    .signal-meter-container .signal-meter.good {
      background-color: #16a085; }
    .signal-meter-container .signal-meter.bad {
      background-color: #e74c3c; }
    .signal-meter-container .signal-meter.ok {
      background-color: #f1c40f; }

#usuarios .BASE {
  border: 2px solid red; }
  #usuarios .BASE .role {
    background: red; }

#usuarios .BASE:hover {
  background-color: rgba(255, 0, 0, 0.35);
  background: -webkit-linear-gradient(left, rgba(255, 0, 0, 0.35), rgba(0, 0, 0, 0));
  background: -o-linear-gradient(right, rgba(255, 0, 0, 0.35), rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(right, rgba(255, 0, 0, 0.35), rgba(0, 0, 0, 0));
  background: linear-gradient(to right, rgba(255, 0, 0, 0.35), rgba(0, 0, 0, 0)); }

#usuarios .INSTALLER {
  border: 2px solid blue; }
  #usuarios .INSTALLER .role {
    background: blue; }

#usuarios .INSTALLER:hover {
  background-color: rgba(0, 0, 255, 0.35);
  background: -webkit-linear-gradient(left, rgba(0, 0, 255, 0.35), rgba(0, 0, 0, 0));
  background: -o-linear-gradient(right, rgba(0, 0, 255, 0.35), rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(right, rgba(0, 0, 255, 0.35), rgba(0, 0, 0, 0));
  background: linear-gradient(to right, rgba(0, 0, 255, 0.35), rgba(0, 0, 0, 0)); }

#usuarios .ADMINISTRATOR {
  border: 2px solid green; }
  #usuarios .ADMINISTRATOR .role {
    background: green; }

#usuarios .ADMINISTRATOR:hover {
  background-color: rgba(0, 255, 0, 0.35);
  background: -webkit-linear-gradient(left, rgba(0, 255, 0, 0.35), rgba(0, 0, 0, 0));
  background: -o-linear-gradient(right, rgba(0, 255, 0, 0.35), rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(right, rgba(0, 255, 0, 0.35), rgba(0, 0, 0, 0));
  background: linear-gradient(to right, rgba(0, 255, 0, 0.35), rgba(0, 0, 0, 0)); }

#usuarios .ROOT {
  border: 2px solid #e2d12b; }
  #usuarios .ROOT .role {
    background: #e2d12b; }

#usuarios .ROOT:hover {
  background-color: rgba(226, 209, 43, 0.35);
  background: -webkit-linear-gradient(left, rgba(226, 209, 43, 0.35), rgba(0, 0, 0, 0));
  background: -o-linear-gradient(right, rgba(226, 209, 43, 0.35), rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(right, rgba(226, 209, 43, 0.35), rgba(0, 0, 0, 0));
  background: linear-gradient(to right, rgba(226, 209, 43, 0.35), rgba(0, 0, 0, 0)); }

#usuarios .usuario {
  padding: 18px 10px 1px 10px;
  margin: 2px; }
  #usuarios .usuario .role {
    padding: 1px 10px;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom-right-radius: 10px; }
  #usuarios .usuario .md-avatar {
    border-radius: initial;
    height: auto; }
  #usuarios .usuario h3 {
    /*margin-right: 2em;*/
    min-width: 100px; }

#usuarios .passDiv {
  background: lightsteelblue;
  padding: 10px;
  border-radius: 10px; }

#usuarios .lang-select *:first-child {
  transform: none;
  -webkit-transform: none; }

.md-mini-mini {
  line-height: 32px !important;
  width: 32px !important;
  height: 32px !important;
  margin: 4px; }
  .md-mini-mini md-icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
    line-height: 20px; }

#permisos .routes md-checkbox {
  background: lightgray;
  padding: 2px 6px 2px 0; }

@media screen and (max-width: 600px) {
  #usuarios .usuario h3 {
    min-width: 0; } }

#wifiAdm md-select {
  margin: auto 20px;
  min-width: 150px; }

#wifiAdm table {
  min-width: 100%;
  border-collapse: collapse;
  margin-top: 1em; }
  #wifiAdm table th, #wifiAdm table td {
    min-width: 80px;
    padding: 0 1em; }
  #wifiAdm table td {
    border-top: 1px solid gray; }
    #wifiAdm table td .green-check {
      font-size: 36px;
      color: green; }
    #wifiAdm table td .red-cross {
      font-size: 36px;
      color: red; }
  #wifiAdm table .column-title {
    cursor: pointer;
    text-align: initial; }

#vertical-navigation {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  overflow-x: hidden;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), width 0.1s linear, min-width 0.1s linear, max-width 0.1s linear;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }
  #vertical-navigation .navigation-header {
    height: 64px;
    min-height: 64px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0 10px; }
    #vertical-navigation .navigation-header .logo .part1, #vertical-navigation .navigation-header .logo .part2 {
      position: absolute;
      max-width: 175px;
      max-height: 45px; }
    #vertical-navigation .navigation-header .logo .part2 {
      /*position:absolute;*/
      transition: opacity 0.3s; }
    #vertical-navigation .navigation-header .versionText {
      position: absolute;
      bottom: 3px;
      /*right: 3px;*/
      left: 190px;
      z-index: 9999;
      font-size: 12px; }
    #vertical-navigation .navigation-header .fold-toggle {
      transition: transform 0.3s ease-in-out 0.1s;
      transform: rotate(0deg);
      margin: 0;
      padding: 8px;
      width: 34px !important;
      height: 34px !important;
      cursor: pointer; }
  #vertical-navigation:not(.md-locked-open) {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
  #vertical-navigation .widgets {
    /*position: fixed;*/
    width: 240px;
    /*bottom: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    border-top: 2px solid rgba(255, 255, 255, 0.7);
    /*transform: translateY(calc(100% - 97px));*/
    /*transition: all 0.5s;*/ }
  #vertical-navigation .widgets:hover {
    /*transform: translateY(0);*/ }

@media only screen and (max-width: 960px) {
  .scrollable {
    height: calc(100vh - 64px);
    margin-bottom: 0; } }

@media only screen and (min-width: 960px) {
  .ms-navigation-folded #content-container {
    margin-left: 64px; }
  .ms-navigation-folded #vertical-navigation {
    position: absolute; }
    .ms-navigation-folded #vertical-navigation .navigation-header .fold-toggle {
      transform: rotate(180deg);
      opacity: 0; }
  .ms-navigation-folded.ms-navigation-folded-open #vertical-navigation .navigation-header .fold-toggle {
    transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.2s;
    opacity: 1; }
  .ms-navigation-folded:not(.ms-navigation-folded-open) #vertical-navigation {
    position: absolute;
    width: 64px;
    min-width: 64px;
    max-width: 64px; }
    .ms-navigation-folded:not(.ms-navigation-folded-open) #vertical-navigation .navigation-header {
      padding: 0 10px; }
      .ms-navigation-folded:not(.ms-navigation-folded-open) #vertical-navigation .navigation-header .logo .part1 {
        max-height: 45px; }
      .ms-navigation-folded:not(.ms-navigation-folded-open) #vertical-navigation .navigation-header .logo .part2 {
        opacity: 0; }
      .ms-navigation-folded:not(.ms-navigation-folded-open) #vertical-navigation .navigation-header .versionText {
        display: none; }
      .ms-navigation-folded:not(.ms-navigation-folded-open) #vertical-navigation .navigation-header .instText {
        font-size: 0; }
    .ms-navigation-folded:not(.ms-navigation-folded-open) #vertical-navigation .widgets {
      bottom: -419px; } }

#quick-panel .activity-tab .friends .friend {
  position: relative; }
  #quick-panel .activity-tab .friends .friend .status {
    position: absolute;
    top: 8px;
    left: 44px;
    width: 18px;
    height: 18px;
    z-index: 10;
    border-radius: 50%;
    border: 3px solid #FFFFFF; }
    #quick-panel .activity-tab .friends .friend .status.online {
      background-color: #00C853; }
    #quick-panel .activity-tab .friends .friend .status.offline {
      background-color: #D50000; }
    #quick-panel .activity-tab .friends .friend .status.away {
      background-color: #FF6D00; }
  #quick-panel .activity-tab .friends .friend .unread-message-count {
    position: absolute;
    left: 16px;
    bottom: 16px;
    width: 18px;
    height: 18px;
    z-index: 10;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    font-size: 11px; }

#quick-panel .activity-tab .servers .server .status {
  margin: 24px 20px 28px 8px; }
  #quick-panel .activity-tab .servers .server .status.normal {
    color: #00C853; }
  #quick-panel .activity-tab .servers .server .status.warn {
    color: #D50000; }
  #quick-panel .activity-tab .servers .server .status.error {
    color: #FF6D00; }

#quick-panel .activity-tab .stat md-progress-linear.normal .md-bar {
  background-color: #00C853; }

#quick-panel .activity-tab .stat md-progress-linear.normal .md-container {
  background-color: rgba(0, 200, 83, 0.3); }

#quick-panel .activity-tab .stat md-progress-linear.warn .md-bar {
  background-color: #FF6D00; }

#quick-panel .activity-tab .stat md-progress-linear.warn .md-container {
  background-color: rgba(255, 109, 0, 0.3); }

#quick-panel .activity-tab .stat md-progress-linear.alert .md-bar {
  background-color: #D50000; }

#quick-panel .activity-tab .stat md-progress-linear.alert .md-container {
  background-color: rgba(213, 0, 0, 0.3); }

#quick-panel .chat-tab .main,
#quick-panel .chat-tab .chat {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0; }

#quick-panel .chat-tab .main .contact {
  position: relative; }
  #quick-panel .chat-tab .main .contact .status {
    position: absolute;
    top: 8px;
    left: 44px;
    width: 18px;
    height: 18px;
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    z-index: 10; }
    #quick-panel .chat-tab .main .contact .status.online {
      background-color: #00C853; }
    #quick-panel .chat-tab .main .contact .status.offline {
      background-color: #D50000; }
    #quick-panel .chat-tab .main .contact .status.away {
      background-color: #FF6D00; }
  #quick-panel .chat-tab .main .contact .unread-message-count {
    position: absolute;
    left: 16px;
    bottom: 16px;
    width: 18px;
    height: 18px;
    z-index: 10;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    font-size: 11px; }

#quick-panel .chat-tab .main .recent .contact .status {
  top: 18px; }

#quick-panel .chat-tab .chat {
  max-height: 100%;
  overflow: hidden; }
  #quick-panel .chat-tab .chat .message-row .bubble {
    position: relative;
    padding: 8px;
    background-color: #eceff1;
    border: 1px solid #dcdfe1; }
    #quick-panel .chat-tab .chat .message-row .bubble:after, #quick-panel .chat-tab .chat .message-row .bubble:before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0; }
    #quick-panel .chat-tab .chat .message-row .bubble:after {
      left: -7px;
      right: auto;
      top: 0px;
      bottom: auto;
      border: 11px solid;
      border-color: #eceff1 transparent transparent transparent; }
    #quick-panel .chat-tab .chat .message-row .bubble:before {
      left: -9px;
      right: auto;
      top: -1px;
      bottom: auto;
      border: 8px solid;
      border-color: #dcdfe1 transparent transparent transparent; }
    #quick-panel .chat-tab .chat .message-row .bubble .time {
      font-size: 11px;
      margin-top: 8px; }
  #quick-panel .chat-tab .chat .message-row.contact .avatar {
    margin: 0 16px 0 0; }
  #quick-panel .chat-tab .chat .message-row.user {
    align-items: flex-end; }
    #quick-panel .chat-tab .chat .message-row.user .avatar {
      order: 2;
      margin: 0 0 0 16px; }
    #quick-panel .chat-tab .chat .message-row.user .bubble {
      background-color: #E8F5E9;
      border: 1px solid #DFEBE0;
      order: 1; }
      #quick-panel .chat-tab .chat .message-row.user .bubble:after {
        right: -7px;
        left: auto;
        top: auto;
        bottom: 0px;
        border-color: transparent transparent #E8F5E9 transparent; }
      #quick-panel .chat-tab .chat .message-row.user .bubble:before {
        right: -9px;
        left: auto;
        top: auto;
        bottom: -1px;
        border-color: transparent transparent #DFEBE0 transparent; }
  #quick-panel .chat-tab .chat .reply {
    border-top: 1px solid rgba(0, 0, 0, 0.12); }
    #quick-panel .chat-tab .chat .reply textarea {
      height: 96px;
      max-height: 96px;
      padding: 24px 16px; }

#quick-panel .today-tab .date > md-list-item {
  min-height: 115px; }

#layout-content-with-toolbar #toolbar {
  padding: 0 0 0 16px; }
  #layout-content-with-toolbar #toolbar .logo .logo-image {
    display: block;
    background: #039BE5;
    width: 32px;
    min-width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    border-radius: 2px; }
  #layout-content-with-toolbar #toolbar .logo .logo-text {
    color: rgba(0, 0, 0, 0.87);
    margin-left: 16px;
    font-size: 16px; }

#layout-horizontal-navigation #toolbar {
  padding: 0 0 0 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  #layout-horizontal-navigation #toolbar .navigation-toggle {
    height: 64px;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    margin-right: 16px; }
    #layout-horizontal-navigation #toolbar .navigation-toggle .md-button {
      margin: 0;
      width: 64px;
      height: 64px; }
  #layout-horizontal-navigation #toolbar .logo .logo-image {
    display: block;
    background: #039BE5;
    width: 32px;
    min-width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    border-radius: 2px; }
  #layout-horizontal-navigation #toolbar .logo .logo-text {
    color: rgba(0, 0, 0, 0.87);
    margin-left: 16px;
    font-size: 16px; }

@media only screen and (max-width: 960px) {
  #layout-horizontal-navigation #toolbar {
    padding: 0; } }

#documentExplorer md-list md-list-item {
  padding-left: 16px; }
  #documentExplorer md-list md-list-item .md-avatar {
    border-radius: 0;
    height: auto;
    max-height: 40px; }
  #documentExplorer md-list md-list-item .folder p {
    font-weight: bolder; }

#documentExplorer .bar md-icon {
  cursor: pointer; }

#documentExplorer .archivos {
  padding-top: 0; }
  #documentExplorer .archivos md-list-item:hover {
    background-color: rgba(158, 158, 158, 0.2); }

#documentExplorer .grid md-list-item {
  margin: 8px;
  padding-left: 0;
  width: 100px; }
  #documentExplorer .grid md-list-item .md-list-item-inner {
    max-width: 100%; }
  #documentExplorer .grid md-list-item img {
    margin: auto;
    height: 40px;
    object-fit: contain; }
  #documentExplorer .grid md-list-item p {
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden; }
  #documentExplorer .grid md-list-item md-menu {
    position: absolute;
    right: 0;
    top: 0; }
    #documentExplorer .grid md-list-item md-menu button {
      padding: 0;
      margin: 0;
      width: auto;
      height: auto; }

#fileExplorer md-list md-list-item {
  padding-left: 16px; }
  #fileExplorer md-list md-list-item .md-avatar {
    border-radius: 0;
    height: auto;
    max-height: 40px; }
  #fileExplorer md-list md-list-item .folder p {
    font-weight: bolder; }

#fileExplorer .bar md-icon {
  cursor: pointer; }

#fileExplorer .archivos {
  padding-top: 0; }
  #fileExplorer .archivos md-list-item:hover {
    background-color: rgba(158, 158, 158, 0.2); }

#fileExplorer .grid md-list-item {
  margin: 8px;
  padding-left: 0;
  width: 100px; }
  #fileExplorer .grid md-list-item .md-list-item-inner {
    max-width: 100%; }
  #fileExplorer .grid md-list-item img {
    margin: auto;
    height: 40px;
    object-fit: contain; }
  #fileExplorer .grid md-list-item p {
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden; }
  #fileExplorer .grid md-list-item md-menu {
    position: absolute;
    right: 0;
    top: 0; }
    #fileExplorer .grid md-list-item md-menu button {
      padding: 0;
      margin: 0;
      width: auto;
      height: auto; }

.avisoAdblock {
  position: absolute;
  bottom: 1em;
  right: 1em;
  background-color: gold;
  border-radius: 1em;
  padding: 0.4em;
  opacity: 0.6;
  z-index: 999;
  transition: opacity 0.25s; }
  .avisoAdblock * {
    cursor: default; }
  .avisoAdblock md-icon {
    cursor: pointer; }

.avisoAdblock:hover {
  opacity: 1; }

#publicidad .toggler {
  cursor: pointer; }

#publicidad .listaArchivos .elemento {
  border-radius: 4px;
  margin: 4px;
  position: relative;
  width: 150px;
  padding: 4px;
  background: lightgray; }
  #publicidad .listaArchivos .elemento > div {
    word-wrap: break-word;
    text-align: center; }

#publicidad .listaArchivos img, #publicidad .listaArchivos video {
  width: 150px;
  height: 150px;
  object-fit: contain; }

#publicidad .listaArchivos .icon-video {
  position: absolute;
  top: 0;
  left: 0; }

#publicidad .listaArchivos .icon-close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer; }

#publicidad .listaImgs > div {
  margin: 8px;
  position: relative;
  width: 200px; }

#publicidad .listaImgs .elemento {
  position: relative;
  width: 200px;
  height: 200px; }
  #publicidad .listaImgs .elemento .imagen {
    max-width: 100%;
    max-height: 100%; }
  #publicidad .listaImgs .elemento a {
    align-self: flex-end;
    pointer-events: none; }
  #publicidad .listaImgs .elemento .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(100, 100, 100, 0.4);
    margin: 0;
    display: none; }
    #publicidad .listaImgs .elemento .overlay .zoom {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 3px;
      border-top-right-radius: 10px;
      background: white;
      cursor: pointer; }
    #publicidad .listaImgs .elemento .overlay .borrar {
      position: absolute;
      top: 0;
      right: 0;
      padding: 3px;
      border-bottom-left-radius: 10px;
      background: white;
      cursor: pointer; }
    #publicidad .listaImgs .elemento .overlay .url {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 3px;
      border-top-left-radius: 10px;
      background: white;
      cursor: pointer; }
  #publicidad .listaImgs .elemento .check {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    font-size: 50px;
    height: 50px;
    width: 50px;
    line-height: 50px;
    cursor: pointer; }
  #publicidad .listaImgs .elemento .selected {
    color: green; }
  #publicidad .listaImgs .elemento .noSelected {
    color: gray; }
  #publicidad .listaImgs .elemento .top-left-icons {
    position: absolute;
    top: 0;
    left: 0; }
    #publicidad .listaImgs .elemento .top-left-icons, #publicidad .listaImgs .elemento .top-left-icons md-icon {
      font-size: 30px;
      height: 30px;
      width: 30px;
      line-height: 30px;
      margin-right: 6px; }

#publicidad .listaImgs .sites-container {
  margin-top: 8px; }
  #publicidad .listaImgs .sites-container .text-boxed-light {
    margin: 2px;
    overflow: hidden;
    text-overflow: ellipsis; }

#publicidad .listaImgs .elemento:hover .overlay {
  display: block; }

#publicidad .listaImgs .div-selected {
  border: 6px solid green; }

#publicidad .uploadContainer {
  border: 2px solid lightgrey;
  border-radius: 10px; }

#publicidad .fileData {
  border: 2px solid lightgrey;
  border-radius: 10px; }
  #publicidad .fileData img {
    max-height: 90px;
    max-width: 90px; }

#publicidad legend {
  padding: 0 10px; }

#grupos-banners .group-container {
  margin: 10px 0;
  border: 1px solid black;
  padding-left: 12px;
  padding-bottom: 12px;
  border-radius: 2px;
  background: lightgray; }

#grupos-banners .groupContainer {
  border: 1px solid black;
  margin: 10px;
  min-width: 270px; }
  #grupos-banners .groupContainer .groupHeader {
    padding: 5px 0 5px 20px;
    background-color: black;
    color: white;
    font-size: 1.6rem; }
  #grupos-banners .groupContainer .groupBody {
    padding: 10px;
    max-height: 15em;
    position: relative;
    overflow: auto; }
    #grupos-banners .groupContainer .groupBody md-checkbox, #grupos-banners .groupContainer .groupBody md-checkbox:last-of-type {
      margin: 3px; }
    #grupos-banners .groupContainer .groupBody .d {
      /*padding-left: 1em;*/
      font-size: 1.3rem;
      font-style: italic; }

#grupos-banners md-radio-group md-radio-group {
  margin: 0 20px; }

#grupos-banners md-radio-group md-radio-button {
  height: auto; }

#grupos-banners .md-icon-button {
  margin: 0;
  padding: 0;
  height: auto; }
  #grupos-banners .md-icon-button .icon {
    color: whitesmoke; }

#sin-grupo-container span {
  /*background: lightcoral;*/
  margin: 4px 8px;
  padding: 4px 8px;
  border-radius: 1em;
  font-size: 16px; }

#setLink-banner .text-boxed, #setLink-banner .text-boxed-light {
  white-space: normal; }

#setLink-banner .bold {
  font-weight: bold;
  border-radius: 2px;
  padding: 4px 8px;
  background-color: #fbf492; }

#setLink-banner label {
  color: rgba(0, 0, 0, 0.54); }

#setLink-banner .padding-horizontal-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

#setLink-banner md-sidenav {
  height: 100%;
  max-width: none;
  width: 70%; }
  #setLink-banner md-sidenav md-content {
    overflow: visible;
    /*height: calc(100% - $toolbar-height)*/ }
  #setLink-banner md-sidenav md-checkbox {
    margin: 8px 0; }
    #setLink-banner md-sidenav md-checkbox span {
      white-space: nowrap;
      /*width: 120px;*/
      overflow: hidden;
      text-overflow: ellipsis;
      display: block; }
  #setLink-banner md-sidenav .first-letter-style span:first-letter {
    font-weight: bold;
    font-size: 180%;
    color: #ff5722;
    line-height: 1; }

#publicidad-ayuda md-icon {
  font-size: 30px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  margin-right: 12px; }

#publicidad-ayuda .selected {
  color: green; }

#publicidad-ayuda .noSelected {
  color: gray; }

@media screen and (max-width: 960px) {
  #publicidad .contenedorImgs .listaImgs .elemento .overlay {
    display: block; } }

#quantionLogin {
  height: 100%; }
  #quantionLogin #login-form-wrapper {
    flex: 1 0 auto;
    padding: 32px; }
    #quantionLogin #login-form-wrapper #login-form {
      width: 384px;
      max-width: 384px;
      padding: 32px;
      background: rgba(255, 255, 255, 0.8);
      text-align: center;
      border-radius: 20px; }
      #quantionLogin #login-form-wrapper #login-form .logo_ {
        border-radius: 30px;
        padding: 10px; }
        #quantionLogin #login-form-wrapper #login-form .logo_ img {
          max-width: 170px;
          max-height: 70px; }
        #quantionLogin #login-form-wrapper #login-form .logo_ h1 {
          margin-bottom: 0; }
      #quantionLogin #login-form-wrapper #login-form .title {
        font-size: 17px;
        margin: 16px 0 32px 0; }
      #quantionLogin #login-form-wrapper #login-form form {
        width: 100%;
        text-align: left; }
        #quantionLogin #login-form-wrapper #login-form form md-checkbox {
          margin: 0; }
        #quantionLogin #login-form-wrapper #login-form form .remember-forgot-password {
          font-size: 13px;
          margin-top: 8px; }
          #quantionLogin #login-form-wrapper #login-form form .remember-forgot-password .remember-me {
            margin-bottom: 16px; }
          #quantionLogin #login-form-wrapper #login-form form .remember-forgot-password .forgot-password {
            font-size: 13px;
            font-weight: 500;
            margin-bottom: 16px; }
        #quantionLogin #login-form-wrapper #login-form form .submit-button {
          width: 220px;
          margin: 16px auto;
          display: block; }
      #quantionLogin #login-form-wrapper #login-form .register {
        margin: 32px auto 24px auto;
        width: 250px;
        font-weight: 500; }
        #quantionLogin #login-form-wrapper #login-form .register .text {
          margin-right: 8px; }
      #quantionLogin #login-form-wrapper #login-form .separator {
        font-size: 15px;
        font-weight: 600;
        margin: 24px auto 16px auto;
        position: relative;
        overflow: hidden;
        width: 100px;
        color: rgba(0, 0, 0, 0.54); }
        #quantionLogin #login-form-wrapper #login-form .separator .text {
          display: inline-block;
          vertical-align: baseline;
          position: relative;
          padding: 0 8px;
          z-index: 9999; }
          #quantionLogin #login-form-wrapper #login-form .separator .text:before, #quantionLogin #login-form-wrapper #login-form .separator .text:after {
            content: '';
            display: block;
            width: 30px;
            position: absolute;
            top: 10px;
            border-top: 1px solid rgba(0, 0, 0, 0.12); }
          #quantionLogin #login-form-wrapper #login-form .separator .text:before {
            right: 100%; }
          #quantionLogin #login-form-wrapper #login-form .separator .text:after {
            left: 100%; }
      #quantionLogin #login-form-wrapper #login-form .md-button.google, #quantionLogin #login-form-wrapper #login-form .md-button.facebook {
        width: 192px;
        text-transform: none;
        color: #FFFFFF;
        font-size: 13px; }
        #quantionLogin #login-form-wrapper #login-form .md-button.google md-icon, #quantionLogin #login-form-wrapper #login-form .md-button.facebook md-icon {
          color: #FFFFFF;
          margin: 0 8px 0 0; }
      #quantionLogin #login-form-wrapper #login-form .md-button.google {
        background-color: #D73D32; }
      #quantionLogin #login-form-wrapper #login-form .md-button.facebook {
        background-color: #3f5c9a; }

@media screen and (max-width: 600px) {
  #login #login-form-wrapper {
    padding: 16px; }
    #login #login-form-wrapper #login-form {
      padding: 24px;
      width: 100%; }
      #login #login-form-wrapper #login-form form .md-button {
        width: 90%; }
      #login #login-form-wrapper #login-form .md-button.google, #login #login-form-wrapper #login-form .md-button.facebook {
        width: 80%; } }

/*#enviarAviso {

    .tipo-aviso{
        img {
            max-width: 320px;
        }
    }

    input.ng-invalid, textarea.ng-invalid{
        background-color: pink;
    }

    input.ng-valid, textarea.ng-valid{
        background-color: lightgreen;
    }

    input.ng-pristine, textarea.ng-pristine, md-select, md-datepicker-input-container{
        background-color: #f7f7f7; 
    }

    input, textarea, md-select{
        transition:background-color 0.5s;
    }

    .pantallaCarga{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        background-color: rgba(150,150,150,0.7);

        .content{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        h1, h3{
            color: #000;
        }
    }

    .selector{
        background-color: black;
        height: 80px;
        max-width: 150px;
    }

    md-input-container img{
        margin-right: 20px;
        background-color: black;
        max-height: 100px;
        max-width: 100px;
        padding: 10px;
        border-radius: 1em;
        min-height: 100px;
        min-width: 100px;
    }

    form{
        margin:0 2em;

        md-checkbox{
            margin:5px 0;
        }
    }

    .temporizacion{
        input, md-datepicker, md-select{
            background-color: rgba(255, 255, 255, 0.3)
        }

        > div {
            > div {
                background-color: rgba(0, 0, 0, 0.3);;
                border-radius: 0.5em;
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {
    
    #enviarAviso {
        md-input-container img{
            margin-right: 0;
        }
        
        form{
            margin: 0;
        }
    }
}

#elegirImagenNotif{
    
    md-dialog-content{
        padding: 12px;
    }

    img{
        cursor: pointer;
        border: 4px solid transparent;
        margin: 6px;
        background-color: black;
        max-height: 80px;
        max-width: 80px;
        padding: 4px;
        border-radius: 1em;
        min-height: 80px;
        min-width: 80px;
    }

    img:hover{
        border:4px solid red;
    }
}*/
#listadoAvisos {
  /* Buzz */ }
  #listadoAvisos input {
    background-color: #f7f7f7; }
  #listadoAvisos md-badge {
    margin: 0 0 0 8px;
    /*background-color: #4288bc;*/
    color: white;
    padding: 3px 6px;
    border-radius: 4px; }
  #listadoAvisos td {
    padding-top: 0;
    padding-bottom: 0; }
  #listadoAvisos .Enviado {
    background-color: #cdcdcd;
    color: #7c7c7c; }
  #listadoAvisos .Enviado:hover {
    background-color: #b9b9b9; }
  #listadoAvisos .NoEnviado {
    background-color: #9dc7fd; }
  #listadoAvisos .NoEnviado:hover {
    background-color: #7092be; }
  #listadoAvisos .pantallaCarga {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: #969696; }
    #listadoAvisos .pantallaCarga .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    #listadoAvisos .pantallaCarga h1, #listadoAvisos .pantallaCarga h3 {
      color: #000; }
  #listadoAvisos .positioner {
    position: relative; }
    #listadoAvisos .positioner > .md-button {
      position: absolute;
      z-index: 7; }
  #listadoAvisos .type-selector > div {
    padding: 8px;
    cursor: pointer; }
    #listadoAvisos .type-selector > div:hover:not(.md-warn-bg) {
      background: lightgray; }
  #listadoAvisos md-list-item:hover {
    background: lightgray; }
  #listadoAvisos md-list-item .md-avatar {
    border-radius: 0; }
  #listadoAvisos md-list-item .md-list-item-text * {
    white-space: normal; }

@-webkit-keyframes hvr-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); } }

@keyframes hvr-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); } }
  #listadoAvisos .hvr-buzz {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0); }
  #listadoAvisos .hvr-buzz:hover, #listadoAvisos .hvr-buzz:focus, #listadoAvisos .hvr-buzz:active {
    -webkit-animation-name: hvr-buzz;
    animation-name: hvr-buzz;
    -webkit-animation-duration: 0.15s;
    animation-duration: 0.15s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite; }
  #listadoAvisos .md-subheader .md-button {
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px; }
    #listadoAvisos .md-subheader .md-button:not(:last-of-type) {
      margin-right: 12px; }

#aviso-details .strong {
  font-weight: bold; }

#aviso-details h2 {
  margin-top: 0; }

#aviso-details .image-container {
  padding: 8px;
  border-radius: 5px;
  background: #252525;
  margin-right: 8px; }
  #aviso-details .image-container img {
    max-height: 100px;
    max-width: 100px; }

#aviso-details .room-container {
  background: lightgray;
  margin: 4px;
  padding: 2px 8px;
  border-radius: 2em; }

#enviarAviso {
  padding: 0; }
  #enviarAviso .tipo-aviso img {
    max-width: 320px; }
  #enviarAviso input.ng-invalid, #enviarAviso textarea.ng-invalid {
    background-color: pink; }
  #enviarAviso input.ng-valid, #enviarAviso textarea.ng-valid {
    background-color: lightgreen; }
  #enviarAviso input.ng-pristine, #enviarAviso textarea.ng-pristine, #enviarAviso md-select, #enviarAviso md-datepicker-input-container {
    background-color: #f7f7f7; }
  #enviarAviso input, #enviarAviso textarea, #enviarAviso md-select {
    transition: background-color 0.5s; }
  #enviarAviso .pantallaCarga {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(150, 150, 150, 0.7); }
    #enviarAviso .pantallaCarga .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    #enviarAviso .pantallaCarga h1, #enviarAviso .pantallaCarga h3 {
      color: #000; }
  #enviarAviso .selector {
    background-color: black;
    height: 80px;
    max-width: 150px; }
  #enviarAviso md-input-container img {
    margin-right: 20px;
    background-color: black;
    max-height: 100px;
    max-width: 100px;
    padding: 10px;
    border-radius: 1em;
    min-height: 100px;
    min-width: 100px; }
  #enviarAviso form {
    margin: 0 2em; }
    #enviarAviso form md-checkbox {
      margin: 5px 0; }
  #enviarAviso .temporizacion input, #enviarAviso .temporizacion md-datepicker, #enviarAviso .temporizacion md-select {
    background-color: rgba(255, 255, 255, 0.3); }
  #enviarAviso .info-temp {
    padding: 4px;
    border-radius: 8px;
    font-size: 14px; }
  #enviarAviso .groupContainer {
    border: 1px solid black;
    margin: 10px;
    min-width: 270px; }
    #enviarAviso .groupContainer .groupHeader {
      padding: 5px 0 5px 20px;
      background-color: black;
      color: white; }
    #enviarAviso .groupContainer .groupBody {
      padding: 10px;
      max-height: 15em;
      position: relative;
      overflow: auto; }
      #enviarAviso .groupContainer .groupBody > div {
        display: block !important; }
      #enviarAviso .groupContainer .groupBody md-checkbox, #enviarAviso .groupContainer .groupBody md-checkbox:last-of-type {
        margin: 3px; }
      #enviarAviso .groupContainer .groupBody .d {
        /*padding-left: 1em;*/
        font-size: 1.3rem;
        font-style: italic; }
      #enviarAviso .groupContainer .groupBody .md-avatar {
        height: 29px;
        width: 29px;
        object-fit: contain;
        margin-left: auto; }
    #enviarAviso .groupContainer .md-icon-button {
      margin: 0;
      padding: 0;
      height: auto; }
      #enviarAviso .groupContainer .md-icon-button .icon {
        color: whitesmoke; }
  #enviarAviso .envioInfo {
    background: yellow;
    padding: 0.5em;
    text-align: center;
    border-radius: 1em; }
  #enviarAviso .container {
    border: 1px solid gray;
    margin-bottom: 8px;
    padding: 4px;
    border-radius: 8px; }
  #enviarAviso .button-container:nth-child(even) {
    background-color: lightgray;
    margin: 0 -4px;
    padding: 2px 6px;
    border-bottom: 1px solid grey;
    border-top: 1px solid grey; }

@media screen and (max-width: 600px) {
  #enviarAviso md-input-container img {
    margin-right: 0; }
  #enviarAviso form {
    margin: 0; } }

#elegirImagenNotif md-dialog-content {
  padding: 12px; }

#elegirImagenNotif img {
  cursor: pointer;
  border: 4px solid transparent;
  margin: 6px;
  background-color: black;
  max-height: 80px;
  max-width: 80px;
  padding: 4px;
  border-radius: 1em;
  min-height: 80px;
  min-width: 80px; }

#elegirImagenNotif img:hover {
  border: 4px solid red; }

@media screen and (max-width: 600px) {
  #listadoAvisos md-list-item > button {
    padding: 0; } }

#enviarPubli .tipo-aviso img {
  max-width: 320px; }

#enviarPubli input.ng-invalid, #enviarPubli textarea.ng-invalid {
  background-color: pink; }

#enviarPubli input.ng-valid, #enviarPubli textarea.ng-valid {
  background-color: lightgreen; }

#enviarPubli input.ng-pristine, #enviarPubli textarea.ng-pristine, #enviarPubli md-select, #enviarPubli md-datepicker-input-container {
  background-color: #f7f7f7; }

#enviarPubli input, #enviarPubli textarea, #enviarPubli md-select {
  transition: background-color 0.5s; }

#enviarPubli .pantallaCarga {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(150, 150, 150, 0.7); }
  #enviarPubli .pantallaCarga .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  #enviarPubli .pantallaCarga h1, #enviarPubli .pantallaCarga h3 {
    color: #000; }

#enviarPubli .selector {
  background-color: black;
  height: 80px;
  max-width: 150px; }

#enviarPubli md-input-container img {
  margin-right: 20px;
  background-color: black;
  max-height: 100px;
  max-width: 100px;
  padding: 10px;
  border-radius: 1em;
  min-height: 100px;
  min-width: 100px; }

#enviarPubli form {
  margin: 0 2em; }
  #enviarPubli form md-checkbox {
    margin: 5px 0; }
  #enviarPubli form .package {
    border: 2px solid black;
    margin: 0.5em; }
    #enviarPubli form .package .md-accent-bg {
      padding: 0 0.5em; }
    #enviarPubli form .package .package-content {
      margin: 0.5em; }
      #enviarPubli form .package .package-content > div {
        min-width: 200px; }
        #enviarPubli form .package .package-content > div img {
          max-height: 3em; }
  #enviarPubli form .envioInfo {
    background: yellow;
    padding: 0.5em;
    text-align: center;
    border-radius: 1em; }

#enviarPubli .temporizacion input, #enviarPubli .temporizacion md-datepicker, #enviarPubli .temporizacion md-select {
  background-color: rgba(255, 255, 255, 0.3); }

#enviarPubli .temporizacion > div > div {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.5em; }

@media screen and (max-width: 600px) {
  #enviarPubli md-input-container img {
    margin-right: 0; }
  #enviarPubli form {
    margin: 0; } }

#estadisticas-urls input {
  background-color: #f7f7f7; }

#estadisticas-urls md-input-container {
  margin: 0; }
  #estadisticas-urls md-input-container h4 {
    margin-right: 10px; }

#estadisticas-urls md-radio-group input {
  margin-left: 15px; }

#estadisticas-urls .errorStats {
  text-align: center; }

#estadisticas-urls md-autocomplete {
  /*min-width: 300px;*/ }

#estadisticas-urls md-datepicker {
  background: transparent;
  padding-right: 0;
  margin-right: 0; }

#instantanea table {
  border-collapse: collapse;
  width: 100%; }

#instantanea td, #instantanea th {
  border: 1px solid darkgray;
  text-align: left;
  padding: 8px;
  vertical-align: top; }

#instantanea tr:nth-child(even) {
  background-color: #dddddd; }

#instantanea .column-title {
  cursor: pointer; }
  #instantanea .column-title:after {
    position: relative;
    font-family: 'icomoon';
    font-weight: normal;
    margin-left: 10px;
    top: 2px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54); }

#instantanea .ord-no:after {
  content: '\eaa8'; }

#instantanea .ord-asc:after {
  content: '\e718'; }

#instantanea .ord-desc:after {
  content: '\e715'; }

#instantanea .filter-tr td {
  padding: 2px; }
  #instantanea .filter-tr td input {
    width: 100%; }

#instantanea .date-container md-datepicker {
  background: transparent;
  padding-right: 0;
  margin-right: 0; }

#instantanea .groupContainer {
  border: 1px solid black;
  margin: 10px;
  min-width: 270px; }
  #instantanea .groupContainer .groupHeader {
    padding: 5px 0 5px 10px;
    background-color: black;
    color: white; }
  #instantanea .groupContainer .groupBody {
    padding: 10px;
    max-height: 15em;
    position: relative;
    overflow: auto; }
    #instantanea .groupContainer .groupBody md-checkbox, #instantanea .groupContainer .groupBody md-checkbox:last-of-type {
      margin: 3px; }
    #instantanea .groupContainer .groupBody .d {
      /*padding-left: 1em;*/
      font-size: 1.3rem;
      font-style: italic; }
    #instantanea .groupContainer .groupBody .md-avatar {
      max-height: 50px;
      max-width: 50px; }
  #instantanea .groupContainer .md-icon-button {
    margin: 0;
    padding: 0;
    height: auto; }
    #instantanea .groupContainer .md-icon-button .icon {
      color: whitesmoke; }
  #instantanea .groupContainer .stb-container {
    margin: 4px 8px;
    padding: 2px;
    border-radius: 2em;
    background: lightgray;
    cursor: pointer; }
    #instantanea .groupContainer .stb-container .icon-checkbox-blank-circle {
      color: darkgray; }
    #instantanea .groupContainer .stb-container span {
      margin: 0 8px 0 2px; }
  #instantanea .groupContainer .checked {
    background: green; }
    #instantanea .groupContainer .checked .icon-checkbox-marked-circle {
      color: limegreen; }
  #instantanea .groupContainer .range-ini {
    position: relative;
    background: yellow; }
    #instantanea .groupContainer .range-ini:before {
      content: ">";
      position: absolute;
      left: -8px; }

#estadisticas-tv input {
  background-color: #f7f7f7; }

#estadisticas-tv md-input-container {
  margin: 0; }
  #estadisticas-tv md-input-container h4 {
    margin-right: 10px; }

#estadisticas-tv md-radio-group input {
  margin-left: 15px; }

#estadisticas-tv .errorStats {
  text-align: center; }

#estadisticas-tv md-autocomplete {
  /*min-width: 300px;*/ }

#estadisticas-tv md-datepicker {
  background: transparent;
  padding-right: 0;
  margin-right: 0; }

#estadisticas-urls input {
  background-color: #f7f7f7; }

#estadisticas-urls md-input-container {
  margin: 0; }
  #estadisticas-urls md-input-container h4 {
    margin-right: 10px; }

#estadisticas-urls md-radio-group input {
  margin-left: 15px; }

#estadisticas-urls .errorStats {
  text-align: center; }

#estadisticas-urls md-autocomplete {
  /*min-width: 300px;*/ }

#estadisticas-urls md-datepicker {
  background: transparent;
  padding-right: 0;
  margin-right: 0; }

#contabilidad md-list .md-list-item-text {
  margin: 7px; }

#contabilidad md-list md-list-item {
  min-height: 0px; }

#contabilidad md-list md-list-item.md-3-line:hover, #contabilidad md-list md-list-item.md-1-line:hover {
  background-color: lightgray; }

#contabilidad .pkgOper {
  border-bottom: 1px solid #ef6c00;
  margin: 2px 0; }

#contabilidad .pkgOper:hover {
  background-color: lightgray; }

#contabilidad md-datepicker {
  margin-right: 0;
  padding-right: 0;
  background-color: transparent; }

#contabilidad .md-1-line h3 {
  margin: auto; }

#contabilidad .total * {
  font-weight: bolder; }

#contabilidad table {
  /*width: 100%;*/
  margin: 0 auto;
  border-spacing: 0; }
  #contabilidad table tr:hover td:first-child {
    background-color: gray; }
  #contabilidad table tr:hover td {
    background-color: lightgray;
    cursor: pointer; }
  #contabilidad table tr:hover td:last-child {
    background-color: steelblue; }
  #contabilidad table tr:hover td.refund-td {
    background-color: #8b000073 !important; }
  #contabilidad table tr:hover td.cortesy-td {
    background-color: #adff2f73 !important; }
  #contabilidad table tr:hover td.balance-td {
    background-color: #b8860b73 !important; }
  #contabilidad table td {
    padding: 10px 8px;
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  #contabilidad table thead tr {
    background: black !important; }
  #contabilidad table th {
    /*padding: 14px 15px;*/
    vertical-align: middle;
    background-color: black;
    color: white;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden; }
  #contabilidad table th, #contabilidad table td {
    width: 100px;
    min-width: 100px;
    max-width: 100px; }
  #contabilidad table tr:last-child, #contabilidad table td:last-child, #contabilidad table tr:nth-last-child(2) {
    background-color: lightsteelblue; }
  #contabilidad table tr:last-child:hover td:first-child, #contabilidad table tr:nth-last-child(2):hover td:first-child {
    background-color: gray; }
  #contabilidad table tr:last-child:hover td, #contabilidad table tr:nth-last-child(2):hover td {
    background-color: lightsteelblue;
    cursor: initial; }
  #contabilidad table tr:last-child:hover td:last-child, #contabilidad table tr:nth-last-child(2):hover td:last-child {
    background-color: steelblue; }
  #contabilidad table td:first-child {
    background-color: lightgray; }
  #contabilidad table td.bold {
    font-weight: bold; }
  #contabilidad table td.zero {
    color: orangered; }
  #contabilidad table td.refund-td {
    background-color: #8b000045; }
  #contabilidad table td.cortesy-td {
    background-color: #adff2f45; }
  #contabilidad table td.balance-td {
    background-color: #b8860b45; }
  #contabilidad table thead tr {
    display: block;
    position: relative; }
  #contabilidad table tbody {
    display: block;
    overflow: auto;
    width: 100%;
    /*height: calc(100vh - 300px);*/ }

#contabilidad .ind-checkbox * {
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Likely future */ }

.md-datepicker-input-mask-opaque {
  background-color: transparent; }

#contabilidad-sin-precios md-list .md-list-item-text {
  margin: 7px; }

#contabilidad-sin-precios md-list md-list-item {
  min-height: 0px; }

#contabilidad-sin-precios md-list md-list-item.md-3-line:hover, #contabilidad-sin-precios md-list md-list-item.md-1-line:hover {
  background-color: lightgray; }

#contabilidad-sin-precios .pkgOper {
  border-bottom: 1px solid #ef6c00;
  margin: 2px 0; }

#contabilidad-sin-precios .pkgOper:hover {
  background-color: lightgray; }

#contabilidad-sin-precios md-datepicker {
  margin-right: 0;
  padding-right: 0;
  background-color: transparent; }

#contabilidad-sin-precios .md-1-line h3 {
  margin: auto; }

#contabilidad-sin-precios .total * {
  font-weight: bolder; }

#contabilidad-sin-precios table {
  /*width: 100%;*/
  margin: 0 auto;
  border-spacing: 0; }
  #contabilidad-sin-precios table tr:hover td:first-child {
    background-color: gray; }
  #contabilidad-sin-precios table tr:hover td {
    background-color: lightgray;
    cursor: pointer; }
  #contabilidad-sin-precios table tr:hover td:last-child {
    background-color: steelblue; }
  #contabilidad-sin-precios table tr:hover td.refund-td {
    background-color: #8b000073 !important; }
  #contabilidad-sin-precios table tr:hover td.cortesy-td {
    background-color: #adff2f73 !important; }
  #contabilidad-sin-precios table tr:hover td.balance-td {
    background-color: #b8860b73 !important; }
  #contabilidad-sin-precios table td {
    padding: 10px 8px;
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  #contabilidad-sin-precios table thead tr {
    background: black !important; }
  #contabilidad-sin-precios table th {
    /*padding: 14px 15px;*/
    vertical-align: middle;
    background-color: black;
    color: white;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden; }
  #contabilidad-sin-precios table th, #contabilidad-sin-precios table td {
    width: 100px;
    min-width: 100px;
    max-width: 100px; }
  #contabilidad-sin-precios table tr:last-child, #contabilidad-sin-precios table td:last-child, #contabilidad-sin-precios table tr:nth-last-child(2) {
    background-color: lightsteelblue; }
  #contabilidad-sin-precios table tr:last-child:hover td:first-child, #contabilidad-sin-precios table tr:nth-last-child(2):hover td:first-child {
    background-color: gray; }
  #contabilidad-sin-precios table tr:last-child:hover td, #contabilidad-sin-precios table tr:nth-last-child(2):hover td {
    background-color: lightsteelblue;
    cursor: initial; }
  #contabilidad-sin-precios table tr:last-child:hover td:last-child, #contabilidad-sin-precios table tr:nth-last-child(2):hover td:last-child {
    background-color: steelblue; }
  #contabilidad-sin-precios table td:first-child {
    background-color: lightgray; }
  #contabilidad-sin-precios table td.bold {
    font-weight: bold; }
  #contabilidad-sin-precios table td.zero {
    color: orangered; }
  #contabilidad-sin-precios table td.refund-td {
    background-color: #8b000045; }
  #contabilidad-sin-precios table td.cortesy-td {
    background-color: #adff2f45; }
  #contabilidad-sin-precios table td.balance-td {
    background-color: #b8860b45; }
  #contabilidad-sin-precios table thead tr {
    display: block;
    position: relative; }
  #contabilidad-sin-precios table tbody {
    display: block;
    overflow: auto;
    width: 100%;
    /*height: calc(100vh - 300px);*/ }

#contabilidad-sin-precios .ind-checkbox * {
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Likely future */ }

.md-datepicker-input-mask-opaque {
  background-color: transparent; }

#gestionCompras md-badge {
  margin: 0 0 0 8px;
  /*background-color: #259b24;*/
  color: white;
  padding: 3px 6px;
  border-radius: 4px; }

#gestionCompras .uploadContainer {
  border: 2px solid lightgrey;
  border-radius: 10px; }

#gestionCompras .input_oculto {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0; }

#gestionCompras .canal-list-item {
  min-width: 375px;
  transition: background 0.25s; }

#gestionCompras .canal-list-item:hover {
  background: lightgray; }

#gestionCompras .pest {
  border: 1px solid lightgray;
  padding: 5px; }

#gestionCompras md-list-item h3, #gestionCompras md-list-item h4 {
  white-space: normal;
  /*max-height: 32px;*/ }

#gestionCompras .asig-list {
  height: 100%; }
  #gestionCompras .asig-list .item {
    max-height: 30px; }

#gestionCompras .listaCanales {
  margin: 5px;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: move;
  background: white; }
  #gestionCompras .listaCanales h4 {
    margin: 5px; }

#gestionCompras .listaCanales:hover {
  background: lightgray; }

#gestionCompras .lsta {
  min-width: 300px;
  max-width: 500px; }

#gestionCompras .lsta-highlight {
  background: yellow;
  height: 29px; }

#gestionCompras .tab {
  min-height: 600px; }

#gestionCompras .mini {
  width: 20px;
  height: 20px;
  min-height: 20px; }

#gestionCompras md-list .md-list-item-text {
  margin: 7px; }

#gestionCompras md-list md-list-item {
  min-height: 0px; }

#gestionCompras md-list md-list-item:hover {
  background-color: lightgray; }

#gestionCompras md-list .Balance {
  background-color: orange; }

#gestionCompras md-list .Cash {
  background-color: green; }

#gestionCompras md-list .Cortesy {
  background-color: greenyellow; }

#gestionCompras md-list .Card {
  background-color: #4ea7e6; }

#gestionCompras md-list .None {
  background-color: gray; }

#gestionCompras md-list .PendingGive {
  background-color: orchid; }

#gestionCompras md-list .PendingReceive {
  background-color: yellow; }

#gestionCompras md-list .Paypal {
  background-color: cyan; }

#gestionCompras md-list .idAccountant {
  min-height: 88px;
  padding-right: 5px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  align-self: stretch; }

#gestionCompras md-list .ingreso {
  color: green; }

#gestionCompras md-list .gasto {
  color: red; }

#gestionCompras md-list .no {
  display: none; }

#gestionCompras .comentario {
  /*color: gray;*/
  font-size: 12px;
  background-color: #ffc621;
  background: -webkit-linear-gradient(left, #ffc621, rgba(0, 0, 0, 0));
  background: -o-linear-gradient(right, #ffc621, rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(right, #ffc621, rgba(0, 0, 0, 0));
  background: linear-gradient(to right, #ffc621, rgba(0, 0, 0, 0));
  border-radius: 15px;
  padding: 5px; }

#gestionCompras md-datepicker {
  margin-right: 0;
  padding-right: 0;
  background-color: transparent;
  transition: opacity 0.25s; }

#gestionCompras .disabled {
  opacity: 0; }

#gestionCompras ng-include {
  overflow: auto; }

@media screen and (max-width: 960px) {
  #gestionCompras md-input-container {
    padding: 0;
    margin-bottom: 0; }
  #gestionCompras md-dialog-content {
    padding: 8px 16px; } }

.md-datepicker-input-mask-opaque {
  background-color: transparent; }

._Balance {
  border-left: 5px solid darkgoldenrod; }
  ._Balance .idAccountant {
    background-color: darkgoldenrod; }

._Package {
  border-left: 5px solid darkgreen; }
  ._Package .idAccountant {
    background-color: darkgreen; }
    ._Package .idAccountant span, ._Package .idAccountant md-icon {
      color: lightgray; }

._Cancellation {
  border-left: 5px solid darkgrey; }
  ._Cancellation .idAccountant {
    background-color: darkgrey; }

._ChangeRoom {
  border-left: 5px solid darkcyan; }
  ._ChangeRoom .idAccountant {
    background-color: darkcyan; }

._Refund {
  border-left: 5px solid darkred; }
  ._Refund .idAccountant {
    background-color: darkred; }
    ._Refund .idAccountant span, ._Refund .idAccountant md-icon {
      color: lightgray; }

._Service {
  border-left: 5px solid indigo; }
  ._Service .idAccountant {
    background-color: indigo; }
    ._Service .idAccountant span, ._Service .idAccountant md-icon {
      color: lightgray; }

._Wallet {
  border-left: 5px solid orangered; }
  ._Wallet .idAccountant {
    background-color: orangered; }
    ._Wallet .idAccountant span, ._Wallet .idAccountant md-icon {
      color: lightgray; }

._Voip {
  border-left: 5px solid darkmagenta; }
  ._Voip .idAccountant {
    background-color: darkmagenta; }
    ._Voip .idAccountant span, ._Voip .idAccountant md-icon {
      color: lightgray; }

.Balance_ {
  background-color: orange; }

.Cash_ {
  background-color: green; }

.Cortesy_ {
  background-color: greenyellow; }

.Card_ {
  background-color: #4ea7e6; }

.None_ {
  background-color: gray; }

.PendingGive_ {
  background-color: orchid; }

.PendingReceive_ {
  background-color: yellow; }

.Paypal_ {
  background-color: cyan; }

#recaudacion md-list .md-list-item-text {
  margin: 7px; }

#recaudacion md-list md-list-item {
  min-height: 0px; }

#recaudacion md-list md-list-item:hover {
  background-color: lightgray; }

#recaudacion md-datepicker {
  margin-right: 0;
  padding-right: 0;
  background-color: transparent; }

#recaudacion .md-1-line h3 {
  margin: auto; }

#recaudacion .total * {
  font-weight: bolder; }

#recaudacion table {
  width: 45%;
  margin-bottom: 1em; }

#recaudacion table, #recaudacion th, #recaudacion td {
  border: 1px solid black;
  border-collapse: collapse; }

#recaudacion th, #recaudacion td {
  padding: 5px;
  text-align: center; }

#recaudacion table tr:nth-child(even) {
  background-color: #eee; }

#recaudacion table tr:nth-child(odd) {
  background-color: #fff; }

#recaudacion table tr:last-child {
  background-color: #8a8a8a; }

#recaudacion .specialTd {
  cursor: pointer; }

#recaudacion .specialTd:hover {
  background-color: lightskyblue; }

#recaudacion ._c1, #recaudacion ._c2, #recaudacion ._c5, #recaudacion ._c10, #recaudacion ._c20, #recaudacion ._c50, #recaudacion ._c100, #recaudacion ._c200 {
  background-color: goldenrod;
  border-radius: 1em;
  min-width: 20px;
  min-height: 20px;
  text-align: center;
  line-height: 20px; }

#recaudacion ._b5, #recaudacion ._b10, #recaudacion ._b20, #recaudacion ._b50, #recaudacion ._b100, #recaudacion ._b200, #recaudacion ._b500 {
  background-color: lightgreen;
  min-width: 20px;
  text-align: center; }

#recaudacion .flex-12 {
  margin: 2px 6px; }

#recaudacion .odd {
  background-color: lightgrey; }

#recaudacion .operation {
  min-height: 28px;
  position: relative; }
  #recaudacion .operation > md-icon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 28px;
    height: 28px;
    width: 28px;
    line-height: 28px; }

.md-datepicker-input-mask-opaque {
  background-color: transparent; }

#recaudacion_completa md-datepicker {
  margin-right: 0;
  padding-right: 0;
  background-color: transparent; }

#recaudacion_completa table {
  width: 100%;
  margin-bottom: 1em; }

#recaudacion_completa table, #recaudacion_completa th, #recaudacion_completa td {
  border: 1px solid black;
  border-collapse: collapse; }

#recaudacion_completa th, #recaudacion_completa td {
  padding: 5px;
  text-align: center; }

#recaudacion_completa th {
  background-color: #8a8a8a; }

#recaudacion_completa td {
  background-color: #eee; }

#recaudacion_simple md-list .md-list-item-text {
  margin: 7px; }

#recaudacion_simple md-list md-list-item {
  min-height: 0px; }

#recaudacion_simple md-list md-list-item:hover {
  background-color: lightgray; }

#recaudacion_simple md-datepicker {
  margin-right: 0;
  padding-right: 0;
  background-color: transparent; }

#recaudacion_simple .md-1-line h3 {
  margin: auto; }

#recaudacion_simple .total * {
  font-weight: bolder; }

#recaudacion_simple table {
  width: 45%;
  margin-bottom: 1em; }

#recaudacion_simple table, #recaudacion_simple th, #recaudacion_simple td {
  border: 1px solid black;
  border-collapse: collapse; }

#recaudacion_simple th, #recaudacion_simple td {
  padding: 5px;
  text-align: center; }

#recaudacion_simple table tr:nth-child(even) {
  background-color: #eee; }

#recaudacion_simple table tr:nth-child(odd) {
  background-color: #fff; }

#recaudacion_simple table tr:last-child {
  background-color: #8a8a8a; }

#recaudacion_simple .specialTd {
  cursor: pointer; }

#recaudacion_simple .specialTd:hover {
  background-color: lightskyblue; }

#recaudacion_simple ._c1, #recaudacion_simple ._c2, #recaudacion_simple ._c5, #recaudacion_simple ._c10, #recaudacion_simple ._c20, #recaudacion_simple ._c50, #recaudacion_simple ._c100, #recaudacion_simple ._c200 {
  background-color: goldenrod;
  border-radius: 1em;
  min-width: 20px;
  min-height: 20px;
  text-align: center;
  line-height: 20px; }

#recaudacion_simple ._b5, #recaudacion_simple ._b10, #recaudacion_simple ._b20, #recaudacion_simple ._b50, #recaudacion_simple ._b100, #recaudacion_simple ._b200, #recaudacion_simple ._b500 {
  background-color: lightgreen;
  min-width: 20px;
  text-align: center; }

#recaudacion_simple .flex-12 {
  margin: 2px 6px; }

#recaudacion_simple .odd {
  background-color: lightgrey; }

#recaudacion_simple .operation {
  min-height: 28px;
  position: relative; }
  #recaudacion_simple .operation > md-icon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 28px;
    height: 28px;
    width: 28px;
    line-height: 28px; }

.md-datepicker-input-mask-opaque {
  background-color: transparent; }

#recaudacion_v md-list .md-list-item-text {
  margin: 7px; }

#recaudacion_v md-list md-list-item {
  min-height: 0px; }

#recaudacion_v md-list md-list-item:hover {
  background-color: lightgray; }

#recaudacion_v md-datepicker {
  margin-right: 0;
  padding-right: 0;
  background-color: transparent; }

#recaudacion_v .md-1-line h3 {
  margin: auto; }

#recaudacion_v .total * {
  font-weight: bolder; }

#recaudacion_v table {
  width: 45%;
  margin-bottom: 1em; }

#recaudacion_v table, #recaudacion_v th, #recaudacion_v td {
  border: 1px solid black;
  border-collapse: collapse; }

#recaudacion_v th, #recaudacion_v td {
  padding: 5px;
  text-align: center; }

#recaudacion_v table tr:nth-child(even) {
  background-color: #eee; }

#recaudacion_v table tr:nth-child(odd) {
  background-color: #fff; }

#recaudacion_v table tr:last-child {
  background-color: #8a8a8a; }

#recaudacion_v .specialTd {
  cursor: pointer; }

#recaudacion_v .specialTd:hover {
  background-color: lightskyblue; }

#recaudacion_v ._c1, #recaudacion_v ._c2, #recaudacion_v ._c5, #recaudacion_v ._c10, #recaudacion_v ._c20, #recaudacion_v ._c50, #recaudacion_v ._c100, #recaudacion_v ._c200 {
  background-color: goldenrod;
  border-radius: 1em;
  min-width: 20px;
  min-height: 20px;
  text-align: center;
  line-height: 20px; }

#recaudacion_v ._b5, #recaudacion_v ._b10, #recaudacion_v ._b20, #recaudacion_v ._b50, #recaudacion_v ._b100, #recaudacion_v ._b200, #recaudacion_v ._b500 {
  background-color: lightgreen;
  min-width: 20px;
  text-align: center; }

#recaudacion_v .flex-12 {
  margin: 2px 6px; }

#recaudacion_v .odd {
  background-color: lightgrey; }

#recaudacion_v .operation {
  min-height: 28px;
  position: relative; }
  #recaudacion_v .operation > md-icon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 28px;
    height: 28px;
    width: 28px;
    line-height: 28px; }

#recaudacion_v .no-select {
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.md-datepicker-input-mask-opaque {
  background-color: transparent; }

#puntos-de-acceso table {
  min-width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
  margin-left: 4px; }
  #puntos-de-acceso table th, #puntos-de-acceso table td {
    min-width: 80px;
    padding: 0 1em; }
  #puntos-de-acceso table td {
    border-top: 1px solid gray; }
  #puntos-de-acceso table .column-title {
    cursor: pointer; }

#puntos-de-acceso .tag {
  margin: 4px 8px;
  padding: 2px 4px; }

#puntos-de-acceso .verde {
  background-color: rgba(108, 179, 0, 0.7); }

#puntos-de-acceso .amarillo {
  background-color: rgba(255, 255, 0, 0.7); }

#puntos-de-acceso .gris {
  background-color: lightgrey; }

#puntos-de-acceso .activo {
  border-left: 8px solid green; }

#puntos-de-acceso .inactivo {
  border-left: 8px solid red; }

#puntos-de-acceso .desconocido {
  border-left: 8px solid gray; }

#puntos-de-acceso md-tab-content {
  padding: 12px; }
  #puntos-de-acceso md-tab-content md-select {
    margin: auto 20px;
    min-width: 150px; }

#puntos-de-acceso md-dialog-content md-checkbox {
  margin-top: 16px; }

#puntos-de-acceso .pagination-button {
  min-width: 36px;
  font-size: 30px;
  padding: 0; }

#puntos-de-acceso .buscador md-input-container {
  margin: 0; }
  #puntos-de-acceso .buscador md-input-container .md-errors-spacer {
    display: none; }

#puntos-de-acceso .buscador .pagination-button {
  min-width: 36px;
  font-size: 30px;
  padding: 0; }

#puntos-de-acceso .buscador > div:first-child {
  border-radius: 3em; }
  #puntos-de-acceso .buscador > div:first-child input {
    border-radius: 10px 3em 3em 10px;
    background-color: #f7f7f7; }
  #puntos-de-acceso .buscador > div:first-child md-icon {
    color: white;
    margin-left: 5px; }

#import-aps table {
  min-width: 100%;
  border-collapse: collapse;
  margin-top: 1em; }
  #import-aps table th, #import-aps table td {
    padding: 0 1em;
    text-align: initial; }
  #import-aps table td {
    border-top: 1px solid gray; }
  #import-aps table .column-title {
    cursor: pointer; }
  #import-aps table .verde {
    background-color: rgba(108, 179, 0, 0.7); }
  #import-aps table .amarillo {
    background-color: rgba(255, 255, 0, 0.7); }

.info-tooltip .md-content {
  white-space: initial;
  height: auto; }

.big-select md-select-menu {
  max-height: 90vh; }
  .big-select md-select-menu md-content {
    max-height: none; }

.autocompletedemoCustomTemplate .autocomplete-custom-template li {
  border-bottom: 1px solid #ccc;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal; }

.autocompletedemoCustomTemplate .autocomplete-custom-template li:last-child {
  border-bottom-width: 0; }

.autocompletedemoCustomTemplate .autocomplete-custom-template .item-title,
.autocompletedemoCustomTemplate .autocomplete-custom-template .item-metadata {
  display: block;
  line-height: 2; }

.autocompletedemoCustomTemplate .autocomplete-custom-template .item-title md-icon {
  height: 18px;
  width: 18px; }

#listadoHabitaciones .Activo, #editarHabitacion .Activo {
  border-left: 7px solid #4CAF50; }

#listadoHabitaciones .Inactivo, #editarHabitacion .Inactivo {
  border-left: 7px solid #F44336; }

#listadoHabitaciones .Boton, #editarHabitacion .Boton {
  border-left: 7px solid orange; }

#listadoHabitaciones .Bloqueado, #editarHabitacion .Bloqueado {
  border-left: 7px solid #3e64ff; }

#listadoHabitaciones .Desconocido, #editarHabitacion .Desconocido {
  border-left: 7px solid black; }

#listadoHabitaciones .fila:hover, #editarHabitacion .fila:hover {
  background-color: #e4e4e4; }

#listadoHabitaciones .filaBloqueada, #editarHabitacion .filaBloqueada {
  background-color: #d4d4d4; }

#listadoHabitaciones .botonCrear, #editarHabitacion .botonCrear {
  position: absolute;
  z-index: 800;
  padding: 10px; }

#listadoHabitaciones input.ng-invalid, #editarHabitacion input.ng-invalid {
  background-color: pink; }

#listadoHabitaciones input.ng-valid, #editarHabitacion input.ng-valid {
  background-color: lightgreen; }

#listadoHabitaciones input.ng-pristine, #listadoHabitaciones input, #editarHabitacion input.ng-pristine, #editarHabitacion input {
  background-color: #f7f7f7; }

#listadoHabitaciones input, #editarHabitacion input {
  transition: background-color 0.5s; }

#listadoHabitaciones md-select, #editarHabitacion md-select {
  transition: background-color 0.5s;
  background-color: #f7f7f7; }

#listadoHabitaciones .container, #editarHabitacion .container {
  min-height: 280px;
  margin: 1em; }
  #listadoHabitaciones .container > div, #editarHabitacion .container > div {
    margin: 1em; }
  #listadoHabitaciones .container h5, #editarHabitacion .container h5 {
    margin: 0; }

#listadoHabitaciones md-checkbox, #editarHabitacion md-checkbox {
  margin-top: 16px; }

#listadoHabitaciones .icono-flecha, #editarHabitacion .icono-flecha {
  width: auto;
  height: auto;
  font-size: 4em;
  line-height: 64px; }

#listadoHabitaciones .buscador md-input-container, #editarHabitacion .buscador md-input-container {
  margin: 0; }
  #listadoHabitaciones .buscador md-input-container .md-errors-spacer, #editarHabitacion .buscador md-input-container .md-errors-spacer {
    display: none; }

#listadoHabitaciones .buscador .pagination-button, #editarHabitacion .buscador .pagination-button {
  min-width: 36px;
  font-size: 30px;
  padding: 0; }
  #listadoHabitaciones .buscador .pagination-button span, #editarHabitacion .buscador .pagination-button span {
    margin: 0; }

#listadoHabitaciones .buscador > div:first-child, #editarHabitacion .buscador > div:first-child {
  /*background: darkblue;*/
  border-radius: 3em; }
  #listadoHabitaciones .buscador > div:first-child input, #editarHabitacion .buscador > div:first-child input {
    border-radius: 10px 3em 3em 10px; }
  #listadoHabitaciones .buscador > div:first-child md-icon, #editarHabitacion .buscador > div:first-child md-icon {
    color: white;
    margin: auto 5px; }

#listadoHabitaciones .buscador span, #editarHabitacion .buscador span {
  margin-left: 3px;
  margin-right: 8px; }

#listadoHabitaciones table, #editarHabitacion table {
  min-width: 100%;
  border-collapse: collapse; }
  #listadoHabitaciones table th, #listadoHabitaciones table td, #editarHabitacion table th, #editarHabitacion table td {
    min-width: 80px;
    padding: 0 1em; }
  #listadoHabitaciones table td, #editarHabitacion table td {
    border-top: 1px solid gray; }
    #listadoHabitaciones table td stb-screenshot, #editarHabitacion table td stb-screenshot {
      width: max-content;
      display: block; }
    #listadoHabitaciones table td *[copy-to-clipboard], #editarHabitacion table td *[copy-to-clipboard] {
      cursor: pointer; }
  #listadoHabitaciones table .column-title, #editarHabitacion table .column-title {
    cursor: pointer; }
    #listadoHabitaciones table .column-title:after, #editarHabitacion table .column-title:after {
      position: relative;
      font-family: 'icomoon';
      font-weight: normal;
      margin-left: 10px;
      top: 2px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.54); }
  #listadoHabitaciones table .ord-no:after, #editarHabitacion table .ord-no:after {
    content: '\eaa8'; }
  #listadoHabitaciones table .ord-asc:after, #editarHabitacion table .ord-asc:after {
    content: '\e718'; }
  #listadoHabitaciones table .ord-desc:after, #editarHabitacion table .ord-desc:after {
    content: '\e715'; }

#listadoHabitaciones .icono-tarjeta, #editarHabitacion .icono-tarjeta {
  font-size: 40px;
  align-self: center;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px; }

#listadoHabitaciones .lang-select *:first-child, #editarHabitacion .lang-select *:first-child {
  transform: none;
  -webkit-transform: none; }

#editarHabitacion md-checkbox {
  margin-left: 0;
  margin-right: 0; }

@media screen and (max-width: 960px) {
  .page-layout.simple.fullwidth > .content {
    padding: 2px; }
  #editarHabitacion md-dialog-content {
    padding: 1px; }
  #editarHabitacion .container {
    min-height: 0;
    margin: 4px; }
    #editarHabitacion .container > div {
      margin: 4px; }
  #editarHabitacion md-tab-item {
    padding: 0; }
    #editarHabitacion md-tab-item span {
      white-space: normal; } }

#swap-mac md-input-container {
  padding-bottom: 0; }
  #swap-mac md-input-container .md-errors-spacer {
    display: none; }

#swap-mac .swap-icon {
  font-size: 36px;
  width: 36px;
  height: 36px;
  line-height: 36px; }

#devices table {
  border-collapse: collapse;
  width: 100%; }

#devices tr:nth-child(even) {
  background-color: #f2f2f2; }

#devices th, #devices td {
  text-align: left;
  padding: 4px; }

#devices a {
  cursor: pointer; }

#updates table {
  max-width: max-content;
  border-collapse: collapse; }
  #updates table th, #updates table td {
    overflow: hidden; }
  #updates table td {
    border-bottom: 1px solid gray; }
    #updates table td md-checkbox {
      margin-bottom: 0;
      margin-left: 12px; }

#updates th:first-of-type, #updates td:first-of-type {
  min-width: 10px; }

#updates .Inactivo {
  background: #F44336; }

#updates .Activo {
  background: #4CAF50; }

#updates .Desconocido {
  background: black; }

#updates .clickable {
  cursor: pointer;
  text-decoration: underline; }
  #updates .clickable:hover {
    background: lightslategrey; }

@media screen and (max-width: 960px) {
  #devices {
    max-height: -webkit-fill-available;
    max-width: -webkit-fill-available; } }

#modelos .md-3-line {
  padding-left: 20px;
  margin: 10px 0;
  border: 1px solid lightgray;
  align-items: center;
  min-height: auto;
  padding: 1em; }
  #modelos .md-3-line .md-avatar {
    border-radius: initial;
    height: auto;
    margin: 8px 16px 8px 0;
    align-self: center; }
  #modelos .md-3-line .md-list-item-text h4 {
    white-space: normal; }

#modelos .md-3-line:hover {
  background-color: lightgray; }

#modelos .features > md-icon {
  font-size: 40px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  opacity: 0.4; }

#modelos .features > md-icon:hover {
  opacity: 1; }

#modelos .hint {
  position: initial;
  margin-top: 10px; }

#modelos_versiones .version {
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  background-color: #2d323e;
  color: white;
  position: relative; }
  #modelos_versiones .version .versionName {
    margin: 0 1em;
    text-overflow: ellipsis;
    overflow: hidden; }
  #modelos_versiones .version .md-icon-button {
    margin: 0;
    padding: 0;
    width: auto;
    height: auto; }
  #modelos_versiones .version .icon-star {
    color: goldenrod; }
  #modelos_versiones .version .md-select-value * {
    color: white; }
  #modelos_versiones .version .deleteButton {
    padding: 2px;
    cursor: pointer;
    transition: border-radius 0.25s; }
  #modelos_versiones .version .deleteButton:hover {
    border-radius: 2em; }

#modelos_versiones md-dialog-actions {
  padding: 0; }

#modelos_versiones md-progress-linear {
  margin: 0 2em; }

#import-modelos .modelo {
  min-width: 280px;
  transition: background 0.25s; }
  #import-modelos .modelo md-checkbox {
    margin-bottom: 0;
    margin-left: 1em; }

#import-modelos .modelo:hover {
  background: lightgray; }

#import-modelos md-list-item {
  transition: background-color 0.25s; }
  #import-modelos md-list-item h4 {
    white-space: normal;
    max-height: 32px;
    margin: 8px 0; }

#import-modelos md-dialog-content p {
  background: darkorange;
  padding: 5px;
  text-align: center; }

#unprovisioned .Activo {
  border-left: 7px solid #4CAF50; }

#unprovisioned .Inactivo {
  border-left: 7px solid #F44336; }

#unprovisioned .Boton {
  border-left: 7px solid orange; }

#unprovisioned .Bloqueado {
  border-left: 7px solid #3e64ff; }

#unprovisioned .Desconocido {
  border-left: 7px solid black; }

#unprovisioned .fila:hover {
  background-color: #e4e4e4; }

#unprovisioned .filaBloqueada {
  background-color: #d4d4d4; }

#unprovisioned .botonCrear {
  position: absolute;
  z-index: 800;
  padding: 10px; }

#unprovisioned input.ng-invalid {
  background-color: pink; }

#unprovisioned input.ng-valid {
  background-color: lightgreen; }

#unprovisioned input.ng-pristine, #unprovisioned input {
  background-color: #f7f7f7; }

#unprovisioned input {
  transition: background-color 0.5s; }

#unprovisioned md-select {
  transition: background-color 0.5s;
  background-color: #f7f7f7; }

#unprovisioned .container {
  min-height: 280px;
  margin: 1em; }
  #unprovisioned .container > div {
    margin: 1em; }
  #unprovisioned .container h5 {
    margin: 0; }

#unprovisioned md-checkbox {
  margin-top: 16px; }

#unprovisioned .icono-flecha {
  width: auto;
  height: auto;
  font-size: 4em;
  line-height: 64px; }

#unprovisioned .buscador md-input-container {
  margin: 0; }
  #unprovisioned .buscador md-input-container .md-errors-spacer {
    display: none; }

#unprovisioned .buscador .pagination-button {
  min-width: 36px;
  font-size: 30px;
  padding: 0; }
  #unprovisioned .buscador .pagination-button span {
    margin: 0; }

#unprovisioned .buscador > div:first-child {
  /*background: darkblue;*/
  border-radius: 3em; }
  #unprovisioned .buscador > div:first-child input {
    border-radius: 10px 3em 3em 10px; }
  #unprovisioned .buscador > div:first-child md-icon {
    color: white;
    margin: auto 5px; }

#unprovisioned .buscador span {
  margin-left: 3px;
  margin-right: 8px; }

#unprovisioned table {
  min-width: 100%;
  border-collapse: collapse; }
  #unprovisioned table th, #unprovisioned table td {
    min-width: 80px;
    padding: 0 1em; }
  #unprovisioned table td {
    border-top: 1px solid gray; }
  #unprovisioned table .column-title {
    cursor: pointer; }
    #unprovisioned table .column-title:after {
      position: relative;
      font-family: 'icomoon';
      font-weight: normal;
      margin-left: 10px;
      top: 2px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.54); }
  #unprovisioned table .ord-no:after {
    content: '\eaa8'; }
  #unprovisioned table .ord-asc:after {
    content: '\e718'; }
  #unprovisioned table .ord-desc:after {
    content: '\e715'; }

#unprovisioned .icono-tarjeta {
  font-size: 40px;
  align-self: center;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px; }

#unprovisioned .lang-select *:first-child {
  transform: none;
  -webkit-transform: none; }

#editarHabitacion md-checkbox {
  margin-left: 0;
  margin-right: 0; }

@media screen and (max-width: 960px) {
  .page-layout.simple.fullwidth > .content {
    padding: 2px; }
  #editarHabitacion md-dialog-content {
    padding: 1px; }
  #editarHabitacion .container {
    min-height: 0;
    margin: 4px; }
    #editarHabitacion .container > div {
      margin: 4px; }
  #editarHabitacion md-tab-item {
    padding: 0; }
    #editarHabitacion md-tab-item span {
      white-space: normal; } }

#ver_masHabitacion input.ng-invalid {
  background-color: pink; }

#ver_masHabitacion input.ng-valid {
  background-color: lightgreen; }

#ver_masHabitacion input.ng-pristine {
  background-color: lightgray; }

#ver_masHabitacion .text-gray {
  margin: 5px 0; }
  #ver_masHabitacion .text-gray span:first-child {
    font-size: 80%;
    color: lightslategray; }

#ver_masHabitacion md-list .md-list-item-text {
  margin: 7px; }

#ver_masHabitacion md-list md-list-item {
  min-height: 0px; }

#ver_masHabitacion md-list md-list-item:hover {
  background-color: lightgray; }

#ver_masHabitacion md-list .Balance {
  background-color: orange; }

#ver_masHabitacion md-list .Cash {
  background-color: lime; }

#ver_masHabitacion md-list .Cortesy {
  background-color: greenyellow; }

#ver_masHabitacion md-list .Card {
  background-color: #4ea7e6; }

#ver_masHabitacion md-list .None {
  background-color: gray; }

#ver_masHabitacion md-list .PendingGive {
  background-color: orchid; }

#ver_masHabitacion md-list .PendingReceive {
  background-color: yellow; }

#ver_masHabitacion md-list .idAccountant {
  min-height: 88px;
  padding-right: 5px;
  line-height: 88px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; }

#ver_masHabitacion md-list .ingreso {
  color: green; }

#ver_masHabitacion md-list .gasto {
  color: red; }

#ver_masHabitacion md-list .no {
  display: none; }

#ver_masHabitacion .strong {
  font-weight: bold; }

#ver_masHabitacion .underline {
  text-decoration: underline; }

.bloqueFila {
  border: 2px solid #b4b4b4;
  padding-left: 20px;
  padding-right: 20px;
  /*margin: 10px 20px;*/
  margin-bottom: 15px;
  border-radius: 15px; }

#voip md-tab-content {
  border: 1px solid lightgray;
  border-top: none; }

#voip .buscador md-input-container {
  margin: 0; }
  #voip .buscador md-input-container .md-errors-spacer {
    display: none; }

#voip .buscador .pagination-button {
  min-width: 36px;
  font-size: 30px;
  padding: 0; }

#voip .buscador > div:first-child {
  border-radius: 3em; }
  #voip .buscador > div:first-child input {
    border-radius: 10px 3em 3em 10px;
    background-color: #f7f7f7; }
  #voip .buscador > div:first-child md-icon {
    color: white;
    margin-left: 5px; }

#voip .buscador span {
  margin-left: 3px;
  margin-right: 8px; }

#voip table {
  min-width: 100%;
  border-collapse: collapse; }
  #voip table th, #voip table td {
    min-width: 80px;
    padding: 0 1em; }
  #voip table td {
    border-top: 1px solid gray; }
  #voip table .column-title {
    cursor: pointer; }
    #voip table .column-title:after {
      position: relative;
      font-family: 'icomoon';
      font-weight: normal;
      margin-left: 10px;
      top: 2px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.54); }
  #voip table .ord-no:after {
    content: '\eaa8'; }
  #voip table .ord-asc:after {
    content: '\e718'; }
  #voip table .ord-desc:after {
    content: '\e715'; }

#voip md-select {
  transition: background-color 0.5s;
  background-color: #f7f7f7; }

#voip .fila:hover {
  background-color: #e4e4e4; }

#tarifas .tarifa {
  border: 1px solid;
  border-radius: 8px;
  padding: 8px;
  margin: 8px;
  text-align: center; }
  #tarifas .tarifa h1 {
    color: red;
    font-weight: bold; }

#tarifas md-switch {
  margin: 0 4px 0 12px; }

#tarifas md-sidenav {
  min-width: 50%; }

#tarifas md-tabs {
  border: 1px solid lightgray; }

#knocks table {
  border-collapse: collapse;
  width: 100%; }

#knocks tr:nth-child(even) {
  background-color: #f2f2f2; }

#knocks th, #knocks td {
  text-align: left;
  padding: 4px; }

@media screen and (max-width: 960px) {
  #knocks {
    max-height: -webkit-fill-available;
    max-width: -webkit-fill-available; } }

#wallets .check-In {
  color: green; }
  #wallets .check-In md-icon {
    color: green;
    margin: 2px; }

#wallets .check-Out {
  color: red; }
  #wallets .check-Out md-icon {
    color: red;
    margin: 2px; }

#wallets .icon-sync {
  font-size: 18px;
  width: 18px;
  height: 18px;
  line-height: 18px; }
  #wallets .icon-sync.animate {
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite; }

#wallets .buscador md-input-container {
  margin: 0; }
  #wallets .buscador md-input-container .md-errors-spacer {
    display: none; }

#wallets .buscador .pagination-button {
  min-width: 36px;
  font-size: 30px;
  padding: 0; }

#wallets .buscador > div:first-child {
  border-radius: 3em; }
  #wallets .buscador > div:first-child input {
    border-radius: 10px 3em 3em 10px;
    background-color: #f7f7f7; }
  #wallets .buscador > div:first-child md-icon {
    color: white;
    margin-left: 5px; }

#wallets .buscador span {
  margin-left: 3px;
  margin-right: 8px; }

#wallets table {
  min-width: 100%;
  border-collapse: collapse; }
  #wallets table th, #wallets table td {
    min-width: 80px;
    padding: 0 1em; }
  #wallets table td {
    border-top: 1px solid gray; }
  #wallets table .column-title {
    cursor: pointer; }
    #wallets table .column-title:after {
      position: relative;
      font-family: 'icomoon';
      font-weight: normal;
      margin-left: 10px;
      top: 2px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.54); }
  #wallets table .ord-no:after {
    content: '\eaa8'; }
  #wallets table .ord-asc:after {
    content: '\e718'; }
  #wallets table .ord-desc:after {
    content: '\e715'; }

#wallets md-select {
  transition: background-color 0.5s;
  background-color: #f7f7f7; }

#vincularStb md-radio-button {
  margin-top: 12px;
  margin-bottom: 12px; }

#lista-check-in-out table {
  min-width: 100%;
  border-collapse: collapse; }
  #lista-check-in-out table th, #lista-check-in-out table td {
    border: 1px solid gray; }

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

#listaIptv md-badge {
  margin: 0 0 0 8px;
  /*background-color: #259b24;*/
  color: white;
  padding: 3px 6px;
  border-radius: 4px; }

#listaIptv .canalVisible:hover {
  background-color: #e4e4e4; }

#listaIptv .canalNoVisible {
  background-color: #fd6464; }

#listaIptv .canalNoVisible:hover {
  background-color: #8d2c2c; }

#listaIptv .normal, #listaIptv .high, #listaIptv .critical, #listaIptv .adv, #listaIptv .alarmas, #listaIptv .noAlarmas {
  border-radius: 15px;
  padding: 5px;
  margin: 5px; }
  #listaIptv .normal md-icon, #listaIptv .high md-icon, #listaIptv .critical md-icon, #listaIptv .adv md-icon, #listaIptv .alarmas md-icon, #listaIptv .noAlarmas md-icon {
    margin-right: 15px; }

#listaIptv .adv {
  background-color: #fefdbe;
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), #fefdbe);
  background: -o-linear-gradient(right, rgba(0, 0, 0, 0), #fefdbe);
  background: -moz-linear-gradient(right, rgba(0, 0, 0, 0), #fefdbe);
  background: linear-gradient(to right, rgba(0, 0, 0, 0), #fefdbe); }

#listaIptv .normal {
  background-color: #87ff8b;
  background: -webkit-linear-gradient(left, #87ff8b, rgba(0, 0, 0, 0));
  background: -o-linear-gradient(right, #87ff8b, rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(right, #87ff8b, rgba(0, 0, 0, 0));
  background: linear-gradient(to right, #87ff8b, rgba(0, 0, 0, 0)); }

#listaIptv .high {
  background-color: #fdef4c;
  background: -webkit-linear-gradient(left, #fdef4c, rgba(0, 0, 0, 0));
  background: -o-linear-gradient(right, #fdef4c, rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(right, #fdef4c, rgba(0, 0, 0, 0));
  background: linear-gradient(to right, #fdef4c, rgba(0, 0, 0, 0)); }

#listaIptv .critical {
  background-color: #fd6464;
  background: -webkit-linear-gradient(left, #fd6464, rgba(0, 0, 0, 0));
  background: -o-linear-gradient(right, #fd6464, rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(right, #fd6464, rgba(0, 0, 0, 0));
  background: linear-gradient(to right, #fd6464, rgba(0, 0, 0, 0)); }

#listaIptv .alarmas {
  background-color: #ffc621;
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), #ffc621);
  background: -o-linear-gradient(right, rgba(0, 0, 0, 0), #ffc621);
  background: -moz-linear-gradient(right, rgba(0, 0, 0, 0), #ffc621);
  background: linear-gradient(to right, rgba(0, 0, 0, 0), #ffc621);
  max-height: 32px;
  overflow-y: hidden;
  transition: max-height 0.5s; }

#listaIptv .alarmas:hover {
  max-height: 180px; }

#listaIptv .noAlarmas {
  background-color: #87ff8b;
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), #87ff8b);
  background: -o-linear-gradient(right, rgba(0, 0, 0, 0), #87ff8b);
  background: -moz-linear-gradient(right, rgba(0, 0, 0, 0), #87ff8b);
  background: linear-gradient(to right, rgba(0, 0, 0, 0), #87ff8b); }

#listaIptv table {
  min-width: 100%;
  border-collapse: collapse; }
  #listaIptv table th, #listaIptv table td {
    min-width: 80px;
    padding: 0 1em; }
  #listaIptv table td {
    border-top: 1px solid gray; }

#editarCanal .logo-container {
  margin-right: 12px;
  width: 150px;
  height: 150px;
  background-color: lightgray;
  cursor: pointer;
  padding: 10px;
  border-radius: 1em; }
  #editarCanal .logo-container img {
    max-height: 100%;
    max-width: 100%; }

#editarCanal md-switch {
  margin: 0; }
  #editarCanal md-switch .md-container {
    margin: 0 8px; }

#elegirImagenCanal .element {
  cursor: pointer;
  margin: 4px 8px;
  padding: 4px;
  width: 80px;
  /*transition:border 0.5s;*/ }
  #elegirImagenCanal .element .img-container {
    height: 60px;
    width: 60px; }
    #elegirImagenCanal .element .img-container img {
      max-height: 100%;
      max-width: 100%; }
  #elegirImagenCanal .element span {
    word-wrap: break-word; }
  #elegirImagenCanal .element:hover {
    background-color: lightgray; }

#importexport .canal-list-item {
  min-width: 280px;
  transition: background 0.25s;
  background: lightgray;
  border-radius: 1em; }

#importexport .disabled {
  background: gray;
  text-decoration: line-through; }

#importexport .md-2-line, #importexport .md-3-line {
  margin: 1px;
  min-height: 0; }

#importexport .iconoCanal {
  min-width: 50px;
  min-height: 50px;
  margin: 4px 8px;
  z-index: 0; }
  #importexport .iconoCanal img {
    max-height: 50px;
    max-width: 50px; }

#importexport md-list-item .iconSelected {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100px;
  font-size: 50px;
  height: 50px;
  line-height: 50px;
  color: royalblue; }

#importexport md-list-item button {
  min-height: 0;
  padding-left: 0;
  background: transparent; }

#importexport .pantallaCarga {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(150, 150, 150, 0.9);
  text-align: center; }
  #importexport .pantallaCarga .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  #importexport .pantallaCarga h1, #importexport .pantallaCarga h3 {
    color: #000; }

#importexport .normal, #importexport .high, #importexport .critical, #importexport .adv, #importexport .alarmas, #importexport .noAlarmas {
  border-radius: 15px;
  padding: 5px;
  margin: 5px; }
  #importexport .normal md-icon, #importexport .high md-icon, #importexport .critical md-icon, #importexport .adv md-icon, #importexport .alarmas md-icon, #importexport .noAlarmas md-icon {
    margin-right: 15px; }

#importexport .adv {
  background-color: #fefdbe;
  background: -webkit-linear-gradient(left, #fefdbe, rgba(0, 0, 0, 0));
  background: -o-linear-gradient(right, #fefdbe, rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(right, #fefdbe, rgba(0, 0, 0, 0));
  background: linear-gradient(to right, #fefdbe, rgba(0, 0, 0, 0)); }

#importexport .normal {
  background-color: #87ff8b;
  background: -webkit-linear-gradient(left, #87ff8b, rgba(0, 0, 0, 0));
  background: -o-linear-gradient(right, #87ff8b, rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(right, #87ff8b, rgba(0, 0, 0, 0));
  background: linear-gradient(to right, #87ff8b, rgba(0, 0, 0, 0)); }

#importexport .high {
  background-color: #fdef4c;
  background: -webkit-linear-gradient(left, #fdef4c, rgba(0, 0, 0, 0));
  background: -o-linear-gradient(right, #fdef4c, rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(right, #fdef4c, rgba(0, 0, 0, 0));
  background: linear-gradient(to right, #fdef4c, rgba(0, 0, 0, 0)); }

#importexport .critical {
  background-color: #fd6464;
  background: -webkit-linear-gradient(left, #fd6464, rgba(0, 0, 0, 0));
  background: -o-linear-gradient(right, #fd6464, rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(right, #fd6464, rgba(0, 0, 0, 0));
  background: linear-gradient(to right, #fd6464, rgba(0, 0, 0, 0)); }

#importexport .alarmas {
  background-color: #ffc621;
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), #ffc621);
  background: -o-linear-gradient(right, rgba(0, 0, 0, 0), #ffc621);
  background: -moz-linear-gradient(right, rgba(0, 0, 0, 0), #ffc621);
  background: linear-gradient(to right, rgba(0, 0, 0, 0), #ffc621);
  max-height: 32px;
  overflow-y: hidden;
  transition: max-height 0.5s; }

#importexport .alarmas:hover {
  max-height: 180px; }

#importexport .noAlarmas {
  background-color: #87ff8b;
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), #87ff8b);
  background: -o-linear-gradient(right, rgba(0, 0, 0, 0), #87ff8b);
  background: -moz-linear-gradient(right, rgba(0, 0, 0, 0), #87ff8b);
  background: linear-gradient(to right, rgba(0, 0, 0, 0), #87ff8b); }

#cVersiones .uploadContainer {
  border: 2px solid lightgrey;
  border-radius: 10px; }

#cVersiones .fileData {
  border: 2px solid lightgrey;
  border-radius: 10px;
  min-width: 320px; }
  #cVersiones .fileData img {
    max-height: 90px;
    max-width: 90px; }

#cVersiones input.ng-invalid {
  background-color: pink; }

#cVersiones input.ng-valid {
  background-color: lightgreen; }

#cVersiones input.ng-pristine, #cVersiones textarea {
  background-color: #f7f7f7; }

#cVersiones input {
  transition: background-color 0.5s; }

#cVersiones h4 {
  text-align: right;
  background-color: #fefdbe;
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), #fefdbe);
  background: -o-linear-gradient(right, rgba(0, 0, 0, 0), #fefdbe);
  background: -moz-linear-gradient(right, rgba(0, 0, 0, 0), #fefdbe);
  background: linear-gradient(to right, rgba(0, 0, 0, 0), #fefdbe); }

#cVersiones .pantallaCarga {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(150, 150, 150, 0.7); }
  #cVersiones .pantallaCarga .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  #cVersiones .pantallaCarga h1, #cVersiones .pantallaCarga h3 {
    color: #000; }

#cVersiones .error {
  /* Position the hint */
  left: 2px;
  right: auto;
  bottom: 7px;
  /* Copy styles from ng-messages */
  font-size: 12px;
  line-height: 14px;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  /* Set our own color */
  color: red; }

#cVersiones .hint {
  position: initial;
  /* Position the hint */
  left: 2px;
  right: auto;
  bottom: 7px;
  /* Copy styles from ng-messages */
  font-size: 12px;
  line-height: 14px;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  /* Set our own color */
  color: gray; }

#cVersiones .vrsn {
  padding-left: 8px;
  min-width: 200px;
  border-radius: 6px;
  margin: 1px; }

@media screen and (max-width: 600px) {
  #cVersiones .fileData {
    min-width: 0; }
  #cVersiones md-input-container {
    margin: 0; }
  #cVersiones .md-inline-form md-radio-group md-radio-button {
    height: auto; } }

#controls .control {
  margin-bottom: 1em;
  border-radius: 8px; }
  #controls .control h2 {
    margin-left: 12px; }

#controls .margin-0 {
  margin: 0 !important; }

#dhcpEquipos .content .form-data-wrapper {
  margin-left: 16px; }
  #dhcpEquipos .content .form-data-wrapper .json-data-wrapper {
    overflow: hidden; }
    #dhcpEquipos .content .form-data-wrapper .json-data-wrapper pre {
      font-size: 16px; }

#dhcpEquipos md-input-container {
  border: 1px solid lightgray;
  border-radius: 5px; }
  #dhcpEquipos md-input-container textarea {
    min-height: calc(100vh - 258px);
    overflow-y: auto;
    line-height: normal; }

#encuestas .md-2-line {
  border: 1px solid lightgray;
  padding-left: 10px;
  transition: background-color 0.25s; }
  #encuestas .md-2-line h3 span, #encuestas .md-2-line h4 span {
    color: lightslategray;
    transition: color 0.25s; }
  #encuestas .md-2-line .md-avatar {
    border-radius: 0; }

#encuestas .md-2-line:hover {
  background-color: rgba(146, 25, 142, 0.35); }
  #encuestas .md-2-line:hover h3 span, #encuestas .md-2-line:hover h4 span {
    color: initial; }

#encuestas .lang-select *:first-child {
  transform: none;
  -webkit-transform: none; }

#editarEncuesta .image-container {
  padding: 8px;
  cursor: pointer;
  margin-right: 12px; }
  #editarEncuesta .image-container:hover {
    background: lightgray; }

#editarEncuesta img {
  max-height: 100px;
  max-width: 100px;
  align-self: center; }

#editarEncuesta .lang-select *:first-child {
  transform: none;
  -webkit-transform: none; }

#preguntasEncuesta md-dialog-content {
  padding: 0; }
  #preguntasEncuesta md-dialog-content .question-list .element {
    padding: 8px;
    font-size: 14px;
    cursor: pointer; }
  #preguntasEncuesta md-dialog-content .respuestas .bold {
    font-weight: bold; }
  #preguntasEncuesta md-dialog-content .respuestas h4:not(.bold) {
    margin: 0; }
  #preguntasEncuesta md-dialog-content img {
    max-height: 100px;
    max-width: 100px; }
  #preguntasEncuesta md-dialog-content .question-list, #preguntasEncuesta md-dialog-content form {
    overflow: auto; }
  #preguntasEncuesta md-dialog-content form {
    padding: 8px; }

#preguntasEncuesta .hint {
  position: initial; }

#respuestasEncuesta li {
  color: darkslategray; }
  #respuestasEncuesta li span {
    color: initial;
    font-size: 15px; }

#respuestasEncuesta li:nth-child(odd) {
  background-color: palegoldenrod; }

#respuestasEncuesta .bar-container {
  width: 100%;
  min-height: 30px; }
  #respuestasEncuesta .bar-container .bar {
    height: inherit; }

@media screen and (max-width: 960px) {
  #editarEncuesta md-dialog-content {
    padding: 8px 16px; }
  #preguntasEncuesta md-dialog-content .question-list .element {
    padding: 4px 8px;
    font-size: 12px; }
  #preguntasEncuesta md-content {
    max-height: 100vh !important;
    padding-right: 0 !important; }
  #preguntasEncuesta .abs-button {
    top: -2px !important;
    right: 0 !important; } }

#gCajeros .md-3-line {
  padding-left: 20px;
  margin: 10px 0;
  border: 1px solid lightgray;
  align-items: center;
  min-height: auto;
  padding: 1em; }

#gCajeros .md-3-line:hover {
  background-color: lightgray; }

#gCajeros .features > md-icon {
  font-size: 40px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  opacity: 0.4; }

#gCajeros .features > md-icon:hover {
  opacity: 1; }

#gCajeros .phone {
  color: lightslategrey; }

#arqueos .odd {
  background-color: lightgrey; }

#arqueos .arqueo div:first-child {
  padding: 0 0.5em; }

#mandos .odd {
  background-color: lightgrey; }

#mandos .mando span {
  min-width: 100px;
  margin-right: 20px; }

#mandos .mando span:nth-child(3) {
  min-width: 200px; }

#cupones .odd {
  background-color: lightgrey; }

#cupones .mando span {
  min-width: 100px;
  margin-right: 20px; }

@media screen and (max-width: 960px) {
  #gCajeros md-dialog-content {
    padding: 8px; }
  #gCajeros md-list-item .features {
    margin: 10px 0 5px 0; } }

@media screen and (min-width: 960px) {
  #gCajeros .container md-input-container {
    margin: 2px 0; } }

#gruposStb .md-2-line {
  padding-left: 20px;
  margin: 10px 0;
  border: 1px solid lightgray; }
  #gruposStb .md-2-line .md-list-item-text span {
    color: lightslategray; }

#gruposStb .md-2-line:hover {
  background-color: rgba(255, 0, 0, 0.35);
  background: -webkit-linear-gradient(left, rgba(255, 0, 0, 0.35), rgba(0, 0, 0, 0));
  background: -o-linear-gradient(right, rgba(255, 0, 0, 0.35), rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(right, rgba(255, 0, 0, 0.35), rgba(0, 0, 0, 0));
  background: linear-gradient(to right, rgba(255, 0, 0, 0.35), rgba(0, 0, 0, 0)); }
  #gruposStb .md-2-line:hover .md-list-item-text span {
    color: initial; }

#gruposStb .stb-container {
  margin: 4px 8px;
  padding: 2px;
  border-radius: 2em;
  background: lightgray;
  cursor: pointer; }
  #gruposStb .stb-container .icon-checkbox-blank-circle {
    color: darkgray; }
  #gruposStb .stb-container span {
    margin: 0 8px 0 2px; }

#gruposStb .checked {
  background: green; }
  #gruposStb .checked .icon-checkbox-marked-circle {
    color: limegreen; }

#gruposStb .range-ini {
  position: relative;
  background: yellow; }
  #gruposStb .range-ini:before {
    content: ">";
    position: absolute;
    left: -8px; }

@media screen and (max-width: 960px) {
  #gruposStb md-dialog-content {
    padding: 8px 2px 0 2px; } }

#idiomas md-list-item {
  margin: 10px 0;
  border: 1px solid lightgray; }
  #idiomas md-list-item img {
    margin: 0 10px;
    max-height: 32px;
    max-width: 48px; }

#idiomas md-list-item:hover {
  background-color: rgba(255, 0, 0, 0.35);
  background: -webkit-linear-gradient(left, rgba(255, 0, 0, 0.35), rgba(0, 0, 0, 0));
  background: -o-linear-gradient(right, rgba(255, 0, 0, 0.35), rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(right, rgba(255, 0, 0, 0.35), rgba(0, 0, 0, 0));
  background: linear-gradient(to right, rgba(255, 0, 0, 0.35), rgba(0, 0, 0, 0)); }

#instalaciones .md-3-line {
  margin: 10px 0;
  border: 1px solid lightgray;
  padding-left: 10px;
  transition: background-color 0.25s; }
  #instalaciones .md-3-line h3, #instalaciones .md-3-line h4 {
    white-space: normal; }
    #instalaciones .md-3-line h3 span, #instalaciones .md-3-line h4 span {
      color: lightslategray;
      transition: color 0.25s; }
  #instalaciones .md-3-line .md-avatar {
    border-radius: initial;
    height: auto;
    margin: 8px 16px 8px 0;
    align-self: center; }

#instalaciones .md-3-line:hover {
  background-color: rgba(150, 191, 25, 0.35);
  /*        background: -webkit-linear-gradient(left, rgba(150, 191, 25, 0.35), transparent);
                background: -o-linear-gradient(right, rgba(150, 191, 25, 0.35), transparent);
                background: -moz-linear-gradient(right, rgba(150, 191, 25, 0.35), transparent);
                background: linear-gradient(to right, rgba(150, 191, 25, 0.35), transparent);*/ }
  #instalaciones .md-3-line:hover h3 span, #instalaciones .md-3-line:hover h4 span {
    color: initial; }

#instalaciones .md-icon-button {
  align-self: center; }

#instalaciones .icon-star {
  color: darkgoldenrod; }

#instalaciones .lang-select *:first-child {
  transform: none;
  -webkit-transform: none; }

#instalaciones textarea {
  overflow: visible; }

#verServiciosInstalacion md-list {
  border: 1px solid lightgray;
  margin: 0 5px;
  position: relative; }
  #verServiciosInstalacion md-list .md-menu {
    position: absolute;
    top: 0;
    right: 0; }
  #verServiciosInstalacion md-list md-list-item {
    padding-left: 1em;
    min-height: 0;
    background-color: lightgrey;
    border-radius: 4em;
    border: 2px solid transparent;
    margin: 3px; }
    #verServiciosInstalacion md-list md-list-item .md-button {
      align-self: center;
      padding: 0;
      width: initial;
      height: initial; }
  #verServiciosInstalacion md-list md-list-item:hover {
    border: 2px solid black; }

#ordenHome .orden .elem {
  padding: 8px;
  margin: 1em;
  border: 1px solid lightgray;
  position: relative;
  background-color: white;
  cursor: move; }
  #ordenHome .orden .elem .delete-button {
    position: absolute;
    top: -20px;
    right: -20px; }
  #ordenHome .orden .elem .left-button {
    position: absolute;
    bottom: -20px;
    left: -20px; }
  #ordenHome .orden .elem .right-button {
    position: absolute;
    bottom: -20px;
    right: -20px; }
  #ordenHome .orden .elem .change-state-button {
    position: absolute;
    top: -20px;
    left: -20px; }
  #ordenHome .orden .elem h4 {
    margin: 0; }

#ordenHome .lsta-highlight {
  background-color: yellow;
  min-height: 70px;
  min-width: 70px;
  border-radius: 1em;
  margin: 13px; }

#ordenHome .orden-sidebar {
  border: 1px solid gray;
  padding: 3px 20px 3px 3px;
  margin-right: 1em; }
  #ordenHome .orden-sidebar img {
    width: 50px; }
  #ordenHome .orden-sidebar .elem {
    border-radius: 1em; }
  #ordenHome .orden-sidebar h3 {
    margin-top: 0; }

#ordenHome .orden-home {
  border: 1px solid gray;
  padding: 3px; }
  #ordenHome .orden-home img {
    width: 50px; }
  #ordenHome .orden-home .elem {
    border-radius: 1em; }
  #ordenHome .orden-home h3 {
    margin-top: 0; }

#ordenHome .orden-extra {
  border: 1px solid gray;
  padding: 3px;
  margin-top: 1em; }
  #ordenHome .orden-extra img {
    width: 50px; }
  #ordenHome .orden-extra .elem {
    border-radius: 1em; }
  #ordenHome .orden-extra h3 {
    margin-top: 0; }

#ordenHome .elem-base {
  background: lightgreen !important; }

#ordenHome .elem-purchasable {
  background: lightcoral !important; }

#ordenHome md-badge {
  margin: 8px;
  padding: 3px 6px;
  border-radius: 4px;
  border: 1px solid; }
  #ordenHome md-badge:first-child {
    background-color: white; }
  #ordenHome md-badge:nth-child(2) {
    background-color: lightgreen; }
  #ordenHome md-badge:nth-child(3) {
    background-color: lightcoral; }

#ordenHome .botones-estado .md-button:first-child {
  background-color: white; }

#ordenHome .botones-estado .md-button:nth-child(2) {
  background-color: lightgreen; }

#ordenHome .botones-estado .md-button:nth-child(3) {
  background-color: lightcoral; }

#ordenHome .leds div {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid darkgray;
  margin: 2px 4px;
  cursor: pointer; }
  #ordenHome .leds div:first-child {
    background-color: white; }
  #ordenHome .leds div:nth-child(2) {
    background-color: lightgreen; }
  #ordenHome .leds div:nth-child(3) {
    background-color: lightcoral; }

#ordenPaquetes .orden .elem {
  margin: 6px;
  border: 1px solid lightgray;
  background-color: white;
  cursor: move; }
  #ordenPaquetes .orden .elem img {
    max-width: 50px;
    max-height: 50px; }
  #ordenPaquetes .orden .elem .delete-button {
    margin-left: 4px; }
    #ordenPaquetes .orden .elem .delete-button .md-button {
      margin: 0; }
  #ordenPaquetes .orden .elem .left-right-buttons {
    margin-right: 8px; }
    #ordenPaquetes .orden .elem .left-right-buttons .md-button {
      margin: 0;
      border-radius: 0; }
      #ordenPaquetes .orden .elem .left-right-buttons .md-button[disabled] {
        background-color: #696969; }
        #ordenPaquetes .orden .elem .left-right-buttons .md-button[disabled] md-icon {
          color: #ffffff42 !important; }
  #ordenPaquetes .orden .elem h3 {
    margin: 0 4px; }

#ordenPaquetes .lsta-highlight {
  background-color: gray;
  min-height: 40px;
  min-width: 80px;
  border-radius: 1em;
  margin: 6px; }

#import-instalaciones .instalacion {
  min-width: 280px;
  transition: background 0.25s; }
  #import-instalaciones .instalacion md-checkbox {
    margin-bottom: 0;
    margin-left: 1em; }

#import-instalaciones .instalacion:hover {
  background: lightgray; }

#import-instalaciones md-list-item {
  transition: background-color 0.25s; }
  #import-instalaciones md-list-item h4 {
    white-space: normal;
    max-height: 32px;
    margin: 8px 0; }

@media screen and (max-width: 960px) {
  #import-instalaciones .instalacion {
    min-width: 0; }
  #import-instalaciones md-dialog-content {
    padding: 8px 16px; } }

#properties table {
  width: 100%; }
  #properties table input {
    width: 100%; }
  #properties table td:last-of-type {
    width: 1px;
    white-space: nowrap; }
  #properties table .md-button {
    margin: 0; }

#tarjetas h2 {
  text-align: center;
  margin-top: 8px;
  margin-bottom: 0; }

#tarjetas .buscador md-input-container {
  margin: 0; }
  #tarjetas .buscador md-input-container .md-errors-spacer {
    display: none; }

#tarjetas .buscador .pagination-button {
  min-width: 36px;
  font-size: 30px;
  padding: 0; }

#tarjetas .buscador > div:first-child {
  border-radius: 3em; }
  #tarjetas .buscador > div:first-child input {
    border-radius: 10px 3em 3em 10px;
    background-color: #f7f7f7; }
  #tarjetas .buscador > div:first-child md-icon {
    color: white;
    margin-left: 5px; }

#tarjetas .buscador span {
  margin-left: 3px;
  margin-right: 8px; }

#tarjetas table {
  min-width: 100%;
  border-collapse: collapse; }
  #tarjetas table th, #tarjetas table td {
    min-width: 80px;
    padding: 4px 1em;
    vertical-align: top; }
  #tarjetas table td {
    border-top: 1px solid gray; }
    #tarjetas table td a {
      cursor: pointer; }
  #tarjetas table .column-title {
    cursor: pointer; }
    #tarjetas table .column-title:after {
      position: relative;
      font-family: 'icomoon';
      font-weight: normal;
      margin-left: 10px;
      top: 2px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.54); }
  #tarjetas table .ord-no:after {
    content: '\eaa8'; }
  #tarjetas table .ord-asc:after {
    content: '\e718'; }
  #tarjetas table .ord-desc:after {
    content: '\e715'; }

#tarjetas md-select {
  transition: background-color 0.5s;
  background-color: #f7f7f7; }

#tarjetas textarea {
  width: 100%;
  resize: vertical;
  background-color: #f7f7f7;
  min-height: 200px; }

#temas .fileData {
  border: 2px solid lightgrey;
  border-radius: 10px; }
  #temas .fileData img {
    max-height: 90px;
    max-width: 90px; }

#temas .tema {
  margin: 1em;
  border-radius: 1em 3em 3em 1em;
  transition: box-shadow 0.25s; }
  #temas .tema h2 {
    margin-right: 3px;
    margin-left: 8px;
    min-width: 120px; }

#temas .tema:hover {
  box-shadow: 5px 5px 5px #aaaaaa; }

#traduccion md-tab-content {
  background: #ffffff61;
  padding-top: 12px;
  overflow: auto; }

#traduccion table {
  border-collapse: collapse; }
  #traduccion table td, #traduccion table th {
    border-bottom: 1px solid gray; }
    #traduccion table td md-icon, #traduccion table th md-icon {
      cursor: pointer; }

#traduccion img {
  max-width: 50px;
  max-height: 50px; }

#traduccion textarea {
  min-height: 100px;
  resize: vertical;
  margin-top: 2px;
  margin-bottom: 4px; }

#traduccion input, #traduccion textarea {
  width: 160px; }

#traduccion .icon-content-copy {
  color: #b90000; }

#traduccion .icon-content-save {
  color: darkblue; }

#traduccion .icon-format-text {
  color: darkgreen; }

#tasks input {
  background-color: #f7f7f7; }

#tasks md-badge {
  margin: 0 0 0 8px;
  /*background-color: #4288bc;*/
  color: white;
  padding: 3px 6px;
  border-radius: 4px; }

#tasks .type-selector > div {
  padding: 8px;
  cursor: pointer; }
  #tasks .type-selector > div:hover:not(.md-warn-bg) {
    background: lightgray; }

#tasks md-list-item:hover {
  background: lightgray; }

#tasks md-list-item .md-avatar {
  border-radius: 0; }

#tasks md-list-item .md-list-item-text * {
  white-space: normal; }

#task-template .arg-container {
  padding-top: 14px; }
  #task-template .arg-container > * {
    margin: 4px; }
    #task-template .arg-container > *:not(:first-child) {
      margin-left: 8px; }
    #task-template .arg-container > *:not(:last-child) {
      margin-right: 8px; }
  #task-template .arg-container:last-of-type {
    margin-bottom: 8px; }

#task-template .groupContainer {
  border: 1px solid black;
  margin: 10px;
  min-width: 270px; }
  #task-template .groupContainer .groupHeader {
    padding: 5px 0 5px 20px;
    background-color: black;
    color: white; }
  #task-template .groupContainer .groupBody {
    padding: 10px;
    max-height: 15em;
    position: relative;
    overflow: auto; }
    #task-template .groupContainer .groupBody md-checkbox, #task-template .groupContainer .groupBody md-checkbox:last-of-type {
      margin: 3px; }
  #task-template .groupContainer .md-icon-button {
    margin: 0;
    padding: 0;
    height: auto; }
    #task-template .groupContainer .md-icon-button .icon {
      color: whitesmoke; }

#task-template .info-temp {
  padding: 4px;
  border-radius: 8px;
  font-size: 14px; }

#procesos input {
  background-color: #f7f7f7; }

#procesos md-badge {
  margin: 0 0 0 8px;
  /*background-color: #4288bc;*/
  color: white;
  padding: 3px 6px;
  border-radius: 4px; }

#procesos .type-selector > div {
  padding: 8px;
  cursor: pointer; }
  #procesos .type-selector > div:hover:not(.md-warn-bg) {
    background: lightgray; }

#procesos md-list-item:hover {
  background: lightgray; }

#procesos md-list-item .md-avatar {
  border-radius: 0; }

#procesos md-list-item .md-list-item-text * {
  white-space: normal; }

#procesos-template .arg-container {
  padding-top: 14px; }
  #procesos-template .arg-container > * {
    margin: 4px; }
    #procesos-template .arg-container > *:not(:first-child) {
      margin-left: 8px; }
    #procesos-template .arg-container > *:not(:last-child) {
      margin-right: 8px; }
  #procesos-template .arg-container:last-of-type {
    margin-bottom: 8px; }

#procesos-template .groupContainer {
  border: 1px solid black;
  margin: 10px;
  min-width: 270px; }
  #procesos-template .groupContainer .groupHeader {
    padding: 5px 0 5px 20px;
    background-color: black;
    color: white; }
  #procesos-template .groupContainer .groupBody {
    padding: 10px;
    max-height: 15em;
    position: relative;
    overflow: auto; }
    #procesos-template .groupContainer .groupBody md-checkbox, #procesos-template .groupContainer .groupBody md-checkbox:last-of-type {
      margin: 3px; }
  #procesos-template .groupContainer .md-icon-button {
    margin: 0;
    padding: 0;
    height: auto; }
    #procesos-template .groupContainer .md-icon-button .icon {
      color: whitesmoke; }

#tasks input {
  background-color: #f7f7f7; }

#tasks md-badge {
  margin: 0 0 0 8px;
  /*background-color: #4288bc;*/
  color: white;
  padding: 3px 6px;
  border-radius: 4px; }

#tasks .type-selector > div {
  padding: 8px;
  cursor: pointer; }
  #tasks .type-selector > div:hover:not(.md-warn-bg) {
    background: lightgray; }

#tasks md-list-item:hover {
  background: lightgray; }

#tasks md-list-item .md-avatar {
  border-radius: 0; }

#tasks md-list-item .md-list-item-text * {
  white-space: normal; }

#task-template .arg-container {
  padding-top: 14px; }
  #task-template .arg-container > * {
    margin: 4px; }
    #task-template .arg-container > *:not(:first-child) {
      margin-left: 8px; }
    #task-template .arg-container > *:not(:last-child) {
      margin-right: 8px; }
  #task-template .arg-container:last-of-type {
    margin-bottom: 8px; }

#task-template .groupContainer {
  border: 1px solid black;
  margin: 10px;
  min-width: 270px; }
  #task-template .groupContainer .groupHeader {
    padding: 5px 0 5px 20px;
    background-color: black;
    color: white; }
  #task-template .groupContainer .groupBody {
    padding: 10px;
    max-height: 15em;
    position: relative;
    overflow: auto; }
    #task-template .groupContainer .groupBody md-checkbox, #task-template .groupContainer .groupBody md-checkbox:last-of-type {
      margin: 3px; }
  #task-template .groupContainer .md-icon-button {
    margin: 0;
    padding: 0;
    height: auto; }
    #task-template .groupContainer .md-icon-button .icon {
      color: whitesmoke; }

#task-template .info-temp {
  padding: 4px;
  border-radius: 8px;
  font-size: 14px; }
